import React, { Suspense, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  type CCProcessorConfiguration,
  CCProcessorConfigurationMap,
} from "./CCProcessorConfigurationMap";
import {
  proceedToCheckout,
  updateOrderStatus,
  updateOrderStatusWithError,
} from "actions/online_ordering/checkout";
import { useOloOrderSuccess } from "hooks/online_ordering/useOloOrderSuccess";

export interface OLOPaymentReturnUrlAuthorizationProps {
  paymentProcessor: string;
  onFinalize?: () => void;
}

export const OLOPaymentReturnUrlAuthorization = ({
  paymentProcessor,
  onFinalize,
}: OLOPaymentReturnUrlAuthorizationProps) => {
  const dispatch = useDispatch();

  // Switches to Checkout page on landing return url.
  useEffect(() => {
    dispatch(proceedToCheckout(["credit"]));
  }, [dispatch]);

  const handlePaymentSuccess = useOloOrderSuccess();
  const handleSuccess = useCallback(async () => {
    await handlePaymentSuccess();
    onFinalize?.();
  }, [handlePaymentSuccess, onFinalize]);

  const handleError = useCallback(
    (errorMessage: string) => {
      dispatch(updateOrderStatus("pending"));
      dispatch(updateOrderStatusWithError("submitOrder", errorMessage, false));
      onFinalize?.();
    },
    [dispatch, onFinalize]
  );

  const ccProcessorConfiguration: CCProcessorConfiguration | undefined =
    CCProcessorConfigurationMap[paymentProcessor];
  const PaymentReturnUrlAuthorizationImpl =
    ccProcessorConfiguration?.returnUrlAuthorization;

  useEffect(() => {
    if (!PaymentReturnUrlAuthorizationImpl) {
      onFinalize?.();
    }
  }, [PaymentReturnUrlAuthorizationImpl, onFinalize]);

  if (!PaymentReturnUrlAuthorizationImpl) {
    return null;
  }
  return (
    <Suspense fallback={<></>}>
      <PaymentReturnUrlAuthorizationImpl
        onSuccess={handleSuccess}
        onError={handleError}
      />
    </Suspense>
  );
};
