import { useMemo } from "react";
import { useMobilePayParameters } from "./useMobilePayParameters";
import { BASE_URL } from "api_urls";
import getBaseUri from "get_base_uri";

export const useMobilePayUrl = (fullUrl?: boolean) => {
  const mobilePayParameters = useMobilePayParameters();
  return useMemo(
    () =>
      `${fullUrl ? BASE_URL : ""}${getBaseUri()}/pay?${mobilePayParameters.toString()}`,
    [fullUrl, mobilePayParameters]
  );
};
