import { useMemo } from "react";
import { BASE_URL } from "api_urls";
import { usePrettyUrl } from "helpers/hooks/usePrettyUrl";

export const useDigitalGiftCardUrl = (fullUrl?: boolean) => {
  const prettyUrl = usePrettyUrl();
  return useMemo(
    () => `${fullUrl ? BASE_URL : ""}/b/${prettyUrl}/gift-card`,
    [fullUrl, prettyUrl]
  );
};
