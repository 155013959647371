import styled, { css } from "styled-components";

export const creditCardGridLayout = `
    "cardNumber cardNumber"
    "expiration cvv"
`;

export const grid = css<{
  includeEmailField?: boolean;
  includePhoneField?: boolean;
}>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 16px;
`;

export const StyledFormWrapper = styled.div<{
  includeEmailField?: boolean;
  includePhoneField?: boolean;
  ccInputsGridLayout?: string;
}>`
  font-size: 1rem; // 14px based on 14px root font-size; decrease to .875rem for 16px root
  line-height: 1.5rem; // ~22.5px based on 14px root font-size; decrease to 1.33rem for 16px root
  ${grid};
  grid-template-areas:
    "header header"
    "firstName lastName"
    ${({ ccInputsGridLayout }) => ccInputsGridLayout ?? creditCardGridLayout}
    "address1 address1"
    "address2 address2"
    "city city"
    "state zip"
    ${props => props.includeEmailField && "email email"}
    ${props => props.includePhoneField && "phone phone"}
    "terms terms"
    "button button";
`;
