import type { Money } from "@upserve/financials";
import { useMutation } from "react-query";
import uuid from "uuid/v4";
import type { AdditionalPaymentData } from "components/mobile_pay/payment/CCProcessorConfigurationMap";
import { transformCardForProcessing } from "helpers/transforms/mobile_pay";
import { postMobilePayCreditCardPayment } from "interfaces/mobile_pay";
import type {
  CheckInfo,
  MobilePayCreditCardInfo,
  PaymentForProcessing,
  ProcessedPaymentInfo,
} from "types/mobile_pay";

export interface SubmitMobilePayPaymentParams {
  checkInfo: CheckInfo;
  total: Money;
  balanceDue: Money;
  tip: Money;
  additionalPaymentData: AdditionalPaymentData | void;
  cardInfo: MobilePayCreditCardInfo;
}

export const useSubmitMobilePayPayment = () => {
  return useMutation<
    ProcessedPaymentInfo,
    unknown,
    SubmitMobilePayPaymentParams
  >(
    async ({
      checkInfo,
      total,
      balanceDue,
      tip,
      additionalPaymentData,
      cardInfo,
    }) => {
      const submissionId = uuid();
      const paymentRequestInfo: PaymentForProcessing =
        transformCardForProcessing(
          checkInfo,
          total,
          balanceDue,
          cardInfo,
          tip,
          submissionId,
          additionalPaymentData
        );
      return postMobilePayCreditCardPayment(paymentRequestInfo);
    }
  );
};
