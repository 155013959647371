import type { Money } from "@upserve/financials";

export enum SuggestedTipSelection {
  LowSuggestedTip = 1,
  MediumSuggestedTip = 2,
  HighSuggestedTip = 3,
  NoTip = 4,
}

export interface RestaurantTipSettings {
  customTipsEnabled: boolean;
  showSuggestedTips: boolean;
  suggestedTipDefault: SuggestedTipSelection;
  suggestedTipPercentHigh: number;
  suggestedTipPercentLow: number;
  suggestedTipPercentMiddle: number;
}

// copied from client code:
// https://github.com/upserve/point_of_sale/blob/master/src/helpers/percent_formatters.ts
const percentFormat = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
const formatPercent = (value: number) => {
  return percentFormat.format(value / 100);
};

// copied from client code:
// https://github.com/upserve/point_of_sale/blob/master/src/components/card_payments/tip_signature.tsx#L114-L116
const getTipAmount = (paymentAmount: Money, percent: number): Money => {
  return paymentAmount.multiply(percent / 100);
};

type TipOption = {
  label: string;
  monetaryValue: Money;
  tipSelection: SuggestedTipSelection;
};

export function generateTipOptions(
  tipOptionSettings: RestaurantTipSettings,
  preTaxPaymentAmount: Money
): Array<TipOption> {
  const {
    suggestedTipPercentHigh,
    suggestedTipPercentLow,
    suggestedTipPercentMiddle,
  } = tipOptionSettings;

  return [
    {
      label: formatPercent(suggestedTipPercentLow),
      monetaryValue: getTipAmount(preTaxPaymentAmount, suggestedTipPercentLow),
      tipSelection: SuggestedTipSelection.LowSuggestedTip,
    },
    {
      label: formatPercent(suggestedTipPercentMiddle),
      monetaryValue: getTipAmount(
        preTaxPaymentAmount,
        suggestedTipPercentMiddle
      ),
      tipSelection: SuggestedTipSelection.MediumSuggestedTip,
    },
    {
      label: formatPercent(suggestedTipPercentHigh),
      monetaryValue: getTipAmount(preTaxPaymentAmount, suggestedTipPercentHigh),
      tipSelection: SuggestedTipSelection.HighSuggestedTip,
    },
  ];
}

export function canAcceptTips(optionSettings: RestaurantTipSettings): boolean {
  return optionSettings.showSuggestedTips || optionSettings.customTipsEnabled;
}
