import moment from "moment-timezone";
import {
  addIndex,
  any,
  append,
  clone,
  contains,
  curry,
  dropWhile,
  equals,
  filter,
  find,
  findIndex,
  flatten,
  indexOf,
  isEmpty,
  isNil,
  keys,
  lt,
  map,
  not,
  path,
  pipe,
  pluck,
  propEq,
  repeat,
  sort,
  takeWhile,
} from "ramda";

import { getPreviewToken } from "get_base_uri";
import { selectedOptionOfAvailable } from "helpers/online_ordering/cart";
import createMomentRange from "helpers/order_times/create_range";
import TIMEZONES from "helpers/timezones";
import { SORT_DAYS } from "helpers/transforms/online_ordering/olo_store_info_transform";

const diff = (a, b) => a - b;

const mapIndexed = addIndex(map);

function nowIsWithinTimeRange(ranges) {
  return any(equals(true))(
    map(range => createMomentRange(range).isCurrent(), ranges)
  );
}

function isValidOrderTime(orderTimes) {
  // always accept orders in preview mode
  if (!isNil(getPreviewToken())) {
    return true;
  }

  // Matches format used in orderTimes keys, e.g. "sun", "mon", etc.
  const todayKey = moment().format("ddd").toLowerCase();

  // 1. Get today's order times
  const todayIndex = SORT_DAYS[todayKey];
  const todayOrderTimes = orderTimes[todayIndex] || {};
  const { timeRanges: today = [] } = todayOrderTimes;

  // 2. Get yesterday's order times
  let yesterdayIndex = todayIndex - 1;
  // If today is Sunday (index = 0), yesterday is Saturday (index = 6)
  if (todayIndex === SORT_DAYS.sun) {
    yesterdayIndex = SORT_DAYS.sat;
  }
  const yesterdayOrderTimes = orderTimes[yesterdayIndex] || {};
  let { timeRanges: yesterday = [] } = yesterdayOrderTimes;
  yesterday = yesterday.map(ranges =>
    // The entire weekly schedule of order times is set relative to today by
    // default inside olo_store_info_transform.js. So we need to manually
    // subtract 1 day in order to calculate yesterday's order times.
    ranges.map(range => range.clone().subtract(1, "days"))
  );

  // Time ranges can span across midnight (e.g. 10pm - 2am), so we need to check
  // both yesterday's and today's time ranges.
  return nowIsWithinTimeRange(yesterday) || nowIsWithinTimeRange(today);
}

function addMinsAndFindNextInterval(time, mins) {
  const timePlusPrep = moment(time).add(mins, "minutes");
  return moment(getFirstAvailableInterval(timePlusPrep));
}

function nextValidOrderDay(
  orderTimes,
  maxPrep = 0,
  day = moment().format("ddd").toLowerCase()
) {
  const dayDetails = find(propEq("day", day), orderTimes);

  // Are there any time ranges for this day?
  if (dayDetails) {
    const timeRangeIsLaterToday = map(
      range => createMomentRange(range).isFuture(),
      dayDetails.timeRanges
    );

    // Are there any time ranges for this day in the future?
    if (any(equals(true))(timeRangeIsLaterToday)) {
      const nextRangeIndex = findIndex(equals(true))(timeRangeIsLaterToday);
      const nextRangeStartAsMoment = dayDetails.timeRanges[nextRangeIndex][0];
      const nextAvailableWithPrep = addMinsAndFindNextInterval(
        nextRangeStartAsMoment,
        maxPrep
      );

      return {
        // eslint-disable-next-line no-underscore-dangle
        time: nextAvailableWithPrep.format("h:mmA z"),
        name: "today",
        timeAsMoment: nextAvailableWithPrep,
      };
    }

    let currentIndex = findIndex(propEq("day", dayDetails.day), orderTimes);
    let nextValidDay = orderTimes[(currentIndex + 1) % 7];
    // If it's Saturday (initial index of 6), we want to rollover to the beginning of the week to index 0)

    let attempts = 0;
    while (equals(nextValidDay.timeRanges.length, 0) && lt(attempts, 7)) {
      attempts += 1;

      if (equals(currentIndex, 6)) {
        currentIndex = 0;
        nextValidDay = orderTimes[currentIndex];
      } else {
        currentIndex += 1;
        nextValidDay = orderTimes[currentIndex];
      }
    }

    // timeRanges moment values have correct time but are always set to today:
    // use the `order` property to set the appropriate date
    const nextValidDayAsMoment =
      nextValidDay.order >= dayDetails.order
        ? moment(nextValidDay.timeRanges[0][0]).day(nextValidDay.order)
        : moment(nextValidDay.timeRanges[0][0])
            .day(nextValidDay.order)
            .add(1, "week");
    const nextAvailableWithPrep = addMinsAndFindNextInterval(
      nextValidDayAsMoment,
      maxPrep
    );
    return {
      time: nextAvailableWithPrep.format("h:mmA z"),
      name: `on ${nextValidDay.prettyName}`,
      timeAsMoment: nextAvailableWithPrep,
    };
  }

  const todaysOrderNum = SORT_DAYS[day];

  const validDaysWithCurrentDay = sort(
    diff,
    append(todaysOrderNum, pluck("order", orderTimes))
  );

  const currentIndex = indexOf(todaysOrderNum, validDaysWithCurrentDay);
  let nextValidDayOrderNum = validDaysWithCurrentDay[currentIndex + 1];

  // Is this the last day in our valid days array?
  if (equals(currentIndex, validDaysWithCurrentDay.length - 1)) {
    [nextValidDayOrderNum] = validDaysWithCurrentDay;
  }

  const nextValidDay = find(propEq("order", nextValidDayOrderNum), orderTimes);
  const nextAvailableWithPrep = addMinsAndFindNextInterval(
    nextValidDay.timeRanges[0][0],
    maxPrep
  );

  return {
    time: nextAvailableWithPrep.format("h:mmA z"),
    name: `on ${nextValidDay.prettyName}`,
  };
}

const formatDayOption = curry((timezone, day, index) => {
  const dayAsMoment = moment().tz(timezone).add(index, "days");
  return {
    ...day,
    dayAsMoment,
  };
});

const formatDayOptionLabel = ({ prettyName, dayAsMoment, timeRanges }) => {
  const orderAheadLabel = `${prettyName}, ${dayAsMoment.format("MMM Do")}`;
  let closedStatus = "";
  if (Array.isArray(timeRanges) && timeRanges.length === 0) {
    closedStatus = " (Closed)";
  }
  return `${orderAheadLabel}${closedStatus}`;
};

const isToday = curry((timezone, option) =>
  equals(moment().tz(timezone).format("dddd"), option.prettyName)
);

const isNotToday = curry((timezone, option) => not(isToday(timezone, option)));

function extendByWeeks(currentWeekSchedule, numberOfWeeks = 6) {
  return flatten(repeat(clone(currentWeekSchedule), numberOfWeeks));
}

const shiftWeekToStartWithCurrentDay = curry(
  (timezone, useExtendedOrderAhead, selectedFulfillmentDetails) => {
    const daysBeforeToday = takeWhile(
      isNotToday(timezone),
      selectedFulfillmentDetails.schedule
    );
    const daysAfterTodayInclusive = dropWhile(
      isNotToday(timezone),
      selectedFulfillmentDetails.schedule
    );

    const currentWeekSchedule = [
      ...daysAfterTodayInclusive,
      ...daysBeforeToday,
    ];
    return {
      ...selectedFulfillmentDetails,
      schedule: useExtendedOrderAhead
        ? extendByWeeks(currentWeekSchedule)
        : currentWeekSchedule,
    };
  }
);

const formatOrderAheadDates = curry((timezone, selectedFulfillmentDetails) => {
  return {
    ...selectedFulfillmentDetails,
    schedule: [
      ...mapIndexed(
        formatDayOption(timezone),
        selectedFulfillmentDetails.schedule
      ),
    ],
  };
});

function getFirstAvailableInterval(time) {
  if (equals(time.minute() % 15, 0)) {
    return time.clone();
  }

  const minutes = 15 - (time.minute() % 15);

  return time.clone().add(minutes, "minutes");
}

function createIncrementalTimesList(startTime, endTime) {
  const timeOptions = [];

  const mutableStartTime = startTime.clone();

  while (mutableStartTime.isSameOrBefore(endTime, "minute")) {
    timeOptions.push(mutableStartTime.format("h:mm A, z"));
    mutableStartTime.add(15, "minutes");
  }

  return timeOptions;
}

const generateListOfTimes = curry(
  (prepTime, orderAheadDate, timezone, range) => {
    const currentTime = moment().tz(timezone);

    if (
      isToday(timezone, orderAheadDate) &&
      currentTime.isSame(orderAheadDate.dayAsMoment, "day") &&
      currentTime.isAfter(range[0].tz(timezone))
    ) {
      const prepTimeFromNow = currentTime.clone().add(prepTime, "minutes");

      if (
        createMomentRange(range).isCurrent() &&
        prepTimeFromNow.isSameOrBefore(range[1].tz(timezone), "minute")
      ) {
        const startTime = getFirstAvailableInterval(prepTimeFromNow);

        return createIncrementalTimesList(startTime, range[1].tz(timezone));
      }
      return ["Try a later date"];
    }

    const startTime = getFirstAvailableInterval(
      range[0].tz(timezone).clone().add(prepTime, "minutes")
    );

    return createIncrementalTimesList(startTime, range[1].tz(timezone));
  }
);

function formatOrderAheadTimes(
  selectedFulfillmentDetails,
  orderAheadDate,
  timezone
) {
  if (!isEmpty(orderAheadDate)) {
    const selectedDaySchedule = find(
      ({ dayAsMoment }) =>
        dayAsMoment.isSame(orderAheadDate.dayAsMoment, "day"),
      selectedFulfillmentDetails.schedule
    );

    if (
      !selectedDaySchedule ||
      equals(selectedDaySchedule.timeRanges.length, 0)
    ) {
      return ["Closed"];
    }
    const assembledTimes = flatten(
      map(
        generateListOfTimes(
          selectedFulfillmentDetails.estimate[1],
          orderAheadDate,
          timezone
        ),
        selectedDaySchedule.timeRanges
      )
    );
    return [...assembledTimes, "Select a time"];
  }

  return ["Select a time"];
}

function formatOrderAheadOptions(
  selectedTransport,
  fulfillmentTypes,
  orderAheadDate,
  timezone,
  useExtendedOrderAhead
) {
  const selectedTransportName = selectedOptionOfAvailable(
    selectedTransport,
    fulfillmentTypes
  );

  const standardTimeZone = TIMEZONES[timezone];

  const transportDetailsWithDayLabels = pipe(
    find(propEq("name", selectedTransportName)),
    shiftWeekToStartWithCurrentDay(standardTimeZone, useExtendedOrderAhead),
    formatOrderAheadDates(standardTimeZone)
  )(fulfillmentTypes);

  return {
    times: formatOrderAheadTimes(
      transportDetailsWithDayLabels,
      orderAheadDate,
      standardTimeZone
    ),
    extended: transportDetailsWithDayLabels,
  };
}

function getCurrentDay(timezone) {
  return moment().tz(TIMEZONES[timezone]).format("ddd").toLowerCase();
}

function getCurrentTime(timezone) {
  const timezoneValue = TIMEZONES[timezone];
  return moment().tz(timezoneValue);
}

function targetWithinRange({
  startTimeHour,
  startTimeMinute,
  startTimeDayMoment,
  endTimeHour,
  endTimeMinute,
  endTimeDayMoment,
  targetTime,
  targetTimeDayMoment,
}) {
  const startDate = moment(startTimeDayMoment);
  const formattedStartDateTime = startDate
    .set("hour", startTimeHour)
    .set("minute", startTimeMinute);

  const endDate = moment(endTimeDayMoment);
  const formattedEndDateTime = endDate
    .set("hour", endTimeHour)
    .set("minute", endTimeMinute);

  const formattedTargetTime = equals(targetTime, "")
    ? moment()
    : moment(targetTime, "hh:mm a");
  const targetTimeHour = formattedTargetTime.hour();
  const targetTimeMinute = formattedTargetTime.minute();
  const formattedTargetDateTime = moment(targetTimeDayMoment)
    .set("hour", targetTimeHour)
    .set("minute", targetTimeMinute);

  return formattedTargetDateTime.isBetween(
    formattedStartDateTime,
    formattedEndDateTime,
    "minute",
    []
  );
}

function withinValidOrderAheadRange({
  targetDayAsMoment,
  targetTime,
  targetDayName,
  timingMask,
}) {
  if (!targetDayAsMoment) {
    return false;
  }

  if (timingMask.startDate) {
    const startDate = moment(timingMask.startDate);
    if (moment(targetDayAsMoment).isBefore(startDate, "day")) {
      return false;
    }
  }
  if (timingMask.endDate) {
    const endDate = moment(timingMask.endDate);
    if (moment(targetDayAsMoment).isAfter(endDate, "day")) {
      return false;
    }
  }

  // check yesterday for timing masks that extend beyond midnight
  let isWithinYesterdayRange = false;
  const yesterdayAsMoment = moment(targetDayAsMoment).subtract(1, "days");
  const yesterdayDayName = yesterdayAsMoment.format("ddd").toLowerCase();
  if (contains(yesterdayDayName, keys(timingMask))) {
    isWithinYesterdayRange = any(range => {
      const [startTimeHour, startTimeMinute] = range[0].split(":");
      const [endTimeHour, endTimeMinute] = range[1].split(":");
      if (endTimeHour < startTimeHour) {
        return targetWithinRange({
          startTimeHour,
          startTimeMinute,
          startTimeDayMoment: yesterdayAsMoment,
          endTimeHour,
          endTimeMinute,
          endTimeDayMoment: targetDayAsMoment,
          targetTime,
          targetTimeDayMoment: targetDayAsMoment,
        });
      }
      return false;
    }, timingMask[yesterdayDayName]);
  }
  if (isWithinYesterdayRange) {
    return true;
  }

  if (!contains(targetDayName, keys(timingMask))) {
    return false;
  }

  return any(range => {
    const [startTimeHour, startTimeMinute] = range[0].split(":");
    const [endTimeHour, endTimeMinute] = range[1].split(":");
    const endDayAsMoment = moment(targetDayAsMoment);
    if (endTimeHour < startTimeHour) {
      endDayAsMoment.add(1, "days");
    }
    return targetWithinRange({
      startTimeHour,
      startTimeMinute,
      startTimeDayMoment: targetDayAsMoment,
      endTimeHour,
      endTimeMinute,
      endTimeDayMoment: endDayAsMoment,
      targetTime,
      targetTimeDayMoment: targetDayAsMoment,
    });
  }, timingMask[targetDayName]);
}

function findItemSections(id, menu) {
  return filter(section => {
    return any(sectionItem => {
      return equals(sectionItem.id, id);
    }, section.items);
  }, menu);
}

function itemTimeisValid(menu, id, dayAsMoment, targetTime, day) {
  const itemSections = findItemSections(id, menu);
  return any(section => {
    if (!section.timingMask) {
      return true;
    }
    if (!section.timingMask[day]) {
      return false;
    }
    return withinValidOrderAheadRange({
      targetDayAsMoment: dayAsMoment,
      targetTime,
      targetDayName: day,
      timingMask: section.timingMask,
    });
  }, itemSections);
}

function filterCart({ cart, menu, orderingOptions }) {
  let dayAsMoment = moment();
  let orderTime = getMomentFormattedForOrderAheadTime(moment(dayAsMoment));
  let day = moment(dayAsMoment).format("ddd").toLowerCase();
  if (!isEmpty(orderingOptions.orderAheadDate)) {
    const { orderAheadDate, orderAheadTime } = orderingOptions;
    orderTime = orderAheadTime;
    ({ dayAsMoment, day } = orderAheadDate);
  }
  const filteredCartItems = filter(item => {
    return itemTimeisValid(menu, item.id, dayAsMoment, orderTime, day);
  }, cart.cartItems);
  return { ...cart, cartItems: filteredCartItems };
}

function getDeliveryStartTimeForDate(orderAheadDate) {
  return path(["timeRanges", 0, 0], orderAheadDate);
}

function getDeliveryEndTimeForDate(orderAheadDate) {
  return path(["timeRanges", 0, 1], orderAheadDate);
}

function getTimeConstraintsForDaySchedule(daySchedule, readyByMax) {
  const constraints = {
    startTime: "00:00",
    endTime: "23:45",
  };
  if (!isEmpty(daySchedule)) {
    let specificStartTime = moment(
      getDeliveryStartTimeForDate(daySchedule)
    ).add(readyByMax, "minutes");
    const specificEndTime = getDeliveryEndTimeForDate(daySchedule);
    if (daySchedule.dayAsMoment.isSame(moment(), "day")) {
      // When placing an order for today, adjust the start time to exclude
      // the portion of the day which has already elapsed.
      const remainder = 15 - (moment().minute() % 15);
      const nextValidTime = moment()
        .add(remainder, "minutes")
        .add(readyByMax, "minutes");
      if (!specificStartTime || nextValidTime.isAfter(specificStartTime)) {
        specificStartTime = nextValidTime;
      }
    }
    if (specificStartTime) {
      constraints.startTime = specificStartTime.format("HH:mm");
    }
    if (specificEndTime) {
      constraints.endTime = specificEndTime.format("HH:mm");
    }
  }
  return constraints;
}

function getFormattedForOrderAheadTime(time, timezone) {
  let hours = time.split(":")[0];
  const mins = time.split(":")[1];
  let meridian = "AM";
  if (hours >= 12) {
    meridian = "PM";
  }
  if (hours > 12) {
    hours -= 12;
  }
  return `${hours}:${mins} ${meridian}, ${timezone}`;
}

function getMomentFormattedForOrderAheadTime(time) {
  return time.format("h:mm A, z");
}

function getOrderAheadTimeAsMoment(orderAheadTime) {
  const time = orderAheadTime.split(" ")[0];
  let meridian = orderAheadTime.split(" ")[1];
  meridian = meridian.replace(/,\s*$/, "");
  let hour = time.split(":")[0];
  const minute = time.split(":")[1];
  if (meridian === "PM" && hour !== "12") {
    hour = parseInt(hour, 10) + 12;
  }
  return moment().set("hour", hour).set("minute", minute);
}

function getTimePickerFormatOrderAheadTime(orderAheadTime) {
  return getOrderAheadTimeAsMoment(orderAheadTime).format("HH:mm");
}

function isOrderAheadOrder({
  selectedTimeFrame,
  orderAheadTime,
  orderAheadDate,
}) {
  return (
    equals(selectedTimeFrame, "Later") &&
    not(equals(orderAheadTime, "")) &&
    not(equals(orderAheadDate, ""))
  );
}

export {
  createIncrementalTimesList, // used in tests only
  filterCart,
  formatDayOptionLabel,
  formatOrderAheadOptions,
  generateListOfTimes, // used in tests only
  getCurrentDay,
  getCurrentTime,
  getDeliveryEndTimeForDate,
  getDeliveryStartTimeForDate,
  getFirstAvailableInterval, // used in tests only
  getFormattedForOrderAheadTime,
  getMomentFormattedForOrderAheadTime,
  getTimeConstraintsForDaySchedule,
  getTimePickerFormatOrderAheadTime,
  isOrderAheadOrder,
  isValidOrderTime,
  itemTimeisValid,
  nextValidOrderDay,
  withinValidOrderAheadRange,
};
