import { equals, map, merge, omit } from "ramda";

import { LOG_OUT } from "actions/authentication";
import {
  DELETE_LOCAL_CARD_RECORD,
  RECEIVE_CREDIT_CARD,
  VALIDATE_CC_FIELD_ON_BLUR,
} from "actions/credit_cards";
import { UPDATE_CONSUMER_INFO } from "actions/online_ordering/consumer";
import { findCardByCardUuid, removeCardByCardUuid } from "helpers/credit_cards";

export const emptyNewCard = {
  // We need a temporary uuid to associate local data with before it is posted
  // The "add" button is hidden when the form is shown so no need to add an
  // incremental key
  address1: "",
  address2: "",
  cardNumber: "",
  cardType: "",
  cardUuid: "new-card",
  city: "",
  cvv: "",
  errors: null,
  expiryMonth: "",
  expiryYear: "",
  firstName: "",
  lastName: "",
  state: "",
  zip: "",
};

function creditCards(state = [], action) {
  switch (action.type) {
    case UPDATE_CONSUMER_INFO: {
      if (action.consumerInfo.cards) {
        return action.replace
          ? action.consumerInfo.cards
          : [...state, ...action.consumerInfo.cards];
      }

      return state;
    }

    case RECEIVE_CREDIT_CARD: {
      if (findCardByCardUuid(action.card.cardUuid, state)) {
        const cardsWithoutStaleRecord = removeCardByCardUuid(
          action.card.cardUuid,
          state
        );

        return [...cardsWithoutStaleRecord, action.card];
      }

      const cardsWithoutTempNewRecord = removeCardByCardUuid("new-card", state);
      return [...cardsWithoutTempNewRecord, action.card];
    }

    case VALIDATE_CC_FIELD_ON_BLUR: {
      if (action.error) {
        return map(card => {
          if (equals(card.cardUuid, action.card.cardUuid)) {
            return {
              ...card,
              errors: merge(card.errors, action.error),
            };
          }
          return card;
        }, state);
      }

      return map(card => {
        if (equals(card.cardUuid, action.card.cardUuid)) {
          return {
            ...card,
            errors: omit([action.field], card.errors),
          };
        }
        return card;
      }, state);
    }

    case DELETE_LOCAL_CARD_RECORD: {
      return removeCardByCardUuid(action.cardUuid, state);
    }

    case LOG_OUT: {
      return [];
    }

    default:
      return state;
  }
}

export default creditCards;
