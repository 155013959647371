import { type UseQueryOptions, useQuery } from "react-query";
import type { OloConsumerSettings } from "components/online_ordering/objects/OloConsumerSettings";
import type { IResponseError } from "helpers/ajax_request";
import { oloGetConsumerSettings } from "interfaces/online_ordering/accounts/oloGetConsumerSettings";

export const oloConsumerSettingsQueryKey = (storePrettyUrl: string) =>
  ["consumerSettings", storePrettyUrl] as const;

export const useOloConsumerSettings = (
  storePrettyUrl: string,
  options?: UseQueryOptions<
    OloConsumerSettings,
    IResponseError,
    OloConsumerSettings,
    readonly ["consumerSettings", string]
  >
) => {
  return useQuery(
    oloConsumerSettingsQueryKey(storePrettyUrl),
    async () => {
      return oloGetConsumerSettings(storePrettyUrl);
    },
    options
  );
};
