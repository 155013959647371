import styled from "styled-components";

export const StyledErrorMessage = styled.div`
  color: #e02948;
  position: absolute;
  top: 92px;
  left: 0;
  text-align: left;

  @media (max-width: 375px) {
    top: 86px;
  }
`;
