import type { DefaultTheme } from "styled-components";

// FIXME Add remaining theme values
const THEME: DefaultTheme = {
  colors: {
    blue: "#445a82",
    gray: "#212322",
    grayLight: "#5a5a5a",
    grayAccent: "#88888d",
    gray50: "#ededed",
  },
  border: {
    radius: "2px",
    grayMid: "1px solid #ededed",
  },
};

export default THEME;
