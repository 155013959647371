import React from "react";
import styled, { keyframes } from "styled-components";

interface ILoaderEllipsis {
  title: string;
}

const growDot = keyframes`
    0% {
        opacity: 0.7;
        border-width: 0;
        transform: translate(4px, 4px);
    }
    25% {
        opacity: 1;
        border-width: 4px;
        transform: translate(0, 0);
    }
    50% {
        opacity: 0.7;
        border-width: 0;
        transform: translate(4px, 4px);
    }
    100% {
        opacity: 0.6;
        border-width: 0;
    }
`;

const StyledLoaderEllipsis = styled.div`
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: 18px 18px 18px;
  justify-content: center;
  margin: auto;
  overflow: visible;
  padding: 10px 5px;

  span {
    width: 18px;
    height: 18px;
  }

  span::before {
    content: "";
    animation: ${growDot} 1.2s linear infinite;
    background-color: ${props => props.theme.colors.blue};
    border-radius: 50%;
    border: 0px solid ${props => props.theme.colors.blue};
    height: 10px;
    transform: translate(4px, 4px);
    opacity: 0.6;
    overflow: visible;
    width: 10px;
    display: block;
  }
  .b::before {
    animation-delay: 0.3s;
  }
  .c::before {
    animation-delay: 0.6s;
  }
`;

export default function LoaderEllipsis({ title }: ILoaderEllipsis) {
  return (
    <StyledLoaderEllipsis
      aria-busy
      aria-label={title}
      aria-live="polite"
      title={title}>
      <span className="a" />
      <span className="b" />
      <span className="c" />
    </StyledLoaderEllipsis>
  );
}
