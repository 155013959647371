import {
  flatten,
  lt,
  map,
  pathOr,
  pipe,
  pluck,
  reduce,
  sum,
  values,
} from "ramda";
import formatMoney from "sig/formatters/money";
import type {
  ICartItem,
  ICartModifier,
  ICartSide,
  ISelectedModifier,
} from "types/cart";
import type { IDetailedTotals, ISubtotalDetail } from "types/totals";

function calculateSubtotalForMods(item: ICartItem): ISubtotalDetail[] {
  return pipe<
    ISelectedModifier[],
    ICartModifier[][],
    ICartModifier[],
    ISubtotalDetail[]
  >(
    pluck("mods"),
    flatten,
    map(mod => ({
      name: mod.name,
      subtotal: mod.price * item.quantity,
    }))
  )(item.selectedModifiers);
}

// Client side tax calculation will soon be deprecated.
// See interfaces/caltulate_totals for backend calculation API.
function calculateTaxesForMods(item: ICartItem) {
  return pipe<
    ISelectedModifier[],
    ICartModifier[][],
    ICartModifier[],
    number[],
    number
  >(
    pluck("mods"),
    flatten,
    map(mod => {
      return mod.taxRate
        ? mod.price * parseFloat(mod.taxRate.percentageRate) * item.quantity
        : 0;
    }),
    sum
  )(item.selectedModifiers);
}

function calculateSubtotalForSides(item: ICartItem): ISubtotalDetail[] {
  return map(
    side => ({
      name: side.name,
      subtotal: side.price * item.quantity,
    }),
    item.selectedSides
  );
}

// Client side tax calculation will soon be deprecated.
// See interfaces/caltulate_totals for backend calculation API.
function calculateTaxesForSides(item: ICartItem) {
  return pipe<ICartSide[], number[], number[], number>(
    map(
      side =>
        side.price * parseFloat(side.taxRate.percentageRate) * item.quantity
    ),
    flatten,
    sum
  )(item.selectedSides);
}

function formatPrice(priceCents: number): string {
  return formatMoney(priceCents * 0.01);
}

function formatSignedPrice(priceCents: number): string {
  const formattedPrice = formatPrice(priceCents);
  if (lt(priceCents, 0)) {
    return formattedPrice;
  }
  return `+${formattedPrice}`;
}

function getItemDetailedTotals(item: ICartItem): IDetailedTotals {
  const base = item.quantity * item.price;
  const mods = calculateSubtotalForMods(item);
  const sides = calculateSubtotalForSides(item);
  return {
    base,
    mods,
    sides,
  };
}

function getItemSubtotal(item) {
  const detailed = getItemDetailedTotals(item);
  return pipe<
    IDetailedTotals,
    Array<IDetailedTotals[keyof IDetailedTotals]>,
    number[],
    number
  >(
    values,
    map(detail => {
      if (detail instanceof Array) {
        return sum(pluck("subtotal", detail));
      }
      return detail;
    }),
    sum
  )(detailed);
}

function getItemTotals(item: ICartItem) {
  const subtotal = getItemSubtotal(item);

  // Client side tax calculation will soon be deprecated.
  // See interfaces/caltulate_totals for backend calculation API.
  let tax =
    pathOr(0, ["taxRate", "percentageRate"], item) *
    (item.quantity * item.price);
  tax += calculateTaxesForMods(item);
  tax += calculateTaxesForSides(item);
  // @todo add modifier and sides totals and taxes here
  return { subtotal, tax };
}

function getTotals(
  items: ICartItem[],
  deliveryFee = 0,
  deliveryFeeTaxRate: string | number = 0
) {
  const deliveryFeeTaxRateNum =
    typeof deliveryFeeTaxRate === "string"
      ? parseFloat(deliveryFeeTaxRate)
      : deliveryFeeTaxRate;
  const deliveryTax = deliveryFee * deliveryFeeTaxRateNum;

  return reduce<ICartItem, { subtotal: number; tax: number }>(
    (acc, item) => {
      const { subtotal: itemSubtotal, tax: itemTax } = getItemTotals(item);

      return {
        subtotal: acc.subtotal + itemSubtotal,
        tax: acc.tax + itemTax,
      };
    },
    { subtotal: 0, tax: deliveryTax },
    items
  );
}

interface ITotalsWithCalculated {
  feeByMethod: number;
  menuTotal: number;
  orderTotal: number;
  promoDiscount: number;
  tax: number;
  total: number;
  upserveFee: number;
}

export {
  formatSignedPrice,
  formatPrice,
  getTotals,
  getItemDetailedTotals,
  getItemTotals,
  getItemSubtotal,
};
export default getTotals;
