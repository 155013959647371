import styled, { css } from "styled-components";

export interface MobilePayInputStylesProps {
  isFocused?: boolean;
  isError?: boolean;
}

const focusStyles = css`
  border-color: #5a5a5a;
`;

export const mobilePayInputStyles = css<MobilePayInputStylesProps>(
  ({ isFocused, isError }) => css`
    padding: 15px;
    @media (max-width: 375px) {
      padding: 12px;
    }
    @media (max-width: 320px) {
      padding: 10px;
    }

    font-size: 24px;
    border: 1px solid #ededed;

    &:focus {
      ${focusStyles};
    }
    ${isFocused ? focusStyles : undefined};

    ${isError
      ? css`
          border: 1px solid #e02948;
        `
      : undefined};
  `
);

export const StyledInput = styled.input<MobilePayInputStylesProps>`
  ${mobilePayInputStyles}
`;
