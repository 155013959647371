/* global APP_GIT_HASH */
import { TrackJS } from "trackjs";
import { TRACK_JS_APPLICATION_KEY, TRACK_JS_TOKEN } from "app_constants";
import Routes from "routes";

if (TrackJS && !TrackJS.isInstalled()) {
  TrackJS.install({
    token: TRACK_JS_TOKEN,
    application: TRACK_JS_APPLICATION_KEY,
    version: APP_GIT_HASH,
  });
}

Routes.dispatch();

if (module.hot) {
  module.hot.accept();
}
