import React from "react";
import styled from "styled-components";

import ConfirmationPage, {
  StyledConfirmationHeader,
  StyledConfirmationText,
} from "components/mobile_pay/views/confirmation_page";

import type { CheckInfo, MerchantInfo } from "types/mobile_pay";

const StyledCheckNumber = styled.span`
  font-weight: bold;
`;

type CheckPaidPageProps = {
  merchantInfo: MerchantInfo;
  checkInfo: CheckInfo;
};

export default function CheckPaidPage({
  merchantInfo,
  checkInfo,
}: CheckPaidPageProps) {
  return (
    <ConfirmationPage merchantInfo={merchantInfo}>
      <StyledConfirmationHeader>You're all set!</StyledConfirmationHeader>
      <StyledConfirmationText>
        Check #<StyledCheckNumber>{checkInfo.checkNumber}</StyledCheckNumber>{" "}
        has already been paid, so you can leave when you're ready.
      </StyledConfirmationText>
    </ConfirmationPage>
  );
}
