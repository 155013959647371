import classNames from "classnames";
import PropTypes from "prop-types";
import { contains, equals, lt, map, not, partial } from "ramda";
import React from "react";

import Checkbox from "components/checkbox";
import { withTracking } from "helpers/analytics";
import { getById } from "helpers/collections";
import { formatSignedPrice } from "helpers/online_ordering/item_totals";

function ModifierSection({
  section,
  item,
  onToggleModifier,
  missingRequiredSelection,
}) {
  const { minimumRequired, maximumRequired, modifiers, name } = section;
  const selectedModsGroup = getById(section.id, item.selectedModifiers);

  let sectionRules;
  const classRules = {
    restrictions: true,
  };

  if (equals(minimumRequired, 0)) {
    const maxMods =
      modifiers.length < maximumRequired ? modifiers.length : maximumRequired;
    sectionRules = `Optional. Choose up to ${maxMods}.`;
  }

  if (minimumRequired > 0) {
    sectionRules = `Required. Please choose ${minimumRequired}`;
    classRules.required =
      missingRequiredSelection &&
      lt(selectedModsGroup.mods.length, minimumRequired);
  }

  let maximumMessage;
  if (
    not(equals(maximumRequired, 0)) &&
    equals(selectedModsGroup.mods.length, maximumRequired)
  ) {
    maximumMessage = (
      <div className="max-reached">You've reached your maximum amount</div>
    );
  }

  return (
    <div className="section modifier-section">
      <div className="section-title">
        <h2>{name}</h2>
        <div className={classNames(classRules)}>{sectionRules}</div>
        {maximumMessage}
      </div>
      <div className="options">
        {map(
          modifier => (
            <div key={modifier.id} className="modifier">
              <Checkbox
                label={modifier.name}
                checked={contains(modifier, selectedModsGroup.mods)}
                onChangeHandler={withTracking(
                  partial(onToggleModifier, [modifier, section]),
                  "Select Mod",
                  modifier
                )}
              />
              <span className="mod-price">
                {formatSignedPrice(modifier.price)}
              </span>
            </div>
          ),
          modifiers
        )}
      </div>
    </div>
  );
}

ModifierSection.propTypes = {
  section: PropTypes.shape({
    minimumRequired: PropTypes.number.isRequired,
    maximumRequired: PropTypes.number.isRequired,
    modifiers: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  item: PropTypes.object.isRequired,
  onToggleModifier: PropTypes.func.isRequired,
  missingRequiredSelection: PropTypes.bool,
};

export default ModifierSection;
