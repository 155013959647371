import PropTypes from "prop-types";
import { map, not, partial, pipe } from "ramda";
import React from "react";
import formatMoney from "sig/formatters/money";

import Button from "components/button";
import Modal from "components/modal";

import ModifierSection from "components/online_ordering/item_modal/modifier_section";
import QuantitySelector from "components/online_ordering/item_modal/quantity_selector";
import SideSection from "components/online_ordering/item_modal/side_section";
import { trackAnalytic, withTracking } from "helpers/analytics";
import { getTargetValue } from "helpers/event";
import {
  allMinimumsMet,
  getMaxAllowedQty,
  scrollToMissingRequirements,
} from "helpers/online_ordering/item_modal";
import { getItemSubtotal } from "helpers/online_ordering/item_totals";

class ItemModal extends React.Component {
  constructor(props) {
    super(props);
    this.specialInstructionsCharacterLimit = 500;
    this.charsLeft = 500;
    this.getCurrentLength = this.getCurrentLength.bind(this);
    this.addItem = this.addItem.bind(this);
    this.showMissingRequiredSelection =
      this.showMissingRequiredSelection.bind(this);
    this.state = { missingRequiredSelection: false };
  }

  componentDidMount() {
    const { item } = this.props;
    trackAnalytic("View Item", item);
  }

  componentDidUpdate() {
    const { missingRequiredSelection } = this.state;
    if (missingRequiredSelection) {
      scrollToMissingRequirements(this.modalContent);
    }
  }

  getCurrentLength(e) {
    this.charsLeft =
      this.specialInstructionsCharacterLimit - e.instructions.length;
  }

  addItem(event) {
    event.stopPropagation();
    const { onAddItem, item } = this.props;
    onAddItem(item);

    trackAnalytic("Add Item", item);
    if (item.instructions) {
      trackAnalytic("Add Instructions", item);
    }
  }

  showMissingRequiredSelection() {
    this.setState({ missingRequiredSelection: true });
  }

  render() {
    const {
      onClose,
      onToggleModifier,
      onToggleSide,
      onIncrementItemQuantity,
      onDecrementItemQuantity,
      onEditInstructions,
      item,
      imageUrl,
    } = this.props;
    const { missingRequiredSelection } = this.state;

    let modifiers;
    if (item.modifierGroups.length > 0) {
      modifiers = map(
        modSection => (
          <ModifierSection
            key={modSection.id}
            section={modSection}
            item={item}
            onToggleModifier={onToggleModifier}
            missingRequiredSelection={missingRequiredSelection}
          />
        ),
        item.modifierGroups
      );
    }

    let sides;
    if (item.sides.length > 0) {
      sides = (
        <SideSection
          sides={item.sides}
          item={item}
          onToggleSide={onToggleSide}
          missingRequiredSelection={missingRequiredSelection}
        />
      );
    }

    let bannerImage;
    if (imageUrl) {
      const scaledBackgroundCover = {
        backgroundImage: `url('${imageUrl}')`,
      };

      bannerImage = (
        <div className="item-image-container">
          <div
            className="item-background-overlay"
            style={scaledBackgroundCover}
          />
          <div className="item-blurred-background" />
          <img className="item-image-main" src={imageUrl} />
        </div>
      );
    }

    return (
      <Modal className="item-modal" title={item.name} onClose={onClose}>
        <div
          className="modal-content"
          ref={mc => {
            this.modalContent = mc;
          }}>
          {bannerImage}
          <div className="description-full">{item.description}</div>

          <div className="section quantity">
            <div className="title">How many would you like?</div>
            <QuantitySelector
              quantity={item.quantity}
              maxAllowed={getMaxAllowedQty(item)}
              onDecrementItemQuantity={withTracking(
                onDecrementItemQuantity,
                "Decrement Quantity",
                { name: item.name, previous_quantity: item.quantity }
              )}
              onIncrementItemQuantity={withTracking(
                onIncrementItemQuantity,
                "Increment Quantity",
                { name: item.name, previous_quantity: item.quantity }
              )}
            />
          </div>

          {sides}
          {modifiers}

          <div className="section instructions">
            <h2>Special instructions</h2>
            <textarea
              className="instructions-input"
              placeholder="Want your order prepared a certain way? Just let us know!"
              maxLength={this.specialInstructionsCharacterLimit}
              value={item.instructions}
              onChange={pipe(
                getTargetValue,
                partial(onEditInstructions, []),
                this.getCurrentLength
              )}
            />
            <span className="length-left">
              {this.charsLeft} characters left
            </span>
          </div>
        </div>

        <div className="item-modal-footer">
          <div className="subtotal">
            Item total: {formatMoney(getItemSubtotal(item) * 0.01)}
          </div>
          <Button
            onClick={this.addItem}
            onDisabledClick={this.showMissingRequiredSelection}
            size="medium"
            disabled={not(allMinimumsMet(item))}>
            Add to order
          </Button>
        </div>
      </Modal>
    );
  }
}

ItemModal.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    taxRate: PropTypes.object.isRequired,
    quantity: PropTypes.number,
    description: PropTypes.string,
    instructions: PropTypes.string,
    selectedSides: PropTypes.array,
  }).isRequired,
  onAddItem: PropTypes.func.isRequired,
  onEditOrderOptions: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onToggleModifier: PropTypes.func.isRequired,
  onToggleSide: PropTypes.func.isRequired,
  onIncrementItemQuantity: PropTypes.func.isRequired,
  onDecrementItemQuantity: PropTypes.func.isRequired,
  onEditInstructions: PropTypes.func.isRequired,
  imageUrl: PropTypes.string,
};

export default ItemModal;
