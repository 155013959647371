import React from "react";

import { OrderCompleteModal } from "./OrderCompleteModal";

import { OrderSubmittingModal } from "./OrderSubmittingModal";
import { useOloSelector } from "hooks/online_ordering/useOloSelector";

export const OrderConfirmationModal = () => {
  const orderStatus = useOloSelector(
    state => state.checkout?.status.orderStatus
  );

  if (orderStatus === "submitting") {
    return <OrderSubmittingModal />;
  }

  if (orderStatus === "success") {
    return <OrderCompleteModal />;
  }

  return null;
};
