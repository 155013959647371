import type {
  MobilePayFirstDataFormValues,
  MobilePayFormValues,
} from "interfaces/mobile_pay/MobilePayFormValues";

export const getFirstDataInitialValues = (
  formValues: MobilePayFormValues
): MobilePayFirstDataFormValues => {
  return {
    ...formValues,
    cardNumber: "",
    cvv: "",
    expiryMonth: "",
    expiryYear: "",
  };
};
