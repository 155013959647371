import { connect } from "react-redux";

import {
  getUserGeolocation,
  handleDeliveryAddressChange,
  setAddressValidationStatus,
  updateAddressSelection,
} from "actions/online_ordering/delivery_address";
import { updateDeliveryAddress } from "actions/online_ordering/ordering_options";

import DeliveryAddressEditor from "components/online_ordering/cart/delivery_address_editor";

function mapStateToProps(state) {
  return {
    deliveryAddress: state.deliveryAddress,
    deliveryGeojson: state.storeInfo.deliveryGeojson, // Pass in
    contactInfo: state.consumer.contactInfo,
  };
}

const mapDispatchToProps = {
  handleMobileGeolocationAddressEntry: getUserGeolocation,
  updateAddressSelection,
  updateDeliveryAddress,
  setAddressValidationStatus,
  handleDeliveryAddressChange,
};

const DeliveryAddressEditorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryAddressEditor);

export default DeliveryAddressEditorContainer;
