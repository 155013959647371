import PropTypes from "prop-types";
import React from "react";

function LoyaltyImage({ type, imagePath }) {
  if (imagePath) {
    return (
      <div
        className="reward-photo"
        style={{ backgroundImage: `url(${imagePath})` }}
      />
    );
  }
  return (
    <div
      className="reward-photo"
      style={{
        backgroundImage: `url(/public/images/loyalty/${type}_main.png)`,
      }}
    />
  );
}

LoyaltyImage.defaultProps = {
  type: "loyalty",
};

LoyaltyImage.propTypes = {
  type: PropTypes.oneOf(["welcome", "loyalty"]),
  imagePath: PropTypes.string,
};

export default LoyaltyImage;
