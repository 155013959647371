import { pick } from "ramda";

import VALIDATION_MESSAGES from "helpers/validation/messages";
import Validators from "helpers/validation/validators";

const newCardValidators = {
  address1: Validators.isNotEmpty,
  cardNumber: Validators.isValidCardNumber,
  city: Validators.isNotEmpty,
  cvv: Validators.isValidCvv,
  expiryMonth: Validators.isValidMonth,
  expiryYear: Validators.isValidExpirationYear,
  firstName: Validators.isNotEmpty,
  lastName: Validators.isNotEmpty,
  state: Validators.isNotEmpty,
  zip: Validators.isValidZipCode,
};

function validateCardDetails(card, validators = newCardValidators) {
  const errors = [];
  if (!Validators.isValid(card, validators, errors)) {
    return pick(errors, VALIDATION_MESSAGES);
  }

  return null;
}

function validateFormDetails(form, validators, messages = VALIDATION_MESSAGES) {
  const errors = [];
  if (!Validators.isValid(form, validators, errors)) {
    return pick(errors, messages);
  }

  return null;
}

function validatePasswordDetails(
  passwordDetails,
  passValidators,
  errorMessages
) {
  const errors = [];
  if (!Validators.isValid(passwordDetails, passValidators, errors)) {
    return pick(errors, errorMessages);
  }
  if (passwordDetails.newPassword !== passwordDetails.confirmPassword) {
    return pick(["confirmPassword"], VALIDATION_MESSAGES);
  }
  return null;
}

function validateInitialPasswordDetails(
  passwordDetails,
  passValidators,
  errorMessages
) {
  const errors = [];
  if (!Validators.isValid(passwordDetails, passValidators, errors)) {
    return pick(errors, errorMessages);
  }
  if (passwordDetails.password !== passwordDetails.confirmPassword) {
    return pick(["confirmPassword"], VALIDATION_MESSAGES);
  }
  return null;
}

function validateEmail(email, emailValidators, errorMessages) {
  const errors = [];
  if (!Validators.isValid({ email }, emailValidators, errors)) {
    return pick(errors, errorMessages);
  }
  return null;
}

function validateBlurredField(field, form, validators, messages) {
  const fieldValidator = pick([field], validators);
  const errors = [];

  if (!Validators.isValid(form, fieldValidator, errors)) {
    return pick(errors, messages);
  }

  return null;
}

export {
  validateCardDetails,
  validateBlurredField,
  newCardValidators,
  validatePasswordDetails,
  validateInitialPasswordDetails,
  validateEmail,
  validateFormDetails,
};
