import React, { memo } from "react";
import { OLOPaymentReturnUrlAuthorization } from "./OLOPaymentReturnUrlAuthorization";
import { useOLOUrl } from "hooks/online_ordering/useOLOUrl";
import { usePaymentAuthorization } from "hooks/payment/usePaymentAuthorization";

const OLOPaymentAuthorizationNonMemoized = () => {
  const oloUrl = useOLOUrl();
  const { paymentProcessor, navigateToBase } = usePaymentAuthorization(oloUrl);

  if (!paymentProcessor) {
    return null;
  }

  return (
    <OLOPaymentReturnUrlAuthorization
      paymentProcessor={paymentProcessor}
      onFinalize={navigateToBase}
    />
  );
};

export const OLOPaymentAuthorization = memo(OLOPaymentAuthorizationNonMemoized);
