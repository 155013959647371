import Aviator from "aviator";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";

import { LoyaltyCreditCardList } from "./loyalty/credit_cards/LoyaltyCreditCardList";
import Header from "components/header";
import Loader from "components/loader";

import OrderHistoryContainer from "containers/account/order_history_container";
import ContactInfoContainer from "containers/loyalty/account/contact_info_container";
import PasswordContainer from "containers/loyalty/account/password_container";
import getBaseUri from "get_base_uri";

function Account({ request, store }) {
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: { queries: { staleTime: 5000 * 60 } },
      }),
    []
  );

  if (!store || !store.getState() || !store.getState().consumer) {
    return (
      <div className="app account">
        <Loader />
      </div>
    );
  }

  const state = store.getState();
  document.title = state.brandInfo.locationName;

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <div className="app account">
          <Header
            locationInfo={state.brandInfo || state.storeInfo}
            onHeadingClick={() => Aviator.navigate(getBaseUri())}
          />

          <a className="back-button navigate" href={getBaseUri(request)}>
            &lt; Back
          </a>
          <div className="content">
            <div className="account-settings card main-view">
              <ContactInfoContainer />
              <PasswordContainer />
              <LoyaltyCreditCardList />
              <OrderHistoryContainer />
            </div>
          </div>
        </div>
      </QueryClientProvider>
    </Provider>
  );
}

Account.propTypes = {
  request: PropTypes.object.isRequired,
  store: PropTypes.object,
};

export default Account;
