import { Money } from "@upserve/financials";
import { Form, Formik } from "formik";
import { equals } from "ramda";
import React, { Suspense, useCallback, useRef, useState } from "react";
import styled from "styled-components";

import { TrackJS } from "trackjs";
import { MobilePayPaymentError } from "./MobilePayPaymentError";
import GratuityComponent from "components/gratuity_component";
import Loader from "components/loader";
import { MobilePayInput } from "components/mobile_pay/inputs/MobilePayInput";
import {
  CCProcessorConfigurationMap,
  type SubmitHandler,
} from "components/mobile_pay/payment/CCProcessorConfigurationMap";
import { StyledWrapper } from "components/mobile_pay/views/page";
import { CreditCardProcessLoading } from "components/payment/CreditCardProcessLoading";
import type { SuggestedTipSelection } from "helpers/gratuity";
import { usePrettyUrl } from "helpers/hooks/usePrettyUrl";
import VALIDATION_MESSAGES from "helpers/validation/messages";
import Validators from "helpers/validation/validators";
import { useAdyenMobilePayPaymentAccess } from "hooks/mobile_pay/payment/adyen/useAdyenMobilePayPaymentAccess";
import { useSubmitMobilePayPayment } from "hooks/mobile_pay/useSubmitMobilePayPayment";
import type { MobilePayFormValues } from "interfaces/mobile_pay/MobilePayFormValues";

import type {
  CheckInfo,
  MerchantInfo,
  ProcessedPaymentInfo,
} from "types/mobile_pay";
import type { CCProcessor } from "types/payments/credit_card";

const StyledPaymentHeading = styled.h1`
  margin: 0 0 30px;

  color: #000;

  font-size: 24px;
  font-weight: bold;
`;

export const StyledLabel = styled.label`
  align-self: flex-start;
  margin-bottom: 9px;
  min-height: 18px;

  color: #5a5a5a;

  font-size: 16px;
  font-weight: bold;
`;

const StyledFormButton = styled.div`
  display: flex;
  min-height: 4rem;

  flex-direction: column;

  button {
    flex: 1;

    font-size: 20px;
    font-weight: bold;
    background: #3e5b83;
  }

  button:disabled {
    cursor: pointer;
    background-color: #b5b5b5;
  }
`;

export interface MobilePayCreditCardFormProps {
  merchantInfo: MerchantInfo;
  checkInfo: CheckInfo;
  onPaymentSuccess: (processedPaymentInfo: ProcessedPaymentInfo) => void;
  onPaymentError: (error: string) => void;
  error: string | null;
}

export function MobilePayCreditCardForm({
  merchantInfo,
  checkInfo,
  onPaymentSuccess,
  onPaymentError,
  error,
}: MobilePayCreditCardFormProps) {
  const prettyUrl = usePrettyUrl();
  const { data: isAdyenEnabled } = useAdyenMobilePayPaymentAccess(prettyUrl);
  const ccProcessor: CCProcessor = isAdyenEnabled ? "adyen" : "firstData";
  const ccProcessorConfiguration = CCProcessorConfigurationMap[ccProcessor];

  const { mutateAsync: submitPayment, data: paymentResponse } =
    useSubmitMobilePayPayment();

  const [tip, setTip] = useState<Money>(Money(0));

  const [selectedTipOption, setSelectedTipOption] = useState<
    SuggestedTipSelection | undefined
  >(undefined);

  const [isTipValid, setIsTipValid] = useState(true);

  function validateFirstName(name) {
    return !Validators.isNotEmpty(name) ? VALIDATION_MESSAGES.firstName : null;
  }

  function validateLastName(name) {
    return !Validators.isNotEmpty(name) ? VALIDATION_MESSAGES.lastName : null;
  }

  function validateEmail(email) {
    return !Validators.isValidEmail(email) ? VALIDATION_MESSAGES.email : null;
  }

  function validatePhone(phone) {
    return !equals("", phone) &&
      (!Validators.isValidPhoneNumber(phone) || !Validators.isNANP(phone))
      ? VALIDATION_MESSAGES.phone
      : null;
  }

  const submitHandlerRef = useRef<SubmitHandler | null>(null);
  const handleSubmit = useCallback(
    async (formValues: MobilePayFormValues) => {
      try {
        const submitHandler = submitHandlerRef.current;
        if (!submitHandler) {
          throw new Error("Unable to find the submit handler.");
        }

        const additionalPaymentData = await submitHandler(formValues);
        const response = await submitPayment({
          cardInfo: formValues,
          checkInfo,
          total: checkInfo.balanceDue, // current implementation only allows for full payment
          balanceDue: Money(0), // current implementation only allows for full payment
          tip,
          additionalPaymentData,
        });

        if (ccProcessorConfiguration?.isCustomizedSubmitProcessing) {
          return;
        }
        onPaymentSuccess(response);
      } catch (ex) {
        const message: string | undefined = await (async () => {
          const errorResponse = (ex as { response?: Response }).response;
          if (errorResponse) {
            const body = await errorResponse.json();
            return body.message;
          }

          return (ex as Error).message;
        })();

        if (message) {
          TrackJS.track(message);
        }

        onPaymentError(
          "There was an error processing your request. Please check the form and try again."
        );
      }
    },
    [
      ccProcessorConfiguration?.isCustomizedSubmitProcessing,
      checkInfo,
      onPaymentError,
      onPaymentSuccess,
      submitPayment,
      tip,
    ]
  );

  if (isAdyenEnabled === undefined) {
    return <Loader />;
  }

  if (!ccProcessorConfiguration) {
    return null;
  }

  const {
    form: CreditCardFormImpl,
    ccInput: CCInput,
    getInitialValues = values => values,
  } = ccProcessorConfiguration;

  return (
    <Formik
      initialValues={getInitialValues({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      })}
      onSubmit={handleSubmit}
      validateOnBlur={false}>
      {({ isValid, dirty, isSubmitting }) => (
        <>
          {isSubmitting && <CreditCardProcessLoading />}
          <Suspense fallback={<Loader />}>
            <CreditCardFormImpl
              merchantInfo={merchantInfo}
              submitHandlerRef={submitHandlerRef}
              paymentResponse={paymentResponse}
              onPaymentSuccess={onPaymentSuccess}>
              <Form translate="yes">
                <StyledWrapper>
                  <StyledPaymentHeading>
                    Pay with Credit Card
                  </StyledPaymentHeading>
                  <MobilePayInput
                    name="firstName"
                    label="First Name"
                    autoComplete="given-name"
                    validate={validateFirstName}
                  />
                  <MobilePayInput
                    name="lastName"
                    label="Last Name"
                    autoComplete="family-name"
                    validate={validateLastName}
                  />
                  <CCInput />
                  <GratuityComponent
                    tipSettings={merchantInfo}
                    preTaxTotal={checkInfo.balanceDue}
                    setTip={setTip}
                    setIsTipValid={setIsTipValid}
                    setSelectedTipOption={setSelectedTipOption}
                    selectedTipOption={selectedTipOption}
                    variant="mobile-pay"
                  />
                  <MobilePayInput
                    name="email"
                    label="Email for Receipt"
                    autoComplete="email"
                    inputMode="email"
                    validate={validateEmail}
                  />
                  <MobilePayInput
                    name="phone"
                    label="Phone Number (Optional)"
                    autoComplete="tel"
                    inputMode="tel"
                    validate={validatePhone}
                    required={false}
                  />
                  <StyledFormButton>
                    {error && <MobilePayPaymentError error={error} />}
                    <button
                      type="submit"
                      disabled={
                        !isValid || isSubmitting || !dirty || !isTipValid
                      }>
                      Pay Check ({`${checkInfo.balanceDue.add(tip).format()}`})
                    </button>
                  </StyledFormButton>
                </StyledWrapper>
              </Form>
            </CreditCardFormImpl>
          </Suspense>
        </>
      )}
    </Formik>
  );
}
