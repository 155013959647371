import React from "react";

import {
  MobilePayCreditCardForm,
  type MobilePayCreditCardFormProps,
} from "./MobilePayCreditCardForm";
import CheckInfoComponent from "components/mobile_pay/views/check_info_component";
import Page from "components/mobile_pay/views/page";

export interface PaymentPageProps extends MobilePayCreditCardFormProps {}

export function PaymentPage(props: PaymentPageProps) {
  const { merchantInfo, checkInfo } = props;
  return (
    <Page merchantInfo={merchantInfo} signlePanelContent={false}>
      <CheckInfoComponent checkInfo={checkInfo} />
      <MobilePayCreditCardForm {...props} />
    </Page>
  );
}
