import { isEmpty } from "ramda";
import React, { type KeyboardEvent, useCallback, useState } from "react";
import styled from "styled-components";

import Button from "components/button";
import Input from "components/input";
import LoaderSmall from "components/loader_small";

const StyledForm = styled.div`
  .row > button {
    width: 6.5rem;
  }
  label,
  .applied-code {
    line-height: 2rem;
  }
  min-height: 3rem;
`;

const StyledGrid = styled.section`
  display: grid;
  grid-template-columns: 120px 1fr 6.5rem;
  grid-column-gap: 0;
  grid-template-areas: "label input button";
  width: 100%;
  label {
    grid-area: label;
  }
  .text-input,
  .applied-code {
    grid-area: input;
    padding-right: 1rem;
    max-height: 33px;
  }
  button,
  ${LoaderSmall} {
    grid-area: button;
    width: 100%;
    line-height: 100%;
  }
  @media (max-width: 1024px) {
    grid-template-areas:
      "label label label"
      "input input button";
  }
`;

const StyledSubheader = styled.h2`
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  grid-area: header;
  margin-bottom: 8px;
`;

interface IPromoCodeProps {
  applyPromo: (promo: string) => void;
  promoCode: string;
  isValid: boolean | null;
}

export default function PromoCode({
  applyPromo,
  promoCode,
  isValid,
}: IPromoCodeProps) {
  const [enteredPromo, setEnteredPromo] = useState(promoCode);
  const [isEntering, setIsEntering] = useState(!!isValid);

  const apply = useCallback(() => {
    if (enteredPromo !== promoCode) {
      const massagedPromo = enteredPromo.trim().toLocaleUpperCase();
      setEnteredPromo(massagedPromo);
      applyPromo(massagedPromo);
    }
  }, [applyPromo, enteredPromo, promoCode]);
  const applyOnKeydown = useCallback(
    ({ key }: KeyboardEvent<HTMLInputElement>) => {
      if (key === "Enter") {
        apply();
      }
    },
    [apply]
  );

  const remove = useCallback(() => {
    applyPromo("");
    setEnteredPromo("");
  }, [applyPromo, setEnteredPromo]);

  const isLoading =
    isValid === null && !!promoCode && promoCode === enteredPromo;

  /* eslint-disable jsx-a11y/no-autofocus */
  return (
    <StyledForm className="form">
      <StyledSubheader>Have a promo code?</StyledSubheader>
      <div className="row">
        {!isEntering && (
          <Button
            size="small"
            className="caps"
            type="primary"
            onClick={() => setIsEntering(true)}>
            +Add
          </Button>
        )}
        {isEntering && !isValid && (
          <StyledGrid>
            <label htmlFor="promo-code">Add a Promo</label>{" "}
            <Input
              autoComplete="off"
              autoFocus
              error={
                isValid === false ? "The promo code entered is not valid." : ""
              }
              id="promo-code"
              onChange={({ target: { value } }) => setEnteredPromo(value)}
              onKeyDown={applyOnKeydown}
              placeholder="Enter promo code"
              type="text"
              value={enteredPromo}
            />
            {isLoading ? (
              <LoaderSmall />
            ) : (
              <Button
                type="primary"
                className="caps"
                onClick={apply}
                disabled={isEmpty(enteredPromo)}>
                Apply
              </Button>
            )}
          </StyledGrid>
        )}
        {isEntering && isValid && (
          <StyledGrid>
            <label>Promo Added</label>{" "}
            <span className="applied-code">{promoCode}</span>
            <Button type="secondary" className="caps" onClick={remove}>
              Remove
            </Button>
          </StyledGrid>
        )}
      </div>
    </StyledForm>
  );
}
