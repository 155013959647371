import { find, forEach } from "ramda";
import { getById } from "helpers/collections";
import {
  addSelectedModInModGroupToItem,
  createCartItem,
} from "helpers/online_ordering/cart";
import {
  allMinimumsMet,
  getMaxAllowedQty,
} from "helpers/online_ordering/item_modal";
import applyStockLevel from "helpers/transforms/online_ordering/stock_level_transform";

const getSectionByItemId = (id, menu) => {
  return find(section => getById(id, section.items), menu);
};

const getItemFromSection = (id, menu) => {
  const section = getSectionByItemId(id, menu);
  return section ? getById(id, section.items) : null;
};

const findAndExtendItemFromMenu = (menu, item) => {
  const foundItem = getItemFromSection(item.itemId, menu);
  return foundItem
    ? { ...createCartItem(foundItem), quantity: item.quantity }
    : null;
};

const getModGroupById = (id, item) => {
  return find(modGroup => getById(id, modGroup.modifiers), item.modifierGroups);
};

const getModFromItem = (id, foundItem) => {
  const modGroup = getModGroupById(id, foundItem);
  return modGroup ? getById(id, modGroup.modifiers) : null;
};

function recreateItemWithSidesOrReject(item, foundItem) {
  let allSidesValid = true;
  let itemWithSides = foundItem;
  forEach(side => {
    const foundSide = getById(side.id, foundItem.sides);
    if (foundSide) {
      itemWithSides = {
        ...foundItem,
        selectedSides: [...foundItem.selectedSides, foundSide],
      };
    } else {
      allSidesValid = false;
    }
  }, item.sides);
  return allSidesValid ? itemWithSides : null;
}

function recreateItemWithModsOrReject(item, foundItem) {
  let allModsValid = true;
  let itemWithMods = foundItem;

  forEach(modifier => {
    const itemModifier = getModFromItem(modifier.id, foundItem);
    if (itemModifier) {
      const selectedModsGroup = getById(
        modifier.modifierGroupId,
        itemWithMods.selectedModifiers
      );
      itemWithMods = addSelectedModInModGroupToItem(
        selectedModsGroup,
        itemWithMods,
        itemModifier
      );
    } else {
      allModsValid = false;
    }
  }, item.modifiers);
  return allModsValid ? itemWithMods : null;
}

function recreateItemOrReject(menu, itemToRecreate, stockLevels, cartItems) {
  const foundItem = findAndExtendItemFromMenu(menu, itemToRecreate);
  if (foundItem) {
    const withStockLevel = applyStockLevel(foundItem, cartItems, stockLevels);

    const foundItemWithSides = recreateItemWithSidesOrReject(
      itemToRecreate,
      withStockLevel
    );
    if (foundItemWithSides) {
      const foundItemWithSidesAndMods = recreateItemWithModsOrReject(
        itemToRecreate,
        foundItemWithSides
      );
      if (foundItemWithSidesAndMods) {
        const maxQuantity = getMaxAllowedQty(foundItemWithSidesAndMods);

        return allMinimumsMet(foundItemWithSidesAndMods) &&
          maxQuantity >= foundItemWithSidesAndMods.quantity
          ? foundItemWithSidesAndMods
          : null;
      }
    }
  }

  return null;
}

function filterReorderIntoValidAndInvalid(
  serializedReorder,
  menu,
  stockLevels
) {
  let validItems = [];
  let invalidItems = [];

  forEach(item => {
    const validatedItem = recreateItemOrReject(
      menu,
      item,
      stockLevels,
      validItems
    );

    if (validatedItem) {
      validItems = [...validItems, validatedItem];
    } else {
      invalidItems = [...invalidItems, item];
    }
  }, JSON.parse(serializedReorder));
  return { invalidItems, validItems };
}

export { filterReorderIntoValidAndInvalid };
