import type { Money } from "@upserve/financials";
import { find, gt, isEmpty, isNil, map, not, propEq, takeLast } from "ramda";
import React from "react";

import DividingLine from "components/dividing_line";
import PriceRow, {
  type IPriceRowProps,
  PriceRowList,
} from "components/online_ordering/cart/price_row";
import Tooltip from "components/online_ordering/tooltip";

import { formatMoneyFromCents } from "helpers/money";

import type { PaymentGiftCard } from "types/reducers/payment";
import type { ICalculatedTotals } from "types/totals";

export interface ICheckoutSummary {
  balanceDue: number | null;
  feeByMethod?: number;
  giftCards: PaymentGiftCard[];
  isAwaitingCalculation: boolean;
  isShowingTip: boolean;
  promo: ICalculatedTotals["promo"] | null;
  serviceFee: number;
  subtotal: number;
  tax: number;
  tip: Money;
  total: number;
  upserveFee: number;
}

interface IPriceRow extends IPriceRowProps {
  key: string;
}

const mapPriceRows = map<IPriceRow, JSX.Element>(({ key, ...forward }) => (
  <PriceRow key={key} {...forward} />
));

export default function CheckoutSummary({
  balanceDue,
  feeByMethod = 0,
  giftCards,
  isAwaitingCalculation,
  isShowingTip,
  promo,
  serviceFee: restaurantFee,
  subtotal,
  tax,
  tip,
  total,
  upserveFee,
}: ICheckoutSummary) {
  const priceRows: IPriceRow[] = [
    {
      key: "subtotal",
      title: "Item Subtotal",
      value: formatMoneyFromCents(subtotal),
    },
  ];

  if (promo) {
    const { discount, percentage } = promo;
    if (discount > 0) {
      priceRows.push({
        key: "promo",
        title: `Promo ${percentage}%`,
        value: `-${formatMoneyFromCents(discount)}`,
      });
    }
  }

  // Build taxes and fees
  const taxesAndFees: IPriceRow[] = [];
  if (!isAwaitingCalculation) {
    taxesAndFees.push({
      key: "tax",
      title: gt(upserveFee, 0) ? "Sales Tax" : "Tax",
      value: formatMoneyFromCents(tax),
    });
    if (feeByMethod) {
      taxesAndFees.push({
        key: "deliveryFee",
        title: "Delivery Fee",
        value: formatMoneyFromCents(feeByMethod),
      });
    }
    if (gt(restaurantFee, 0)) {
      taxesAndFees.push({
        key: "restaurantFee",
        title: "Restaurant Fee",
        value: formatMoneyFromCents(restaurantFee),
      });
    }
  }

  if (gt(upserveFee, 0)) {
    taxesAndFees.push({
      key: "serviceFee",
      title: "Service Fee*",
      value: formatMoneyFromCents(upserveFee),
    });
    if (!isAwaitingCalculation) {
      // When Upserve Fee applies, only show taxesAndFees tooltip during checkout,
      // after calculation completes.
      priceRows.push({
        key: "taxAndFee",
        title: (
          <>
            Taxes and Fees
            <Tooltip>
              <PriceRowList isDense>{mapPriceRows(taxesAndFees)}</PriceRowList>
              <p id="asterisk">
                *This fee is charged by our online ordering partner, Lightspeed
                (U-Series), to help offset costs associated with the convenience
                of placing online orders.
              </p>
            </Tooltip>
          </>
        ),
        value: formatMoneyFromCents(
          tax + feeByMethod + upserveFee + restaurantFee
        ),
      });
    }
  } else {
    // When Upserve Fee does not apply, taxes and fees always displayed
    priceRows.push(...taxesAndFees);
  }

  if (isShowingTip) {
    priceRows.push({
      key: "tip",
      title: `Tip${
        tip ? ` ${Math.round((100 * tip.valueOf()) / subtotal)}%` : ""
      }`,
      value: tip.format(),
    });
  }

  priceRows.push({
    key: "total",
    isBold: true,
    title: "Order Total",
    value: formatMoneyFromCents(total),
  });

  let paymentRows: IPriceRow[] = [];
  const makeRowFromGiftCard = (giftCard: PaymentGiftCard) => {
    return {
      key: `gift card ${giftCard.index}`,
      title: (
        <>
          {`Gift Card: ...${takeLast(4, giftCard.giftCardNumber)}`}
          <Tooltip>
            <PriceRowList isDense>
              <PriceRow
                title="Current balance:"
                value={formatMoneyFromCents(giftCard.balance)}
              />
              <PriceRow
                title="Balance after purchase:"
                value={formatMoneyFromCents(giftCard.remainingBalance)}
              />
            </PriceRowList>
          </Tooltip>
        </>
      ),
      value: formatMoneyFromCents(giftCard.amount + giftCard.tipAmount),
    };
  };

  const firstGiftCard = find(propEq("index", 0), giftCards)
    ? makeRowFromGiftCard(
        find(propEq("index", 0), giftCards) as PaymentGiftCard
      )
    : undefined;

  const secondGiftCard = find(propEq("index", 1), giftCards)
    ? makeRowFromGiftCard(
        find(propEq("index", 1), giftCards) as PaymentGiftCard
      )
    : undefined;

  if (firstGiftCard) {
    paymentRows = [...paymentRows, firstGiftCard];
  }

  if (secondGiftCard) {
    paymentRows = [...paymentRows, secondGiftCard];
  }

  if (not(isNil(balanceDue))) {
    paymentRows = [
      ...paymentRows,
      {
        key: "balance due",
        title: "Balance Due",
        value: formatMoneyFromCents(balanceDue!),
        isBold: true,
      },
    ];
  }

  return (
    <div className="checkout-summary">
      <DividingLine role="presentation" />
      <PriceRowList>{mapPriceRows(priceRows)}</PriceRowList>
      {!isEmpty(giftCards) && (
        <>
          <DividingLine role="presentation" />
          <PriceRowList>{mapPriceRows(paymentRows)}</PriceRowList>
        </>
      )}
    </div>
  );
}
