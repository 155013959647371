import { camelizeKeys } from "humps";

import { ORDERS_URL } from "api_urls";
import { getPrettyUrl, getPreviewToken } from "get_base_uri";

import { checkStatus, parseJSON, postRequest } from "helpers/ajax_request";

export function postOrder(order) {
  const prettyUrl = getPrettyUrl();
  const previewToken = getPreviewToken();

  const endpoint = `${ORDERS_URL}/${prettyUrl}/order${
    previewToken ? `?preview_token=${previewToken}` : ""
  }`;
  return fetch(endpoint, postRequest(order))
    .then(checkStatus)
    .then(parseJSON)
    .then(response => {
      return { ...camelizeKeys(response), action: response.action }; // Do NOT camelize the action object
    });
}

export function getPaymentDetails(paymentId, parameters) {
  const prettyUrl = getPrettyUrl();
  const endpoint = `${ORDERS_URL}/${prettyUrl}/payment_details/${paymentId}`;
  return fetch(endpoint, postRequest(parameters))
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys);
}
