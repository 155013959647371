import classNames from "classnames";
import PropTypes from "prop-types";
import { partial } from "ramda";
import React from "react";
import ConfirmModal from "components/confirm_modal";
import Icon from "components/icon";

import { withTracking } from "helpers/analytics";

class CreditCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };

    this.showRemoveModal = this.showRemoveModal.bind(this);
    this.closeRemoveModal = this.closeRemoveModal.bind(this);
  }

  showRemoveModal() {
    this.setState({ showModal: true });
  }

  closeRemoveModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { card, handleRemoveCard, className } = this.props;
    const { showModal } = this.state;
    const {
      lastFour,
      sentenceCaseType,
      cardUuid,
      lowercaseType,
      expiryMonth,
      expiryYear,
      zip,
    } = card;

    let modal;
    if (showModal) {
      modal = (
        <ConfirmModal
          className="remove-credit-modal"
          confirmButtonText="Remove card"
          message={`Are you sure you want to remove ${sentenceCaseType} ending in *** ${lastFour}?`}
          title="Are you sure?"
          onCancel={this.closeRemoveModal}
          onConfirm={withTracking(
            partial(handleRemoveCard, [card]),
            "Account Page: Remove CC"
          )}
        />
      );
    }

    let content;
    content = (
      <>
        <Icon className="card-icon" type={`credit_cards/${lowercaseType}`} />
        <div className="card-details">
          {sentenceCaseType} ending in *** {lastFour}
          <span className="hidden">
            | Exp: {`${expiryMonth}/${expiryYear}`} | Zip: {zip}
          </span>
        </div>
        <div className="actions">
          <a
            className="update remove"
            onClick={withTracking(
              this.showRemoveModal,
              "OLO - Account - click remove credit card"
            )}>
            Remove
          </a>
        </div>
        {modal}
      </>
    );

    return (
      <li className={classNames("credit-card", className)} key={cardUuid}>
        {content}
      </li>
    );
  }
}

CreditCard.propTypes = {
  card: PropTypes.object.isRequired,
  handleRemoveCard: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default CreditCard;
