import { path } from "ramda";

const getTargetValue = path(["target", "value"]);
const getTargetChecked = path(["target", "checked"]);

function preventDefault(event) {
  event.preventDefault();
  return event;
}

function stopPropogation(event) {
  event.stopPropogation();
  return event;
}

function submitOnEnter(onSubmit, data, event) {
  if (event.key === "Enter") {
    onSubmit(data);
  }
}

export {
  getTargetValue,
  getTargetChecked,
  preventDefault,
  stopPropogation,
  submitOnEnter,
};
