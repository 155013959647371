import React, { type ReactNode, Suspense } from "react";
import { OloAccountInput } from "./OloAccountInput";
import LoaderEllipsis from "components/loader_ellipsis";
import { validateFirstName, validateLastName } from "helpers/credit_card_form";

export interface OloAccountCreditCardInputsProps {
  children?: ReactNode;
}

export const OloAccountCreditCardInputs = ({
  children,
}: OloAccountCreditCardInputsProps) => {
  return (
    <div className="cc-details-form form">
      <Suspense
        fallback={
          <LoaderEllipsis title="We are loading the credit card form..." />
        }>
        {children}
      </Suspense>
      <div className="row">
        <OloAccountInput
          id="edit-credt-card-details-first-name"
          name="firstName"
          label="First name"
          autoComplete="cc-given-name"
          required
          type="text"
          validate={validateFirstName}
        />
      </div>
      <div className="row">
        <OloAccountInput
          id="edit-credt-card-details-last-name"
          name="lastName"
          label="Last name"
          autoComplete="cc-family-name"
          required
          type="text"
          validate={validateLastName}
        />
      </div>
    </div>
  );
};
