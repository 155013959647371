import React from "react";
import Icon from "components/icon";

function Landing() {
  return (
    <div className="landing">
      <div>
        <h1>Loyalty</h1>
        <a href="/b/swipely-bistro">Swipely Bistro</a>
        <h1>Online Ordering</h1>
        <a href="/s/breadcrumb-test-account-providence">
          Breadcrumb Test Account Providence
        </a>
        <h1>Mobile Pay</h1>
        <a href="/s/breadcrumb-test-account-providence/pay?testmobilepay">
          Breadcrumb Test Account Providence
        </a>
        <h1>Digital Gift Card</h1>
        <ul>
          <li>
            <a href="/b/cafe-neptune/gift-card">Cafe Neptune</a>
          </li>
          <li>
            <a href="/b/cafe-neptune/gift-card/preview?logo_preview=aHR0cHM6Ly9yZXMuY2xvdWRpbmFyeS5jb20vdXBzZXJ2ZS9pbWFnZS91cGxvYWQvdjE2ODYwNzc5OTQvcHF3ZGxhcHViYnJjYnh4d2lsZ2Uud2VicA%3D%3D&banner_preview=aHR0cHM6Ly9yZXMuY2xvdWRpbmFyeS5jb20vdXBzZXJ2ZS9pbWFnZS91cGxvYWQvdjE3MTcxMDIxMzYvY2ZteHczaGhqeWgwbmVjdWxwaWwuanBn">
              Preview
            </a>
          </li>
        </ul>
      </div>
      <Icon className="ghost" type="receipt-ghost" />
    </div>
  );
}

export default Landing;
