import { Money } from "@upserve/financials";
import React from "react";
import { StyledBoldContent } from "./StyledBoldContent";
import { useFormatPhone } from "hooks/shared/useFormatPhone";
import {
  type DigitalGiftCardApi,
  DigitalGiftCardDeliveryMethod,
} from "types/digital_gift_cards";

export interface DigitalGiftCardProps {
  digitalGiftCard: DigitalGiftCardApi;
}

export const DigitalGiftCard = ({ digitalGiftCard }: DigitalGiftCardProps) => {
  const { deliveryMethod, recipient, recipientEmail, recipientPhone, amount } =
    digitalGiftCard;
  const { EMAIL_RECEIPT, TEXT_RECEIPT } = DigitalGiftCardDeliveryMethod;

  const formatPhone = useFormatPhone();

  return (
    <span data-testid="digital-gift-card">
      Gift Card Amount:{" "}
      <StyledBoldContent>{Money(amount).format()}</StyledBoldContent>
      <br />
      {(deliveryMethod === EMAIL_RECEIPT ||
        deliveryMethod === TEXT_RECEIPT) && (
        <span data-testid="digital-gift-card-recipient">
          Sent to:
          <br />
          <StyledBoldContent>{recipient}</StyledBoldContent> at{" "}
          <StyledBoldContent>
            {deliveryMethod === TEXT_RECEIPT && formatPhone(recipientPhone)}
            {deliveryMethod === EMAIL_RECEIPT && recipientEmail}
          </StyledBoldContent>
          <br />
        </span>
      )}
    </span>
  );
};
