import { Money } from "@upserve/financials";
import { equals } from "ramda";
import type { ThunkAction } from "redux-thunk";

import {
  type IUpdateTip,
  parseOrderCalculationError,
  setPaymentGiftCardRequestStatus,
  updateTip,
} from "actions/online_ordering/checkout";
import { getPrettyUrl } from "get_base_uri";

import type { IResponseError } from "helpers/ajax_request";
import { calculateTipAmount } from "helpers/online_ordering/checkout";
import { filterCart } from "helpers/order_times";

import { calculateTotals } from "interfaces/calculate_totals";
import { selectGiftCardRequest, selectOrderDraft } from "selectors";

import type { IRootState } from "types/reducers";
import { RequestStatus } from "types/requests";
import type { ICalculatedTotals } from "types/totals";

type ThunkResult<R> = ThunkAction<
  R,
  IRootState,
  undefined,
  | IReceiveCalcTotals
  | IApplyPromo
  | IAwaitCalcTotals
  | ISetGiftCardRequest
  | IUpdateTip
>;

export const AWAIT_CALCULATED_TOTALS = "calculate_totals/AWAIT";
export const RECEIVE_CALCULATED_TOTALS = "calculate_totals/RECEIVE";
export const APPLY_PROMO = "calculate_totals/APPLY_PROMO";

export interface IReceiveCalcTotals {
  type: typeof RECEIVE_CALCULATED_TOTALS;
  calculatedTotals: ICalculatedTotals;
}

export interface IAwaitCalcTotals {
  type: typeof AWAIT_CALCULATED_TOTALS;
}

export function awaitCalculatedTotals(): IAwaitCalcTotals {
  return {
    type: AWAIT_CALCULATED_TOTALS,
  };
}

export interface IApplyPromo {
  type: typeof APPLY_PROMO;
  promoCode: string;
  isValidated: boolean | null;
}

export interface ISetGiftCardRequest {
  type: string;
  status: RequestStatus;
}

export function applyPromo(
  promoCode: string,
  isValidated: boolean | null = null // null indicates validation is incomplete/unknown
): IApplyPromo {
  return {
    type: "calculate_totals/APPLY_PROMO",
    promoCode,
    isValidated,
  };
}

export function receiveCalculatedTotals(
  calculatedTotals: ICalculatedTotals
): IReceiveCalcTotals {
  return {
    type: RECEIVE_CALCULATED_TOTALS,
    calculatedTotals,
  };
}

export function fetchCalculatedTotals(retries = 0): ThunkResult<void> {
  return (dispatch, getState) => {
    const orderDraft = selectOrderDraft(getState());

    const {
      cart,
      checkout: {
        payment: { selectedTipOption, tip },
      },
      orderingInfo: { menu },
      orderingOptions,
      storeInfo,
    } = getState();

    const filteredCart = filterCart({
      cart,
      menu,
      orderingOptions,
    });
    dispatch(
      updateTip(
        Money(
          calculateTipAmount(
            filteredCart.cartItems,
            orderingOptions.selectedTransport,
            storeInfo,
            selectedTipOption,
            tip
          )
        )
      )
    );

    dispatch(awaitCalculatedTotals());

    calculateTotals(getPrettyUrl(), orderDraft)
      .then(totals => {
        dispatch(receiveCalculatedTotals(totals));
        if (totals.promo.discount) {
          dispatch(applyPromo(orderDraft.order.promoCode, true));
        } else if (orderDraft.order.promoCode) {
          dispatch(applyPromo(orderDraft.order.promoCode, false));
        }
        const request = selectGiftCardRequest(getState());
        if (equals(request.giftCardsRequest, RequestStatus.REQUESTING)) {
          dispatch(setPaymentGiftCardRequestStatus(RequestStatus.SUCCESS));
        }
      })
      .catch(({ response }: IResponseError) => {
        const orderNow = selectOrderDraft(getState());
        if (!equals(orderDraft, orderNow)) {
          // Bail out if order has changed since this request was made.
          return;
        }
        if (!response || retries >= 3) {
          dispatch(
            parseOrderCalculationError(response, "Could not process your order")
          );
          if (orderDraft.order.promoCode) {
            dispatch(applyPromo(orderDraft.order.promoCode, false));
          }
        } else if (response) {
          if (response.status >= 500) {
            // For 500 level errors, retry
            dispatch(fetchCalculatedTotals(retries + 1));
          } else {
            // For meaningful errors below 500, parse and display to user.
            dispatch(parseOrderCalculationError(response));
            if (orderDraft.order.promoCode) {
              dispatch(applyPromo(orderDraft.order.promoCode, false));
            }
            const request = selectGiftCardRequest(getState());
            if (equals(request.giftCardsRequest, RequestStatus.REQUESTING)) {
              dispatch(setPaymentGiftCardRequestStatus(RequestStatus.FAILURE));
            }
          }
        }
      });
  };
}
