import Aviator from "aviator";

import { getBaseUri } from "get_base_uri";
import { publishStore } from "interfaces/olo_store_info";

export const START_PUBLISH = "online_ordering/publish_status/START_PUBLISH";
export const CANCEL_PUBLISH = "online_ordering/publish_status/CANCEL_PUBLISH";
export const CONFIRM_PUBLISH = "online_ordering/publish_status/CONFIRM_PUBLISH";
export const PUBLISHING = "online_ordering/publish_status/PUBLISHING";
export const PUBLISH_SUCCESS = "online_ordering/publish_status/PUBLISH_SUCCESS";
export const PUBLISH_FAIL = "online_ordering/publish_status/PUBLISH_FAIL";
export const PUBLISH_DONE = "online_ordering/publish_status/PUBLISH_DONE";

function startPublish() {
  return {
    type: START_PUBLISH,
  };
}

function cancelPublish() {
  return {
    type: CANCEL_PUBLISH,
  };
}

function publishing() {
  return {
    type: PUBLISHING,
  };
}

function publishSuccess() {
  return {
    type: PUBLISH_SUCCESS,
  };
}

function publishFail() {
  return {
    type: PUBLISH_FAIL,
  };
}

function confirmPublish() {
  return dispatch => {
    dispatch(publishing());
    return publishStore()
      .then(() => dispatch(publishSuccess()))
      .catch(() => dispatch(publishFail()));
  };
}

function donePublish() {
  Aviator.navigate(getBaseUri());
  return {
    type: PUBLISH_DONE,
  };
}

export { startPublish, cancelPublish, confirmPublish, donePublish };
