import PropTypes from "prop-types";
import { join, map } from "ramda";
import React from "react";

import Button from "components/button";
import Modal from "components/modal";

function CartChangeModal({ filteredItems, onCancel }) {
  return (
    <Modal
      className="cart-change-modal"
      title="Items removed"
      onClose={onCancel}>
      <div className="contents">
        <p>
          This restaurant's menu varies by time of day. The following items are
          not available at your selected time and have been removed from your
          cart:{" "}
          <span className="bold">
            {join(
              ",",
              map(item => item.name, filteredItems)
            )}
          </span>
          .
        </p>
        <div className="confirm-actions">
          <Button type="primary" onClick={onCancel} size="large">
            Okay
          </Button>
        </div>
      </div>
    </Modal>
  );
}

CartChangeModal.propTypes = {
  filteredItems: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CartChangeModal;
