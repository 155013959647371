import { camelizeKeys } from "humps";
import { equals, pipe } from "ramda";

import { CARD_API_URL, LOYALTY_API_URL } from "api_urls";
import {
  checkStatus,
  deleteRequest,
  getRequest,
  parseJSON,
  postRequest,
} from "helpers/ajax_request";
import getEnv from "helpers/get_env";
import formatCards from "helpers/transforms/loyalty/credit_cards_transform";

/* eslint-disable */
///////////////////
///////////////////////////////////////////////////////
//////////
/* eslint-enable */

function getCreditCardInfo() {
  if (equals(getEnv(), "development")) {
    return pipe(camelizeKeys, formatCards)(CARDS.default);
  }
  return fetch(`${LOYALTY_API_URL}/user/cards`, getRequest())
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys)
    .then(formatCards);
}

function postCreditCard(cardWithToken, callback, errorCallback) {
  return fetch(`${CARD_API_URL}/consumers/card`, postRequest(cardWithToken))
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys)
    .then(formatCards)
    .then(callback)
    .catch(errorCallback);
}

function postTokenRequestForNewCard(
  callback,
  userUuid,
  enrollmentSalt,
  enrollmentSig
) {
  let fetchOptions;
  if (userUuid)
    fetchOptions = postRequest({ userUuid, enrollmentSalt, enrollmentSig });
  else fetchOptions = postRequest();

  return fetch(`${LOYALTY_API_URL}/card_enrollment_token`, fetchOptions)
    .then(checkStatus)
    .then(parseJSON)
    .then(callback);
}

function deleteCreditCard(card, callback) {
  return fetch(
    `${LOYALTY_API_URL}/user/cards/${card.token}`,
    deleteRequest(card)
  )
    .then(checkStatus)
    .then(callback);
}

export default getCreditCardInfo;

export {
  postCreditCard,
  deleteCreditCard,
  getCreditCardInfo,
  postTokenRequestForNewCard,
};
