import PropTypes from "prop-types";
import { partial, pipe } from "ramda";
import React from "react";

import Button from "components/button";
import Input from "components/input";
import Modal from "components/modal";
import { getTargetValue } from "helpers/event";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleKeyDown(event) {
    const { onEmailSubmit, email } = this.props;
    if (event.key === "Enter") {
      partial(onEmailSubmit, [email]);
    }
  }

  render() {
    const { onClose, onEmailSubmit, onUpdateField, email, error } = this.props;

    return (
      <Modal
        title="Forgot password"
        onClose={onClose}
        className="reset-password-modal">
        <div className="contents">
          <div className="message">
            No worries, it happens to all of us. Put in your email address and
            we'll send over a reset password email.
          </div>
          <Input
            id="reset-password-email"
            value={email}
            placeholder="Email"
            onChange={pipe(getTargetValue, partial(onUpdateField, ["email"]))}
            onKeyDown={this.handleKeyDown}
            error={error}
            autoFocus
          />
          <Button
            className="reset-password-button"
            onClick={partial(onEmailSubmit, [email])}>
            Reset password
          </Button>
        </div>
      </Modal>
    );
  }
}

ResetPassword.propTypes = {
  onClose: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  onEmailSubmit: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  error: PropTypes.string,
};

export default ResetPassword;
