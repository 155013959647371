import Aviator from "aviator";
import { camelizeKeys } from "humps";
import { equals, pipe } from "ramda";
import { TrackJS } from "trackjs";

import { LOYALTY_API_URL } from "api_urls";
import { checkStatus, getRequest, parseJSON } from "helpers/ajax_request";
import getEnv from "helpers/get_env";

/* eslint-disable */
///////////////////
///////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
//////////
/* eslint-enable */

function getRewardProgression(callback) {
  if (equals(getEnv(), "development")) {
    return pipe(camelizeKeys)(LOCAL_REWARD_PROGRESSION);
  }

  const request = Aviator.getCurrentRequest();
  return fetch(
    `${LOYALTY_API_URL}/brands/${request.namedParams.prettyUrl}/reward_progression.json`,
    getRequest()
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys)
    .then(rewardProgression => {
      if (callback) callback(rewardProgression);
      return rewardProgression;
    });
}

function getRewards(callback) {
  if (equals(getEnv(), "development")) {
    if (callback) callback(pipe(camelizeKeys)(LOCAL_REWARDS.default));
    return pipe(camelizeKeys)(LOCAL_REWARDS.default);
  }
  const request = Aviator.getCurrentRequest();
  return fetch(
    `${LOYALTY_API_URL}/brands/${request.namedParams.prettyUrl}/rewards.json`,
    getRequest()
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys)
    .then(rewards => {
      if (callback) callback(rewards);
      return rewards;
    })
    .catch(error => {
      // For some reason some brands have a loyalty program but don't
      // have rewards set up - if this is the case treat them like
      // brands without a loyalty program and kick them back to our marketing page
      TrackJS.track(error);
      if (equals(getEnv(), "production")) {
        window.location.href = "http://upserve.com";
      }
    });
}

function getLoyalty(callback) {
  Promise.all([getRewards(), getRewardProgression()]).then(
    ([rewards, rewardProgression]) => {
      callback({
        rewards,
        progression: rewardProgression,
      });
    }
  );
}

export { getLoyalty, getRewards, getRewardProgression };
export default getLoyalty;
