import React from "react";
import styled from "styled-components";

const LightspeedIcon = require("images/lightspeed.png");

const StyledDGCFooter = styled.div`
  margin-top: 20px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledPoweredByRow = styled.div`
  display: flex;
  align-items: center;

  font-size: 1rem; // 14px based on 14px root font-size; decrease to .875rem for 16px root
  line-height: 1.125rem; // ~16px based on 14px root font-size; decrease to 1rem for 16px root

  > img {
    margin-bottom: 5px;
  }
`;

export default function Footer() {
  return (
    <StyledDGCFooter>
      <StyledPoweredByRow>
        <img
          src={LightspeedIcon}
          height="30"
          width="120"
          alt="Lightspeed (U-Series)"
        />
      </StyledPoweredByRow>
      <a
        href="https://assets-upserve.s3.amazonaws.com/terms-and-conditions/digital-gift-cards-terms-and-conditions.html"
        target="_blank"
        rel="noopener noreferrer">
        Terms and Conditions
      </a>
    </StyledDGCFooter>
  );
}
