import { toggleModal, updateField } from "actions/authentication";
import { submitCard, submitEmail } from "interfaces/loyalty_sign_up";

export const CLEAR_USER_INFO = "loyalty/CLEAR_USER_INFO";
export const UPDATE_USER_INFO = "loyalty/UPDATE_USER_INFO";

function clearUserInfo() {
  return {
    type: CLEAR_USER_INFO,
  };
}

function updateUserInfo(user) {
  return {
    type: UPDATE_USER_INFO,
    user,
  };
}

function signUpSubmitEmail(email) {
  return dispatch => {
    submitEmail(
      email,
      user => {
        dispatch(updateUserInfo(user));
      },
      error => {
        if (error.message === "Unauthorized") {
          dispatch(toggleModal(true));
          dispatch(updateField("email", email));
        }
      }
    );
  };
}

function signUpSubmitCard(
  userUuid,
  enrollmentSalt,
  enrollmentSig,
  unencryptedPan
) {
  return dispatch => {
    submitCard(
      userUuid,
      enrollmentSalt,
      enrollmentSig,
      unencryptedPan,
      () => {
        dispatch(updateUserInfo({ hasCards: true }));
      },
      error => {
        if (error.message === "Conflict") {
          dispatch(
            toggleModal(
              true,
              "It looks like you’ve enrolled that credit card before. You can log into your account here. If you don’t recall your password, click “Forgot password?” below."
            )
          );
        }
      }
    );
  };
}

export { clearUserInfo, updateUserInfo, signUpSubmitEmail, signUpSubmitCard };
