import { merge } from "ramda";
import { combineReducers } from "redux";
import { UPDATE_LOYALTY_INFO } from "actions";
import { CLEAR_USER_INFO, UPDATE_USER_INFO } from "actions/loyalty";
import authentication from "reducers/authentication";
import consumer from "reducers/online_ordering/consumer";

function loyalty(state = {}, action) {
  switch (action.type) {
    case UPDATE_LOYALTY_INFO: {
      return merge(state, action.loyalty);
    }
    default:
      return state;
  }
}

function user(state = {}, action) {
  switch (action.type) {
    case UPDATE_USER_INFO:
      return merge(state, action.user);
    case CLEAR_USER_INFO:
      return {};
    default:
      return state;
  }
}

const reducers = combineReducers({
  storeInfo: (state = {}) => state,
  brandInfo: (state = {}) => state,
  user,
  authentication,
  loyalty,
  consumer,
});

export default reducers;
