import type { ComponentType } from "react";
import { connect } from "react-redux";

import { submitOrder } from "actions/online_ordering";
import {
  applyPromo,
  fetchCalculatedTotals,
} from "actions/online_ordering/calculate_totals";
import {
  applyGiftCard,
  removeGiftCard,
  returnToMenu,
  updateCurbsidePickupInstructions,
  updateDeliveryInstructions,
  updateIsCurbsidePickup,
  updateOrderStatus,
  updateOrderStatusWithError,
  updateSelectedtipOption,
  updateTip,
} from "actions/online_ordering/checkout";

import Checkout from "components/online_ordering/checkout";
import { filterCart } from "helpers/order_times";

export interface CheckoutContainerProps {
  sessionId: string;
  onPaymentSuccess: () => Promise<void>;
}

function mapStateToProps(state) {
  return {
    storeInfo: state.storeInfo,
    checkout: state.checkout,
    consumer: state.consumer,
    cart: filterCart({
      cart: state.cart,
      menu: state.orderingInfo.menu,
      orderingOptions: state.orderingOptions,
    }),
    orderingOptions: state.orderingOptions,
    selectedTipOption: state.checkout.payment.selectedTipOption,
  };
}

const mapDispatchToProps = {
  applyGiftCard,
  applyPromo,
  fetchCalculatedTotals,
  onOrderStatusUpdate: updateOrderStatus,
  onReturnToMenu: returnToMenu,
  onSubmitOrder: submitOrder,
  onUpdateDeliveryInstructions: updateDeliveryInstructions,
  onUpdateIsCurbsidePickup: updateIsCurbsidePickup,
  onUpdateCurbsidePickupInstructions: updateCurbsidePickupInstructions,
  onUpdateOrderStatusWithError: updateOrderStatusWithError,
  removeGiftCard,
  setTip: updateTip,
  setSelectedTipOption: updateSelectedtipOption,
};

const CheckoutContainer: ComponentType<CheckoutContainerProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(Checkout);

export default CheckoutContainer;
