import PropTypes from "prop-types";

import { isEmpty, not, partial, pipe } from "ramda";
import React from "react";

import Button from "components/button";
import Icon from "components/icon";
import Input from "components/input";
import Modal from "components/modal";
import { withTracking } from "helpers/analytics";
import { getTargetValue, submitOnEnter } from "helpers/event";
import handleFormErrors from "helpers/handle_form_errors";
import isOLO from "helpers/is_olo";

function Login({
  authentication,
  onClose,
  onChangeModalType,
  onUpdateField,
  onLogIn,
  locationName,
  onSocialLogIn,
}) {
  const { email, password, customAuthMessage } = authentication;
  let createAccount;
  let description;
  let socialLoginButton;
  if (isOLO()) {
    createAccount = (
      <a
        className="create-account"
        onClick={partial(onChangeModalType, ["CREATE_ACCOUNT"])}>
        Don't have an account? Create one.
      </a>
    );
    description = (
      <div className="modal-copy">
        {locationName} uses the Lightspeed (U-Series) platform for online
        ordering. Create an account for easy reordering.
      </div>
    );
    socialLoginButton = (
      <Button
        className="auth0-login-button"
        onClick={partial(onSocialLogIn, ["facebook"])}>
        Log In with Facebook
      </Button>
    );
  }

  if (not(isEmpty(customAuthMessage))) {
    description = <div className="modal-copy">{customAuthMessage}</div>;
  }
  return (
    <Modal title="Welcome back!" onClose={onClose} className="login-modal">
      <form>
        <div className="contents">
          {description}
          <div id="lock-cont" />
          {socialLoginButton}
          <Input
            id="login-email"
            placeholder="Email"
            autoComplete="username"
            value={email}
            onChange={pipe(getTargetValue, partial(onUpdateField, ["email"]))}
            onKeyDown={partial(submitOnEnter, [onLogIn, authentication])}
            error={handleFormErrors("email", authentication)}
            autoFocus
          />
          <Input
            id="login-password"
            type="password"
            value={password}
            placeholder="Password"
            autoComplete="current-password"
            onChange={pipe(
              getTargetValue,
              partial(onUpdateField, ["password"])
            )}
            onKeyDown={partial(submitOnEnter, [onLogIn, authentication])}
            error={handleFormErrors("password", authentication)}
          />
          <Button
            className="login-button"
            onClick={partial(onLogIn, [authentication])}>
            Log In
          </Button>
          <a
            className="forgot-password"
            onClick={withTracking(
              partial(onChangeModalType, ["RESET_PASSWORD"]),
              "Clicked Forgot Password"
            )}>
            Forgot password?
          </a>
          {createAccount}
        </div>
      </form>
      <div className="login-footer">
        <a
          href="https://upserve.com/"
          target="_blank"
          rel="noopener noreferrer">
          <Icon className="logo-icon" type="upserve-by-lightspeed" />
        </a>
      </div>
    </Modal>
  );
}

Login.propTypes = {
  authentication: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onLogIn: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  onChangeModalType: PropTypes.func.isRequired,
  locationName: PropTypes.string.isRequired,
  onSocialLogIn: PropTypes.func.isRequired,
};

export default Login;
