import {
  assoc,
  countBy,
  flatten,
  identity,
  isEmpty,
  map,
  propOr,
  reduce,
  repeat,
} from "ramda";
import { Maybe } from "true-myth";

import type { ICartItem } from "types/cart";
import type { IItem, ISide, IStockLevels } from "types/menu";

export default function applyStockLevel<A extends IItem>(
  item: A,
  cartItems: ICartItem[],
  stockLevels: IStockLevels
): A {
  const cartItemsCountById = countBy(
    identity,
    flatten(
      map(
        cartItem =>
          repeat(
            [cartItem.id].concat(
              cartItem.selectedSides.map(side => side.sideId)
            ),
            cartItem.quantity
          ),
        cartItems
      )
    )
  );

  const initialStockLevel = Maybe.of(stockLevels[item.id]);
  const stockInCart: number = propOr(0, item.id, cartItemsCountById);
  const remainingStock = initialStockLevel.match({
    Just: stockLevel => stockLevel - stockInCart,
    Nothing: () => null,
  });
  if (!isEmpty(item.sides)) {
    const sides = reduce<ISide, ISide[]>(
      (sidesAcc, sideItem) => {
        const initialSideStockLevel = Maybe.of(stockLevels[sideItem.sideId]);
        const sideStockInCart: number = propOr(
          0,
          sideItem.sideId,
          cartItemsCountById
        );
        const remainingSideStock = initialSideStockLevel.match({
          Just: stockLevel => stockLevel - sideStockInCart,
          Nothing: () => null,
        });
        return sidesAcc.concat(
          assoc("stockLevel", Maybe.of(remainingSideStock), sideItem)
        );
      },
      [],
      item.sides
    );
    return { ...item, sides, stockLevel: Maybe.of(remainingStock) };
  }
  return { ...item, stockLevel: Maybe.of(remainingStock) };
}
