import PropTypes from "prop-types";
import React from "react";

import Button from "components/button";
import Modal from "components/modal";

function ConfirmModal({
  className,
  confirmButtonText,
  message,
  messageLine2,
  onCancel,
  onConfirm,
}) {
  let line2;
  if (messageLine2) {
    line2 = <div className="message">{messageLine2}</div>;
  }
  return (
    <Modal onClose={onCancel} className={`confirm-modal ${className}`}>
      <div className="contents">
        <div className="message">{message}</div>
        {line2}
        <div className="confirm-actions">
          <Button
            className="cancel"
            type="secondary"
            onClick={onCancel}
            size="medium">
            Cancel
          </Button>
          <Button
            className="confirm"
            type="primary"
            onClick={onConfirm}
            size="medium">
            {confirmButtonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  className: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  messageLine2: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmModal;
