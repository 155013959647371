import PropTypes from "prop-types";
import { equals, find, identity, isEmpty, not } from "ramda";
import React, { useMemo } from "react";

import Picker from "components/picker";
import { withTracking } from "helpers/analytics";
import {
  formatDayOptionLabel,
  formatOrderAheadOptions,
} from "helpers/order_times";

const UNSELECTED_OPTION = "Select a date";
const dateOptionFormatter = option => {
  if (option === UNSELECTED_OPTION) {
    return UNSELECTED_OPTION;
  }
  return formatDayOptionLabel(option);
};

function OrderTimeEditor({
  storeInfo,
  orderingOptions: {
    orderAheadDate,
    orderAheadTime,
    selectedTransport,
    timeError,
  },
  onUpdateOrderDate,
  onUpdateOrderTime,
  useExtendedOrderAhead,
}) {
  const orderAheadDetails = formatOrderAheadOptions(
    selectedTransport,
    storeInfo.fulfillmentTypes,
    orderAheadDate,
    storeInfo.timezone,
    useExtendedOrderAhead
  );

  let timePicker;
  if (!isEmpty(orderAheadDate)) {
    timePicker = (
      <div className="row">
        <label className="shrink">Time</label>
        <Picker
          currentOption={
            isEmpty(orderAheadTime) ? "Select a time" : orderAheadTime
          }
          options={orderAheadDetails.times}
          onOptionClick={withTracking(
            onUpdateOrderTime,
            "OLO - Order Method Form - select schedule time"
          )}
          formatter={identity}
        />
      </div>
    );
  }

  let sectionTitle = "Delivery time";
  if (not(equals(selectedTransport, "Delivery"))) {
    sectionTitle = "Pick up time";
  }

  let datePickerClassName;
  let timeErrorMessage;
  if (timeError) {
    datePickerClassName = "error";
    timeErrorMessage =
      "Your order time is no longer valid. Please pick another time.";
  }

  const dateOptions = useMemo(
    () => orderAheadDetails.extended.schedule.concat(UNSELECTED_OPTION),
    [orderAheadDetails.extended.schedule]
  );

  let selectedDaySchedule = UNSELECTED_OPTION;
  if (!isEmpty(orderAheadDate)) {
    const matchingOpt = find(
      ({ dayAsMoment }) =>
        dayAsMoment.isSame(orderAheadDate.dayAsMoment, "day"),
      orderAheadDetails.extended.schedule
    );
    if (matchingOpt) {
      selectedDaySchedule = matchingOpt;
    }
  }

  return (
    <div className="form order-time-container">
      <div className="title">{sectionTitle}</div>
      <div className="row">
        <label className="shrink">Date</label>
        <Picker
          currentOption={selectedDaySchedule}
          className={datePickerClassName}
          options={dateOptions}
          onOptionClick={withTracking(
            onUpdateOrderDate,
            "OLO - Order Method Form - select schedule date"
          )}
          formatter={dateOptionFormatter}
          errorMessage={timeErrorMessage}
        />
      </div>
      {timePicker}
    </div>
  );
}

OrderTimeEditor.propTypes = {
  storeInfo: PropTypes.object.isRequired,
  orderingOptions: PropTypes.object.isRequired,
  onUpdateOrderDate: PropTypes.func.isRequired,
  onUpdateOrderTime: PropTypes.func.isRequired,
  useExtendedOrderAhead: PropTypes.bool,
};

export default OrderTimeEditor;
