import Aviator from "aviator";
import { match } from "ramda";
import { Maybe } from "true-myth";

export function getPreviewBannerUrl(
  request = Aviator.getCurrentRequest()
): Maybe<string> {
  const { banner_preview } = request.queryParams;
  return decodePreviewLink(banner_preview);
}

export function getPreviewLogoUrl(
  request = Aviator.getCurrentRequest()
): Maybe<string> {
  const { logo_preview } = request.queryParams;
  return decodePreviewLink(logo_preview);
}

export function decodePreviewLink(requestValue: any): Maybe<string> {
  if (requestValue) {
    const decodedPreviewLink = atob(requestValue);
    if (
      match(
        /^https:\/\/res(-\d)?.cloudinary.com\/upserve(-\w*)*\/image\/upload\/.*$/,
        decodedPreviewLink
      ).length >= 1
    ) {
      return Maybe.of(decodedPreviewLink);
    }
  }
  return new Maybe.Nothing();
}
