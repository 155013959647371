import PropTypes from "prop-types";
import { gt, isEmpty } from "ramda";
import React, { useEffect } from "react";

import LocationInfo from "components/location_info";
import CheckoutContainer from "containers/online_ordering/checkout_container";
import MenuContainer from "containers/online_ordering/menu_container";
import { useOloOrderSuccess } from "hooks/online_ordering/useOloOrderSuccess";
import { useSessionId } from "hooks/shared/useSessionId";

function MainView({
  cart,
  storeInfo,
  orderingInfo,
  orderingOptions,
  isCheckingOut,
  onFetchMenu,
  onCheckAndApplyLocalStorage,
}) {
  const sessionId = useSessionId();
  const onPaymentSuccess = useOloOrderSuccess();

  useEffect(() => {
    if (!isCheckingOut) {
      onFetchMenu();
    }
    // added since a new eslint rule,
    // feel free to fix this eslint error if you can test it properly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckingOut]);

  // Only do this once when the component is mounted with data
  // Need the menu in order to apply a re-order
  useEffect(() => {
    if (orderingInfo.menu) {
      onCheckAndApplyLocalStorage(
        orderingInfo.menu,
        orderingInfo.stockLevels,
        orderingOptions.selectedTransport
      );
    }
    // added since a new eslint rule,
    // feel free to fix this eslint error if you can test it properly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderingInfo.menu === null]); // Evaluates only on component mount, and when moving from `true -> false`

  let content;
  if (isCheckingOut && gt(cart.cartItems.length, 0)) {
    content = (
      <CheckoutContainer
        sessionId={sessionId}
        onPaymentSuccess={onPaymentSuccess}
      />
    );
  } else {
    content = <MenuContainer key="menu" />;
  }

  let description;
  if (!isEmpty(storeInfo.description)) {
    description = (
      <div className="store-description card">{storeInfo.description}</div>
    );
  }
  return (
    <div className="main-view" key="left-container">
      {description}
      {content}
      <LocationInfo isBrand={false} info={storeInfo} />
    </div>
  );
}

MainView.propTypes = {
  cart: PropTypes.object.isRequired,
  storeInfo: PropTypes.object.isRequired,
  orderingInfo: PropTypes.object.isRequired,
  orderingOptions: PropTypes.object.isRequired,
  isCheckingOut: PropTypes.bool.isRequired,
  onFetchMenu: PropTypes.func.isRequired,
  onCheckAndApplyLocalStorage: PropTypes.func.isRequired,
};

export default MainView;
