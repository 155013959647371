import PropTypes from "prop-types";
import React from "react";

function Overlay({ onClick, children }) {
  return (
    <div
      className="overlay"
      onClick={event => {
        if (onClick && event.target === event.currentTarget) onClick(event);
      }}>
      {children}
    </div>
  );
}

Overlay.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default Overlay;
