import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React from "react";

import styled from "styled-components";

const StyledCheckbox = styled.div`
  .MuiFormControlLabel-root {
    margin-left: -8px;
    align-items: center;
    vertical-align: top;
  }

  .MuiTypography-body1 {
    font-family: "Graphik", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem; // 14px based on 14px root font-size; decrease to .875rem for 16px root
    line-height: 1.55rem; // ~22px based on 14px root font-size; decrease to 1.33rem for 16px root
    color: #000000;
  }

  .MuiIconButton-root {
    width: 25px;
    padding: 0 5px;

    .MuiSvgIcon-root {
      width: 100%;
      height: 100%;
    }
  }

  .MuiCheckbox-colorSecondary {
    &:hover {
      background-color: transparent;
    }

    &.Mui-checked {
      color: #445a82;

      &:hover {
        background-color: transparent;
      }
    }
  }
`;

type MuiCheckboxProps = {
  label: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function MuiCheckbox({
  label,
  checked,
  onChange,
  ...forward
}: MuiCheckboxProps) {
  return (
    <StyledCheckbox>
      <FormControlLabel
        className="v2-checkbox"
        control={
          <Checkbox checked={checked} onChange={onChange} disableRipple />
        }
        label={label}
        {...forward}
      />
    </StyledCheckbox>
  );
}
