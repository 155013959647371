import Aviator from "aviator";
import { camelizeKeys } from "humps";
import { equals, pipe } from "ramda";

import { YOUR_REWARDS_API_URL } from "api_urls";
import { checkStatus, getRequest, parseJSON } from "helpers/ajax_request";
import getEnv from "helpers/get_env";
import formatOrderHistory from "helpers/transforms/loyalty/order_history_transform";

/* eslint-disable */
///////////////////
///////////////////////////////////////////////////////////////////////
//////////
/* eslint-enable */

function getOrderHistory() {
  if (equals(getEnv(), "development")) {
    return pipe(camelizeKeys, formatOrderHistory)(ORDER_HISTORY.default);
  }

  return fetch(
    `${YOUR_REWARDS_API_URL}/purchases.json?m=${
      Aviator.getCurrentRequest().namedParams.prettyUrl
    }`,
    getRequest()
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys)
    .then(formatOrderHistory);
}

export default getOrderHistory;
