import styled from "styled-components";

export const StyledErrorHeader = styled.h1`
  margin-top: 8px;
  margin-bottom: 12px;

  font-weight: bold;
  font-size: 36px;
  line-height: 40px;

  color: #212322;
`;
