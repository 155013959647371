import { type Reducer, combineReducers } from "redux";

import { UPDATE_ORDERING_INFO } from "actions/online_ordering";

import type { IConsumer } from "components/online_ordering/objects/IConsumer";
import authentication from "reducers/authentication";
import cart from "reducers/online_ordering/cart";
import checkout from "reducers/online_ordering/checkout";
import consumer from "reducers/online_ordering/consumer";
import deliveryAddress from "reducers/online_ordering/delivery_address";
import orderingOptions from "reducers/online_ordering/ordering_options";
import publishStatus from "reducers/online_ordering/publish_status";
import storeInfo from "reducers/online_ordering/store_info";
import type { ICartItem } from "types/cart";
import type { Menu } from "types/menu";
import type { ICheckoutStatusState } from "types/reducers/checkout_status";
import type { IOrderingOptionsState } from "types/reducers/ordering_options";
import type { IPaymentState } from "types/reducers/payment";
import type { StoreInfo } from "types/reducers/store_info";

export interface OnlineOrderingReduxState {
  menu: unknown | null;
  deliveryTaxRate: unknown | null;
  stockLevels?: unknown | null;
  cart?: {
    cartItems: ICartItem[];
  };
  consumer?: IConsumer;
  checkout?: {
    status: ICheckoutStatusState;
    payment: IPaymentState;
  };
  orderingInfo?: {
    menu: Menu;
  };
  orderingOptions?: IOrderingOptionsState;
  storeInfo?: StoreInfo;
  payment?: IPaymentState;
}

const initialState: OnlineOrderingReduxState = {
  menu: null,
  deliveryTaxRate: null,
  stockLevels: null,
};

function orderingInfo(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ORDERING_INFO: {
      return {
        ...state,
        menu: action.orderingInfo.menu,
        deliveryTaxRate: action.orderingInfo.deliveryTaxRate,
        stockLevels: action.orderingInfo.stockLevels,
      };
    }

    default:
      return state;
  }
}

const reducers: Reducer<OnlineOrderingReduxState> = combineReducers({
  authentication,
  cart,
  checkout,
  consumer,
  deliveryAddress,
  orderingInfo,
  orderingOptions,
  publishStatus,
  // @ts-expect-error ignore reducer ts errors for now
  storeInfo,
});

export default reducers;
