import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import { equals, merge, partial } from "ramda";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import Input from "components/input";

function ContactInfo({ contactInfo, onContactInfoSave }) {
  const [contactFieldValues, setContactFields] = useState(contactInfo);
  const [isSaving, setIsSaving] = useState(false);
  const [updatedAt, setUpdatedAt] = useState(null);

  // Save handler is debounced
  const handleSave = useMemo(() => {
    function saveValues(fieldValues) {
      setIsSaving(true);
      onContactInfoSave(fieldValues);
    }
    return debounce(saveValues, 1000);
  }, [onContactInfoSave]);

  const handleUpdate = useCallback(
    (field, event) => {
      const merged = merge(contactFieldValues, { [field]: event.target.value });
      setContactFields(merged);
      // Run debounced save handler any time field values change
      handleSave(merged);
    },
    // added since a new eslint rule,
    // feel free to fix this eslint error if you can test it properly
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contactFieldValues]
  );

  // After saving is complete, set updatedAt
  useEffect(() => {
    if (isSaving && equals(contactInfo, contactFieldValues)) {
      setIsSaving(false);
      setUpdatedAt(new Date());
    }
  }, [contactInfo, contactFieldValues, isSaving]);

  const nameUpdated = updatedAt ? (
    <span key={updatedAt} className="updated-text">
      Updated
    </span>
  ) : undefined;

  const { firstName, lastName, email } = contactFieldValues;

  return (
    <div className="account-section form contact-info">
      <div className="name row">
        <span className="label">Name</span>
        <Input
          id="contact-info-first-name"
          type="text"
          value={firstName}
          onChange={partial(handleUpdate, ["firstName"])}
          placeholder="First name"
        />
        <Input
          id="contact-info-last-name"
          type="text"
          value={lastName}
          onChange={partial(handleUpdate, ["lastName"])}
          placeholder="Last name"
        />
        {nameUpdated}
      </div>
      <div className="email row">
        <span className="label">Email</span>
        {email}
      </div>
    </div>
  );
}

ContactInfo.propTypes = {
  contactInfo: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string.isRequired,
  }),
  onContactInfoSave: PropTypes.func,
};

export default ContactInfo;
