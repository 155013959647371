import { contains, equals, map, merge, min, pick, pipe, pluck } from "ramda";
import uuid from "uuid/v4";
import { replaceInCollection } from "helpers/collections";

function selectedOptionOfAvailable(selectedTransport, fulfillmentTypes) {
  if (equals(selectedTransport, "Dine in")) {
    return selectedTransport;
  }
  const transportOptions = pluck("name", fulfillmentTypes);

  if (contains(selectedTransport, transportOptions)) {
    return selectedTransport;
  }
  if (contains("Pick Up", transportOptions)) {
    return "Pick up";
  }

  return "Delivery";
}

function createCartItem(item) {
  return {
    ...item,
    cartId: uuid(),
    quantity: 1,
    instructions: "",
    selectedSides: [],
    selectedModifiers: map(
      pipe(
        group => {
          return {
            ...group,
            safeMin: min(group.modifiers.length, group.minimumRequired),
          };
        },
        pick(["id", "safeMin"]),
        merge({ mods: [] })
      ),
      item.modifierGroups
    ),
  };
}

function addSelectedModInModGroupToItem(
  selectedModsGroup,
  cartItem,
  selectedMod
) {
  const updatedSelectedModsGroup = {
    ...selectedModsGroup,
    mods: [...selectedModsGroup.mods, selectedMod],
  };

  return {
    ...cartItem,
    selectedModifiers: replaceInCollection(
      selectedModsGroup,
      updatedSelectedModsGroup,
      cartItem.selectedModifiers
    ),
  };
}

export {
  selectedOptionOfAvailable,
  createCartItem,
  addSelectedModInModGroupToItem,
};
