import PropTypes from "prop-types";
import React from "react";

function QuantitySelector({
  quantity,
  maxAllowed,
  onIncrementItemQuantity,
  onDecrementItemQuantity,
}) {
  return (
    <div className="quantity-wrapper">
      <div className="quantity-selector">
        <button
          className="decrement"
          disabled={quantity === 1}
          type="button"
          onClick={onDecrementItemQuantity}>
          &ndash;
        </button>
        <div className="quantity-value">{quantity}</div>
        <button
          className="increment"
          type="button"
          onClick={onIncrementItemQuantity}
          disabled={quantity === maxAllowed}>
          +
        </button>
      </div>
      {quantity === maxAllowed && (
        <div className="quantity-info">{`Max quantity allowed is ${maxAllowed}.`}</div>
      )}
    </div>
  );
}

QuantitySelector.propTypes = {
  quantity: PropTypes.number,
  maxAllowed: PropTypes.number,
  onIncrementItemQuantity: PropTypes.func,
  onDecrementItemQuantity: PropTypes.func,
};

export default QuantitySelector;
