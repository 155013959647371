import Aviator from "aviator";
import { merge, omit } from "ramda";
import { combineReducers } from "redux";
import { LOG_OUT } from "actions/authentication";
import {
  CLEAR_CHECKOUT_FORM_ERRORS,
  RETURN_CHECKOUT_FORM_ERRORS,
  VALIDATE_CONTACT_FIELD_ON_BLUR,
} from "actions/online_ordering";
import {
  APPLY_ORDER_ADDRESS_FROM_LOCAL_STORAGE,
  UPDATE_CONTACT_INFO,
} from "actions/online_ordering/checkout";
import { UPDATE_CONSUMER_INFO } from "actions/online_ordering/consumer";
import { REORDER } from "actions/online_ordering/ordering_options";
import { getBaseUri } from "get_base_uri";

import {
  saveOrderAddressToLocalStorage,
  saveReorderToLocalStorage,
} from "helpers/local_storage";

import cards from "reducers/credit_cards";
import addresses from "reducers/online_ordering/addresses";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  textAlerts: true,
  phoneNumbers: [], // Alternate phone numbers
  address: {
    line1: "",
    line2: "",
    city: "",
    state: "",
    zip: "",
  },
  addresses: [],
  errors: null,
};

function contactInfo(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CONSUMER_INFO: {
      const contactDetails = omit(["cards", "orders"], action.consumerInfo);
      return {
        ...state,
        ...contactDetails,
      };
    }

    case UPDATE_CONTACT_INFO: {
      let updatedContactInfo = {
        ...state,
        ...action.contactInfo,
      };

      if (action.contactInfo.address) {
        updatedContactInfo = {
          ...state,
          ...action.contactInfo,
          address: {
            ...state.address,
            ...action.contactInfo.address,
          },
        };
      }

      saveOrderAddressToLocalStorage(updatedContactInfo.address);

      return updatedContactInfo;
    }

    case APPLY_ORDER_ADDRESS_FROM_LOCAL_STORAGE: {
      const { address } = action;

      return {
        ...state,
        address,
      };
    }

    case VALIDATE_CONTACT_FIELD_ON_BLUR: {
      if (action.error) {
        return {
          ...state,
          errors: merge(state.errors, action.error),
        };
      }

      return {
        ...state,
        errors: omit([action.field], state.errors),
      };
    }

    case RETURN_CHECKOUT_FORM_ERRORS: {
      return {
        ...state,
        errors: action.contactFormErrors,
      };
    }

    case CLEAR_CHECKOUT_FORM_ERRORS: {
      return {
        ...state,
        errors: null,
      };
    }

    case LOG_OUT: {
      return initialState;
    }

    default:
      return state;
  }
}

function orders(state = [], action) {
  switch (action.type) {
    case UPDATE_CONSUMER_INFO: {
      if (action.consumerInfo.orders) {
        return action.replace
          ? action.consumerInfo.orders
          : [...state, ...action.consumerInfo.orders];
      }

      return state;
    }

    case REORDER: {
      saveReorderToLocalStorage(action.items);
      Aviator.navigate(getBaseUri());
      return state;
    }

    case LOG_OUT: {
      return [];
    }

    default:
      return state;
  }
}

const consumerReducers = combineReducers({
  addresses,
  contactInfo,
  cards,
  orders,
});

export default consumerReducers;
