import Haikunator from "haikunator";
import {
  equals,
  find,
  isNil,
  map,
  not,
  pipe,
  propEq,
  reject,
  split,
} from "ramda";
import uuid from "uuid/v4";

import type { IConsumer } from "components/online_ordering/objects/IConsumer";
import { getPrettyUrl } from "get_base_uri";

import { transformCartItem } from "helpers/transforms/online_ordering/cart_item";
import type { IOrderPayload } from "types/online_ordering/IOrderPayload";
import type { IRootState } from "types/reducers";
import type { IOrderingOptionsState } from "types/reducers/ordering_options";
import type {
  BalanceGiftCard,
  OrderPaymentGiftCard,
} from "types/reducers/payment";
import type { Fulfillment, StoreInfo } from "types/reducers/store_info";
import type { OrderPayment } from "types/totals";

export function formatPaymentType(paymentType) {
  switch (paymentType) {
    case "credit":
      return "CREDIT";
    case "gift_card":
      return "GIFT_CARD";
    default:
      return "CASH";
  }
}

export function formatFulfillmentType(selectedTransport) {
  switch (selectedTransport) {
    case "Delivery":
      return "delivery";
    case "Dine in":
      return "dine_in";
    default:
      return "pickup";
  }
}

export function parseOrderAheadTime(time) {
  const timeParts = split(" ", time);

  const [hourAndMinute, amPM] = timeParts;
  const [hours, minutes] = pipe(
    split(":"),
    map(str => parseInt(str, 10))
  )(hourAndMinute);

  let adjustedHours = hours;
  if (amPM.startsWith("PM") && not(equals(12, hours))) {
    adjustedHours = hours + 12;
  } else if (amPM.startsWith("AM") && equals(12, hours)) {
    adjustedHours = hours - 12;
  }

  return { hours: adjustedHours, minutes };
}

export function generatePaymentsPayload(
  payment
): (OrderPayment | OrderPaymentGiftCard)[] {
  const {
    giftCards,
    calculatedTotals: { payments },
  } = payment;
  return reject(
    isNil,
    map(orderPayment => {
      const paymentTipAmount = orderPayment.tipAmount
        ? orderPayment.tipAmount
        : 0;
      const paymentAmount = orderPayment.amount + paymentTipAmount; // amount here is the amount charged to the payment method so this includes the tip

      if (equals("CREDIT", orderPayment.paymentType)) {
        if (
          equals(0, orderPayment.amount) &&
          equals(0, orderPayment.tipAmount)
        ) {
          // When $0 is paid via Credit, we don't actually charge the card.
          // Subsequently, we don't send it to Ubergateway and shouldn't tell the Point-of-Sale
          // that there's an associated payment for it.
          // The only way this case should be hit would be if someone:
          // * paid with a gift card, the store doesn't accept cash, the gift card covered the total, and they didn't tip
          // * paid with a credit card, applied a promo code so the amount due was zero, and they didn't tip
          // We can probably simplify all this by filtering out these conditions when we initially populate
          // the payments object in checkout.
          return undefined;
        }

        return {
          paymentType: orderPayment.paymentType,
          amount: paymentAmount,
          tipAmount: paymentTipAmount,
        };
      }

      if (equals("GIFT_CARD", orderPayment.paymentType)) {
        return {
          amount: paymentAmount,
          giftCardNumber: orderPayment.giftCardNumber,
          paymentType: orderPayment.paymentType,
          pin: find<BalanceGiftCard>(
            p => p?.index === orderPayment.index,
            giftCards
          )?.pin,
          tipAmount: paymentTipAmount,
        };
      }

      // CASH
      return {
        paymentType: orderPayment.paymentType,
        amount: paymentAmount,
        tipAmount: paymentTipAmount,
      };
    }, payments.payments)
  );
}

export function orderTransform({
  storeInfo,
  checkout,
  consumerInfo,
  cart,
  orderingOptions,
  sessionId,
}: {
  storeInfo: StoreInfo;
  checkout: IRootState["checkout"];
  consumerInfo: IConsumer;
  cart: IRootState["cart"];
  orderingOptions: IOrderingOptionsState;
  sessionId: string;
}): IOrderPayload {
  const {
    payment: {
      calculatedTotals,
      paymentType,
      promoCode,
      promoCodeIsValid,
      tip,
    },
  } = checkout;
  const { contactInfo } = consumerInfo;
  const { permalink } = storeInfo;
  const orderId = uuid();
  const haikunator = new Haikunator({
    adjectives: [permalink],
    nouns: [],
    defaults: {
      delimiter: "-",
      tokenLength: 5,
    },
  });

  const prettyUrl = getPrettyUrl();

  const {
    deliveryFee: feeByMethod,
    tax,
    orderTotal,
    fees,
    withFeeIncluded,
    total,
  } = calculatedTotals;

  const finalGratuity = equals("CASH", formatPaymentType(paymentType))
    ? 0
    : tip.valueOf();

  let addressForOrder: Pick<IOrderPayload, "line1" | "city" | "state" | "zip"> =
    {};
  if (equals(orderingOptions.selectedTransport, "Delivery")) {
    addressForOrder = {
      line1: consumerInfo.contactInfo.address.line1,
      city: consumerInfo.contactInfo.address.city,
      state: consumerInfo.contactInfo.address.state,
      zip: consumerInfo.contactInfo.address.zip,
    };
  }

  let orderAhead: {} = {};
  if (equals(orderingOptions.selectedTimeFrame, "Later")) {
    const { orderAheadDate, orderAheadTime } = orderingOptions;
    const parsedTime = parseOrderAheadTime(orderAheadTime);

    if (typeof orderAheadDate === "string") {
      throw new Error("orderAheadDate must be an object");
    }

    const promisedDate = orderAheadDate.dayAsMoment
      .hour(parsedTime.hours)
      .minute(parsedTime.minutes)
      .second(0)
      .millisecond(0)
      .toDate();

    orderAhead = {
      promisedDate,
    };
  }

  let dineInFulfillmentInfo: Pick<IOrderPayload, "dineInInfo"> = {};
  if (equals(orderingOptions.selectedTransport, "Dine in")) {
    const dineInOption = find(
      propEq("name", orderingOptions.selectedTransport),
      storeInfo.fulfillmentTypes
    ) as Fulfillment;
    let dineInCheckType: "tab" | "table" = "tab";
    if (dineInOption && dineInOption.checkTypes) {
      [dineInCheckType] = dineInOption.checkTypes;
    }
    dineInFulfillmentInfo = {
      dineInInfo: {
        checkType: dineInCheckType,
        tableId: orderingOptions.dineInTableId,
      },
    };
  }

  let pickupFulfillmentInfo: {} = {};
  if (
    equals(orderingOptions.selectedTransport, "Pick up") &&
    orderingOptions.isCurbsidePickup
  ) {
    pickupFulfillmentInfo = {
      pickupInfo: {
        isCurbsidePickup: orderingOptions.isCurbsidePickup,
        curbsidePickupInstructions: orderingOptions.curbsidePickupInstructions,
      },
    };
  }

  let deliveryFulfillmentInfo: {} = {};
  if (equals(orderingOptions.selectedTransport, "Delivery")) {
    deliveryFulfillmentInfo = {
      deliveryInfo: {
        address: {
          addressLine1: contactInfo.address.line1,
          addressLine2: contactInfo.address.line2,
          city: contactInfo.address.city,
          state: contactInfo.address.state,
          country: "",
          zipCode: contactInfo.address.zip,
        },
      },
    };
  }

  return {
    order: {
      id: orderId,
      timePlaced: new Date(),
      confirmationCode: haikunator.haikunate(),
      fulfillmentInfo: {
        type: formatFulfillmentType(orderingOptions.selectedTransport),
        instructions: orderingOptions.deliveryInstructions,
        customer: {
          firstName: contactInfo.firstName,
          lastName: contactInfo.lastName,
          phone: contactInfo.phone,
          email: contactInfo.email,
        },
        ...pickupFulfillmentInfo,
        ...deliveryFulfillmentInfo,
        ...dineInFulfillmentInfo,
      },
      payments: {
        total: withFeeIncluded ? total : orderTotal,
        payments: generatePaymentsPayload(checkout.payment),
      },
      charges: {
        fees: feeByMethod,
        items: map(transformCartItem, cart.cartItems),
        taxes: tax,
        tip: { amount: finalGratuity },
        total: withFeeIncluded ? total : orderTotal,
        deliveryFee: fees.deliveryFee,
        consumerFee: fees.consumerFee,
        serviceFee: fees.serviceFee,
        feeTotal: fees.total,
        feeVersion: fees.version,
      },
      promoCode: promoCodeIsValid ? promoCode : "",
      ...orderAhead,
    },
    orderTotal, // Do not send Upserve Fee as part of final total. It will be determined by the backend.
    email: consumerInfo.contactInfo.email,
    firstName: consumerInfo.contactInfo.firstName,
    lastName: consumerInfo.contactInfo.lastName,
    ...addressForOrder,
    phoneNumber: consumerInfo.contactInfo.phone,
    textAlerts: consumerInfo.contactInfo.textAlerts,
    storePrettyUrl: prettyUrl,
    submissionId: orderId,
    sessionId,
  };
}
