import { type TypedUseSelectorHook, useSelector } from "react-redux";
import type { LoyaltyCreditCard } from "types/loyalty/LoyaltyCreditCard";

export interface LoyaltyReduxState {
  consumer?: {
    cards: readonly LoyaltyCreditCard[];
  };
}

export const useLoyaltySelector =
  useSelector as TypedUseSelectorHook<LoyaltyReduxState>;
