import React from "react";
import styled from "styled-components";

const StyledSubmissionErrorMessage = styled.div`
  display: block;
  position: relative;
  bottom: 10px;

  font-size: 16px;
  color: #e02948;
`;

export const DigitalGiftCardPaymentError = ({ error }: { error: string }) => {
  return <StyledSubmissionErrorMessage>{error}</StyledSubmissionErrorMessage>;
};
