import { equals, head, is, isEmpty, isNil, map } from "ramda";

import {
  findFormattersByLowercase,
  formatObscuredCardNumberByLowercase,
} from "helpers/credit_cards";
import { getOrderAddressFromLocalStorage } from "helpers/local_storage";

const emptyAddress = {
  line1: "",
  line2: "",
  city: "",
  state: "",
  zip: "",
};

function formatConsumerInfo(info) {
  const primaryPhone = isEmpty(info.phoneNumbers)
    ? ""
    : head(info.phoneNumbers);
  return {
    ...info,
    phone: is(Object, primaryPhone) ? primaryPhone.phoneNumber : primaryPhone,
    address: isEmpty(info.addresses)
      ? emptyAddress
      : info.addresses.find(address =>
          equals(address, getOrderAddressFromLocalStorage())
        ) || head(info.addresses),
    cards: map(transformCard, info.cards),
    addresses: map(
      address => map(val => (isNil(val) ? "" : val), address),
      info.addresses
    ),
  };
}

export function transformCard(card) {
  return {
    ...card,
    cvv: "",
    lowercaseType: card.cardType,
    sentenceCaseType: findFormattersByLowercase(card.cardType).prettyName,
    obscuredNumber: formatObscuredCardNumberByLowercase(
      card.cardType,
      card.lastFour
    ),
    dropdownFormat: `${
      findFormattersByLowercase(card.cardType).prettyName
    } ending in *** ${card.lastFour}`,
  };
}

export default formatConsumerInfo;
