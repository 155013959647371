import { Money } from "@upserve/financials";
import { head, merge, omit } from "ramda";

import { LOG_OUT } from "actions/authentication";
import {
  SET_CREDIT_CARD_ERROR_MESSAGES,
  VALIDATE_CC_FIELD_ON_BLUR,
} from "actions/credit_cards";
import {
  CLEAR_CHECKOUT_FORM_ERRORS,
  RETURN_CHECKOUT_FORM_ERRORS,
} from "actions/online_ordering";
import {
  APPLY_PROMO,
  AWAIT_CALCULATED_TOTALS,
  type IApplyPromo,
  type IReceiveCalcTotals,
  RECEIVE_CALCULATED_TOTALS,
} from "actions/online_ordering/calculate_totals";
import {
  PROCEED_TO_CHECKOUT,
  SET_PAYMENT_GIFT_CARD_INFO,
  SET_PAYMENT_GIFT_CARD_REQUEST,
  UPDATE_NEW_CREDIT_CARD,
  UPDATE_PAYMENT_TYPE,
  UPDATE_SELECTED_TIP_OPTION,
  UPDATE_TIP,
} from "actions/online_ordering/checkout";
import type { SuggestedTipSelection } from "helpers/gratuity";

import type {
  BalanceGiftCard,
  IErrors,
  INewCreditCard,
  IPaymentState,
} from "types/reducers/payment";

import { RequestStatus } from "types/requests";
import type { ICalculatedTotals } from "types/totals";

const initialCalculatedTotals: ICalculatedTotals = {
  total: 0,
  tax: 0,
  deliveryFee: 0,
  menuTotal: 0,
  orderTotal: 0,
  upserveFee: 0,
  promo: {
    discount: 0,
    percentage: 0,
  },
  balanceDue: 0,
  payments: {
    payments: [],
  },
  fees: {
    consumerFee: 0,
    deliveryFee: 0,
    serviceFee: 0,
    total: 0,
    version: "",
  },
  serviceFee: 0,
  withFeeIncluded: false,
};

// -1 is never a reasonable value for total, so use it here to signal waiting for response from the calculation endpoint
// filed HQ-8839 to handle this better
const awaitingCalculatedTotals: ICalculatedTotals = {
  ...initialCalculatedTotals,
  total: -1,
};

export const INITIAL_CREDIT_CARD_FORM_VALUES: INewCreditCard = {
  address1: "",
  address2: "",
  cardNumber: "",
  city: "",
  cvv: "",
  errors: null,
  expiryMonth: "",
  expiryYear: "",
  firstName: "",
  lastName: "",
  state: "",
  zip: "",
  ccProcessor: "firstData",
};

const initialState: IPaymentState = {
  calculatedTotals: initialCalculatedTotals,
  errors: null,
  paymentType: "credit",
  promoCode: "",
  promoCodeIsValid: null,
  newCreditCard: INITIAL_CREDIT_CARD_FORM_VALUES,
  giftCards: [],
  giftCardsRequest: RequestStatus.NOT_STARTED,
  tip: Money(0),
  selectedTipOption: undefined,
};

interface UpdatePaymentTypeAction {
  type: typeof UPDATE_PAYMENT_TYPE;
  paymentType: IPaymentState["paymentType"];
}

interface UpdateNewCreditCardAction {
  type: typeof UPDATE_NEW_CREDIT_CARD;
  newCreditCard: INewCreditCard;
}

interface ValidateCCFieldOnBlurAction {
  type: typeof VALIDATE_CC_FIELD_ON_BLUR;
  error: IErrors;
  field: string;
}

interface SetCreditCardErrorMessagesAction {
  type: typeof SET_CREDIT_CARD_ERROR_MESSAGES;
  error: IErrors;
}

interface AwaitCalculatedTotalsAction {
  type: typeof AWAIT_CALCULATED_TOTALS;
}

interface ReturnCheckoutFormErrorsAction {
  type: typeof RETURN_CHECKOUT_FORM_ERRORS;
  ccFormErrors: any;
}

interface ClearCheckoutFormErrorsAction {
  type: typeof CLEAR_CHECKOUT_FORM_ERRORS;
}

interface ProceedToCheckoutAction {
  type: typeof PROCEED_TO_CHECKOUT;
  paymentOptions: Array<IPaymentState["paymentType"]>;
}

interface SetPaymentGiftCardRequestAction {
  type: typeof SET_PAYMENT_GIFT_CARD_REQUEST;
  status: RequestStatus;
}

interface SetPaymentGiftCardInfo {
  type: typeof SET_PAYMENT_GIFT_CARD_INFO;
  giftCards: BalanceGiftCard[];
}

interface UpdateTipAction {
  type: typeof UPDATE_TIP;
  tip: Money;
}

interface UpdateSelectedTipOptionAction {
  type: typeof UPDATE_SELECTED_TIP_OPTION;
  selectedTipOption: SuggestedTipSelection;
}

type PaymentAction =
  | UpdatePaymentTypeAction
  | UpdateNewCreditCardAction
  | ValidateCCFieldOnBlurAction
  | SetCreditCardErrorMessagesAction
  | IApplyPromo
  | AwaitCalculatedTotalsAction
  | IReceiveCalcTotals
  | ReturnCheckoutFormErrorsAction
  | ClearCheckoutFormErrorsAction
  | ProceedToCheckoutAction
  | SetPaymentGiftCardRequestAction
  | SetPaymentGiftCardInfo
  | UpdateTipAction
  | UpdateSelectedTipOptionAction;

function payment(
  state: IPaymentState = initialState,
  action: PaymentAction
): IPaymentState {
  switch (action.type) {
    case UPDATE_PAYMENT_TYPE: {
      return {
        ...state,
        paymentType: action.paymentType,
      };
    }

    case UPDATE_NEW_CREDIT_CARD:
      return {
        ...state,
        newCreditCard: {
          ...state.newCreditCard,
          ...action.newCreditCard,
        } as INewCreditCard,
      };

    case VALIDATE_CC_FIELD_ON_BLUR: {
      if (action.error) {
        return {
          ...state,
          newCreditCard: {
            ...state.newCreditCard,
            errors: merge(state.newCreditCard.errors || {}, action.error),
          },
        };
      }

      return {
        ...state,
        newCreditCard: {
          ...state.newCreditCard,
          errors: omit([action.field], state.newCreditCard.errors),
        },
      };
    }

    case SET_CREDIT_CARD_ERROR_MESSAGES: {
      return {
        ...state,
        newCreditCard: {
          ...state.newCreditCard,
          errors: merge(state.newCreditCard.errors ?? {}, action.error),
        },
      };
    }

    case APPLY_PROMO: {
      const { promoCode, isValidated } = action;
      return {
        ...state,
        promoCode,
        promoCodeIsValid: isValidated,
      };
    }

    case AWAIT_CALCULATED_TOTALS: {
      return {
        ...state,
        calculatedTotals: awaitingCalculatedTotals,
      };
    }

    case RECEIVE_CALCULATED_TOTALS: {
      return {
        ...state,
        calculatedTotals: action.calculatedTotals,
      };
    }

    case RETURN_CHECKOUT_FORM_ERRORS: {
      return {
        ...state,
        newCreditCard: {
          ...state.newCreditCard,
          errors: action.ccFormErrors,
        },
      };
    }

    case CLEAR_CHECKOUT_FORM_ERRORS: {
      return {
        ...state,
        newCreditCard: {
          ...state.newCreditCard,
          errors: null,
        },
        errors: null,
      };
    }

    case PROCEED_TO_CHECKOUT: {
      const paymentType = head(action.paymentOptions);
      if (paymentType) {
        return {
          ...state,
          paymentType,
        };
      }

      return state;
    }

    case LOG_OUT: {
      return initialState;
    }

    case SET_PAYMENT_GIFT_CARD_REQUEST: {
      return {
        ...state,
        giftCardsRequest: action.status,
      };
    }

    case SET_PAYMENT_GIFT_CARD_INFO: {
      return {
        ...state,
        giftCards: action.giftCards,
      };
    }

    case UPDATE_TIP: {
      return {
        ...state,
        tip: action.tip,
      };
    }

    case UPDATE_SELECTED_TIP_OPTION: {
      return {
        ...state,
        selectedTipOption: action.selectedTipOption,
      };
    }

    default:
      return state;
  }
}

export default payment;
