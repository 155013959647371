import { ENV, type Environment } from "helpers/get_env";

export interface Auth0Config {
  domain: string;
  clientID: string;
  realm: string;
}

const CONFIG_BY_ENVIRONMENT: Record<Environment, Auth0Config> = {
  development: {
    domain: "upserve.auth0.com",
    clientID: "T72t5alQWwGaHn2MlXVgWX7Fe01uss4D",
    realm: "Swipely-App",
  },
  development_proxy: {
    domain: "upserve-staging.auth0.com",
    clientID: "6Xd3Y424ehXPc9a26r410tyioFc6gNj3",
    realm: "HQ-Service",
  },
  staging: {
    domain: "upserve-staging.auth0.com",
    clientID: "6Xd3Y424ehXPc9a26r410tyioFc6gNj3",
    realm: "HQ-Service",
  },
  production: {
    domain: "upserve.auth0.com",
    clientID: "T72t5alQWwGaHn2MlXVgWX7Fe01uss4D",
    realm: "Swipely-App",
  },
};

export const AUTH0_CONFIG = CONFIG_BY_ENVIRONMENT[ENV];

/**
 * @deprecated Please use `AUTH0_CONFIG` const
 */
export function auth0Config() {
  return AUTH0_CONFIG;
}

/**
 * @deprecated use auth0Config not the default export
 */
export default auth0Config;
