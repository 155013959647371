import { removeCreditCard, saveNewCreditCard } from "actions/credit_cards";
import isOLO from "helpers/is_olo";
import { validatePasswordDetails } from "helpers/validation/forms";
import { ACCOUNT_CREATION_MESSAGES } from "helpers/validation/messages";
import Validators from "helpers/validation/validators";
import { putContactInfo, putPassword } from "interfaces/account_info";
import { putConsumerInfo } from "interfaces/consumer_info";

export const UPDATE_PASSWORD_FIELD = "account/UPDATE_PASSWORD_FIELD";
export const SHOW_PASSWORD_FORM = "account/SHOW_PASSWORD_FORM";
export const UPDATE_PASSWORD_RESPONSE = "account/UPDATE_PASSWORD_RESPONSE";
export const CANCEL_PASSWORD_FORM_EDITS = "account/CANCEL_PASSWORD_FORM_EDITS";
export const RETURN_PASSWORD_FORM_ERRORS =
  "account/RETURN_PASSWORD_FORM_ERRORS";

const validators = {
  currentPassword: Validators.minimumLength(6),
  newPassword: Validators.minimumLength(6),
  confirmPassword: Validators.minimumLength(6),
};

function updateContactInfo(contactInfo) {
  return {
    type: "UPDATE_CONTACT_INFO",
    contactInfo,
  };
}

function saveContactInfo(contactInfo) {
  if (isOLO()) {
    const { firstName, lastName } = contactInfo;
    return () => {
      putConsumerInfo(firstName, lastName);
    };
  }
  return dispatch => {
    putContactInfo(contactInfo, response => {
      dispatch(updateContactInfo(response));
    });
  };
}

function updatePasswordField(field, value) {
  return {
    type: UPDATE_PASSWORD_FIELD,
    field,
    value,
  };
}

function showPasswordForm() {
  return {
    type: SHOW_PASSWORD_FORM,
  };
}

function cancelPasswordFormEdits() {
  return {
    type: CANCEL_PASSWORD_FORM_EDITS,
  };
}

function updatePasswordResponse(response) {
  return {
    type: UPDATE_PASSWORD_RESPONSE,
    response,
  };
}

function updatePassword(passwordDetails) {
  const errors = validatePasswordDetails(
    passwordDetails,
    validators,
    ACCOUNT_CREATION_MESSAGES
  );

  if (errors) {
    return {
      type: RETURN_PASSWORD_FORM_ERRORS,
      errors,
    };
  }
  return dispatch => {
    putPassword(
      passwordDetails.newPassword,
      passwordDetails.currentPassword,
      response => {
        dispatch(updatePasswordResponse(response));
      }
    );
  };
}

export {
  updateContactInfo,
  saveContactInfo,
  saveNewCreditCard,
  removeCreditCard,
  updatePassword,
  updatePasswordField,
  showPasswordForm,
  cancelPasswordFormEdits,
};
