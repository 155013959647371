import { Money } from "@upserve/financials";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import { contains, equals, isEmpty, pipe, reduce } from "ramda";
import React, { createRef } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { CheckoutError } from "./checkout/CheckoutError";
import Button from "components/button";
import Checkbox from "components/checkbox";
import GratuityComponent from "components/gratuity_component";
import CurbsidePickupInstructions from "components/online_ordering/checkout/curbside_pickup_instructions";
import DeliveryInstructions from "components/online_ordering/checkout/delivery_instructions";
import GiftCard from "components/online_ordering/checkout/gift_card";
import PromoCode from "components/online_ordering/checkout/promo_code";
import CustomerInfoContainer from "containers/account/customer_info_container";
import PaymentContainer from "containers/online_ordering/checkout/payment_container";
import trackAnalytic from "helpers/analytics";
import { getTargetChecked } from "helpers/event";
import {
  GENERIC_ERROR,
  getTipSettings,
  isOrderValid,
} from "helpers/online_ordering/checkout";
import { getFullfillmentTypeFromSelectedTransport } from "helpers/online_ordering/fulfillment_types";
import getTotals from "helpers/online_ordering/item_totals";
import { RequestStatus } from "types/requests";

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    this.debouncedSubmit = debounce(this.handleSubmit, 3000, {
      leading: true,
      trailing: false,
    });
    this.state = {
      showPaymentForm: true,
      orderResponse: undefined,
    };
    this.setShowPaymentForm = this.setShowPaymentForm.bind(this);
    this.getPaymentConfigurationRef = createRef(null);
  }

  componentDidMount() {
    this.calculateTotals();
  }

  componentDidUpdate({
    cart: { cartItems: prevCartItems },
    checkout: { payment: prevPayment },
    orderingOptions: { selectedTransport: prevSelectedTransport },
  }) {
    const { showPaymentForm } = this.state;
    const {
      cart: { cartItems },
      checkout: { payment },
      orderingOptions: { selectedTransport },
    } = this.props;

    let tipChanged = false;
    const paymentHasTip =
      equals(payment.paymentType, "credit") || !isEmpty(payment.giftCards);
    if (!equals(payment.calculatedTotals.total, -1) && paymentHasTip) {
      // total will be -1 while we are waiting for calculate endpoint response
      let tipSumFromCalculatedTotals = 0;
      if (!isEmpty(payment.calculatedTotals.payments.payments.length)) {
        tipSumFromCalculatedTotals = reduce(
          (sum, paymentObject) => {
            return sum + paymentObject.tipAmount;
          },
          0,
          payment.calculatedTotals.payments.payments
        );
      }
      tipChanged = !equals(tipSumFromCalculatedTotals, payment.tip.valueOf());
    }

    if (
      !equals(cartItems, prevCartItems) ||
      payment.promoCode !== prevPayment.promoCode ||
      payment.paymentType !== prevPayment.paymentType ||
      selectedTransport !== prevSelectedTransport ||
      tipChanged
    ) {
      // If anything changed that would impact the total, recalculate totals.
      this.calculateTotals();
    }

    if (!equals(payment, prevPayment)) {
      if (equals(payment.giftCardsRequest, RequestStatus.NOT_STARTED)) {
        this.setShowPaymentForm(true);
      } else if (equals(payment.giftCardsRequest, RequestStatus.REQUESTING)) {
        this.setShowPaymentForm(showPaymentForm);
      } else if (payment.calculatedTotals) {
        this.setShowPaymentForm(
          !equals(payment.calculatedTotals.balanceDue, 0)
        );
      }
    }
  }

  setShowPaymentForm(show) {
    this.setState({ showPaymentForm: show });
  }

  handleSubmit = async () => {
    const {
      storeInfo,
      checkout,
      consumer,
      cart,
      orderingOptions,
      onSubmitOrder,
      onOrderStatusUpdate,
      flags,
      sessionId,
      onUpdateOrderStatusWithError,
      onPaymentSuccess,
    } = this.props;
    try {
      onOrderStatusUpdate("submitting");

      const paymentConfiguration =
        this.getPaymentConfigurationRef.current?.() ?? null;

      const orderResponse = await onSubmitOrder({
        paymentConfiguration,
        storeInfo,
        cart,
        checkout,
        consumerInfo: consumer,
        orderingOptions,
        featureFlags: flags,
        sessionId,
      });

      // if `isCustomizedSubmitProcessing` is true,
      // it means the the individual payment handler would handle the flow to update the order status
      const isCustomizedSubmitProcessing =
        paymentConfiguration?.isCustomizedSubmitProcessing ?? false;
      if (!isCustomizedSubmitProcessing) {
        await onPaymentSuccess();
      }

      this.setState({ orderResponse });
    } catch (ex) {
      onOrderStatusUpdate("error");
      trackAnalytic("Order submission error", ex.message);

      // If it is a submission error.
      if (ex.response) {
        const response = ex.response;
        if (
          response.status &&
          response.status >= 400 &&
          response.status < 500
        ) {
          onUpdateOrderStatusWithError("submitOrder", GENERIC_ERROR, false);
        } else {
          onUpdateOrderStatusWithError(
            "submitOrder",
            "Sorry, online ordering is temporarily unavailable.",
            false
          );
        }
      }
    }
  };

  calculateTotals() {
    const { fetchCalculatedTotals } = this.props;
    fetchCalculatedTotals(0);
  }

  render() {
    const { showPaymentForm, orderResponse } = this.state;
    const {
      applyGiftCard,
      applyPromo,
      cart,
      checkout,
      consumer,
      flags,
      onReturnToMenu,
      onUpdateDeliveryInstructions,
      onUpdateIsCurbsidePickup,
      onUpdateCurbsidePickupInstructions,
      orderingOptions,
      removeGiftCard,
      storeInfo,
      setTip,
      setSelectedTipOption,
    } = this.props;
    const {
      payment: {
        calculatedTotals,
        paymentType,
        promoCode,
        promoCodeIsValid,
        giftCards,
        giftCardsRequest,
        selectedTipOption,
        tip,
      },
      status: { orderMenuError },
    } = checkout;
    const curbsidePickupAvailable =
      flags.oloCurbsidePickupMvp && storeInfo.settings.pickup?.curbsideEnabled;

    let submitDisabled = !isOrderValid(
      cart,
      checkout,
      storeInfo,
      orderingOptions,
      consumer
    );
    if (!calculatedTotals) {
      submitDisabled = true;
    }

    const selectedFulfillmentType = getFullfillmentTypeFromSelectedTransport(
      orderingOptions.selectedTransport,
      storeInfo.fulfillmentTypes
    );
    const paymentOptions = selectedFulfillmentType.paymentMethods;

    let gratuitySection;
    if (equals(paymentType, "credit")) {
      const { subtotal } = getTotals(cart.cartItems);
      gratuitySection = (
        <GratuityComponent
          tipSettings={getTipSettings(
            storeInfo.fulfillmentTypes,
            orderingOptions.selectedTransport,
            storeInfo.settings
          )}
          preTaxTotal={Money(subtotal)}
          setTip={setTip}
          setSelectedTipOption={setSelectedTipOption}
          selectedTipOption={selectedTipOption}
          tip={tip}
          variant="olo"
        />
      );
    }

    let deliveryInstructions;
    if (orderingOptions.selectedTransport === "Delivery") {
      deliveryInstructions = (
        <DeliveryInstructions
          instructions={orderingOptions.deliveryInstructions}
          onUpdateDeliveryInstructions={onUpdateDeliveryInstructions}
        />
      );
    }

    let curbsidePickupInstructions;
    if (
      orderingOptions.selectedTransport === "Pick up" &&
      orderingOptions.isCurbsidePickup
    ) {
      curbsidePickupInstructions = (
        <CurbsidePickupInstructions
          instructions={orderingOptions.curbsidePickupInstructions}
          guestInstructions={
            storeInfo.settings.pickup?.curbsideGuestInstructions
          }
          onUpdateCurbsidePickupInstructions={
            onUpdateCurbsidePickupInstructions
          }
        />
      );
    }

    let curbsidePickupSettings;
    if (
      orderingOptions.selectedTransport === "Pick up" &&
      curbsidePickupAvailable
    ) {
      curbsidePickupSettings = (
        <div className="curbside-pickup-settings">
          <Checkbox
            label="Curbside Pickup"
            checked={orderingOptions.isCurbsidePickup}
            onChangeHandler={pipe(getTargetChecked, onUpdateIsCurbsidePickup)}
          />
          {curbsidePickupInstructions}
        </div>
      );
    }

    return (
      <div className="checkout card">
        <CheckoutError>
          {orderMenuError && (
            <Button type="primary" size="md" onClick={() => onReturnToMenu()}>
              Update Order
            </Button>
          )}
        </CheckoutError>
        <CustomerInfoContainer saveOnBlur={false} />
        {deliveryInstructions}
        {curbsidePickupSettings}
        {gratuitySection}
        <PromoCode
          applyPromo={applyPromo}
          promoCode={promoCode}
          isValid={promoCodeIsValid}
        />
        {contains("gift_card", paymentOptions) && (
          <GiftCard
            applyGiftCard={applyGiftCard}
            balanceDue={calculatedTotals ? calculatedTotals.balanceDue : null}
            giftCards={giftCards}
            giftCardStatus={giftCardsRequest}
            removeGiftCard={removeGiftCard}
          />
        )}
        <TransitionGroup>
          {showPaymentForm && (
            <CSSTransition
              classNames="expanding"
              timeout={{ enter: 300, exit: 300 }}>
              <PaymentContainer
                orderResponse={orderResponse}
                getPaymentConfigurationRef={this.getPaymentConfigurationRef}
              />
            </CSSTransition>
          )}
        </TransitionGroup>
        <div className="submit-button-wrapper">
          {/* add total and ellipsis spinner for calculation - see HQ-4470 */}
          <p>
            By submitting your order, you agree to Lightspeed’s{" "}
            <a
              href="https://assets-upserve.s3.amazonaws.com/terms-and-conditions/online-ordering-terms-and-conditions.html"
              rel="noopener noreferrer"
              target="_blank">
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              href="https://upserve.com/privacy/"
              rel="noopener noreferrer"
              target="_blank">
              Privacy Policy
            </a>
            .
          </p>
          <Button
            type="primary"
            size="large"
            className="submit-order"
            onClick={(...e) => {
              trackAnalytic("Clicked Submit Order");
              this.debouncedSubmit(...e);
            }}
            disabled={submitDisabled}>
            Submit Order
          </Button>
        </div>
      </div>
    );
  }
}

Checkout.propTypes = {
  applyGiftCard: PropTypes.func.isRequired,
  applyPromo: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired,
  checkout: PropTypes.object.isRequired,
  consumer: PropTypes.object.isRequired,
  fetchCalculatedTotals: PropTypes.func.isRequired,
  flags: PropTypes.object,
  onOrderStatusUpdate: PropTypes.func.isRequired,
  onReturnToMenu: PropTypes.func.isRequired,
  onSubmitOrder: PropTypes.func.isRequired,
  onPaymentSuccess: PropTypes.func.isRequired,
  onUpdateDeliveryInstructions: PropTypes.func.isRequired,
  onUpdateIsCurbsidePickup: PropTypes.func.isRequired,
  onUpdateCurbsidePickupInstructions: PropTypes.func.isRequired,
  onUpdateOrderStatusWithError: PropTypes.func.isRequired,
  orderingOptions: PropTypes.object.isRequired,
  removeGiftCard: PropTypes.func.isRequired,
  sessionId: PropTypes.string.isRequired,
  setSelectedTipOption: PropTypes.func.isRequired,
  setTip: PropTypes.func.isRequired,
  storeInfo: PropTypes.object.isRequired,
};

export default withLDConsumer()(Checkout);
