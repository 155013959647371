import Aviator from "aviator";
import React from "react";
import { OnlineOrderingCreditCardList } from "./payment/OnlineOrderingCreditCardList";
import Header from "components/header";
import { Loader } from "components/loader";
import AddressesContainer from "containers/account/addresses_container";
import CustomerInfoContainer from "containers/account/customer_info_container";
import OrderHistoryContainer from "containers/account/order_history_container";
import getBaseUri, { dineInQueryString, isDineInUri } from "get_base_uri";
import { usePrettyUrl } from "helpers/hooks/usePrettyUrl";
import { useOloStoreInfo } from "hooks/online_ordering/useOloStoreInfo";
import type { AviatorRequest } from "types/Aviator";

export interface AccountProps {
  request: AviatorRequest;
}

export const Account = ({ request }: AccountProps) => {
  const storePrettyUrl = usePrettyUrl();
  const { data: storeInfo } = useOloStoreInfo(storePrettyUrl);

  if (!storeInfo) {
    return (
      <div className="app account">
        <Loader />
      </div>
    );
  }

  return (
    <div className="app account">
      <Header
        locationInfo={storeInfo}
        onHeadingClick={() =>
          Aviator.navigate(`${getBaseUri(request)}${dineInQueryString()}`)
        }
      />

      <a
        className="back-button navigate"
        href={`${getBaseUri(request)}${dineInQueryString()}`}>
        &lt; Back to your order
      </a>
      <div className="content">
        <div className="account-settings card main-view">
          <CustomerInfoContainer saveOnBlur />
          <AddressesContainer />
          <OnlineOrderingCreditCardList />
          {!isDineInUri() && <OrderHistoryContainer />}
        </div>
      </div>
    </div>
  );
};
