import header from "images/header.png";
import PropTypes from "prop-types";
import { equals } from "ramda";
import React from "react";

import AuthenticationContainer from "containers/authentication_container";
import { getPreviewHeaderUrl, showDefaultHeaderImage } from "get_base_uri";
import trackAnalytic from "helpers/analytics";

// Transform profile:
// 1. Automatic file format
// 2. Automatic quality with `best` profile
// 3. Limit-based cropping
// 4. Max height of 512px to give retina screens detail
const CUSTOM_HEADER_TRANSFORM = "/f_auto,q_auto:best,c_limit,h_512";
const CUSTOM_HEADER_PATH_SPLIT_TOKEN = "/upload";

function Header({ locationInfo, onHeadingClick }) {
  const { locationName, locationUrl, address, phoneNumber, settings } =
    locationInfo;

  let storeInfo;
  if (address) {
    const { line1, line2, city, state: geoState, zip } = address;

    const formattedAddress = `${line1},${
      line2 ? ` ${line2}` : ""
    } ${city} ${geoState} ${zip}`;
    storeInfo = (
      <div className="store-info">
        <span className="address">{formattedAddress}</span>
        <span className="address-spacer">|</span>
        <span className="phone">{phoneNumber}</span>
      </div>
    );
  }

  let websiteLink;
  if (locationUrl) {
    let formattedUrl = `//${locationUrl}`;
    if (locationUrl && equals(locationUrl.substr(0, 4), "http")) {
      formattedUrl = locationUrl;
    }

    websiteLink = (
      <div className="website">
        <a
          href={formattedUrl}
          onClick={() => {
            trackAnalytic("Click Restaurant URL");
            return true;
          }}
          target="_blank"
          rel="noopener noreferrer">
          {locationUrl}
        </a>
      </div>
    );
  }

  const getPreviewUrl = () => {
    if (showDefaultHeaderImage()) {
      return header;
    }
    return getPreviewHeaderUrl();
  };

  const getCustomHeaderUrl = () => {
    if (settings && settings.headerImageUrl) {
      return getTransformUrl(settings.headerImageUrl);
    }
    return null;
  };

  const bannerUrl = getPreviewUrl() || getCustomHeaderUrl() || header;

  return (
    <div className="header" style={{ backgroundImage: `url(${bannerUrl})` }}>
      <div className="location-details">
        <button
          className="location-name clickable"
          onClick={onHeadingClick}
          type="button">
          {locationName}
        </button>
        {storeInfo}
        {websiteLink}
      </div>
      <AuthenticationContainer />
    </div>
  );
}

Header.propTypes = {
  locationInfo: PropTypes.object,
  onHeadingClick: PropTypes.func,
  selectedTransport: PropTypes.string,
};

function isTransformableUrl(path) {
  return (
    path.indexOf("cloudinary") > -1 && // Is a cloudinary url
    path.indexOf("upserve") > -1 && // in an upserve cloud
    path.indexOf("olo-curated") === -1 && // not a curated image
    path.indexOf(CUSTOM_HEADER_PATH_SPLIT_TOKEN) > -1 && // that contains the split token
    path.indexOf(CUSTOM_HEADER_TRANSFORM) === -1 && // does NOT already include the transform
    path.split("/").length === 8 // and has the expected number of segments
  );
}

// todo: This is a horrid kludge. Need to get these
//       transform paths into a server-side call to
//       reliably use Cloudinary SDK with public_id
//       values.
export function getTransformUrl(path) {
  if (!isTransformableUrl(path)) {
    return path;
  }

  const transformedPath = path.split(CUSTOM_HEADER_PATH_SPLIT_TOKEN);

  transformedPath.splice(
    1,
    0,
    CUSTOM_HEADER_PATH_SPLIT_TOKEN + CUSTOM_HEADER_TRANSFORM
  );

  return transformedPath.join("");
}

const Test = {
  isTransformableUrl,
  getTransformUrl,
};

export default Header;
export { Test };
