import { connect } from "react-redux";

import {
  decrementItemQuantity,
  editInstructions,
  incrementItemQuantity,
  toggleModifier,
  toggleSide,
} from "actions/online_ordering/cart";

import { editOrderOptions } from "actions/online_ordering/ordering_options";

import ItemModal from "components/online_ordering/item_modal";

function mapStateToProps(state, ownProps) {
  return {
    item: state.cart.editableItem,
    onAddItem: ownProps.onAddItem,
    onClose: ownProps.onClose,
  };
}

const mapDispatchToProps = {
  onToggleModifier: toggleModifier,
  onToggleSide: toggleSide,
  onIncrementItemQuantity: incrementItemQuantity,
  onDecrementItemQuantity: decrementItemQuantity,
  onEditInstructions: editInstructions,
  onEditOrderOptions: editOrderOptions,
};

const ItemModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemModal);

export default ItemModalContainer;
