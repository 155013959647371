import React, { type ReactNode, useCallback, useState } from "react";

import styled from "styled-components";
import CreditCard from "components/account/credit_cards/credit_card";
import Button from "components/button";
import { withTracking } from "helpers/analytics";

const StyledCreditCardList = styled.ul`
  width: 100%;
  max-width: 480px;
  min-width: 300px;
  overflow-x: auto;
  display: grid;
  grid-template-columns: 0fr 1fr 0fr;
  grid-row-gap: 15px;
  grid-column-gap: 10px;
`;

const StyledCreditCard = styled(CreditCard)`
  display: grid;
  grid-template-columns: subgrid;
  grid-column-start: 1;
  grid-column-end: -1;
`;

export interface CreditCardListChildrenProps {
  onCloseForm: () => void;
}

export interface BaseCard {
  cardUuid: string;
}

export interface CreditCardListProps<TCard extends BaseCard = BaseCard> {
  appName: string;
  creditCardList: readonly TCard[];
  onRemoveCreditCard: (card: TCard) => void;
  children: (props: CreditCardListChildrenProps) => ReactNode;
}

export function CreditCardList<TCard extends BaseCard = BaseCard>({
  appName,
  creditCardList,
  onRemoveCreditCard,
  children,
}: CreditCardListProps<TCard>) {
  const [showCardForm, setShowCardForm] = useState(false);

  const handleCreateNewCard = useCallback(() => {
    setShowCardForm(true);
  }, []);

  const handleCloseForm = useCallback(() => {
    setShowCardForm(false);
  }, []);

  return (
    <div className="card-list-wrapper">
      <h3>Credit card information</h3>
      <StyledCreditCardList>
        {creditCardList.map(card => (
          <StyledCreditCard
            key={card.cardUuid}
            card={card}
            handleRemoveCard={onRemoveCreditCard}
          />
        ))}
      </StyledCreditCardList>
      {showCardForm ? (
        children({ onCloseForm: handleCloseForm })
      ) : (
        <Button
          type="text"
          onClick={withTracking(
            handleCreateNewCard,
            `${appName} - Account - click add new credit card`
          )}>
          + Add new card
        </Button>
      )}
    </div>
  );
}
