import { ORDERS_URL } from "api_urls";
import { checkStatus, getRequest, parseJSON } from "helpers/ajax_request";
import type { AdyenPaymentAccessResponse } from "interfaces/credit_card/adyen/AdyenPaymentMethodsResponse";

export async function getAdyenDigitalGiftCardPaymentAccess(
  prettyUrl: string
): Promise<AdyenPaymentAccessResponse> {
  const response = await fetch(
    `${ORDERS_URL}/gift_cards/${prettyUrl}/payment_access/`,
    getRequest()
  );
  checkStatus(response);
  return parseJSON(response);
}
