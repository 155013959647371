import React, { type ReactNode, useLayoutEffect, useState } from "react";
import { scrollToWithSpacing } from "helpers/browser";
import { useOloSelector } from "hooks/online_ordering/useOloSelector";

export interface CheckoutErrorProps {
  children?: ReactNode;
}

export const CheckoutError = ({ children }: CheckoutErrorProps) => {
  const orderStatusError = useOloSelector(
    state => state.checkout?.status.orderStatusError
  );
  const [errorContainer, setErrorContainer] = useState<HTMLDivElement | null>(
    null
  );

  useLayoutEffect(() => {
    if (errorContainer) {
      scrollToWithSpacing(errorContainer);
    }
  }, [errorContainer]);

  if (!orderStatusError) {
    return null;
  }

  return (
    <div className="order-error" ref={setErrorContainer}>
      <div>{orderStatusError}</div>
      {children}
    </div>
  );
};
