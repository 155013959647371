import { useField } from "formik";
import type { FieldValidator } from "formik/dist/types";
import React, { type LabelHTMLAttributes } from "react";
import Input, { type IInputProps } from "components/input";

interface OloAccountInputBaseProps extends Omit<IInputProps, "error"> {
  name: string;
  label: string;
  validate?: FieldValidator;
}

export type OloAccountInputProps = OloAccountInputBaseProps &
  (
    | {
        hidesLabel?: false;
        labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
      }
    | { hidesLabel: true; labelProps?: never }
  );

export const OloAccountInput = ({
  name,
  hidesLabel,
  label,
  validate,
  labelProps,
  ...rest
}: OloAccountInputProps) => {
  const [inputProps, { error, touched }] = useField({
    name,
    validate,
  });
  const { id } = rest;
  return (
    <>
      {!hidesLabel && (
        <label htmlFor={id} {...labelProps}>
          {label}
        </label>
      )}

      <Input
        aria-label={label}
        {...rest}
        {...inputProps}
        error={touched ? error : undefined}
      />
    </>
  );
};
