import { withLDConsumer } from "launchdarkly-react-client-sdk";
import PropTypes from "prop-types";

import { equals, find, isEmpty, isNil, not, partial, propEq } from "ramda";
import React from "react";
import { OLOPaymentAuthorization } from "./checkout/payment/OLOPaymentAuthorization";
import { OrderConfirmationModal } from "./checkout/payment/order_confirm_modal/OrderConfirmationModal";
import UnapprovedMenuWarning from "./unapproved_menu_warning";
import Button from "components/button";
import Icon from "components/icon";
import AcceptingOrders from "components/online_ordering/cart/accepting_orders";
import CartChangeModal from "components/online_ordering/cart/cart_change_modal";
import ItemsRemovedModal from "components/online_ordering/cart/items_removed_modal";
import OrderingOptionsBarContainer from "containers/online_ordering/cart/ordering_options_bar_container";
import { selectedOptionOfAvailable } from "helpers/online_ordering/cart";

import { isValidOrderTime, nextValidOrderDay } from "helpers/order_times";

class Cart extends React.Component {
  static getDerivedStateFromProps(
    { filteredItems, invalidReorderItems },
    { confirmedFilteredItems }
  ) {
    if (not(equals(filteredItems, confirmedFilteredItems))) {
      return { showChangeCartModal: true };
    }
    if (invalidReorderItems && invalidReorderItems.length > 0) {
      return { showItemsRemovedModal: true };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      showChangeCartModal: false,
      showItemsRemovedModal: false,
      // eslint-disable-next-line react/no-unused-state
      confirmedFilteredItems: [],
    };

    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.closeCartChangeModal = this.closeCartChangeModal.bind(this);
    this.closeItemsRemovedModal = this.closeItemsRemovedModal.bind(this);
  }

  componentDidMount() {
    const { orderingOptions, storeInfo, onChangeTransportMethod } = this.props;
    const { selectedTransport } = orderingOptions;
    const selectedOptionName = selectedOptionOfAvailable(
      selectedTransport,
      storeInfo.fulfillmentTypes
    );
    if (!equals(selectedOptionName, selectedTransport)) {
      onChangeTransportMethod(selectedOptionName);
    }
  }

  handleRemoveItem(cartId) {
    const { clearCalculatedTotals, onReturnToMenu, onRemoveItem, cart } =
      this.props;
    if (equals(cart.cartItems.length, 1)) {
      onReturnToMenu();
      clearCalculatedTotals();
      return onRemoveItem(cartId);
    }

    return onRemoveItem(cartId);
  }

  closeCartChangeModal() {
    const { filteredItems } = this.props;
    this.setState({
      showChangeCartModal: false,
      // eslint-disable-next-line react/no-unused-state
      confirmedFilteredItems: filteredItems,
    });
  }

  closeItemsRemovedModal() {
    this.setState({ showItemsRemovedModal: false });
    this.props.removeInvalidReorderItems();
  }

  render() {
    const {
      cart,
      cartClass,
      checkout,
      createEditableItem,
      deliveryTaxRate,
      filteredItems,
      invalidReorderItems,
      onChangeDeliveryTimeFrame,
      onDeleteEditableItem,
      onProceedToCheckout,
      onReturnToMenu,
      onUpdateItem,
      orderingOptions,
      storeInfo,
      useExtendedOrderAhead,
      stockLevels,
      flags,
    } = this.props;

    const { cartItems } = cart;
    const {
      payment: { calculatedTotals, giftCards, paymentType, tip },
      status: { orderStatusError, orderStatusErrorSource, isCheckingOut },
    } = checkout;

    const selectedOptionName = selectedOptionOfAvailable(
      orderingOptions.selectedTransport,
      storeInfo.fulfillmentTypes
    );
    const selectedType = find(
      propEq("name", selectedOptionName),
      storeInfo.fulfillmentTypes
    );
    const dineInAvailable = selectedType && flags.oloDineInMvp;

    if (equals(selectedOptionName, "Dine in") && !dineInAvailable) {
      // return early if dine in is not available as the rest of this file requires the fulfillment type to be present
      return (
        <div className={`cart card ${cartClass}`}>
          <OrderingOptionsBarContainer
            cartClass={cartClass}
            cartIsEmpty={isEmpty(cartItems)}
            selectedOptionName={selectedOptionName}
            useExtendedOrderAhead={useExtendedOrderAhead}
          />
          <div className={`cart card ${cartClass}`}>
            <div className="ordering-not-available">
              <p className="message">
                Dine-In ordering is not currently available at{" "}
                {storeInfo.locationName}. Please work with your server to place
                an order.
              </p>
              <Icon type="receipt-ghost" />
            </div>
          </div>
        </div>
      );
    }

    if (
      equals(selectedOptionName, "Dine in") &&
      isNil(orderingOptions.dineInTableName)
    ) {
      // if table name is not present, user did not scan a QR code
      return (
        <div className={`cart card ${cartClass}`}>
          <OrderingOptionsBarContainer
            cartClass={cartClass}
            cartIsEmpty={isEmpty(cartItems)}
            selectedOptionName={selectedOptionName}
            useExtendedOrderAhead={useExtendedOrderAhead}
          />
          <div className={`cart card ${cartClass}`}>
            <div className="ordering-not-available">
              <p className="message">
                {storeInfo.locationName} requires you scan a QR code on-premise
                to place a dine-in online order.
              </p>
              <Icon type="receipt-ghost" />
            </div>
          </div>
        </div>
      );
    }

    let storeIsOpen = false;
    const transportMethodHours = find(
      propEq("name", selectedOptionName),
      storeInfo.fulfillmentTypes
    ).schedule;
    storeIsOpen = isValidOrderTime(transportMethodHours);

    if (not(equals(storeInfo.orderTakingStatus, "accepting"))) {
      return (
        <div className={`cart card ${cartClass}`}>
          <div className="ordering-not-available">
            <Icon type="heart-break" />
            <div className="message first">
              This location is currently not accepting online orders.
            </div>
            <div className="message second">
              Please check back another time.
            </div>
          </div>
        </div>
      );
    }

    let content;

    if (
      not(equals(this.props.storeInfo.integrationStatus, "live")) &&
      this.props.storeInfo.published === true
    ) {
      content = (
        <div className={`cart card ${cartClass}`}>
          <div className="ordering-not-available">
            <UnapprovedMenuWarning />
            <Icon type="receipt-ghost" />
          </div>
        </div>
      );
    } else if (
      storeIsOpen ||
      equals(orderingOptions.selectedTimeFrame, "Later")
    ) {
      content = (
        <AcceptingOrders
          calculatedTotals={calculatedTotals}
          calculationError={
            orderStatusError && orderStatusErrorSource === "calculateTotal"
              ? orderStatusError
              : null
          }
          cartItems={cartItems}
          createEditableItem={createEditableItem}
          deliveryTaxRate={deliveryTaxRate}
          giftCards={giftCards}
          isCheckingOut={isCheckingOut}
          onAddMoreItems={onReturnToMenu}
          onCheckout={onProceedToCheckout}
          onDeleteEditableItem={onDeleteEditableItem}
          onRemoveItem={this.handleRemoveItem}
          onUpdateItem={onUpdateItem}
          orderingOptions={orderingOptions}
          paymentType={paymentType}
          stockLevels={stockLevels}
          storeInfo={storeInfo}
          tip={tip}
        />
      );
    } else if (equals("Dine in", orderingOptions.selectedTransport)) {
      content = (
        <div className={`cart card ${cartClass}`}>
          <div className="ordering-not-available">
            <p className="message">
              You are currently outside of {storeInfo.locationName}'s dine-in
              ordering hours. Please work with your server to place an order.
            </p>
            <Icon type="receipt-ghost" />
          </div>
        </div>
      );
    } else {
      const nextDay = nextValidOrderDay(
        transportMethodHours,
        selectedType?.estimate[1] // max prep time
      );

      let preorderButton;
      let message = `We’re not currently accepting orders for ${selectedOptionName.toLowerCase()}. ${selectedOptionName} will resume ${
        nextDay.name
      } at ${nextDay.time}.`;

      if (storeInfo.futureOrder && !equals(selectedOptionName, "Dine in")) {
        preorderButton = (
          <div className="preorder-wrapper">
            <Button
              className="preorder"
              type="primary"
              onClick={partial(onChangeDeliveryTimeFrame, ["Later"])}
              size="medium">
              Preorder
            </Button>
          </div>
        );

        message = `Now accepting preorders for ${selectedOptionName.toLowerCase()} starting ${
          nextDay.name
        } at ${nextDay.time}.`;
      }

      content = (
        <div className={`cart card ${cartClass}`}>
          <div className="ordering-not-available">
            <div className="message">{message}</div>
            {preorderButton}
            <Icon type="receipt-ghost" />
          </div>
        </div>
      );
    }

    let cartChangeModal;
    let itemsRemovedModal;

    if (this.state.showChangeCartModal && !isEmpty(filteredItems)) {
      cartChangeModal = (
        <CartChangeModal
          filteredItems={filteredItems}
          onCancel={this.closeCartChangeModal}
        />
      );
    }

    if (this.state.showItemsRemovedModal) {
      itemsRemovedModal = (
        <ItemsRemovedModal
          items={invalidReorderItems}
          onCancel={this.closeItemsRemovedModal}
        />
      );
    }
    return (
      <div className={`cart card ${cartClass}`}>
        <OrderingOptionsBarContainer
          cartClass={cartClass}
          cartIsEmpty={isEmpty(cartItems)}
          selectedOptionName={selectedOptionName}
          useExtendedOrderAhead={useExtendedOrderAhead}
        />
        {content}
        {itemsRemovedModal}
        {cartChangeModal}
        <OrderConfirmationModal />
        <OLOPaymentAuthorization />
      </div>
    );
  }
}

Cart.propTypes = {
  cart: PropTypes.object.isRequired,
  cartClass: PropTypes.string.isRequired,
  checkout: PropTypes.object.isRequired,
  clearCalculatedTotals: PropTypes.func.isRequired,
  createEditableItem: PropTypes.func.isRequired,
  deliveryTaxRate: PropTypes.string,
  filteredItems: PropTypes.array,
  invalidReorderItems: PropTypes.array,
  onChangeDeliveryTimeFrame: PropTypes.func.isRequired,
  onChangeTransportMethod: PropTypes.func.isRequired,
  onDeleteEditableItem: PropTypes.func.isRequired,
  onProceedToCheckout: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onReturnToMenu: PropTypes.func.isRequired,
  onUpdateItem: PropTypes.func,
  orderingOptions: PropTypes.object,
  removeInvalidReorderItems: PropTypes.func.isRequired,
  storeInfo: PropTypes.object.isRequired,
  useExtendedOrderAhead: PropTypes.bool,
  stockLevels: PropTypes.object,
  flags: PropTypes.object,
};

export default withLDConsumer()(Cart);
