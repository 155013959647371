import { useEffect } from "react";
import trackAnalytic from "helpers/analytics";

// TODO import thru npm instead of declaring like this.
declare global {
  interface Window {
    analytics: {
      identify: (identifier: string) => void;
      page: (obj: unknown) => void;
    };
  }
}

export const useOloAnalytics = ({
  identifier,
  identifyTrackingMessage,
}: {
  identifier?: string;
  identifyTrackingMessage: string;
}) => {
  const analytics = window.analytics;

  useEffect(() => {
    if (!analytics || !identifier) {
      return;
    }

    analytics.identify(identifier);
    trackAnalytic(identifyTrackingMessage);
  }, [analytics, identifier, identifyTrackingMessage]);

  useEffect(() => {
    if (!analytics) {
      return;
    }

    window.onerror = (message, source, line, col, error) => {
      trackAnalytic("Error", {
        message,
        source,
        line,
        col,
        error,
      });
      return false;
    };

    // This will set the property values: https://github.com/segmentio/analytics.js/blob/master/analytics.js#L587
    analytics.page({ referrer: null, search: null, url: null });
  }, [analytics]);
};
