import moment from "moment";
import { find, propEq } from "ramda";
import { useMemo } from "react";
import type { MinifiedOrderInfo } from "./useModalOrderInformation";
import { selectedOptionOfAvailable } from "helpers/online_ordering/cart";

export const useOrderReadyTime = ({
  orderingOptions,
  storeInfo,
  checkoutStatus,
}: MinifiedOrderInfo) => {
  const { selectedTransport } = orderingOptions;
  const { orderTime, isOrderAhead } = checkoutStatus;
  const { fulfillmentTypes } = storeInfo;

  const transportMethod = useMemo(() => {
    const selectedOptionName = selectedOptionOfAvailable(
      selectedTransport,
      fulfillmentTypes
    );
    return find(propEq("name", selectedOptionName), fulfillmentTypes);
  }, [fulfillmentTypes, selectedTransport]);

  return useMemo(() => {
    if (!orderTime || !transportMethod) {
      return null;
    }
    const orderTimeMoment = moment(orderTime);
    if (isOrderAhead) {
      return orderTimeMoment.format("dddd, MMMM Do YYYY, h:mm A z");
    }
    return `${orderTimeMoment
      .clone()
      .add(transportMethod.estimate[0], "minutes")
      .format("h:mm a")} - ${orderTimeMoment
      .clone()
      .add(transportMethod.estimate[1], "minutes")
      .format("h:mm a")}`;
  }, [isOrderAhead, orderTime, transportMethod]);
};
