import { type ComponentType, type ReactNode, type Ref, lazy } from "react";
import { getFirstDataInitialValues } from "./first_data/getFirstDataInitialValues";
import type { OloAccountCreditCardFormValues } from "types/online_ordering/accounts/OloAccountCreditCardFormValues";
import type { OloAccountSaveCreditCardResult } from "types/online_ordering/OloAccountSaveCreditCardResult";
import type { CCProcessor } from "types/payments/credit_card";
import type { StoreInfo } from "types/reducers/store_info";

export interface OloCreditCardFormImplProps {
  storeInfo: StoreInfo;
  submitHandlerRef: Ref<SubmitHandler>;
  children: ReactNode;
}

export type SubmitHandler<
  T extends OloAccountCreditCardFormValues = OloAccountCreditCardFormValues,
> = {
  bivarianceHack(values: T): Promise<OloAccountSaveCreditCardResult>;
}["bivarianceHack"];

export interface CCProcessorConfiguration {
  form: ComponentType<OloCreditCardFormImplProps>;
  inputs: ComponentType;
  getInitialValues?: (
    initialFormValues: OloAccountCreditCardFormValues
  ) => OloAccountCreditCardFormValues;
}

export const CCProcessorConfigurationMap: Record<
  CCProcessor,
  CCProcessorConfiguration
> = {
  firstData: {
    form: lazy(() => import("./first_data/OloAccountFirstDataForm")),
    inputs: lazy(
      () => import("./first_data/OloAccountFirstDataCreditCardInputs")
    ),
    getInitialValues: getFirstDataInitialValues,
  },
  adyen: {
    form: lazy(() => import("./adyen/OloAccountAdyenForm")),
    inputs: lazy(() => import("./adyen/OloAccountAdyenCreditCardInputs")),
  },
};
