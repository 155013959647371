import { ORDERS_URL } from "api_urls";
import { checkStatus, deleteRequest } from "helpers/ajax_request";

export const oloDeleteCreditCard = async (
  storePrettyUrl: string,
  cardUuid: string
) => {
  const response = await fetch(
    `${ORDERS_URL}/card_registration`,
    deleteRequest({ storePrettyUrl, id: cardUuid }) as RequestInit
  );
  checkStatus(response);
};
