import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { addIndex, equals, forEach } from "ramda";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import Button from "components/button";
import Input from "components/input";
import type { ILDFlags } from "types/ld_feature_flags";
import type { GiftCardProps } from "types/reducers/payment";
import { RequestStatus } from "types/requests";

const StyledGrid = styled.section`
  display: grid;
  grid-template-columns: 1fr 120px 6.5rem;
  grid-template-areas: "card-number pin-number button";
  grid-column-gap: 0;
  width: 100%;

  div {
    grid-area: pin-number;
  }

  div:first-child {
    grid-area: card-number;
  }

  .text-input,
  .applied-code {
    padding-right: 1rem;
    max-height: 33px;
  }

  button {
    grid-area: button;
    width: 100%;
    line-height: 100%;
  }
  @media (max-width: 1024px) {
    row-gap: 13px;
    grid-template-columns: 7.5rem 6.5rem 1fr;
    grid-template-areas:
      "card-number card-number card-number"
      "pin-number button .";

    button {
      align-self: flex-end;
      line-height: 150%;
      max-height: 36.38px;
    }
    .text-input,
    .applied-code {
      max-height: 37px;
    }
  }
`;

const StyledFlex = styled.div`
  display: flex;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

interface IGiftCardRowProps {
  applyGiftCard: (newGiftCard: GiftCardProps, flags: ILDFlags | {}) => void;
  removeGiftCard: (index: number, flags: ILDFlags | {}) => void;
  giftCard: GiftCardProps;
  giftCardStatus: RequestStatus;
  giftCards: GiftCardProps[];
  flags?: ILDFlags | {};
}

function GiftCardRow({
  applyGiftCard,
  removeGiftCard,
  giftCard,
  giftCardStatus,
  giftCards,
  flags = {},
}: IGiftCardRowProps) {
  const [giftCardObject, setGiftCardObject] = useState(giftCard);

  const formatGiftCardNumber = val => {
    let newVal = "";
    const foreachIndexed = addIndex(forEach);
    foreachIndexed((value, idx) => {
      if ((val.length - idx) % 4 === 0 && idx > 0) newVal = newVal.concat("-");
      newVal = newVal.concat(value as string);
    }, val);
    return newVal;
  };

  useEffect(() => {
    let newGiftCardNumber = giftCard.giftCardNumber;
    if (!/-/g.test(giftCard.giftCardNumber)) {
      newGiftCardNumber = formatGiftCardNumber(giftCard.giftCardNumber);
    }
    setGiftCardObject({ ...giftCard, giftCardNumber: newGiftCardNumber });
  }, [giftCard]);

  const removeDashes = input => {
    return input.replace(/-/g, "");
  };

  const apply = useCallback(() => {
    const newGiftCard = {
      ...giftCardObject,
      giftCardNumber: removeDashes(giftCardObject.giftCardNumber),
    };
    applyGiftCard(newGiftCard, flags);
    // added since a new eslint rule,
    // feel free to fix this eslint error if you can test it properly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyGiftCard, giftCardObject]);

  const remove = useCallback(() => {
    removeGiftCard(giftCardObject.index, flags);
    // added since a new eslint rule,
    // feel free to fix this eslint error if you can test it properly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeGiftCard, giftCardObject]);

  const updateGiftCard = event => {
    const propName = event.target.name;
    let newVal = event.target.value;
    const re = /^[\d\-\b]+$/;
    const val = removeDashes(event.target.value);
    if (event.target.value === "" || re.test(event.target.value)) {
      if (equals(propName, "giftCardNumber")) {
        newVal = formatGiftCardNumber(val);
      }
      setGiftCardObject({ ...giftCardObject, [propName]: newVal });
    }
  };

  const isApplied = giftCardCheck => {
    return giftCards.some(
      giftCardItem =>
        equals(
          giftCardItem.giftCardNumber,
          removeDashes(giftCardCheck.giftCardNumber)
        ) &&
        equals(giftCardItem.pin, giftCardCheck.pin) &&
        equals(giftCardItem.index, giftCardCheck.index)
    );
  };

  const isFailed = giftCardCheck => {
    return (
      !isApplied(giftCardCheck) && equals(giftCardStatus, RequestStatus.FAILURE)
    );
  };

  const isDuplicate = giftCardCheck => {
    return giftCards.some(
      giftCardItem =>
        equals(
          giftCardItem.giftCardNumber,
          removeDashes(giftCardCheck.giftCardNumber)
        ) && !equals(giftCardItem.index, giftCardCheck.index)
    );
  };

  const handleErrors = giftCardCheck => {
    if (isFailed(giftCardCheck)) {
      return "The card entered is not valid";
    }
    if (isDuplicate(giftCardCheck)) {
      return "The card you entered is already applied";
    }
    return "";
  };

  return (
    <div className="row">
      <StyledGrid>
        <StyledFlex>
          <label
            htmlFor={`gift-card-number${giftCardObject.index}`}
            className="shrink">
            Card Number
          </label>{" "}
          <Input
            disabled={isApplied(giftCardObject)}
            error={handleErrors(giftCardObject)}
            id={`gift-card-number${giftCardObject.index}`}
            name="giftCardNumber"
            type="tel"
            onChange={e => updateGiftCard(e)}
            value={giftCardObject.giftCardNumber}
          />
        </StyledFlex>
        <StyledFlex>
          <label
            htmlFor={`gift-card-pin${giftCardObject.index}`}
            className="shrink">
            PIN
          </label>{" "}
          <Input
            disabled={isApplied(giftCardObject)}
            error={
              isFailed(giftCardObject) || isDuplicate(giftCardObject) ? " " : ""
            }
            id={`gift-card-pin${giftCardObject.index}`}
            name="pin"
            onChange={e => updateGiftCard(e)}
            type="tel"
            value={giftCardObject.pin}
          />
        </StyledFlex>
        {isApplied(giftCardObject) ? (
          <Button type="secondary" className="caps" onClick={remove}>
            Remove
          </Button>
        ) : (
          <Button
            type="primary"
            className="caps"
            onClick={apply}
            disabled={
              !giftCardObject.giftCardNumber ||
              !giftCardObject.pin ||
              isDuplicate(giftCardObject)
            }>
            Apply
          </Button>
        )}
      </StyledGrid>
    </div>
  );
}

export default withLDConsumer()(GiftCardRow);
