/* global google */
import {
  any,
  compose,
  contains,
  curry,
  equals,
  find,
  forEach,
  head,
  map,
  path,
  propEq,
  reject,
  uniq,
} from "ramda";

import isMobile from "helpers/is_mobile_device";

const DEFAULT_AUTOCOMPLETE_OPTIONS = {
  componentRestrictions: { country: ["us", "ca"] },
  fields: ["address_components", "geometry"],
  types: ["address"],
};

const NEW_EMPTY_ADDRESS = {
  uuid: "new-address",
  line1: "",
  line2: "",
  city: "",
  state: "",
  zip: "",
};

function getAddressFromGooglePlace(googlePlace, line2) {
  function placeGet(key) {
    let value = "";
    forEach(component => {
      if (contains(key, component.types)) {
        value = component.short_name || component.long_name;
      }
    }, googlePlace.address_components);
    return value;
  }

  return {
    pretty: googlePlace.formatted_address,
    line1: `${placeGet("street_number")} ${placeGet("route")}`,
    line2,
    city:
      placeGet("locality") ||
      placeGet("sublocality") ||
      placeGet("sublocality_level_1") ||
      placeGet("administrative_area_level_3") ||
      placeGet("administrative_area_level_2"),
    state: placeGet("administrative_area_level_1"),
    country: placeGet("country"),
    zip: placeGet("postal_code"),
  };
}

function fillAddress(address, autoComplete, onUpdateAddress) {
  const place = autoComplete.getPlace();
  const parsedAddress = getAddressFromGooglePlace(place, address.line2);
  onUpdateAddress(address.uuid, parsedAddress);
}

const findAddressByUuid = curry((addressUuid, addresses) =>
  find(propEq("uuid", addressUuid), addresses)
);

const removeAddressByUuid = curry((addressUuid, addresses) =>
  reject(propEq("uuid", addressUuid), addresses)
);

const isolateDisplayableAddresses = compose(removeAddressByUuid("new-address"));

function formatAddressForDropdown(address) {
  const { line1, line2 = "", city, state, zip } = address;

  return {
    pretty: `${line1},${line2 ? ` ${line2}` : ""} ${city}, ${state} ${zip}`,
    line1,
    line2,
    city,
    state,
    zip,
  };
}

function coordinatesForAddress(address) {
  const geocoder = new google.maps.Geocoder();
  const requestParams = {
    address: address.pretty,
    region: "US",
  };

  return new Promise((res, rej) => {
    geocoder.geocode(requestParams, (response, status) => {
      if (status === "OK") {
        const location = path(["geometry", "location"], head(response));
        res(location);
      }
      rej("Error fetching coordinates");
    });
  });
}

const ADDRESS_VALIDATIONS = {
  NOT_STARTED: "NOT_STARTED",
  VALID: "VALID",
  INVALID: "INVALID",
};

function getAddressValidation(isValid) {
  let addressValidationStatus = ADDRESS_VALIDATIONS.NOT_STARTED;
  if (typeof isValid === "boolean") {
    addressValidationStatus = isValid
      ? ADDRESS_VALIDATIONS.VALID
      : ADDRESS_VALIDATIONS.INVALID;
  }
  return addressValidationStatus;
}

const isValidAddressStatus = equals(ADDRESS_VALIDATIONS.VALID);

function setUserAddressViaMobileGeolocation() {
  return new Promise((res, rej) => {
    if (!isMobile() || !navigator.geolocation) {
      rej("Unsupported Device.");
    }
    navigator.geolocation.getCurrentPosition(
      position => {
        res(position.coords);
      },
      error => {
        rej(error);
      }
    );
  });
}

function coordinatesToNearestAddress(coordinates) {
  const geocoder = new google.maps.Geocoder();
  const requestParams = {
    location: { lat: coordinates.latitude, lng: coordinates.longitude },
  };

  return new Promise((res, rej) => {
    geocoder.geocode(requestParams, (response, status) => {
      if (status === "OK") {
        res(response);
      }
      rej(status);
    });
  });
}

function validateLocationIsWithinZone(deliveryGeojson, address) {
  const parsedGeoJson = JSON.parse(deliveryGeojson);
  let isValidLocation = false;
  // GeoJson data can contain multiple polygons, all need to be mapped
  // over and coordinates checked.
  const polygonCoordinates = map(feature => {
    return map(coordPairs => {
      // in Geojson longitude is first element in the tuple
      // see here: https://macwright.org/lonlat/
      const [lng, lat] = coordPairs;
      return { lat, lng };
    }, uniq(feature.geometry.coordinates[0]));
  }, parsedGeoJson.features);

  const polygons = map(polygon => {
    return new google.maps.Polygon({
      paths: polygon,
    });
  }, polygonCoordinates);

  return new Promise((res, rej) => {
    coordinatesForAddress(address).then(
      coordinates => {
        isValidLocation = any(
          polygon =>
            google.maps.geometry.poly.containsLocation(coordinates, polygon),
          polygons
        );
        res({ isValidLocation });
      },
      error => {
        console.error(error);
        rej({ isValidLocation, error });
      }
    );
  });
}

function preventBrowserAutofill(input) {
  // According to Google using the word "address" in id, name, or label will
  // prompt Chrome to autofill the input (1). In local testing however, the
  // word "street" in the placeholder attribute is the only text that reliably
  // caused autofill to appear. Chromium does not honor autocomplete="off"
  // attribute (2), which is automatically set by the Google Maps JS. However,
  // it can be overridden using the Stack-Overflow-inspired code below (3),
  // which indirectly forces Chrome autofill to be disabled (4). However, 1pass
  // autofill must be disabled by adding the word "search" at the beginning or
  // end of an id or name (5).
  // 1. https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform).
  // 2. https://crbug.com/587466
  // 3. https://stackoverflow.com/a/49161445
  // 4. https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#preventing_autofilling_with_autocompletenew-password
  // 5. https://1password.community/discussion/comment/611096/#Comment_611096
  const el = input;

  // Prevent 1password autofill. Also prepend "search-"" only once, so it's not
  // continually prepended every time an input is re-rendered (which can happen
  // on every input keypress, for example).
  if (!el.id.startsWith("search")) {
    el.id = `search-${el.id}`;
  }

  // Prevent Chrome autofill. Every time an input is rendered, make sure that
  // autocomplete is set to "new-password" even after Google Maps attempts to
  // set it to "off".
  const observerHack = new MutationObserver(function () {
    observerHack.disconnect();
    el.autocomplete = "new-password";
  });
  observerHack.observe(el, {
    attributes: true,
    attributeFilter: ["autocomplete"],
  });
}

function initGoogleAutocomplete({
  input,
  options = DEFAULT_AUTOCOMPLETE_OPTIONS,
  onAddressSelected,
}) {
  // Only proceed if the input and Google Autocomplete service are available.
  if (!google?.maps?.places?.Autocomplete) return;
  if (!input) return;

  // Init Google autocomplete input.
  const autocomplete = new google.maps.places.Autocomplete(input, options);

  // Prevent browser autofill from displaying over autocomplete results.
  preventBrowserAutofill(input);

  // Only proceed if callback function is defined.
  if (!onAddressSelected) return;

  // When autocomplete result is selected...
  autocomplete.addListener("place_changed", () => {
    // Pass the autocomplete and address objects to the callback function.
    const address = getAddressFromGooglePlace(autocomplete.getPlace());
    onAddressSelected({ autocomplete, address });
  });
}

export {
  ADDRESS_VALIDATIONS,
  coordinatesForAddress,
  coordinatesToNearestAddress,
  fillAddress,
  findAddressByUuid,
  formatAddressForDropdown,
  getAddressFromGooglePlace,
  getAddressValidation,
  initGoogleAutocomplete,
  isolateDisplayableAddresses,
  isValidAddressStatus,
  NEW_EMPTY_ADDRESS,
  removeAddressByUuid,
  setUserAddressViaMobileGeolocation,
  validateLocationIsWithinZone,
};
