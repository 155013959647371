import { type KeyboardEvent, useMemo, useState } from "react";

function useToolTipTrigger() {
  const [isFocused, setFocused] = useState(false);
  const [isHovered, setHovered] = useState(false);

  const bind = useMemo(() => {
    const closeToolTip = (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        setFocused(false);
        setHovered(false);
      }
      return null;
    };

    return {
      onBlur: () => setFocused(false),
      onFocus: () => setFocused(true),
      onKeyDown: (e: KeyboardEvent) => closeToolTip(e),
      onMouseEnter: () => setHovered(true),
      onMouseLeave: () => setHovered(false),
      tabIndex: 0,
    };
  }, []);

  return { bind, isOpen: isFocused || isHovered };
}

export default useToolTipTrigger;
