export enum RequestStatus {
  NOT_STARTED = "notStarted",
  REQUESTING = "requesting",
  SUCCESS = "success",
  FAILURE = "failure",
}

export type RemoteData<Result> =
  | { type: RequestStatus.NOT_STARTED }
  | { type: RequestStatus.REQUESTING }
  | { type: RequestStatus.SUCCESS; result: Result }
  | { type: RequestStatus.FAILURE; reason: string };

export function NotStarted<Result>(): RemoteData<Result> {
  return { type: RequestStatus.NOT_STARTED };
}

export function Requesting<Result>(): RemoteData<Result> {
  return { type: RequestStatus.REQUESTING };
}

export function Success<Result>(result: Result): RemoteData<Result> {
  return { type: RequestStatus.SUCCESS, result };
}

export function Failure<Result>(reason: string): RemoteData<Result> {
  return { type: RequestStatus.FAILURE, reason };
}
