import { useFlags } from "launchdarkly-react-client-sdk";
import React, { type ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateConsumerInfo } from "actions/online_ordering/consumer";
import { updateStoreInfo } from "actions/online_ordering/store_info";
import { Loader } from "components/loader";
import { usePrettyUrl } from "helpers/hooks/usePrettyUrl";
import { updatedStoreInfoPerFlags } from "helpers/online_ordering";
import { useOloAnalytics } from "hooks/online_ordering/useOloAnalytics";
import { useOloConsumerInfo } from "hooks/online_ordering/useOloConsumerInfo";
import { useOloStoreInfo } from "hooks/online_ordering/useOloStoreInfo";

export interface OloAppBootstrapProps {
  identifyTrackingMessage: string;
  children: ReactNode;
}

export const OloAppBootstrap = ({
  children,
  identifyTrackingMessage,
}: OloAppBootstrapProps) => {
  const storePrettyUrl = usePrettyUrl();
  const dispatch = useDispatch();
  const flags = useFlags();

  const { data: storeInfo } = useOloStoreInfo(storePrettyUrl, {
    enabled: Boolean(flags),
    select: storeInfoData => {
      return updatedStoreInfoPerFlags(storeInfoData, flags);
    },
    onSuccess: storeInfoData => {
      dispatch(updateStoreInfo(storeInfoData));
    },
  });

  const { data: consumerInfo } = useOloConsumerInfo(storePrettyUrl, {
    onSuccess: consumerInfoData => {
      dispatch(updateConsumerInfo(consumerInfoData, true));
    },
  });

  useOloAnalytics({ identifier: consumerInfo?.email, identifyTrackingMessage });

  useEffect(() => {
    if (storeInfo?.locationName) {
      document.title = storeInfo.locationName;
    }
  }, [storeInfo?.locationName]);

  if (!storeInfo || !flags) {
    return (
      <div className="app">
        <Loader />
      </div>
    );
  }

  return <>{children}</>;
};
