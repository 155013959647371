import PropTypes from "prop-types";
import { partial, pipe } from "ramda";
import React from "react";

import Button from "components/button";
import Input from "components/input";
import { getTargetValue } from "helpers/event";
import handleFormErrors from "helpers/handle_form_errors";

class PasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleKeyDown(event) {
    const { updatePassword, password } = this.props;
    if (event.key === "Enter") {
      partial(updatePassword, [password]);
    }
  }

  render() {
    const {
      password,
      updatePassword,
      updatePasswordField,
      showPasswordForm,
      cancelPasswordFormEdits,
    } = this.props;
    const { isEditing, currentPassword, newPassword, confirmPassword } =
      password;

    let content;
    if (isEditing) {
      content = (
        <div className="password-form form expanded-form">
          <div className="row">
            <span className="label">Current Password</span>
            <Input
              id="password-form-current-password"
              type="password"
              value={currentPassword}
              onChange={pipe(
                getTargetValue,
                partial(updatePasswordField, ["currentPassword"])
              )}
              error={handleFormErrors("currentPassword", password)}
              onKeyDown={this.handleKeyDown}
            />
          </div>
          <div className="row">
            <span className="label">New Password</span>
            <Input
              id="password-form-new-password"
              type="password"
              value={newPassword}
              onChange={pipe(
                getTargetValue,
                partial(updatePasswordField, ["newPassword"])
              )}
              error={handleFormErrors("newPassword", password)}
              onKeyDown={this.handleKeyDown}
            />
          </div>
          <div className="row">
            <span className="label">Confirm Password</span>
            <Input
              id="password-form-confirm-password"
              type="password"
              value={confirmPassword}
              onChange={pipe(
                getTargetValue,
                partial(updatePasswordField, ["confirmPassword"])
              )}
              error={handleFormErrors("confirmPassword", password)}
              onKeyDown={this.handleKeyDown}
            />
          </div>
          <div className="buttons">
            <Button onClick={cancelPasswordFormEdits} type="secondary">
              Cancel
            </Button>
            <Button onClick={partial(updatePassword, [password])}>
              Update
            </Button>
          </div>
        </div>
      );
    } else {
      content = (
        <a className="edit-button" onClick={showPasswordForm}>
          Edit
        </a>
      );
    }

    return (
      <div className="account-section password">
        <h3 className="title">Password</h3>

        {content}
      </div>
    );
  }
}

PasswordForm.propTypes = {
  password: PropTypes.object,
  updatePasswordField: PropTypes.func,
  showPasswordForm: PropTypes.func,
  cancelPasswordFormEdits: PropTypes.func,
  updatePassword: PropTypes.func,
};

export default PasswordForm;
