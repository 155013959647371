import { useField, useFormikContext } from "formik";
import type { FieldValidator, FormikHelpers } from "formik/dist/types";
import { equals, none } from "ramda";
import React, {
  type ChangeEvent,
  type InputHTMLAttributes,
  type Ref,
} from "react";
import { StyledFormControl } from "./StyledFormControl";
import { StyledInput } from "./StyledInput";
import { StyledErrorMessage } from "components/mobile_pay/StyledErrorMessage";
import { StyledLabel } from "components/mobile_pay/views/MobilePayCreditCardForm";
import type { MobilePayFormValues } from "interfaces/mobile_pay/MobilePayFormValues";

export interface MobilePayInputProps<
  T extends MobilePayFormValues = MobilePayFormValues,
> extends Pick<
    InputHTMLAttributes<HTMLInputElement>,
    "autoComplete" | "inputMode" | "placeholder" | "required"
  > {
  name: string;
  label?: string;
  validate?: FieldValidator;
  ref?: Ref<HTMLInputElement>;
  maxLength?: number | ((number: T) => number);
  ariaLabel?: InputHTMLAttributes<HTMLInputElement>["aria-label"];
  onChange?: (
    event: ChangeEvent<HTMLInputElement>,
    setFieldValue: FormikHelpers<T>["setFieldValue"]
  ) => void;
  onBlur?: (
    event: ChangeEvent<HTMLInputElement>,
    setFieldValue: FormikHelpers<T>["setFieldValue"],
    setFieldTouched: FormikHelpers<T>["setFieldTouched"]
  ) => void;
}

export const MobilePayInput = <
  T extends MobilePayFormValues = MobilePayFormValues,
>({
  name,
  validate,
  label,
  maxLength,
  onChange,
  onBlur,
  ariaLabel,
  placeholder,
  required = true,
  ...rest
}: MobilePayInputProps<T>) => {
  const { values, setFieldValue, handleChange, handleBlur, setFieldTouched } =
    useFormikContext<T>();
  const [field, { error, touched }] = useField({
    name,
    validate,
    placeholder,
  });
  const showsError = Boolean(error && touched);
  return (
    <StyledFormControl className={name} key={name}>
      {none(equals(name), ["expiryMonth", "expiryYear"]) ? (
        <StyledLabel htmlFor={name}>{label}</StyledLabel>
      ) : (
        <StyledLabel>{label}</StyledLabel>
      )}
      <StyledInput
        id={name}
        isError={showsError}
        {...field}
        placeholder={placeholder}
        maxLength={
          typeof maxLength === "function" ? maxLength(values) : maxLength
        }
        onChange={event => {
          if (onChange) {
            onChange(event, setFieldValue);
          } else handleChange(event);
        }}
        onBlur={event => {
          if (onBlur) {
            onBlur(event, setFieldValue, setFieldTouched);
          } else handleBlur(event);
        }}
        aria-label={ariaLabel}
        aria-invalid={showsError}
        aria-describedby={`${name}-error`}
        aria-required={required}
        required={required}
        {...rest}
      />
      {showsError ? (
        <StyledErrorMessage id={`${name}-error`}>{error}</StyledErrorMessage>
      ) : null}
    </StyledFormControl>
  );
};
