import { updateContactInfo } from "actions/online_ordering/checkout";

export const CHANGE_DELIVERY_TIME_FRAME =
  "online_ordering/ordering_options/CHANGE_DELIVERY_TIME_FRAME";
export const CHANGE_TRANSPORT_METHOD =
  "online_ordering/ordering_options/CHANGE_TRANSPORT_METHOD";
export const EDIT_ORDER_OPTIONS =
  "online_ordering/ordering_options/EDIT_ORDER_OPTIONS";
export const UPDATE_DELIVERY_ADDRESS_VALIDITY =
  "online_ordering/ordering_options/UPDATE_DELIVERY_ADDRESS_VALIDITY";
export const APPLY_OPTIONS_FROM_LOCAL_STORAGE =
  "online_ordering/ordering_options/APPLY_OPTIONS_FROM_LOCAL_STORAGE";
export const UPDATE_ORDER_DATE =
  "online_ordering/ordering_options/UPDATE_ORDER_DATE";
export const UPDATE_ORDER_TIME =
  "online_ordering/ordering_options/UPDATE_ORDER_TIME";
export const SET_ORDER_TIME_ERROR =
  "online_ordering/ordering_options/SET_ORDER_TIME_ERROR";
export const REORDER = "online_ordering/ordering_options/REORDER";
export const SET_DINE_IN_TABLE_PROPS =
  "online_ordering/ordering_options/SET_DINE_IN_TABLE_PROPS";

function changeDeliveryTimeFrame(timeFrame) {
  return {
    type: CHANGE_DELIVERY_TIME_FRAME,
    timeFrame,
  };
}

function changeTransportMethod(method) {
  return {
    type: CHANGE_TRANSPORT_METHOD,
    method,
  };
}

function editOrderOptions(isEditable) {
  return {
    type: EDIT_ORDER_OPTIONS,
    isEditable,
  };
}

function updateDeliveryAddressValidity(isValid) {
  return {
    type: UPDATE_DELIVERY_ADDRESS_VALIDITY,
    isValid,
  };
}

function updateDeliveryAddress(address, isValid) {
  return dispatch => {
    dispatch(updateContactInfo({ address }));
    dispatch(updateDeliveryAddressValidity(isValid));
  };
}

function updateOrderDate(date) {
  return {
    type: UPDATE_ORDER_DATE,
    date,
  };
}

function updateOrderTime(time) {
  return {
    type: UPDATE_ORDER_TIME,
    time,
  };
}

function setOrderTimeError() {
  return {
    type: SET_ORDER_TIME_ERROR,
  };
}

function reorder(items) {
  return {
    type: REORDER,
    items,
  };
}

function applyOptionsFromLocalStorage(options) {
  return {
    type: APPLY_OPTIONS_FROM_LOCAL_STORAGE,
    options,
  };
}

function setDineInTableProps(tableId, tableName) {
  return {
    type: SET_DINE_IN_TABLE_PROPS,
    tableId,
    tableName,
  };
}

export {
  applyOptionsFromLocalStorage,
  changeDeliveryTimeFrame,
  changeTransportMethod,
  editOrderOptions,
  reorder,
  setDineInTableProps,
  setOrderTimeError,
  updateDeliveryAddress,
  updateOrderDate,
  updateOrderTime,
};
