import { camelizeKeys } from "humps";

import { ORDERS_URL } from "api_urls";
import {
  checkStatus,
  getRequest,
  parseJSON,
  postRequest,
} from "helpers/ajax_request";
import type { IOrderDraft } from "types/online_ordering/IOrderDraft";
import type { GiftCardBalanceRequest } from "types/reducers/payment";
import type { ICalculatedTotals } from "types/totals";

export function calculateTotals(
  storePrettyUrl: string,
  orderDraft: IOrderDraft
): Promise<ICalculatedTotals> {
  return fetch(
    `${ORDERS_URL}/${storePrettyUrl}/cart/calculate`,
    postRequest(orderDraft) as RequestInit
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys);
}

export function getGiftCardBalance(
  giftCardInfo: GiftCardBalanceRequest
): Promise<{ balance: number }> {
  const balanceUrl = `${ORDERS_URL}/gift_cards/balance?balance_request_id=${giftCardInfo.requestId}&gift_code=${giftCardInfo.giftCode}&pin=${giftCardInfo.pin}&store_pretty_url=${giftCardInfo.storePrettyUrl}`;
  return fetch(balanceUrl, getRequest() as RequestInit)
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys);
}
