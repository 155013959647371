import { connect } from "react-redux";

import {
  cancelPublish,
  confirmPublish,
  donePublish,
  startPublish,
} from "actions/online_ordering/publish_status";

import PreviewBanner from "components/online_ordering/preview_banner";

function mapStateToProps(state) {
  return {
    publishStatus: state.publishStatus.status,
  };
}

const mapDispatchToProps = {
  onStartPublish: startPublish,
  onCancelPublish: cancelPublish,
  onConfirmPublish: confirmPublish,
  onDonePublish: donePublish,
};

const PreviewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviewBanner);

export default PreviewContainer;
