import React, { Suspense, useCallback } from "react";
import styled from "styled-components";

import { DigitalGiftCard } from "./DigitalGiftCard";
import { CCProcessorConfigurationMap } from "components/digital_gift_cards/payment/CCProcessorConfigurationMap";
import Loader from "components/loader";
import Modal from "components/modal";
import { useAdyenDigitalGiftCardPaymentAccess } from "hooks/digital_gift_cards/payment/adyen/useAdyenDigitalGiftCardPaymentAccess";
import type { DigitalGiftCardPaymentConfirmation } from "types/digital_gift_cards/DigitalGiftCardPaymentConfirmation";
import type { CCProcessor } from "types/payments/credit_card";

const success = require("images/icons/success-check.svg");

const StyledConfirmationModal = styled.div`
  padding: 60px 30px 60px 30px;
  overflow-y: auto;

  font-size: 24px;

  text-align: center;

  @media (max-width: 1024px) {
    width: 80%;
  }
`;

const StyledConfirmationHeader = styled.h1`
  font-family: "Graphik", sans-serif;
  font-weight: bold;
  font-size: 1.75rem; // ~24px based on 14px root font-size; decrease to 1.5rem for 16px root
  color: #212322;
`;

const StyledConfirmationContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  font-size: 1rem; // 14px based on 14px root font-size; decrease to .875rem for 16px root
  line-height: 1.5rem; // ~22.5px based on 14px root font-size; decrease to 1.33rem for 16px root
`;

export interface DigitalGiftCardConfirmationModalProps {
  paymentConfirmation: DigitalGiftCardPaymentConfirmation;
  storePrettyUrl: string;
}

export const DigitalGiftCardConfirmationModal = ({
  paymentConfirmation,
  storePrettyUrl,
}: DigitalGiftCardConfirmationModalProps) => {
  const { data: isAdyenEnabled } =
    useAdyenDigitalGiftCardPaymentAccess(storePrettyUrl);
  const ccProcessor: CCProcessor = isAdyenEnabled ? "adyen" : "firstData";
  const ccProcessorConfiguration = CCProcessorConfigurationMap[ccProcessor];
  const ConfirmationModalInformation =
    ccProcessorConfiguration?.confirmationModalInformation;

  const { digitalGiftCards, consumerEmail, confirmationModalData } =
    paymentConfirmation;

  const handleCloseModal = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Modal onClose={handleCloseModal} className="">
      <StyledConfirmationModal>
        <img
          alt="success"
          src={success}
          style={{ width: "65px", height: "65px" }}
        />
        <StyledConfirmationHeader>Thank you!</StyledConfirmationHeader>
        <StyledConfirmationContent>
          <div>
            Your card is on the way.
            <br />
            {digitalGiftCards
              // Even though BE doesn't support more than 1 DGC but will hardcode it to render just one
              .slice(0, 1)
              .map(digitalGiftCard => (
                <DigitalGiftCard
                  digitalGiftCard={digitalGiftCard}
                  key={digitalGiftCard.oloGiftCardId}
                />
              ))}
            {ConfirmationModalInformation && confirmationModalData && (
              <Suspense fallback={<Loader />}>
                <ConfirmationModalInformation data={confirmationModalData} />
              </Suspense>
            )}
          </div>
          <div data-testid="email-receipt">
            We'll send an email receipt to:
            <br />
            {consumerEmail}
          </div>
        </StyledConfirmationContent>
      </StyledConfirmationModal>
    </Modal>
  );
};
