import {
  CANCEL_PUBLISH,
  CONFIRM_PUBLISH,
  PUBLISHING,
  PUBLISH_FAIL,
  PUBLISH_SUCCESS,
  START_PUBLISH,
} from "actions/online_ordering/publish_status";

const initialState = {
  status: "NOT_STARTED",
};

function publishStatus(state = initialState, action) {
  switch (action.type) {
    case START_PUBLISH:
    case CONFIRM_PUBLISH:
    case PUBLISHING:
    case PUBLISH_SUCCESS:
    case PUBLISH_FAIL:
      return {
        ...state,
        status: action.type,
      };

    case CANCEL_PUBLISH:
      return {
        status: "NOT_STARTED",
      };

    default:
      return state;
  }
}

export default publishStatus;
