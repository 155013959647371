/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from "react";
import styled from "styled-components";

const ReceiptGhostIcon = require("images/icons/receipt-ghost.svg");

const StyledWrapper = styled.div`
  background-color: white;
  background-image: url(${ReceiptGhostIcon});
  background-position: center 30vh;
  background-repeat: no-repeat;
  background-size: 70px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  section {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: calc(30vh + 125px);
    padding: 0 10vw;
    text-align: center;
  }
  h2 {
    color: black;
    font-size: 32px;
  }
`;

export default function ErrorMessage() {
  const onClickReload = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <StyledWrapper>
      <section>
        <h2>Oops! Something has gone wrong.</h2>
        <p>
          Don't worry – it's nothing you did. Please{" "}
          <a href="#" onClick={onClickReload}>
            reload the page
          </a>{" "}
          and try again.
        </p>
      </section>
    </StyledWrapper>
  );
}
