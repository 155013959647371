import PropTypes from "prop-types";
import React from "react";

function RewardProgressBar({ points, goal, rewardValue }) {
  const parsedGoal = parseInt(goal, 10);
  const percentCompleted = (points / parsedGoal) * 100;

  return (
    <div className="reward-progress-bar">
      <span>$0</span>
      <span className="bar">
        <span className="completed" style={{ width: `${percentCompleted}%` }} />
      </span>
      <span>{rewardValue}</span>
    </div>
  );
}

RewardProgressBar.propTypes = {
  points: PropTypes.number.isRequired,
  goal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  rewardValue: PropTypes.string,
};

export default RewardProgressBar;
