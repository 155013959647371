import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import Icon from "components/icon";
import { getOLOStatus } from "interfaces/olo_status";

export function OnlineOrderingStatus() {
  const [active, setActive] = useState<boolean>(false);
  const [messageHeader, setMessageHeader] = useState<string>("");
  const [messageContentHTMLSanitized, setMessageContentHTMLSanitized] =
    useState<string>("");

  useEffect(() => {
    (async () => {
      try {
        const oloStatus = await getOLOStatus();
        setActive(oloStatus.active);
        if (oloStatus.active) {
          setMessageHeader(oloStatus.messageHeader);
          setMessageContentHTMLSanitized(
            DOMPurify.sanitize(oloStatus.messageContentHTML)
          );
        }
      } catch {
        // ignore failure to get status
      }
    })();
  }, []);

  if (!active) {
    return null;
  }

  return (
    <div className="message-banner card">
      <Icon type="exclamation-circle-light" />
      <div className="message-content">
        <p>
          <span className="message-header">{messageHeader}</span>
          <br />
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: messageContentHTMLSanitized }}
          />
        </p>
      </div>
    </div>
  );
}
