import { connect } from "react-redux";

import { returnToMenu } from "actions/online_ordering/checkout";

import Header from "components/header";

function mapStateToProps(state) {
  return {
    locationInfo: state.storeInfo,
  };
}

const mapDispatchToProps = {
  onHeadingClick: returnToMenu,
};

const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header);

export default HeaderContainer;
