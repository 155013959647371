import PropTypes from "prop-types";
import { partial } from "ramda";
import React from "react";

import Input from "components/input";

import { handleErrors } from "helpers/credit_cards";
import isOLO from "helpers/is_olo";

function EditCreditCardDetails({
  card,
  handleUpdateField,
  onBlurValidateCCField,
}) {
  let cardNumberField;
  if (card && card.lastFour) {
    cardNumberField = card.obscuredNumber;
  } else {
    cardNumberField = (
      <Input
        id="edit-credit-card-details-cc-number"
        autoComplete="cc-number"
        className="cc-form-input"
        error={handleErrors("cardNumber", card)}
        inputMode="numeric"
        maxLength="16"
        onBlur={partial(onBlurValidateCCField, ["cardNumber", card])}
        onChange={partial(handleUpdateField, [card.cardUuid, "cardNumber"])}
        required
        type="text"
        value={card.cardNumber}
      />
    );
  }

  const hideForLoyalty = isOLO() ? "" : "hidden";

  return (
    <div className="cc-details-form form">
      <div className="row card-number">
        <label htmlFor="edit-credit-card-details-cc-number">Card Number</label>
        {cardNumberField}
      </div>
      <div className={`row ${hideForLoyalty}`}>
        <label htmlFor="edit-credt-card-details-exp-month">Exp</label>
        <div className="multi-field">
          <Input
            id="edit-credt-card-details-exp-month"
            autoComplete="cc-exp-month"
            className="exp month"
            error={handleErrors("expiryMonth", card)}
            inputMode="numeric"
            maxLength="2"
            onBlur={partial(onBlurValidateCCField, ["expiryMonth", card])}
            onChange={partial(handleUpdateField, [
              card.cardUuid,
              "expiryMonth",
            ])}
            placeholder="MM"
            required
            type="text"
            value={card.expiryMonth}
          />
          <span className="expiry-divider">/</span>
          <Input
            id="edit-credt-card-details-exp-year"
            autoComplete="cc-exp-year"
            className="exp year"
            error={handleErrors("expiryYear", card)}
            inputMode="numeric"
            maxLength="4"
            onBlur={partial(onBlurValidateCCField, ["expiryYear", card])}
            onChange={partial(handleUpdateField, [card.cardUuid, "expiryYear"])}
            placeholder="YYYY"
            required
            type="text"
            value={card.expiryYear}
          />
        </div>
        <label htmlFor="edit-credt-card-details-cvv" className="cvv">
          CVV
        </label>
        <Input
          id="edit-credt-card-details-cvv"
          autoComplete="cc-csc"
          error={handleErrors("cvv", card)}
          maxLength="4"
          onBlur={partial(onBlurValidateCCField, ["cvv", card])}
          onChange={partial(handleUpdateField, [card.cardUuid, "cvv"])}
          required
          type="text"
          value={card.cvv}
        />
      </div>
      <div className={`row ${hideForLoyalty}`}>
        <label htmlFor="edit-credt-card-details-first-name">First name</label>
        <Input
          id="edit-credt-card-details-first-name"
          autoComplete="cc-given-name"
          error={handleErrors("firstName", card)}
          onBlur={partial(onBlurValidateCCField, ["firstName", card])}
          onChange={partial(handleUpdateField, [card.cardUuid, "firstName"])}
          required
          type="text"
          value={card.firstName}
        />
      </div>
      <div className={`row ${hideForLoyalty}`}>
        <label htmlFor="edit-credt-card-details-last-name">Last name</label>
        <Input
          id="edit-credt-card-details-last-name"
          autoComplete="cc-family-name"
          error={handleErrors("lastName", card)}
          onBlur={partial(onBlurValidateCCField, ["lastName", card])}
          onChange={partial(handleUpdateField, [card.cardUuid, "lastName"])}
          required
          type="text"
          value={card.lastName}
        />
      </div>
    </div>
  );
}

EditCreditCardDetails.propTypes = {
  card: PropTypes.object,
  handleUpdateField: PropTypes.func.isRequired,
  onBlurValidateCCField: PropTypes.func.isRequired,
};

export default EditCreditCardDetails;
