/* global google */
import isMobile from "helpers/is_mobile_device";

function fetchUserAddressViaMobileGeolocation() {
  return new Promise((res, rej) => {
    if (!isMobile() || !navigator.geolocation) {
      rej("Unsupported Device.");
    }
    navigator.geolocation.getCurrentPosition(
      position => {
        res(position.coords);
      },
      error => {
        rej(error);
      }
    );
  });
}

function coordinatesToNearestAddress(coordinates) {
  const geocoder = new google.maps.Geocoder();
  const requestParams = {
    location: { lat: coordinates.latitude, lng: coordinates.longitude },
  };

  return new Promise((res, rej) => {
    geocoder.geocode(requestParams, (response, status) => {
      if (status === "OK") {
        res(response);
      }
      rej(status);
    });
  });
}

export { fetchUserAddressViaMobileGeolocation, coordinatesToNearestAddress };
