import React, { useCallback } from "react";
import AlertModal from "components/alert_modal";
import { useOloConsumerSettings } from "hooks/online_ordering/accounts/useOloConsumerSettings";
import { useOloUpdateConsumerSettings } from "hooks/online_ordering/accounts/useOloUpdateConsumerSettings";
import { useAdyenOLOPaymentAccess } from "hooks/online_ordering/payment/adyen/useAdyenOLOPaymentAccess";

export interface OnlineOrderingReAddAdyenCardsModalProps {
  storePrettyUrl: string;
}

export const OnlineOrderingReAddAdyenCardsModal = ({
  storePrettyUrl,
}: OnlineOrderingReAddAdyenCardsModalProps) => {
  const { data: isAdyenEnabled } = useAdyenOLOPaymentAccess(storePrettyUrl);
  const { data: consumerSettings } = useOloConsumerSettings(storePrettyUrl, {
    enabled: Boolean(isAdyenEnabled),
  });

  const { mutateAsync: updateConsumerSettings } =
    useOloUpdateConsumerSettings(storePrettyUrl);

  const handleConfirm = useCallback(async () => {
    await updateConsumerSettings({
      seenReAddCreditModal: "true",
    });
  }, [updateConsumerSettings]);

  if (!consumerSettings || consumerSettings.seenReAddCreditModal === "true") {
    return null;
  }

  return (
    <AlertModal
      data-testid="OnlineOrderingReAddAdyenCardsModal"
      onConfirm={handleConfirm}
      message={
        'Due to changes in this restaurant\'s credit card processing configuration, you will need to re-add any previously stored credit cards to your Online Ordering user profile. This process will only need to be completed once. Please click on "+ Add New Card" in the Credit Card Information section of your user profile to re-enter your credit cards.'
      }
      confirmButtonText="OK"
    />
  );
};
