import { camelizeKeys } from "humps";
import { assoc, equals, map } from "ramda";
import { TrackJS } from "trackjs";

import { BRAND_BUCKET } from "api_urls";
import { getPrettyUrl } from "get_base_uri";
import {
  checkStatus,
  cloudfrontGetRequest,
  parseJSON,
} from "helpers/ajax_request";
import getEnv from "helpers/get_env";
import formatBrandInfo from "helpers/transforms/loyalty/brand_info_transform";

import getStoreInfo from "interfaces/store_info";

function getBrandAndStoreInfo(callback, brandPrettyUrl = getPrettyUrl()) {
  return fetch(
    `${BRAND_BUCKET}/${brandPrettyUrl}/brand.json`,
    cloudfrontGetRequest()
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys)
    .then(formatBrandInfo)
    .then(brandInfo => {
      const storeRequests = map(
        storePrettyUrl => getStoreInfo(null, storePrettyUrl),
        brandInfo.stores
      );

      Promise.all(storeRequests).then(stores => {
        callback(assoc("stores", stores, brandInfo));
      });
    })
    .catch(error => {
      // if we aren't getting a brand back it either
      // doesn't exist or AWS is down
      TrackJS.track(error);
      if (equals(getEnv(), "production")) {
        window.location.href = "http://upserve.com";
      }
    });
}

function getBrandInfo(brandPrettyUrl = getPrettyUrl()) {
  return fetch(
    `${BRAND_BUCKET}/${brandPrettyUrl}/brand.json`,
    cloudfrontGetRequest()
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys)
    .then(formatBrandInfo)
    .catch(error => {
      // if we aren't getting a brand back it either
      // doesn't exist or AWS is down
      TrackJS.track(error);
      if (equals(getEnv(), "production")) {
        window.location.href = "http://upserve.com";
      }
    });
}

export { getBrandInfo, getBrandAndStoreInfo };
