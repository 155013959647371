import { map } from "ramda";
import {
  findFormattersByType,
  formatObscuredCardNumberByType,
} from "helpers/credit_cards";
// Take only the data we need and adjust keys as necessary to match olo data structure & keys
function formatCard(card) {
  const { token, type, lastFour } = card;

  return {
    token,
    type,
    lowercaseType: findFormattersByType(type).lowercase,
    sentenceCaseType: findFormattersByType(type).prettyName,
    obscuredNumber: formatObscuredCardNumberByType(type, lastFour),
    cardUuid: token,
    lastFour,
    cardType: type,
    expiryYear: null, // Not currently stored for loyalty
    expiryMonth: null, // Not currently stored for loyalty
    zip: null, // Not currently stored for loyalty
  };
}

function formatCards(cards) {
  if (Array.isArray(cards)) {
    return map(formatCard, cards);
  }

  return formatCard(cards);
}

export default formatCards;
