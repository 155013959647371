// Take only the data we need and adjust keys as necessary to match
// olo data structure & keys
function formatAccountInfo(info) {
  const { uuid, email, firstName, lastName } = info;

  return {
    uuid,
    email,
    firstName,
    lastName,
  };
}

export default formatAccountInfo;
