import { connect } from "react-redux";

import {
  changeDeliveryTimeFrame,
  changeTransportMethod,
  editOrderOptions,
  updateDeliveryAddress,
  updateOrderDate,
  updateOrderTime,
} from "actions/online_ordering/ordering_options";

import OrderingOptionsBar from "components/online_ordering/cart/ordering_options_bar";

function mapStateToProps(state) {
  return {
    consumerInfo: state.consumer,
    contactInfo: state.consumer.contactInfo,
    deliveryAddress: state.deliveryAddress,
    orderingOptions: state.orderingOptions,
    storeInfo: state.storeInfo,
  };
}

const mapDispatchToProps = {
  onChangeDeliveryTimeFrame: changeDeliveryTimeFrame,
  onChangeTransportMethod: changeTransportMethod,
  onEditOrderOptions: editOrderOptions,
  onUpdateDeliveryAddress: updateDeliveryAddress,
  onUpdateOrderDate: updateOrderDate,
  onUpdateOrderTime: updateOrderTime,
};

const OrderingOptionsBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderingOptionsBar);

export default OrderingOptionsBarContainer;
