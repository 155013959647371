import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { equals, find, map, propEq } from "ramda";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Button from "components/button";
import Icon from "components/icon";
import Loader from "components/loader";
import GiftCardRow from "components/online_ordering/checkout/gift_card_row";
import type { ILDFlags } from "types/ld_feature_flags";
import type { GiftCardProps } from "types/reducers/payment";
import { RequestStatus } from "types/requests";

const StyledForm = styled.div`
  .row {
    display: block;
  }
  .row > button {
    width: 6.5rem;
  }
  .row > div {
    width: 100%;
  }
  .gift-card-header {
    max-height: 21px;
    display: flex;
    .icon-wrapper {
      margin: 0 0 0 12px;
    }
  }
  label,
  .applied-code {
    line-height: 2rem;
  }
  min-height: 3rem;

  .loader:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      rgba(224, 224, 224, 0.8),
      rgba(224, 224, 224, 0.8)
    );

    background: -webkit-radial-gradient(
      rgba(224, 224, 224, 0.8),
      rgba(224, 224, 224, 0.8)
    );
  }
  .loader {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    .logo {
      top: 0;
    }
  }
  .loader .indicator:after {
    top: 0;
  }
`;

const StyledSubheader = styled.h2`
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  grid-area: header;
  margin-bottom: 8px;
`;

interface IGiftCardProps {
  applyGiftCard: (newGiftCard: GiftCardProps, flags: ILDFlags | {}) => void;
  balanceDue: number;
  giftCards: GiftCardProps[];
  giftCardStatus: RequestStatus;
  removeGiftCard: (index: number, flags: ILDFlags | {}) => void;
}

function GiftCard({
  applyGiftCard,
  balanceDue,
  giftCards,
  giftCardStatus,
  removeGiftCard,
}: IGiftCardProps) {
  const [giftCardsList, setGiftCardsList] =
    useState<GiftCardProps[]>(giftCards);
  const [showAddButton, setShowAddButton] = useState(true);
  const blankGiftCard = {
    index: giftCards.length,
    giftCardNumber: "",
    pin: "",
  };

  useEffect(() => {
    setGiftCardsList(giftCards);
    if (giftCards.length < 2 && balanceDue > 0) {
      setShowAddButton(true);
    } else {
      setShowAddButton(false);
    }
  }, [giftCards, balanceDue]);

  const isLoading = equals(giftCardStatus, RequestStatus.REQUESTING);

  return (
    <StyledForm className="form">
      {isLoading && <Loader />}
      <div className="gift-card-header">
        <StyledSubheader>Pay with gift card</StyledSubheader>
        <Icon type="gift-card-balance" />
      </div>
      <div className="row">
        {giftCardsList &&
          map(
            idx => {
              const card = find(propEq("index", idx), giftCardsList);
              if (card) {
                return (
                  <GiftCardRow
                    applyGiftCard={applyGiftCard}
                    key={`card${idx}`}
                    giftCard={card}
                    giftCards={giftCards}
                    giftCardStatus={giftCardStatus}
                    removeGiftCard={removeGiftCard}
                  />
                );
              }
              return null;
            },
            [0, 1]
          )}
        {showAddButton && (
          <Button
            className="caps"
            type="primary"
            onClick={() => {
              setShowAddButton(false);
              setGiftCardsList([...giftCardsList, { ...blankGiftCard }]);
            }}>
            +Add
          </Button>
        )}
      </div>
    </StyledForm>
  );
}

export default withLDConsumer()(GiftCard);
