import PropTypes from "prop-types";
import { pipe } from "ramda";
import React from "react";

import { trackAnalytic } from "helpers/analytics";
import { getTargetValue } from "helpers/event";

const CHARACTER_LIMIT = 500;

function CurbsidePickupInstructions({
  instructions,
  guestInstructions,
  onUpdateCurbsidePickupInstructions,
}) {
  const charsLeft = CHARACTER_LIMIT - instructions.length;

  let additionalGuestInstructions;
  if (guestInstructions && guestInstructions !== "") {
    additionalGuestInstructions = (
      <div className="curbside-pickup-guest-instructions">
        {guestInstructions}
      </div>
    );
  }

  return (
    <div className="curbside-pickup-instructions">
      {additionalGuestInstructions}
      <label>
        Add vehicle details to help us find you (make, model, color, etc)
      </label>
      <textarea
        value={instructions}
        onChange={pipe(getTargetValue, onUpdateCurbsidePickupInstructions)}
        maxLength={CHARACTER_LIMIT}
        onBlur={() =>
          trackAnalytic("OLO - Checkout - add curbside pickup instructions")
        }
      />
      <span className="length-left">{charsLeft} characters left</span>
    </div>
  );
}

CurbsidePickupInstructions.propTypes = {
  instructions: PropTypes.string.isRequired,
  guestInstructions: PropTypes.string,
  onUpdateCurbsidePickupInstructions: PropTypes.func.isRequired,
};

export default CurbsidePickupInstructions;
