import { Form, Formik } from "formik";
import React, { Suspense, useCallback, useRef, useState } from "react";
import { TrackJS } from "trackjs";
import {
  CCProcessorConfigurationMap,
  type SubmitHandler,
} from "./CCProcessorConfigurationMap";
import { OloAccountCreditCardAddressInputs } from "./OloAccountCreditCardAddressInputs";
import { OloAccountCreditCardInputs } from "./OloAccountCreditCardInputs";
import Button from "components/button";
import LoaderEllipsis from "components/loader_ellipsis";
import { CreditCardProcessLoading } from "components/payment/CreditCardProcessLoading";
import { FormSubmissionError } from "components/shared/errors/FormSubmissionError";
import { useAdyenOLOPaymentAccess } from "hooks/online_ordering/payment/adyen/useAdyenOLOPaymentAccess";
import type { OloAccountCreditCardFormValues } from "types/online_ordering/accounts/OloAccountCreditCardFormValues";
import type { OloAccountSaveCreditCardResult } from "types/online_ordering/OloAccountSaveCreditCardResult";
import type { CCProcessor } from "types/payments/credit_card";
import type { StoreInfo } from "types/reducers/store_info";

const initialValues: OloAccountCreditCardFormValues = {
  address1: "",
  address2: "",
  city: "",
  firstName: "",
  lastName: "",
  state: "",
  zip: "",
};

export interface OloCreditCardFormProps {
  storePrettyUrl: string;
  storeInfo: StoreInfo;
  onSaveSuccess: (result: OloAccountSaveCreditCardResult) => void;
  onCancel: () => void;
}

export const OloAccountCreditCardForm = ({
  storePrettyUrl,
  storeInfo,
  onSaveSuccess,
  onCancel,
}: OloCreditCardFormProps) => {
  const { data: isAdyenEnabled } = useAdyenOLOPaymentAccess(storePrettyUrl);
  const ccProcessor: CCProcessor = isAdyenEnabled ? "adyen" : "firstData";
  const ccProcessorConfiguration = CCProcessorConfigurationMap[ccProcessor];

  const [error, setError] = useState<string | null>(null);

  const submitHandlerRef = useRef<SubmitHandler | null>(null);
  const handleSubmit = useCallback(
    async (formValues: OloAccountCreditCardFormValues) => {
      try {
        const submitHandler = submitHandlerRef.current;
        if (!submitHandler) {
          throw new Error("Unable to find the submit handler.");
        }

        const result = await submitHandler(formValues);

        onSaveSuccess(result);
      } catch (ex) {
        const message: string | undefined = await (async () => {
          const errorResponse = (ex as { response?: Response }).response;
          if (errorResponse) {
            const body = await errorResponse.json();
            return body.message;
          }

          return (ex as Error).message;
        })();

        if (message) {
          TrackJS.track(message);
        }

        setError(
          "There was an error processing your request. Please check the form and try again."
        );
      }
    },
    [onSaveSuccess]
  );

  if (isAdyenEnabled === undefined) {
    return <LoaderEllipsis title="We are loading the credit card form..." />;
  }

  if (!ccProcessorConfiguration) {
    return null;
  }

  const {
    form: CreditCardFormImpl,
    inputs: InputsImpl,
    getInitialValues = values => values,
  } = ccProcessorConfiguration;

  return (
    <Formik<OloAccountCreditCardFormValues>
      initialValues={getInitialValues(initialValues)}
      onSubmit={handleSubmit}
      validateOnBlur>
      {({ isSubmitting, isValid, dirty }) => (
        <div className="form expanded-form edit-credit-card">
          <h4 className="hidden">Create Credit Card</h4>
          {isSubmitting && <CreditCardProcessLoading />}
          <Suspense
            fallback={
              <LoaderEllipsis title="We are loading the credit card form..." />
            }>
            <CreditCardFormImpl
              storeInfo={storeInfo}
              submitHandlerRef={submitHandlerRef}>
              <Form translate="yes">
                <OloAccountCreditCardInputs>
                  <InputsImpl />
                </OloAccountCreditCardInputs>
                <OloAccountCreditCardAddressInputs />
                {error && <FormSubmissionError>{error}</FormSubmissionError>}
                <div className="buttons">
                  <Button
                    className="button cancel"
                    type="secondary"
                    onClick={onCancel}
                    size="small">
                    Cancel
                  </Button>
                  <Button
                    className="button update"
                    type="primary"
                    size="small"
                    disabled={!isValid || isSubmitting || !dirty}
                    isSubmit>
                    Save
                  </Button>
                </div>
              </Form>
            </CreditCardFormImpl>
          </Suspense>
        </div>
      )}
    </Formik>
  );
};
