import React, { Suspense, useCallback } from "react";
import {
  type CCProcessorConfiguration,
  CCProcessorConfigurationMap,
} from "./CCProcessorConfigurationMap";
import type { DigitalGiftCardPaymentConfirmation } from "types/digital_gift_cards/DigitalGiftCardPaymentConfirmation";

export interface DigitalGiftCardReturnUrlAuthorizationProps {
  storePrettyUrl: string;
  paymentProcessor: string;
  onSuccess: (confirmation: DigitalGiftCardPaymentConfirmation) => void;
  onError: (error: string) => void;
  onFinalize: () => void;
}

export const DigitalGiftCardPaymentReturnUrlAuthorization = ({
  storePrettyUrl,
  paymentProcessor,
  onSuccess,
  onError,
  onFinalize,
}: DigitalGiftCardReturnUrlAuthorizationProps) => {
  const handleSuccess = useCallback(
    (confirmation: DigitalGiftCardPaymentConfirmation) => {
      onSuccess(confirmation);
      onFinalize();
    },
    [onFinalize, onSuccess]
  );

  const handleError = useCallback(
    (errorMessage: string) => {
      onError(errorMessage);
      onFinalize();
    },
    [onError, onFinalize]
  );

  const ccProcessorConfiguration: CCProcessorConfiguration | undefined =
    CCProcessorConfigurationMap[paymentProcessor];
  const PaymentReturnUrlAuthorizationImpl =
    ccProcessorConfiguration?.returnUrlAuthorization;

  if (!PaymentReturnUrlAuthorizationImpl) {
    return null;
  }
  return (
    <Suspense fallback={<></>}>
      <PaymentReturnUrlAuthorizationImpl
        storePrettyUrl={storePrettyUrl}
        onSuccess={handleSuccess}
        onError={handleError}
      />
    </Suspense>
  );
};
