import { useQuery } from "react-query";
import { TrackJS } from "trackjs";
import { BASE_URL } from "api_urls";
import { getMerchantInfo } from "interfaces/mobile_pay";
import type { MerchantInfo, MobilePayRedirect } from "types/mobile_pay";

export const useMerchantInfo = (prettyUrl: string) => {
  return useQuery<
    MerchantInfo | MobilePayRedirect,
    unknown,
    MerchantInfo,
    ["merchantInfo", string]
  >(
    ["merchantInfo", prettyUrl],
    async () => {
      return getMerchantInfo(prettyUrl);
    },
    {
      onSuccess: merchantInfo => {
        if ("redirectPrettyUrl" in merchantInfo) {
          window.location.replace(
            `${BASE_URL}/s/${merchantInfo.redirectPrettyUrl}/pay${window.location.search}`
          );
        }
      },
      onError: error => {
        TrackJS.track(`error fetching merchant info from S3: ${error}`);
      },
    }
  );
};
