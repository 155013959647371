import { type ComponentType, lazy } from "react";

import { AdyenDigitalGiftCardCCInputsGridAreaName } from "./adyen/AdyenDigitalGiftCardCCInputsWrapper";
import type { DigitalGiftCardConfirmationModalInformationProps } from "./DigitalGiftCardConfirmationModalInformationProps";
import type { DigitalGiftCardFormProps } from "./DigitalGiftCardFormProps";
import type { DigitalGiftCardReturnUrlAuthorizationProps } from "./DigitalGiftCardReturnUrlAuthorizationProps";
import { getFirstDataInitialValues } from "./firstData/getFirstDataInitialValues";
import type { DigitalGiftCardOrderForm } from "components/digital_gift_cards/order_form";
import type { DigitalGiftCardFormValues } from "types/digital_gift_cards/DigitalGiftCardFormValues";
import type { CCProcessor } from "types/payments/credit_card";

export type PaymentData = Record<string, any>;
export type ConfirmationModalData = Record<string, any>;
export type SubmitHandlerReturn = {
  additionalPaymentData?: PaymentData;
  confirmationModalData?: ConfirmationModalData;
};

export type SubmitHandler<
  T extends DigitalGiftCardFormValues = DigitalGiftCardFormValues,
> = (
  values: T,
  orderFormValues: DigitalGiftCardOrderForm
) => Promise<SubmitHandlerReturn>;

export interface CCProcessorConfiguration {
  getInitialValues?: (
    initialFormValues: DigitalGiftCardFormValues
  ) => DigitalGiftCardFormValues;
  form: ComponentType<DigitalGiftCardFormProps<any, any>>;
  ccInputs: ComponentType;
  ccInputsGridLayout?: string;
  returnUrlAuthorization?: ComponentType<DigitalGiftCardReturnUrlAuthorizationProps>;
  /**
   * Enable customized submit processing, where the default success / failure handling won't be used
   */
  isCustomizedSubmitProcessing: boolean;
  confirmationModalInformation?: ComponentType<
    DigitalGiftCardConfirmationModalInformationProps<any>
  >;
}

export const CCProcessorConfigurationMap: Record<
  CCProcessor,
  CCProcessorConfiguration
> = {
  firstData: {
    form: lazy(() => import("./firstData/FirstDataDigitalGiftCardForm")),
    ccInputs: lazy(
      () => import("./firstData/FirstDataDigitalGiftCardCCInputs")
    ),
    isCustomizedSubmitProcessing: false,
    getInitialValues: getFirstDataInitialValues,
    confirmationModalInformation: lazy(
      () =>
        import(
          "./firstData/FirstDataDigitalGiftCardConfirmationModalInformation"
        )
    ),
  },
  adyen: {
    form: lazy(() => import("./adyen/AdyenDigitalGiftCardForm")),
    ccInputs: lazy(() => import("./adyen/AdyenDigitalGiftCardCCInputs")),
    ccInputsGridLayout: `"${AdyenDigitalGiftCardCCInputsGridAreaName} ${AdyenDigitalGiftCardCCInputsGridAreaName}"`,
    returnUrlAuthorization: lazy(
      () => import("./adyen/AdyenDigitalGiftCardPaymentReturnUrlAuthorization")
    ),
    isCustomizedSubmitProcessing: true,
  },
};
