import { pick } from "ramda";
import { TrackJS } from "trackjs";
import { getPrettyUrl } from "get_base_uri";

import { isDevEnvironment } from "helpers/get_env";

const propsForEvents = {
  "View Item": ["id", "name"],
  "Add Item": ["id", "name", "quantity"],
  "Add Instructions": ["id", "name", "instructions"],
  "Select Mod": ["id", "name"],
  "Select Side": ["id", "name"],
  "Select Delivery": [],
  "Select Pick up": [],
  "Click Log In": [],
  "Enter Address": true,
  "Add Gratuity (Auto)": ["option", "subtotal"],
  "Add Gratuity (Custom)": ["amount"],
  "Add CC (With Order)": [],
  "Select Credit": [],
  "Select Cash": [],
  "Remove Item": ["name"],
  'Used "Any updates, change your order"': [],
  "Update Item (Via Tab)": ["name"],
  "Click Footer Link (Restaurant Platform)": [],
  "Click Footer Link (Restaurant Blog)": [],
  "Click Footer Link (Free POS Tour)": [],
  "Click Footer Link (Upserve Online Ordering)": [],
  "Click Footer Link (Powered by Upserve)": [],
  "Access Account Page": [],
  "Log In Success": [],
  "Log In Failure": [],
  "Clicked Forgot Password": [],
  "Clicked Check Out": [],
  "Clicked Submit Order": [],
  "Increment Quantity": ["name", "previous_quantity"],
  "Decrement Quantity": ["name", "previous_quantity"],
  Error: true,

  // Loyalty
  'Click "Join for FREE"': ["email"],
  "Click Restaurant URL": [],
  "Click Account": [],
  "Account Page: Add CC": [],
  "Account Page: Remove CC": [],
  'Click "change email"': [],

  // TODO
  "Edit PW": [],
  "Account page: Edit CC": [],
  "Reset Password": [],
};

function isTracking() {
  return !isDevEnvironment();
}

function trackAnalytic(event, properties, options, callback) {
  if (!isTracking()) {
    // eslint-disable-next-line no-console
    console.log("Analytics event:", event, properties, options, callback);
    return;
  }

  if (typeof window.analytics === "undefined") return;
  // strict event names and props (turn off shallow prop matching with true)
  const eventProps = propsForEvents[event];
  if (eventProps) {
    const filtered =
      !properties || eventProps === true
        ? properties
        : pick(eventProps, properties);
    window.analytics.track(event, filtered, options, callback);
  } else {
    window.analytics.track(event, null, options, callback);
  }
}

function withTracking(origFunc, ...trackParams) {
  return function (e) {
    trackAnalytic(...trackParams);
    return origFunc(e);
  };
}

function setTrackJSPrettyUrlMetadata(prettyUrl = getPrettyUrl()) {
  if (TrackJS && TrackJS.isInstalled()) {
    TrackJS.addMetadata("prettyUrl", prettyUrl);
  }
}

export { isTracking, trackAnalytic, withTracking, setTrackJSPrettyUrlMetadata };
export default trackAnalytic;
