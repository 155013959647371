import { useMemo } from "react";
import type { IConsumer } from "components/online_ordering/objects/IConsumer";
import {
  getOrderInfoFromLocalStorage,
  removeOrderInfoFromLocalStorage,
} from "helpers/local_storage";
import { useOloSelector } from "hooks/online_ordering/useOloSelector";
import type { CCProcessor } from "types/payments/credit_card";
import type { ICheckoutStatusState } from "types/reducers/checkout_status";
import type { IOrderingOptionsState } from "types/reducers/ordering_options";
import type { StoreInfo } from "types/reducers/store_info";

export type MinifiedOrderInfo = {
  storeInfo: StoreInfo;
  contactInfo: IConsumer["contactInfo"];
  checkoutStatus: ICheckoutStatusState;
  orderingOptions: IOrderingOptionsState;
  ccProcessor: CCProcessor | undefined;
};

export const useModalOrderInformation = () => {
  const storeInfo = useOloSelector(state => state.storeInfo!);

  const orderInfo = useMemo<Omit<MinifiedOrderInfo, "storeInfo">>(() => {
    const info = getOrderInfoFromLocalStorage();
    removeOrderInfoFromLocalStorage();
    return info;
  }, []);

  return {
    storeInfo,
    ...orderInfo,
  };
};
