import moment from "moment-timezone";
import { any, equals, gt, isEmpty, isNil, map, prop } from "ramda";
import { createSelector } from "reselect";

import { determineFee } from "helpers/online_ordering/cart/checkout_summary";
import { calculateTipAmount } from "helpers/online_ordering/checkout";
import { getTotals } from "helpers/online_ordering/item_totals";
import { filterCart, itemTimeisValid } from "helpers/order_times";
import { transformCartItem } from "helpers/transforms/online_ordering/cart_item";
import {
  formatFulfillmentType,
  formatPaymentType,
} from "helpers/transforms/online_ordering/order_transform";
import type { ICartItem } from "types/cart";
import type { IOrderDraft } from "types/online_ordering/IOrderDraft";
import type { IRootState } from "types/reducers";
import type { IOrderAheadDate } from "types/reducers/ordering_options";
import type { OrderPayment } from "types/totals";

const selectCart = (state: IRootState) => state.cart;
const selectCheckout = (state: IRootState) => state.checkout;
const selectConsumer = state => state.consumer;
const selectDeliveryAddress = state => state.deliveryAddress;
const selectOrderingInfo = state => state.orderingInfo;
const selectOrderingOptions = (state: IRootState) => state.orderingOptions;
const selectStoreInfo = state => state.storeInfo;

const selectDeliveryGeojson = createSelector(
  selectStoreInfo,
  prop("deliveryGeojson")
);

const selectItemsOutOfTimeRange = createSelector(
  selectCart,
  selectOrderingInfo,
  selectOrderingOptions,
  (
    { cartItems },
    { menu },
    { orderAheadDate, orderAheadTime, selectedTimeFrame }
  ) => {
    let containsOutOfTimeRangeItems = false;
    if (gt(cartItems.length, 0)) {
      const orderDateMoment =
        selectedTimeFrame === "Later"
          ? (orderAheadDate as IOrderAheadDate).moment
          : moment();
      const orderTime =
        selectedTimeFrame === "Later"
          ? orderAheadTime
          : moment().format("h:mm A, zz");
      const orderDateDay =
        selectedTimeFrame === "Later"
          ? (orderAheadDate as IOrderAheadDate).day
          : moment().format("ddd").toLowerCase();

      containsOutOfTimeRangeItems = any(
        (item: ICartItem) =>
          !itemTimeisValid(
            menu,
            item.id,
            orderDateMoment,
            orderTime,
            orderDateDay
          ),
        cartItems
      );
    }
    return containsOutOfTimeRangeItems;
  }
);

const selectSubtotal = createSelector(
  selectCart,
  selectOrderingInfo,
  selectOrderingOptions,
  selectStoreInfo,
  (
    { cartItems },
    { deliveryTaxRate },
    { selectedTransport },
    { deliveryFee }
  ) => {
    const feeByMethod = determineFee(deliveryFee, selectedTransport);
    const { subtotal } = getTotals(cartItems, feeByMethod, deliveryTaxRate);
    return subtotal;
  }
);

const selectFulfillmentType = createSelector(
  selectOrderingOptions,
  ({ selectedTransport }) => selectedTransport
);

const selectPaymentType = createSelector(
  selectCheckout,
  ({ payment: { paymentType } }) => paymentType
);

const selectPromoCode = createSelector(
  selectCheckout,
  ({ payment: { promoCode, promoCodeIsValid } }) => ({
    promoCode,
    promoCodeIsValid,
  })
);

const selectGiftCards = createSelector(
  selectCheckout,
  ({ payment: { giftCards } }) => ({
    giftCards,
  })
);

const selectTip = createSelector(selectCheckout, ({ payment: { tip } }) => ({
  tip,
}));

const selectSelectedTipOption = createSelector(
  selectCheckout,
  ({ payment: { selectedTipOption } }) => ({ selectedTipOption })
);

const selectGiftCardRequest = createSelector(
  selectCheckout,
  ({ payment: { giftCardsRequest } }) => ({
    giftCardsRequest,
  })
);

const selectOrderDraft = createSelector(
  selectCart,
  selectFulfillmentType,
  selectPaymentType,
  selectPromoCode,
  selectGiftCards,
  selectTip,
  selectSelectedTipOption,
  selectStoreInfo,
  selectOrderingInfo,
  selectOrderingOptions,
  (
    cart,
    fulfillmentType,
    paymentType,
    { promoCode },
    { giftCards },
    { tip },
    { selectedTipOption },
    storeInfo,
    { menu },
    orderingOptions
  ): IOrderDraft => {
    const filteredCart = filterCart({
      cart,
      menu,
      orderingOptions,
    });
    const items = filteredCart.cartItems.map(transformCartItem);
    const giftCardsForOrder = map(card => {
      return {
        paymentType: "GIFT_CARD",
        index: card.index,
        giftCardNumber: card.giftCardNumber,
        balance: card.balance.valueOf(),
      };
    }, giftCards);
    const shouldIncludeTip =
      equals(paymentType, "credit") || !isEmpty(giftCards);
    let gratuityValue = 0;
    if (!isNil(selectedTipOption) || gt(tip.valueOf(), 0)) {
      // custom tip component has selected tip or tip value
      gratuityValue = calculateTipAmount(
        filteredCart.cartItems,
        fulfillmentType,
        storeInfo,
        selectedTipOption,
        tip
      );
    }
    const order: IOrderDraft["order"] = {
      fulfillmentInfo: {
        type: formatFulfillmentType(fulfillmentType),
      },
      payments: {
        tipTotal: shouldIncludeTip ? gratuityValue : 0,
        payments: [
          {
            paymentType: formatPaymentType(paymentType),
          } as OrderPayment,
          ...giftCardsForOrder,
        ],
      },
      promoCode,
      charges: {
        items,
      },
    };
    return { order };
  }
);

export {
  selectCart,
  selectCheckout,
  selectConsumer,
  selectDeliveryAddress,
  selectDeliveryGeojson,
  selectGiftCardRequest,
  selectGiftCards,
  selectItemsOutOfTimeRange,
  selectOrderDraft,
  selectOrderingInfo,
  selectOrderingOptions,
  selectPaymentType,
  selectPromoCode,
  selectSelectedTipOption,
  selectStoreInfo,
  selectSubtotal,
};
