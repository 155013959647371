import { mergeRight, omit } from "ramda";
import React, { type ChangeEvent } from "react";
import EditCreditCardDetails from "components/account/credit_cards/edit_credit_card_details";
import Button from "components/button";
import { ENV } from "helpers/get_env";
import {
  newCardValidators,
  validateBlurredField,
  validateCardDetails,
} from "helpers/validation/forms";
import VALIDATION_MESSAGES from "helpers/validation/messages";
import Validators from "helpers/validation/validators";
import { emptyNewCard } from "reducers/credit_cards";
import type { LoyaltyAccountCreditCardFormValues } from "types/loyalty/LoyaltyAccountCreditCardFormValues";
import type { OloAccountCreditCardFormValues } from "types/online_ordering/accounts/OloAccountCreditCardFormValues";

export interface LoyaltyAccountCreditCardFormProps {
  onUpdateCard: (card: OloAccountCreditCardFormValues) => Promise<void>;
  onCloseForm: () => void;
}

interface LoyaltyAccountCreditCardFormState {
  card: LoyaltyAccountCreditCardFormValues;
  errors: Record<string, string> | null;
}

export class LoyaltyAccountCreditCardForm extends React.Component<
  LoyaltyAccountCreditCardFormProps,
  LoyaltyAccountCreditCardFormState
> {
  constructor(props) {
    super(props);

    this.state = {
      card: emptyNewCard,
      errors: null,
    };
  }

  handleSubmit = async () => {
    const { onUpdateCard, onCloseForm } = this.props;
    const { card } = this.state;
    const cardValues = { ...card };

    const loyaltyCardValidators = {
      cardNumber: Validators.isValidCardNumber,
    };
    const errors = validateCardDetails(card, loyaltyCardValidators);
    if (errors) {
      this.updateErrors(errors);
      return;
    }

    const forceInvalidLuhn =
      ENV !== "production" && window.location.search === "?forceinvalidluhn";
    if (forceInvalidLuhn) {
      cardValues.cardNumber = cardValues.cardNumber.replace(
        /([0-9])$/,
        (match, group1: string) => ((parseInt(group1) + 1) % 10).toString()
      );
    }

    try {
      await onUpdateCard(cardValues);
      onCloseForm();
    } catch (ex) {
      if ((ex as Error).message) {
        this.updateErrors({
          cardNumber: (ex as Error).message,
        });
        if (ENV !== "production") {
          console.warn(
            "⚠️ Non-production Loyalty only accepts invalid card numbers. Please add ?forceinvalidluhn at the end of the URL. The UI will submit an invalid credit card when you submit by automatically adding the last digit by 1."
          );
        }
      }
    }
  };

  handleUpdateField = (
    cardUuid: string,
    field: string,
    input: ChangeEvent<HTMLInputElement>
  ) => {
    const value = input.target.value;
    this.setState(({ card: prevCard }) => ({
      card: { ...prevCard, [field]: value },
    }));
  };

  handleValidateCCFieldOnBlur = (
    field: string,
    card: OloAccountCreditCardFormValues
  ) => {
    const errors = validateBlurredField(
      field,
      card,
      newCardValidators,
      VALIDATION_MESSAGES
    );
    this.updateErrors(errors, field);
  };

  updateErrors = (errors: Record<string, string>, field?: string) => {
    this.setState(({ errors: prevErrors }) => {
      const prevErrorsWithoutField =
        field && prevErrors ? omit([field], prevErrors) : {};
      return {
        errors: mergeRight(prevErrorsWithoutField, errors),
      };
    });
  };

  render() {
    const { onCloseForm } = this.props;
    const { card, errors } = this.state;

    const cardWithErrors = { ...card, errors };

    return (
      <div className="form expanded-form edit-credit-card">
        <h4 className="hidden">Create Credit Card</h4>
        <EditCreditCardDetails
          card={cardWithErrors}
          handleUpdateField={this.handleUpdateField}
          onBlurValidateCCField={this.handleValidateCCFieldOnBlur}
        />
        <div className="buttons">
          <Button
            className="button cancel"
            type="secondary"
            onClick={onCloseForm}
            size="small">
            Cancel
          </Button>

          <Button
            className="button update"
            type="primary"
            onClick={this.handleSubmit}
            size="small">
            Save
          </Button>
        </div>
      </div>
    );
  }
}
