import { connect } from "react-redux";

import {
  changeModalType,
  createAccount,
  logIn,
  logOut,
  resetPassword,
  setPassword,
  socialSignIn,
  toggleModal,
  updateField,
  validatePasswordOnBlur,
} from "actions/authentication";

import AuthModal from "components/authentication/auth_modal";
import isOLO from "helpers/is_olo";

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
    consumer: state.consumer,
    locationName: isOLO()
      ? state.storeInfo.locationName
      : state.brandInfo.locationName,
  };
}

const mapDispatchToProps = {
  onSocialLogIn: socialSignIn,
  onLogIn: logIn,
  onLogOut: logOut,
  onResetPassword: resetPassword,
  onSetPassword: setPassword,
  onChangeModalType: changeModalType,
  onToggleModal: toggleModal,
  onUpdateField: updateField,
  onCreateAccount: createAccount,
  validatePasswordOnBlur,
};

const AuthenticationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthModal);

export default AuthenticationContainer;
