import auth0 from "auth0-js";
import Aviator from "aviator";
import { camelizeKeys } from "humps";
import JwtDecode from "jwt-decode";
import { tap } from "ramda";
import { LOYALTY_API_URL, ORDERS_URL } from "api_urls";
import getBaseUri, { dineInQueryString, getPrettyUrl } from "get_base_uri";
import { checkStatus, parseJSON, postRequest } from "helpers/ajax_request";
import trackAnalytic from "helpers/analytics";
import auth0Config from "helpers/auth0_config";

import storage from "storage";

function postResetPassword(email, callback) {
  return fetch(`${LOYALTY_API_URL}/user/password/reset`, postRequest({ email }))
    .then(checkStatus)
    .then(callback);
}

function postPassword(password, callback) {
  return fetch(`${LOYALTY_API_URL}/user/password`, postRequest({ password }))
    .then(checkStatus)
    .then(callback);
}

function postOLOPassword(password, emailToken, reset, callback) {
  return fetch(
    `${ORDERS_URL}/consumer_registration`,
    postRequest({ password, emailToken, reset })
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys)
    .then(callback);
}

function postNewUserConfirmation(emailToken, callback) {
  const request = Aviator.getCurrentRequest();

  return (
    fetch(
      `${LOYALTY_API_URL}/api_key${request.queryString}`, // endpoint tbd
      postRequest()
    )
      // Cookie is set on successful request
      // that cookie is then implicitly passed
      // during postPassword (because we do not have the JWT)
      // return fetch(`${LOYALTY_API_URL}/user/confirm`, // endpoint tbd
      //   getRequest({ emailToken })
      // )
      .then(checkStatus)
      .then(parseJSON)
      .then(camelizeKeys)
      .then(tap(resp => storage.setItem("rememberToken", resp.apiKey)))
      .then(callback)
  );
}

function postOLOAccountCreation(user, callback) {
  const prettyUrl = getPrettyUrl();
  const { firstName, lastName, email } = user;

  return fetch(
    `${ORDERS_URL}/consumer`,
    postRequest({ firstName, lastName, email, storePrettyUrl: prettyUrl })
  )
    .then(checkStatus)
    .then(callback);
}

function authenticate(email, password, callback, errorCallback) {
  storage.setItem(
    "currentUrl",
    `${window.location.pathname}${window.location.search}${window.location.hash}`
  );

  const { domain, clientID, realm } = auth0Config();

  const webAuth = new auth0.WebAuth({
    domain,
    clientID,
    responseType: "token id_token",
    redirectUri: `${window.location.origin}/login_success`,
  });

  webAuth.client.login(
    {
      realm,
      username: email,
      // eslint-disable-next-line
      password: password,
      scope: "openid",
    },
    (err, authResult) => {
      if (err) {
        errorCallback(err);
      } else {
        storage.setItem("accessToken", authResult.accessToken);
        storage.setItem("idToken", authResult.idToken);
        storage.setItem("jwtExpiration", JwtDecode(authResult.idToken).exp);

        webAuth.client.userInfo(authResult.accessToken, (userErr, user) => {
          if (user) storage.setItem("user", JSON.stringify(user));
          trackAnalytic("Log In Success", { email });
          window.location.href = `${getBaseUri()}${dineInQueryString()}`; // redirecting instead of reloading because there are some query parameters we don't want to persist (for example, email tokens from account creation)
        });
      }
    }
  );
}

function socialAuthenticate(platform) {
  storage.setItem(
    "currentUrl",
    `${window.location.pathname}${window.location.search}${window.location.hash}`
  );

  const { domain, clientID } = auth0Config();

  const webAuth = new auth0.WebAuth({
    domain,
    clientID,
    responseType: "token id_token",
    redirectUri: `${window.location.origin}/s/social_login_success`,
  });

  webAuth.authorize({
    connection: platform,
  });
}

export {
  postPassword,
  postOLOPassword,
  postResetPassword,
  authenticate,
  postNewUserConfirmation,
  postOLOAccountCreation,
  socialAuthenticate,
};
