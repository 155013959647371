import { type ComponentType, lazy } from "react";

import { getFirstDataInitialValues } from "./firstData/getFirstDataInitialValues";
import type { MobilePayFormProps } from "./MobilePayFormProps";
import type { MobilePayReturnUrlAuthorizationProps } from "./MobilePayReturnUrlAuthorizationProps";
import type { MobilePayFormValues } from "interfaces/mobile_pay/MobilePayFormValues";
import type { CCProcessor } from "types/payments/credit_card";

export type AdditionalPaymentData = Record<string, any>;
export type SubmitHandler<T extends MobilePayFormValues = MobilePayFormValues> =
  (values: T) => Promise<AdditionalPaymentData | void>;

export interface CCProcessorConfiguration {
  form: ComponentType<MobilePayFormProps<any, any>>;
  ccInput: ComponentType;
  returnUrlAuthorization?: ComponentType<MobilePayReturnUrlAuthorizationProps>;
  /**
   * Enable customized submit processing, where the default success / failure handling won't be used
   */
  isCustomizedSubmitProcessing: boolean;
  getInitialValues?: (
    initialFormValues: MobilePayFormValues
  ) => MobilePayFormValues;
}

export const CCProcessorConfigurationMap: Record<
  CCProcessor,
  CCProcessorConfiguration
> = {
  adyen: {
    form: lazy(() => import("./adyen/AdyenMobilePayForm")),
    ccInput: lazy(() => import("./adyen/AdyenMobilePayInputs")),
    returnUrlAuthorization: lazy(
      () => import("./adyen/AdyenMobilePayPaymentReturnUrlAuthorization")
    ),
    isCustomizedSubmitProcessing: true,
  },
  firstData: {
    form: lazy(() => import("./firstData/FirstDataMobilePayForm")),
    ccInput: lazy(() => import("./firstData/FirstDataMobilePayInputs")),
    isCustomizedSubmitProcessing: false,
    getInitialValues: getFirstDataInitialValues,
  },
};
