import { connect } from "react-redux";

import { validateAndPutContactInfo } from "actions/online_ordering";
import { updateContactInfo } from "actions/online_ordering/checkout";
import CustomerInfo from "components/online_ordering/checkout/customer_info";

function mapStateToProps(state) {
  return {
    contactInfo: state.consumer.contactInfo,
  };
}

const mapDispatchToProps = {
  onValidateAndSaveContactInfo: validateAndPutContactInfo,
  onContactInfoUpdate: updateContactInfo,
};

const CustomerInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerInfo);

export default CustomerInfoContainer;
