import PropTypes from "prop-types";
import { isEmpty, isNil, map } from "ramda";
import React from "react";

import OrderRow from "components/account/order_history/order_row";
import isOLO from "helpers/is_olo";

function OrderHistory({ orderHistory, reorder }) {
  if (isNil(orderHistory) || isEmpty(orderHistory)) {
    return <span />;
  }

  let orderNumber;
  if (isOLO()) {
    orderNumber = <th className="total">Order #</th>;
  }
  const renderedRows = map(
    order => (
      <OrderRow order={order} key={order.confirmationCode} reorder={reorder} />
    ),
    orderHistory
  );

  return (
    <div className="account-section order-history">
      <h3>Order history</h3>
      <table className="order-history-table">
        <thead>
          <tr>
            <th className="date">Date</th>
            <th className="method">Type</th>
            <th className="total">Total</th>
            {orderNumber}
          </tr>
        </thead>
        <tbody>{renderedRows}</tbody>
      </table>
    </div>
  );
}

OrderHistory.propTypes = {
  orderHistory: PropTypes.array.isRequired,
  reorder: PropTypes.func.isRequired,
};

export default OrderHistory;
