import type { CoreOptions } from "@adyen/adyen-web/dist/types/core/types";
import getApp from "helpers/get_app";
import getEnv from "helpers/get_env";

export const SEGMENT_API_KEY = "qsiFG4InsIeUw7Quw3pN6QScMH1pjgJn";
export const TRACK_JS_TOKEN = "fd970d226c4b475d897759f950989046";

const ENV = getEnv();
const APP = getApp();

export const ADYEN_ENV: CoreOptions["environment"] =
  ENV === "production" ? "live-us" : "test";
export const ADYEN_CLIENT_KEY =
  ADYEN_ENV === "live-us"
    ? "live_W3M4VO2SG5DN5GPMEA5DSNO4BYJJ5TBH"
    : "test_XHFIACS4DZDHZK2KSSQ527MUNAGMG2NY";

const CONFIG = {
  olo: {
    test: "olo-test",
    development: "olo-dev",
    staging: "olo-staging",
    production: "olo-prod",
  },
  pay: {
    test: "pay-test",
    development: "pay-dev",
    staging: "pay-staging",
    production: "pay-prod",
  },
  loyalty: {
    test: "loyalty-test",
    development: "loyalty-dev",
    staging: "loyalty-staging",
    production: "loyalty-prod",
  },
  giftCard: {
    production: "digital-gift-cards-prod",
  },
  unknown: {
    test: "unknown-test",
    development: "unknown-dev",
    staging: "unknown-staging",
    production: "unknown-prod",
  },
};

const TRACK_JS_APPLICATION_KEY = CONFIG[APP][ENV];

export { TRACK_JS_APPLICATION_KEY };
