import styled from "styled-components";

export const StyledErrorText = styled.div`
  font-size: 24px;
  line-height: 30px;

  word-break: break-word;

  color: #212322;
`;
