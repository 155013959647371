import React, { type ReactNode, useEffect } from "react";
import styled from "styled-components";

import Header from "components/mobile_pay/header";
import type { MerchantInfo } from "types/mobile_pay";

export const StyledWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  padding: 20px;

  @media (max-width: 320px) {
    padding: 10px;
  }

  text-align: center;

  > .icon-wrapper svg {
    width: 100px;
    height: 96px;
  }
`;

const StyledAddressFooter = styled.div`
  padding: 25px;

  text-align: center;

  font-size: 24px;
  line-height: 30px;

  color: #000000;
`;

export const StyledBoldText = styled.span`
  font-weight: bold;
`;

type PageProps = {
  merchantInfo: MerchantInfo;
  children: React.ReactNode;
  signlePanelContent?: boolean | undefined;
};

export default function Page({
  merchantInfo,
  children,
  signlePanelContent = true,
}: PageProps) {
  const { name, streetLine1, streetLine2, locality, region, phone } =
    merchantInfo;
  const telephoneHref = `tel:${phone.replace(/[^\w\s]|_/g, "")}`;
  const address2: ReactNode = streetLine2.match({
    Just: address => <div>{address}</div>,
    Nothing: () => <></>,
  });

  const content = signlePanelContent ? (
    <StyledWrapper>{children}</StyledWrapper>
  ) : (
    <>{children}</>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header merchantName={merchantInfo.name} />
      {content}
      <StyledAddressFooter>
        <StyledBoldText>{name}</StyledBoldText>
        <div>
          {streetLine1}
          {address2}
        </div>
        <div>{`${locality}, ${region}`}</div>
        <a href={telephoneHref}>{phone}</a>
      </StyledAddressFooter>
    </>
  );
}
