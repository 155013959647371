import { type UseQueryOptions, useQuery } from "react-query";
import { TrackJS } from "trackjs";
import type { IResponseError } from "helpers/ajax_request";
import { getDigitalGiftCardsSettings } from "interfaces/digital_gift_cards";
import type { DigitalGiftCardsSettings } from "types/digital_gift_cards";

export const useDigitalGiftCardsSettings = (
  brandPrettyUrl: string,
  options?: UseQueryOptions<
    DigitalGiftCardsSettings,
    IResponseError,
    DigitalGiftCardsSettings,
    ["digitalGiftCardsSettings", string]
  >
) => {
  return useQuery(
    ["digitalGiftCardsSettings", brandPrettyUrl],
    () => {
      return getDigitalGiftCardsSettings(brandPrettyUrl);
    },
    {
      onError: error => {
        TrackJS.track(`error fetching settings from S3: ${error}`);
      },
      ...options,
    }
  );
};
