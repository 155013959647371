/* eslint-disable react/no-multi-comp */
import { any, dropLast, isEmpty, not, reduce } from "ramda";
import React from "react";
import styled from "styled-components";

import { getTransformUrl } from "components/header";
import Icon from "components/icon";
import useViewportDimensions from "helpers/hooks/useViewportDimensions";
import { LdFlags, hasFeatureFlagEnabled } from "helpers/online_ordering";
import type { DigitalGiftCardsSettings, Flags } from "types/digital_gift_cards";

const header = require("images/ecard_header.png");

export type MarginProps = {
  leftMargin: string;
};

const StyledDefaultName = styled.div<MarginProps>`
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: ${({ leftMargin }) => leftMargin};

  font-weight: 600;
  font-size: 1.75rem; //~24px based on 14px root font-size; decrease to 1.5rem for 16px root
  line-height: 1.75rem; //~24px based on 14px root font-size; decrease to 1.5rem for 16px root

  @media (max-width: 1024px) {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 0;
    text-align: center;
    font-size: 1.125rem; // ~16px based on 14px root font-size; decrease to 1rem for 16px root
  }
`;

const StyledCustomLogo = styled.div<MarginProps>`
  margin-left: ${({ leftMargin }) => leftMargin};
  width: 200px;
  height: 66px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    height: 90%;
    object-fit: contain;
  }

  @media (max-width: 1024px) {
    margin: auto;
  }
`;

const StyledBanner = styled.div`
  height: 200px;
  width: 100%;

  @media (max-width: 1024px) {
    height: 128px;
  }
`;

const StyledStoreDetails = styled.div<MarginProps>`
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  padding: 32px;
  margin-left: ${({ leftMargin }) => leftMargin};
  width: 850px;

  position: relative;
  bottom: 32px;
  margin-bottom: -32px;

  font-size: 1rem; // 14px based on 14px root font-size; decrease to .875rem for 16px root
  line-height: 1.5rem; // ~22.5px based on 14px root font-size; decrease to 1.33rem for 16px root
  white-space: pre-wrap;

  @media (max-width: 1024px) {
    margin-left: 20px;
    margin-right: 20px;
    width: auto;
    padding: 12px;
  }
`;

const StyledDetailsName = styled.div`
  font-weight: 600;
  font-size: 1.75rem; //~24px based on 14px root font-size; decrease to 1.5rem for 16px root
  line-height: 2.25rem;
`;

const StyledMessageBanner = styled.div<MarginProps>`
  margin-left: ${({ leftMargin }) => leftMargin};
  margin-bottom: 32px;
  padding: 16px 32px;
  border-radius: 8px;
  background-color: #ffedee;
  display: flex;
  align-items: center;
  width: 850px;

  .icon {
    color: #e02948;
    height: 30px;
    width: auto;
  }

  .message-content {
    padding-left: 20px;
  }

  .message-header {
    font-weight: 600;
  }

  @media (max-width: 1024px) {
    margin-left: 20px;
    margin-right: 20px;
    width: auto;
    padding: 12px;
  }
`;

type HeaderProps = {
  settings: DigitalGiftCardsSettings;
  leftMargin: string;
  flags?: Flags;
};

export default function Header({
  settings,
  leftMargin,
  flags = {},
}: HeaderProps) {
  const {
    additionalDetails,
    bannerUrl,
    city,
    logoUrl,
    name,
    phone,
    state,
    street1,
    street2,
    website,
    zip,
  } = settings;

  const { mobile } = useViewportDimensions();

  const customBannerUrl = bannerUrl.match({
    Just: url => {
      return getTransformUrl(url);
    },
    Nothing: () => header,
  });

  const logoOrName = logoUrl.match({
    Just: url => {
      return (
        <StyledCustomLogo leftMargin={leftMargin}>
          <img src={getTransformUrl(url)} alt={name} />
        </StyledCustomLogo>
      );
    },
    Nothing: () => (
      <StyledDefaultName leftMargin={leftMargin}>{name}</StyledDefaultName>
    ),
  });

  const displayName = logoUrl.isNothing() ? null : (
    <StyledDetailsName>{name}</StyledDetailsName>
  );

  const displayAddress = dropLast(
    2,
    reduce(
      (acc, item) => {
        if (not(isEmpty(item))) {
          return acc.concat(`${item}, `);
        }
        return acc;
      },
      "",
      [street1, street2, city, state, zip]
    )
  );

  const displayPhone = phone.unwrapOr("");

  const displayAddrPhone = mobile ? (
    <>
      <div>{displayAddress}</div>
      <div>{displayPhone}</div>
    </>
  ) : (
    <div>
      {`${displayAddress}${
        any(isEmpty)([displayAddress, displayPhone]) ? "" : " | "
      }${displayPhone}`}
    </div>
  );

  const displayWebsite = website.match({
    Just: web => {
      const absoluteUrl = /https?:\/\//.test(web) ? web : `http://${web}`;
      return (
        <div>
          <a href={`${absoluteUrl}`} target="_blank" rel="noopener noreferrer">
            {web}
          </a>
        </div>
      );
    },
    Nothing: () => null,
  });

  const displayDetails = additionalDetails.match({
    Just: details => <div>{details}</div>,
    Nothing: () => null,
  });

  let errorBanner;
  if (hasFeatureFlagEnabled(flags, LdFlags.ERROR_BANNER)) {
    errorBanner = (
      <StyledMessageBanner leftMargin={leftMargin}>
        <Icon type="exclamation-circle-light" />
        <div className="message-content">
          <p>
            <span className="message-header">
              Our Credit Card processing partner, First Data, has acknowledged a
              partial service interruption.
            </span>
            <br />
            Please try your purchase again soon.
          </p>
        </div>
      </StyledMessageBanner>
    );
  }

  return (
    <>
      {logoOrName}
      <StyledBanner
        style={{
          backgroundImage: `url(${customBannerUrl})`,
          backgroundSize: "cover",
        }}
      />
      <StyledStoreDetails leftMargin={leftMargin}>
        {displayName}
        {displayAddrPhone}
        {displayWebsite}
        {displayDetails}
      </StyledStoreDetails>
      {errorBanner}
    </>
  );
}
