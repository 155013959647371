import {
  type StoreInfoActionTypes,
  UPDATE_STORE_INFO,
} from "actions/online_ordering/store_info";
import type { StoreInfo } from "types/reducers/store_info";

const initialState: StoreInfo = {
  prettyUrl: "",
  permalink: "",
  apiUsername: "",
  locationUrl: "",
  locationName: "",
  description: "",
  phoneNumber: "",
  integrationStatus: "",
  orderTakingStatus: "",
  deliveryFee: 0,
  deliveryFeeTaxRateId: "",
  deliveryMinimumOrder: "",
  deliveryZips: [],
  deliveryGeojson: "",
  deliveryMapSettings: "",
  timezone: "",
  ugMerchantKey: "",
  address: {
    line1: "",
    line2: "",
    city: "",
    state: "",
    zip: "",
  },
  fulfillmentTypes: [],
  latitude: "",
  longitude: "",
  published: false,
  futureOrder: false,
  orderReadyBy: 0,
  deliveryOrderReadyBy: 0,
  deliveryOrderReadyByMax: 0,
  pickupOrderReadyBy: 0,
  pickupOrderReadyByMax: 0,
  settings: {
    collapseGroupsByDefault: false,
    headerImageUrl: "",
  },
  mapUrl: "",
};

function storeInfo(
  state: StoreInfo = initialState,
  action: StoreInfoActionTypes
) {
  switch (action.type) {
    case UPDATE_STORE_INFO: {
      return {
        ...action.storeInfo,
      };
    }
    default: {
      return state;
    }
  }
}

export default storeInfo;
