import React, { type ReactNode } from "react";
import styled, { css } from "styled-components";

interface IPriceRowListStyle {
  isDense?: boolean;
}

export const PriceRowList = styled.dl<IPriceRowListStyle>`
  color: ${props => props.theme.colors.grayLight};
  display: grid;
  grid-row-gap: ${props => (props.isDense ? "5px" : "10px")};
  grid-template-columns: 2fr 1fr;
  line-height: 20px;
  margin: 0;
`;

interface IPriceStyle {
  isBold?: boolean;
}

const boldCSS = css`
  color: ${props => props.theme.colors.gray};
  font-weight: 600;
  font-size: 1.125rem; // ~16px based on 14px root font-size; decrease to 1rem for 16px
`;

const PriceTitle = styled.dt<IPriceStyle>`
  ${props => props.isBold && boldCSS};
`;

const PriceValue = styled.dd<IPriceStyle>`
  ${props => props.isBold && boldCSS};
  margin-inline-start: 0;
  text-align: right;
`;

export interface IPriceRowProps extends IPriceStyle {
  title: ReactNode;
  value: ReactNode;
}

export default function PriceRow({
  isBold = false,
  title,
  value,
}: IPriceRowProps) {
  return (
    <>
      <PriceTitle isBold={isBold}>{title}</PriceTitle>
      <PriceValue isBold={isBold}>{value}</PriceValue>
    </>
  );
}
