import { decamelizeKeys } from "humps";
import storage from "storage";

function getRequest(): RequestInit {
  return {
    method: "GET",
    credentials: "same-origin",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": storage.getItem("idToken"), // JWT
      "X-Api-Key": storage.getItem("rememberToken"),
    },
  };
}

function cloudfrontGetRequest(): RequestInit {
  return {
    method: "GET",
    cache: "no-cache",
  };
}

function putRequest(values: unknown): RequestInit {
  return {
    method: "PUT",
    credentials: "same-origin",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": storage.getItem("idToken"), // JWT
      "X-Api-Key": storage.getItem("rememberToken"),
    },
    body: JSON.stringify(decamelizeKeys(values)),
  };
}

function postRequest(values = {}): RequestInit {
  return {
    method: "POST",
    credentials: "same-origin",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": storage.getItem("idToken"), // JWT
      "X-Api-Key": storage.getItem("rememberToken"),
    },
    body: JSON.stringify(decamelizeKeys(values)),
  };
}

function deleteRequest(values) {
  return {
    method: "DELETE",
    credentials: "same-origin",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": storage.getItem("idToken"), // JWT
      "X-Api-Key": storage.getItem("rememberToken"),
    },
    body: JSON.stringify(decamelizeKeys(values)),
  };
}

interface IResponseError extends Error {
  response?: Response;
}

function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error: IResponseError = new Error(response.statusText);
  error.response = response;
  throw error;
}

const parseJSON = (response: Response) => response.json();

export {
  IResponseError,
  checkStatus,
  cloudfrontGetRequest,
  deleteRequest,
  getRequest,
  parseJSON,
  postRequest,
  putRequest,
};
