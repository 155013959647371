import PropTypes from "prop-types";
import { partial, pipe } from "ramda";
import React from "react";

import Button from "components/button";
import Icon from "components/icon";
import Input from "components/input";
import Modal from "components/modal";
import { getTargetValue, submitOnEnter } from "helpers/event";
import handleFormErrors from "helpers/handle_form_errors";

function CreateAccount({
  onCreateAccount,
  onChangeModalType,
  onClose,
  onUpdateField,
  authentication,
  locationName,
}) {
  return (
    <Modal
      title="Create an account"
      onClose={onClose}
      className="create-account-modal">
      <div className="contents">
        <div className="message">
          {locationName} uses the Lightspeed (U-Series) platform for online
          ordering. Create an account for easy ordering in the future and keep
          track of your loyalty!
        </div>
        <Input
          id="create-account-first-name"
          placeholder="First name"
          value={authentication.firstName}
          onChange={pipe(getTargetValue, partial(onUpdateField, ["firstName"]))}
          onKeyDown={partial(submitOnEnter, [onCreateAccount, authentication])}
          error={handleFormErrors("firstName", authentication)}
          autoFocus
          maxLength="32"
        />
        <Input
          id="create-account-last-name"
          placeholder="Last name"
          value={authentication.lastName}
          onChange={pipe(getTargetValue, partial(onUpdateField, ["lastName"]))}
          onKeyDown={partial(submitOnEnter, [onCreateAccount, authentication])}
          error={handleFormErrors("lastName", authentication)}
          maxLength="32"
        />
        <Input
          id="create-account-email"
          placeholder="Email"
          value={authentication.email}
          onChange={pipe(getTargetValue, partial(onUpdateField, ["email"]))}
          onKeyDown={partial(submitOnEnter, [onCreateAccount, authentication])}
          error={handleFormErrors("email", authentication)}
        />
        <Button
          className="create-account-button"
          onClick={partial(onCreateAccount, [authentication])}>
          Sign up
        </Button>
        <a
          className="show-login"
          onClick={partial(onChangeModalType, ["LOGIN"])}>
          Returning customer? Log in.
        </a>
      </div>
      <div className="login-footer">
        <a
          href="https://upserve.com/"
          target="_blank"
          rel="noopener noreferrer">
          <Icon className="logo-icon" type="upserve-by-lightspeed" />
        </a>
      </div>
    </Modal>
  );
}

CreateAccount.propTypes = {
  onChangeModalType: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  authentication: PropTypes.object.isRequired,
  locationName: PropTypes.string.isRequired,
  onCreateAccount: PropTypes.func.isRequired,
};

export default CreateAccount;
