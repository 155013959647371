import cn from "classnames";
import { isEmpty, isNil } from "ramda";
import React, { type InputHTMLAttributes, forwardRef } from "react";

export interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  id: string;
}

type Ref = HTMLInputElement;

const Input = forwardRef<Ref, IInputProps>(function Input(
  props: IInputProps,
  ref
) {
  const { error, className = "", id, ...forward } = props;
  const inputErrorId = `${id}-error-msg`;

  const hasError = !isNil(error) && !isEmpty(error);
  let errorClass = "";
  let renderedError;
  if (hasError) {
    errorClass = "error";
    renderedError = (
      <span className="error-text" id={inputErrorId}>
        {error}
      </span>
    );
  }

  return (
    <span className={`text-input ${errorClass}`}>
      <input
        {...forward}
        {...(hasError
          ? { "aria-errormessage": inputErrorId, "aria-invalid": true }
          : {})}
        className={cn("text", className, errorClass)}
        id={id}
        ref={ref}
      />
      {renderedError}
    </span>
  );
});

export default Input;
