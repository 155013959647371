import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { oloConsumerInfoQueryKey } from "./useOloConsumerInfo";
import { orderSuccess } from "actions/online_ordering";
import { usePrettyUrl } from "helpers/hooks/usePrettyUrl";

export const useOloOrderSuccess = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const storePrettyUrl = usePrettyUrl();
  return useCallback(async () => {
    dispatch(orderSuccess());
    await queryClient.invalidateQueries(
      oloConsumerInfoQueryKey(storePrettyUrl)
    );
  }, [dispatch, queryClient, storePrettyUrl]);
};
