import { useQuery } from "react-query";
import { useLoyaltySelector } from "./useLoyaltySelector";

/**
 * Temporary measure before migrating from redux to react query.
 */
export const useLoyaltyCreditCards = () => {
  const creditCards = useLoyaltySelector(state => state.consumer?.cards);
  return useQuery(
    ["creditCards", creditCards?.map(({ cardUuid }) => cardUuid).join("/")],
    () => {
      return creditCards;
    },
    {
      staleTime: undefined, // staleTime is configured to be undefined so whenever redux updates it will update react query client data.
    }
  );
};
