import React from "react";
import { DigitalGiftCardPaymentReturnUrlAuthorization } from "./DigitalGiftCardPaymentReturnUrlAuthorization";
import { useDigitalGiftCardUrl } from "hooks/digital_gift_cards/useDigitalGiftCardUrl";
import { usePaymentAuthorization } from "hooks/payment/usePaymentAuthorization";
import type { DigitalGiftCardPaymentConfirmation } from "types/digital_gift_cards/DigitalGiftCardPaymentConfirmation";

export interface DigitalGiftCardPaymentAuthorizationProps {
  storePrettyUrl: string;
  onSuccess: (response: DigitalGiftCardPaymentConfirmation) => void;
  onError: (error: string) => void;
}

export const DigitalGiftCardPaymentAuthorization = ({
  storePrettyUrl,
  onSuccess,
  onError,
}: DigitalGiftCardPaymentAuthorizationProps) => {
  const digitalGiftCardUrl = useDigitalGiftCardUrl();
  const { paymentProcessor, navigateToBase } =
    usePaymentAuthorization(digitalGiftCardUrl);

  if (!paymentProcessor) {
    return null;
  }

  return (
    <DigitalGiftCardPaymentReturnUrlAuthorization
      storePrettyUrl={storePrettyUrl}
      paymentProcessor={paymentProcessor}
      onSuccess={onSuccess}
      onError={onError}
      onFinalize={navigateToBase}
    />
  );
};
