import { Money } from "@upserve/financials";
import { isEmpty } from "ramda";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { DigitalGiftCardConfirmationModal } from "./confirm/DigitalGiftCardConfirmationModal";
import { DigitalGiftCardCreditCardForm } from "./form/DigitalGiftCardCreditCardForm";
import { DigitalGiftCardPaymentAuthorization } from "./payment/DigitalGiftCardPaymentAuthorization";
import type { MarginProps } from "components/digital_gift_cards/header";
import OrderForm, {
  type DigitalGiftCardOrderForm,
} from "components/digital_gift_cards/order_form";

import type {
  DigitalGiftCardOrderApiResponse,
  DigitalGiftCardsSettings,
} from "types/digital_gift_cards";
import type { DigitalGiftCardPaymentConfirmation } from "types/digital_gift_cards/DigitalGiftCardPaymentConfirmation";

const DefaultGiftCard = require("images/default-gift-card.png");

export const StyledDGCMainContent = styled.div<MarginProps>`
  width: 850px;
  margin-left: ${({ leftMargin }) => leftMargin};
  display: flex;

  @media (max-width: 1024px) {
    margin-left: 20px;
    margin-right: 20px;
    width: auto;
    padding: 12px;
    flex-direction: column-reverse;
  }
`;

export const StyledFormWrapper = styled.div`
  width: 424px;
  padding: 0 32px;

  @media (max-width: 1024px) {
    width: auto;
  }
`;

export const StyledGiftCardOutline = styled.div`
  margin-top: 32px;
  width: 360px;
  height: 224px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  border: 2px solid #f6f6f6;
  box-shadow: 2px 5px 5px #888888;
  box-sizing: border-box;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    max-height: 90%;
    max-width: 90%;
    object-fit: contain;
  }

  @media (max-width: 1024px) {
    width: 270px;
    height: 165px;
    margin: auto;
    margin-bottom: 32px;
  }
`;

export const StyledDefaultGiftCardWrapper = styled.div`
  margin-top: 32px;
  width: 360px;
  height: 224px;

  img {
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 1024px) {
    width: 270px;
    height: 165px;
    margin: auto;
    margin-bottom: 32px;
  }
`;

type MainContentProps = {
  settings: DigitalGiftCardsSettings;
  leftMargin: string;
};

export default function MainContent<
  T extends DigitalGiftCardOrderApiResponse = DigitalGiftCardOrderApiResponse,
>({ settings, leftMargin }: MainContentProps) {
  const { logoUrl, storePrettyUrl, ugMerchantKey } = settings;

  const [orderFormValid, setOrderFormValid] = useState(false);
  const [orderFormValues, setOrderFormValues] =
    useState<DigitalGiftCardOrderForm>();
  const [error, setError] = useState<string | null>(null);

  const [paymentConfirmation, setPaymentConfirmation] = useState<
    DigitalGiftCardPaymentConfirmation | undefined
  >(undefined);
  const handlePaymentSuccess = useCallback(
    (confirmation: DigitalGiftCardPaymentConfirmation) => {
      setPaymentConfirmation(confirmation);
    },
    []
  );

  const giftCardImage = logoUrl.match({
    Just: url => (
      <StyledGiftCardOutline>
        <img src={url} alt="gift card" />
      </StyledGiftCardOutline>
    ),
    Nothing: () => (
      <StyledDefaultGiftCardWrapper>
        <img src={DefaultGiftCard} alt="gift card" />
      </StyledDefaultGiftCardWrapper>
    ),
  });

  const handlePaymentError = useCallback((errorMessage: string) => {
    setError(errorMessage);
  }, []);

  const balanceDue =
    orderFormValues &&
    !isEmpty(orderFormValues.amount) &&
    !Number.isNaN(parseFloat(orderFormValues.amount))
      ? Money.fromDollars(orderFormValues.amount)
      : Money(0);

  return (
    <StyledDGCMainContent leftMargin={leftMargin}>
      <StyledFormWrapper>
        <OrderForm
          settings={settings}
          setFormValid={setOrderFormValid}
          setFormValues={setOrderFormValues}
        />
        <DigitalGiftCardCreditCardForm
          merchantInfo={{ ugMerchantKey, storePrettyUrl }}
          paymentInfo={{ balanceDue }}
          orderFormValues={orderFormValues}
          isSubmitDisabled={!orderFormValid || balanceDue.isZero()}
          onPaymentSuccess={handlePaymentSuccess}
          onPaymentError={handlePaymentError}
          error={error}
        />
      </StyledFormWrapper>
      {giftCardImage}

      <DigitalGiftCardPaymentAuthorization
        storePrettyUrl={storePrettyUrl}
        onSuccess={handlePaymentSuccess}
        onError={handlePaymentError}
      />
      {paymentConfirmation && (
        <DigitalGiftCardConfirmationModal
          paymentConfirmation={paymentConfirmation}
          storePrettyUrl={storePrettyUrl}
        />
      )}
    </StyledDGCMainContent>
  );
}
