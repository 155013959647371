import { connect } from "react-redux";

import { fetchMenu } from "actions/online_ordering";
import MainView from "components/online_ordering/main_view";
import { checkAndApplyLocalStorage } from "helpers/local_storage";

function mapStateToProps(state) {
  return {
    storeInfo: state.storeInfo,
    orderingInfo: state.orderingInfo,
    cart: state.cart,
    isCheckingOut: state.checkout.status.isCheckingOut,
    orderingOptions: state.orderingOptions,
  };
}

const mapDispatchToProps = {
  onFetchMenu: fetchMenu,
  onCheckAndApplyLocalStorage: checkAndApplyLocalStorage,
};

const MainViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainView);

export default MainViewContainer;
