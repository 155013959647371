import React from "react";
import styled from "styled-components";

const StyledSubmissionErrorMessage = styled.div`
  position: relative;
  bottom: 10px;

  align-self: flex-start;

  font-size: 16px;
  color: #e02948;
`;

export interface MobilePayPaymentErrorProps {
  error: string;
}

export const MobilePayPaymentError = ({
  error,
}: MobilePayPaymentErrorProps) => {
  return (
    <StyledSubmissionErrorMessage
      data-testid="payment-error"
      className="error payment-error">
      {error}
    </StyledSubmissionErrorMessage>
  );
};
