import {
  RESET_DELIVERY_ADDRESS,
  SET_ADDRESS_VALIDATION_STATUS,
  SET_GEOLOCATION_FETCH_ERROR,
  SET_USER_GEOLOCATED_ADDRESS,
  UPDATE_DELIVERY_ADDRESS_VALUE,
  UPDATE_GEOLOCATION_INFLIGHT_STATUS,
} from "actions/online_ordering/delivery_address";
import { ADDRESS_VALIDATIONS } from "helpers/address";

const initialState = {
  addressValidationStatus: ADDRESS_VALIDATIONS.NOT_STARTED,
  geolocationFetchError: null,
  geolocationInFlight: false,
  userGeolocatedAddress: null,
  value: { pretty: "Select an address" },
};

function deliveryAddress(state = initialState, action) {
  switch (action.type) {
    case SET_USER_GEOLOCATED_ADDRESS: {
      return {
        ...state,
        userGeolocatedAddress: action.addressFromResponse,
      };
    }

    case SET_GEOLOCATION_FETCH_ERROR: {
      return {
        ...state,
        geolocationFetchError: action.error,
      };
    }

    case UPDATE_GEOLOCATION_INFLIGHT_STATUS: {
      return {
        ...state,
        geolocationInFlight: action.inFlight,
      };
    }

    case SET_ADDRESS_VALIDATION_STATUS: {
      const { addressValidationStatus } = action;
      return {
        ...state,
        addressValidationStatus,
      };
    }

    case UPDATE_DELIVERY_ADDRESS_VALUE: {
      return {
        ...state,
        value: action.address,
      };
    }

    case RESET_DELIVERY_ADDRESS: {
      return initialState;
    }

    default:
      return state;
  }
}

export default deliveryAddress;
