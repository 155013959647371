import Aviator from "aviator";
import PropTypes from "prop-types";
import React from "react";
import { Provider } from "react-redux";

import { confirmNewUser, showSetPasswordModal } from "actions/authentication";

import Footer from "components/footer";
import Header from "components/header";
import Loader from "components/loader";
import LoyaltyContainer from "containers/loyalty_container";
import getBaseUri from "get_base_uri";
import isLoggedIn from "is_logged_in";
// import MenuContainer from 'containers/menu_container';
// import CartContainer from 'containers/cart_container';

function App({ store, request }) {
  if (!store || !store.getState() || !store.getState().brandInfo) {
    return (
      <div className="app">
        <Loader />
      </div>
    );
  }

  const state = store.getState();

  // Manually set page title to brand name on load
  document.title = state.brandInfo.locationName;

  if (request.queryParams.email_token && !isLoggedIn()) {
    store.dispatch(confirmNewUser(request.queryParams.email_token));
    store.dispatch(showSetPasswordModal());
  }

  const content = <LoyaltyContainer />;
  const contentClass = "loyalty-only";

  // TODO when implementing online ordering we'll need to rethink this logic
  // Loyalty is brand level so we don't have and individual store's info
  /*
  let content;
  let contentClass = '';
  if (!state.storeInfo.hasOrdering) {
    contentClass = 'loyalty-only';
    content = <LoyaltyContainer />;
  }
  else if (!state.storeInfo.hasLoyalty) {
    content = [
      <MenuContainer key='menu' />,
      <CartContainer key='cart' />,
    ];
  }
  else {
    content = [
      <div key='left-container'>
        <LoyaltyContainer key='loyalty' />
        <MenuContainer key='menu' />
      </div>,
      <CartContainer key='cart' />,
    ];
  }
  */

  return (
    <Provider store={store}>
      <div className="app">
        <Header
          locationInfo={state.brandInfo}
          onHeadingClick={() => Aviator.navigate(getBaseUri())}
        />
        <div className={`content ${contentClass}`}>{content}</div>
        <Footer />
      </div>
    </Provider>
  );
}

App.propTypes = {
  request: PropTypes.object.isRequired,
  store: PropTypes.object,
};

export default App;
