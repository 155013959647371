import { concat, curry, find, prop, propEq, reject } from "ramda";
import type { AdyenCardType } from "types/payments/adyen/AdyenWebSDK";

export type LoyaltyCardType = "AX" | "VI" | "MC" | "DS";

export type OloCardType = "amex" | "visa" | "mastercard" | "discover";

export interface CreditCardFormatter {
  type: LoyaltyCardType;
  oloCardType: OloCardType;
  adyenType: AdyenCardType;
  prettyName: string;
  lowercase: string;
  maxLength: number;
  format: string;
  choppedFormat: string;
  firstChar: string;
  cvvMaxLength: number;
}

export const CARD_FORMATTERS = [
  {
    type: "AX",
    oloCardType: "amex",
    adyenType: "amex",
    prettyName: "Amex",
    lowercase: "amex",
    maxLength: 15,
    format: "xxxx xxxxxx xxxxx",
    choppedFormat: "xxxx xxxxxx x",
    firstChar: "3",
    cvvMaxLength: 4,
  },
  {
    type: "VI",
    oloCardType: "visa",
    adyenType: "visa",
    prettyName: "Visa",
    lowercase: "visa",
    maxLength: 16,
    format: "xxxx xxxx xxxx xxxx",
    choppedFormat: "xxxx xxxx xxxx ",
    firstChar: "4",
    cvvMaxLength: 3,
  },
  {
    type: "MC",
    oloCardType: "mastercard",
    adyenType: "mc",
    prettyName: "MasterCard",
    lowercase: "mastercard",
    maxLength: 16,
    format: "xxxx xxxx xxxx xxxx",
    choppedFormat: "xxxx xxxx xxxx ",
    firstChar: "5",
    cvvMaxLength: 3,
  },
  {
    type: "DS",
    oloCardType: "discover",
    adyenType: "discover",
    prettyName: "Discover",
    lowercase: "discover",
    maxLength: 16,
    format: "xxxx xxxx xxxx xxxx",
    choppedFormat: "xxxx xxxx xxxx ",
    firstChar: "6",
    cvvMaxLength: 3,
  },
] as const satisfies readonly CreditCardFormatter[];

export const FIRST_CHAR_TO_FORMATTER_MAP: Record<string, CreditCardFormatter> =
  Object.fromEntries(
    CARD_FORMATTERS.map(formatter => [formatter.firstChar, formatter])
  );

export const OLO_CARD_TYPE_TO_FORMATTER_MAP: Record<
  OloCardType,
  CreditCardFormatter
> = Object.fromEntries(
  CARD_FORMATTERS.map(formatter => [formatter.oloCardType, formatter])
) as Record<OloCardType, CreditCardFormatter>;

export type CreditCardLowerCaseType =
  (typeof CARD_FORMATTERS)[number]["lowercase"];

export type CreditCardSentenceCaseType =
  (typeof CARD_FORMATTERS)[number]["prettyName"];

export const findFormattersByType = (type: string) =>
  find(propEq("type", type), CARD_FORMATTERS);

export const findFormattersByLowercase = lowercaseName =>
  find(propEq("lowercase", lowercaseName), CARD_FORMATTERS);

/**
 * @deprecated use FIRST_CHAR_TO_FORMATTER_MAP directly
 * @param digit
 */
export const findCardTypeByFirstDigit = (
  digit: string | number
): CreditCardFormatter | undefined => {
  return FIRST_CHAR_TO_FORMATTER_MAP[digit];
};

export const formatObscuredCardNumberByType = (
  type: string,
  lastFour: string
) => concat(findFormattersByType(type)?.choppedFormat ?? "", lastFour);

export const formatObscuredCardNumberByLowercase = (
  lowercaseName: string,
  lastFour: string
) =>
  concat(
    findFormattersByLowercase(lowercaseName)?.choppedFormat ?? "",
    lastFour
  );

export const removeCardByCardUuid = curry((cardUuid, cards) =>
  reject(propEq("cardUuid", cardUuid), cards)
);
export const findCardByCardUuid = curry((cardUuid, cards) =>
  find(propEq("cardUuid", cardUuid), cards)
);

export function handleErrors(key, card) {
  if (card.errors) return prop(key, card.errors);
  return null;
}
