import PropTypes from "prop-types";
import React from "react";

import formatMoney from "sig/formatters/money";

import LoyaltyImage from "components/loyalty/loyalty_image";
import RewardProgressBar from "components/loyalty/reward_progress_bar";

function PointReward({ reward, progression }) {
  const goal = parseInt(reward.price, 10);

  let description;
  if (progression.pointsAccrued === 0) {
    description =
      "We're looking forward to seeing you again. Be sure to use your enrolled card to begin earning your rewards";
  } else {
    const pct = (progression.pointsAccrued / goal) * 100;
    let descriptionText;
    if (pct < 33) {
      descriptionText = "Great start!";
    } else if (pct < 66) {
      descriptionText = "Getting there!";
    } else {
      descriptionText = "You're almost there!";
    }

    const remainingSpend = goal - progression.pointsAccrued;
    description = [
      <div key="description-text">{descriptionText}</div>,
      <div key="remaining-spend">
        Spend {formatMoney(remainingSpend, { precision: 0 })} to earn{" "}
        {reward.rewardValue}
      </div>,
    ];
  }

  return (
    <div className="point-reward reward">
      <LoyaltyImage type="loyalty" imagePath={reward.coverPhoto} />
      <div className="reward-content">
        <div className="title">
          {reward.rewardValue} cash back when you spend{" "}
          {formatMoney(goal, { precision: 0 })}
        </div>
        <div className="description">{description}</div>
        <RewardProgressBar
          points={progression.pointsAccrued}
          goal={reward.price}
          rewardValue={reward.rewardValue}
        />
      </div>
    </div>
  );
}

PointReward.propTypes = {
  reward: PropTypes.shape({
    rewardValue: PropTypes.string.isRequired,
    coverPhoto: PropTypes.string,
    threshold: PropTypes.string,
  }).isRequired,
  progression: PropTypes.object.isRequired,
};

export default PointReward;
