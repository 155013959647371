import moment from "moment";
import {
  all,
  equals,
  find,
  forEach,
  isEmpty,
  isNil,
  map,
  omit,
  pick,
  pipe,
  prop,
  propEq,
} from "ramda";

import { emptyCart } from "actions/online_ordering/cart";
import {
  addOrderConfirmationDetails,
  parseOrderSubmissionError,
  returnToMenu,
  updateContactInfo,
  updateNewCreditCard,
  updateOrderStatus,
} from "actions/online_ordering/checkout";
import { setOrderTimeError } from "actions/online_ordering/ordering_options";
import type { PaymentConfiguration } from "components/online_ordering/checkout/payment/PaymentConfiguration";
import type { IConsumer } from "components/online_ordering/objects/IConsumer";
import { saveOrderInfoToLocalStorage } from "helpers/local_storage";
import { orderTransform } from "helpers/transforms/online_ordering/order_transform";
import { validateBlurredField } from "helpers/validation/forms";
import VALIDATION_MESSAGES from "helpers/validation/messages";
import Validators, { phoneRegex } from "helpers/validation/validators";
import { postPhoneNumber, putConsumerInfo } from "interfaces/consumer_info";
import { postOrder } from "interfaces/order";
import getOrderingInfo, {
  getItemStockLevelInfo,
} from "interfaces/ordering_info";

import isLoggedIn from "is_logged_in";
import type { OnlineOrderingReduxState } from "reducers/online_ordering";
import { INITIAL_CREDIT_CARD_FORM_VALUES } from "reducers/online_ordering/checkout/payment";
import type { IOrderPayload } from "types/online_ordering/IOrderPayload";
import type { IOrderingOptionsState } from "types/reducers/ordering_options";
import type { IErrors, INewCreditCard } from "types/reducers/payment";
import type { StoreInfo } from "types/reducers/store_info";

export const UPDATE_ORDERING_INFO = "online_ordering/UPDATE_ORDERING_INFO";
export const SUBMIT_ORDER = "online_ordering/SUBMIT_ORDER";
export const RETURN_CHECKOUT_FORM_ERRORS =
  "online_ordering/RETURN_CHECKOUT_FORM_ERRORS";
export const CLEAR_CHECKOUT_FORM_ERRORS =
  "online_ordering/CLEAR_CHECKOUT_FORM_ERRORS";
export const VALIDATE_CC_FIELD_ON_BLUR =
  "online_ordering/VALIDATE_CC_FIELD_ON_BLUR";
export const VALIDATE_CONTACT_FIELD_ON_BLUR =
  "online_ordering/VALIDATE_CONTACT_FIELD_ON_BLUR";

const contactInfoValidators = {
  firstName: Validators.isNotEmpty,
  lastName: Validators.isNotEmpty,
  phone: Validators.isValidPhoneNumber,
  email: Validators.isValidEmail,
};

function validateContactInfo(contactInfo, validators) {
  const errors = [];

  if (!Validators.isValid(contactInfo, validators, errors)) {
    return pick(errors, VALIDATION_MESSAGES);
  }
  // HACK: this section needs rewriting it's duplicating a lot of different checks
  // but we have to solve an issue for a customer immediately
  let sanitizedErrors = contactInfo.errors;
  if (!isEmpty(contactInfo.errors)) {
    // We know that the following fields are valid even if they haven't been omitted
    // from state yet
    sanitizedErrors = omit(["firstName", "lastName", "email"], sanitizedErrors);
  }

  if (!isEmpty(sanitizedErrors)) {
    // These could contain errors from phone NANP validation

    return sanitizedErrors;
  }

  return null;
}

function validateContactFieldOnBlur(field, contact) {
  const error = validateBlurredField(
    field,
    contact,
    contactInfoValidators,
    VALIDATION_MESSAGES
  );

  return {
    type: VALIDATE_CONTACT_FIELD_ON_BLUR,
    error,
    field,
  };
}

function clearCheckoutFormErrors() {
  return {
    type: CLEAR_CHECKOUT_FORM_ERRORS,
  };
}

function returnContactErrors(error, field) {
  return {
    type: VALIDATE_CONTACT_FIELD_ON_BLUR,
    error,
    field,
  };
}

// Returns an array of errors when validating the fields
function validateAllContactInfo(contact) {
  const contactFields = ["firstName", "lastName", "email", "phone"];
  // Return an error object with a null or string error.
  return map(field => {
    const error = validateBlurredField(
      field,
      contact,
      contactInfoValidators,
      VALIDATION_MESSAGES
    );
    return { field, error };
  }, contactFields);
}

function validateAndPutContactInfo(field, contact, saveOnBlur = false) {
  const fieldValidationErrors = validateAllContactInfo(contact);
  let nanpValidation;
  let formattedPhone;
  return dispatch => {
    // Check there are no errors
    if (all(pipe(prop("error"), isNil), fieldValidationErrors)) {
      nanpValidation = Validators.validatePhoneNANP(contact);
      if (nanpValidation.allowSave) {
        formattedPhone = contact.phone.replace(
          phoneRegex,
          nanpValidation.regex
        );
        dispatch(updateContactInfo({ phone: formattedPhone }));
      }

      if (isLoggedIn() && saveOnBlur) {
        if (equals(field, "phone")) {
          // These functions should not be returned we need to save the data AND
          // clear the error if there is one which happens in the action below
          if (nanpValidation.allowSave) postPhoneNumber(formattedPhone);
        } else {
          putConsumerInfo(
            contact.firstName,
            contact.lastName,
            contact.textAlerts
          );
        }
      }
    }
    forEach(error => {
      dispatch(returnContactErrors(error.error, error.field));
    }, fieldValidationErrors);
    if (
      !isNil(nanpValidation) &&
      !isNil(nanpValidation.error) &&
      contact.textAlerts // Don't show the NANP error if text updates is unchecked
    )
      dispatch(returnContactErrors(nanpValidation.error, nanpValidation.field));
  };
}

function validateAndSaveContactField(field, contact) {
  const fieldValidationErrors = validateAllContactInfo(contact);
  let nanpValidation;
  let formattedPhone;
  return dispatch => {
    if (
      (equals(field, "phone") || equals(field, "textAlerts")) &&
      isNil(find(propEq("field", "phone"), fieldValidationErrors)?.error)
    ) {
      nanpValidation = Validators.validatePhoneNANP(contact);
      if (nanpValidation.allowSave) {
        formattedPhone = contact.phone.replace(
          phoneRegex,
          nanpValidation.regex
        );
        dispatch(updateContactInfo({ phone: formattedPhone }));
      }
    }
    forEach(error => {
      if (
        equals(error.field, field) ||
        ((equals(field, "phone") || equals(field, "textAlerts")) &&
          equals(error.field, "phone"))
      ) {
        dispatch(returnContactErrors(error.error, error.field));
      }
    }, fieldValidationErrors);
    if (
      !isNil(nanpValidation) &&
      !isNil(nanpValidation.error) &&
      contact.textAlerts // Don't show the NANP error if text updates is unchecked
    )
      dispatch(returnContactErrors(nanpValidation.error, nanpValidation.field));
  };
}

function updateOrderingInfo(orderingInfo) {
  return {
    type: UPDATE_ORDERING_INFO,
    orderingInfo,
  };
}

function fetchMenu() {
  return dispatch => {
    Promise.all([getOrderingInfo(), getItemStockLevelInfo()])
      .then(([orderingInfo, stockLevels]) => {
        dispatch(updateOrderingInfo({ ...orderingInfo, stockLevels }));
      })
      .catch(e => console.error(e));
  };
}

function returnCheckoutFormErrors(contactFormErrors, ccFormErrors) {
  return {
    type: RETURN_CHECKOUT_FORM_ERRORS,
    contactFormErrors,
    ccFormErrors,
  };
}

function orderTimeExpired(orderingOptions, storeInfo: StoreInfo) {
  if (equals(orderingOptions.selectedTimeFrame, "Later")) {
    const methodDetails = find(
      propEq("name", orderingOptions.selectedTransport),
      storeInfo.fulfillmentTypes
    );
    const estimateWindow = methodDetails ? methodDetails.estimate[1] : 0;

    const orderTime = moment(
      orderingOptions.orderAheadTime.replace(/, \w{3}/g, ""),
      "h:mm A"
    );
    const orderDayAndTime = orderingOptions.orderAheadDate.dayAsMoment
      .clone()
      .set({
        hour: orderTime.hour(),
        minute: orderTime.minute(),
      });
    return orderDayAndTime.isBefore(moment().add(estimateWindow, "minutes"));
  }

  return false;
}

export type PaymentFormValidator<T extends INewCreditCard = INewCreditCard> = (
  formValues: T
) => IErrors | null;

export interface PaymentSubmitHandlerResponse<T = Record<string, unknown>> {
  additionalData: T;
}

export type PaymentSubmitHandler<
  T extends INewCreditCard = INewCreditCard,
  TResponse = Record<string, unknown>,
> = (newCreditCard: T) => Promise<PaymentSubmitHandlerResponse<TResponse>>;

export function submitOrder({
  paymentConfiguration,
  storeInfo,
  cart,
  checkout,
  consumerInfo,
  orderingOptions,
  sessionId,
}: {
  paymentConfiguration?: PaymentConfiguration | null;
  storeInfo: StoreInfo;
  cart: NonNullable<OnlineOrderingReduxState["cart"]>;
  checkout: NonNullable<OnlineOrderingReduxState["checkout"]>;
  consumerInfo: IConsumer;
  orderingOptions: IOrderingOptionsState;
  sessionId: string;
}) {
  return async dispatch => {
    if (orderTimeExpired(orderingOptions, storeInfo)) {
      dispatch(setOrderTimeError());
      throw new Error("Order Time Validation Errors");
    }

    const contactFormErrors = validateContactInfo(
      consumerInfo.contactInfo,
      contactInfoValidators
    );
    const paymentFormErrors =
      paymentConfiguration?.paymentFormValidator?.(
        checkout.payment.newCreditCard
      ) ?? null;

    if (contactFormErrors || paymentFormErrors) {
      dispatch(returnCheckoutFormErrors(contactFormErrors, paymentFormErrors));
      throw new Error("Contact Form Validation Errors");
    }

    const paymentSubmitHandlerResponse =
      (await paymentConfiguration?.paymentSubmitHandler?.(
        checkout.payment.newCreditCard
      )) ?? null;

    const orderInfo: IOrderPayload = {
      ...orderTransform({
        storeInfo,
        checkout,
        consumerInfo,
        cart,
        orderingOptions,
        sessionId,
      }),
      ...paymentSubmitHandlerResponse?.additionalData,
    };

    dispatch(clearCheckoutFormErrors());

    return postOrder(orderInfo)
      .then(orderResponse => {
        const checkoutStatus = {
          ...checkout.status,
          confirmationCode: orderInfo.order.confirmationCode,
          ...(orderingOptions.selectedTimeFrame !== "Later"
            ? {
                orderTime: orderInfo.order.timePlaced,
                isOrderAhead: false,
              }
            : {
                orderTime: orderInfo.order.promisedDate,
                isOrderAhead: true,
              }),
        };

        dispatch(addOrderConfirmationDetails(checkoutStatus));

        saveOrderInfoToLocalStorage({
          contactInfo: consumerInfo.contactInfo,
          checkoutStatus,
          orderingOptions: orderingOptions,
          ccProcessor: checkout.payment?.newCreditCard.ccProcessor,
        });

        return orderResponse;
      })
      .catch(ex => {
        if (ex.response) {
          dispatch(parseOrderSubmissionError(ex.response));
        }
        return Promise.reject(ex);
      });
  };
}

function orderSuccess(isReturnToMenu = true) {
  return dispatch => {
    dispatch(updateNewCreditCard(INITIAL_CREDIT_CARD_FORM_VALUES));
    dispatch(updateOrderStatus("success"));
    dispatch(emptyCart());
    if (isReturnToMenu) {
      dispatch(returnToMenu());
    }
  };
}

export {
  fetchMenu,
  updateOrderingInfo,
  validateAndPutContactInfo,
  validateAndSaveContactField,
  validateContactFieldOnBlur,
  orderSuccess,
};
