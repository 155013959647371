import classNames from "classnames";
import PropTypes from "prop-types";
import { any, equals, lt, map, not, partial, propEq } from "ramda";
import React from "react";
import formatMoney from "sig/formatters/money";

import Checkbox from "components/checkbox";
import { withTracking } from "helpers/analytics";

function formatSidePrice(price) {
  if (lt(price, 0)) {
    return formatMoney(price * 0.01);
  }
  return `+${formatMoney(price * 0.01)}`;
}

function SideSection({ item, sides, onToggleSide, missingRequiredSelection }) {
  const { maxSides, minSides, quantity } = item;

  let sectionRules;
  const classRules = {
    restrictions: true,
  };
  if (equals(minSides, 0) && maxSides > 0) {
    const maxMods = sides.length < maxSides ? sides.length : maxSides;
    sectionRules = `Optional. Choose up to ${maxMods}.`;
  }
  if (minSides > 0) {
    sectionRules = `Required. Please choose ${minSides}`;
    classRules.required =
      missingRequiredSelection && lt(item.selectedSides.length, minSides);
  }

  let maximumMessage;
  if (not(equals(maxSides, 0)) && equals(item.selectedSides.length, maxSides)) {
    maximumMessage = (
      <div className="max-reached">You've reached your maximum amount</div>
    );
  }

  const isSideDisabled = side => {
    return side.stockLevel.match({
      Just: stock => stock < quantity,
      Nothing: () => false,
    });
  };

  return (
    <div className="section side-section">
      <div className="section-title">
        <h2>Choice of side</h2>
        <div className={classNames(classRules)}>{sectionRules}</div>
        {maximumMessage}
      </div>
      <div className="options">
        {map(
          side => (
            <div key={side.id} className="side">
              <Checkbox
                label={`${side.name} ${formatSidePrice(side.price)}`}
                checked={any(propEq("sideId", side.sideId))(item.selectedSides)}
                onChangeHandler={withTracking(
                  partial(onToggleSide, [side, maxSides]),
                  "Select Side",
                  side
                )}
                disabled={isSideDisabled(side)}
              />
            </div>
          ),
          sides
        )}
      </div>
    </div>
  );
}

SideSection.propTypes = {
  sides: PropTypes.array.isRequired,
  onToggleSide: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  missingRequiredSelection: PropTypes.bool,
};

export default SideSection;
