import { connect } from "react-redux";

import {
  clearUserInfo,
  signUpSubmitCard,
  signUpSubmitEmail,
} from "actions/loyalty";

import SignUp from "components/loyalty/sign_up";

function mapStateToProps({ brandInfo, loyalty, user }) {
  return {
    brandInfo,
    loyalty,
    user,
  };
}

const mapDispatchToProps = {
  onSubmitEmail: signUpSubmitEmail,
  onSubmitCard: signUpSubmitCard,
  onClearUser: clearUserInfo,
};

const SignUpContainer = connect(mapStateToProps, mapDispatchToProps)(SignUp);

export default SignUpContainer;
