import { useEffect, useMemo } from "react";
import { TrackJS } from "trackjs";
import uuid from "uuid/v4";

export const useSessionId = () => {
  const sessionId = useMemo(() => uuid(), []);
  useEffect(() => {
    TrackJS.configure({ sessionId });
  }, [sessionId]);
  return sessionId;
};
