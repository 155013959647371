import { find, propEq, reject, union, without } from "ramda";

const getById = (id, collection) => find(propEq("id", id), collection);

function removeById(id, collection) {
  return reject(propEq("id", id), collection);
}

function removeByCartId(cartId, collection) {
  return reject(propEq("cartId", cartId), collection);
}

function replaceInCollection(oldItem, newItem, collection) {
  return union([newItem], without([oldItem], collection));
}

function replaceByCartId(oldItem, newItem, collection) {
  const cartWithoutOldItem = removeByCartId(oldItem.cartId, collection);
  return union([newItem], cartWithoutOldItem);
}

export {
  getById,
  removeById,
  removeByCartId,
  replaceInCollection,
  replaceByCartId,
};
