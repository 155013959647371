import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import styled from "styled-components";

import Footer from "components/digital_gift_cards/footer";
import Header from "components/digital_gift_cards/header";
import MainContent from "components/digital_gift_cards/main_content";
import { calculateLeftMargin } from "helpers/digital_gift_cards";
import useViewportDimensions from "helpers/hooks/useViewportDimensions";
import type {
  DigitalGiftCardOrderApiResponse,
  DigitalGiftCardsSettings,
} from "types/digital_gift_cards";

export const StyledDGCPurchasePage = styled.div`
  background: #ffffff;
  margin: 0;

  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #000000;

  min-height: 100vh;
`;

type PurchasePageProps = {
  settings: DigitalGiftCardsSettings;
};

export default function PurchasePage<
  T extends DigitalGiftCardOrderApiResponse = DigitalGiftCardOrderApiResponse,
>({ settings }: PurchasePageProps) {
  const flags = useFlags();

  const { w } = useViewportDimensions();

  return (
    <StyledDGCPurchasePage>
      <Header
        settings={settings}
        leftMargin={calculateLeftMargin(w)}
        flags={flags}
      />
      <MainContent<T> settings={settings} leftMargin={calculateLeftMargin(w)} />
      <Footer />
    </StyledDGCPurchasePage>
  );
}
