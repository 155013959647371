import { connect } from "react-redux";

import { saveContactInfo } from "actions/account";

import ContactInfo from "components/account/contact_info";

function mapStateToProps(state) {
  return {
    contactInfo: state.consumer.contactInfo,
  };
}

const mapDispatchToProps = {
  onContactInfoSave: saveContactInfo,
};

const ContactInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactInfo);

export default ContactInfoContainer;
