import React from "react";
import Loader from "components/loader";
import Modal from "components/modal";

export const OrderSubmittingModal = () => {
  return (
    <Modal className="order-confirm-modal">
      <div className="modal-content">
        <div className="center">
          <div>
            <h2>We're submitting your order...</h2>
            <Loader />
          </div>
        </div>
      </div>
    </Modal>
  );
};
