import { all, isEmpty } from "ramda";
import type { DigitalGiftCardsSettings } from "types/digital_gift_cards";

export function calculateLeftMargin(w: number) {
  const calculatedLeftMargin = (w - 850) / 2;
  return calculatedLeftMargin > 0 ? `${calculatedLeftMargin}px` : "400px";
}

function isValidParam(val: any): boolean {
  return val && !isEmpty(val);
}
export function settingsIncludesRequiredValues(
  settings: DigitalGiftCardsSettings
): boolean {
  const { name, storePrettyUrl, ugMerchantKey } = settings;

  return all(isValidParam, [name, storePrettyUrl, ugMerchantKey]);
}
