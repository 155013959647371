import { connect } from "react-redux";

import {
  cancelAddressEdits,
  createNewAddress,
  editExistingAddress,
  removeAddress,
  saveNewAddress,
  saveUpdatedAddress,
  updateAddress,
  updateAddressField,
} from "actions/online_ordering/addresses";

import Addresses from "components/account/addresses";

function mapStateToProps(state) {
  return {
    addresses: state.consumer.addresses.existing,
    editableAddresses: state.consumer.addresses.editable,
  };
}

const mapDispatchToProps = {
  onCreateNewAddress: createNewAddress,
  onSaveUpdatedAddress: saveUpdatedAddress,
  onCancelAddressEdits: cancelAddressEdits,
  onEditExistingAddress: editExistingAddress,
  onSaveNewAddress: saveNewAddress,
  onUpdateAddressField: updateAddressField,
  onUpdateAddress: updateAddress,
  onRemoveAddress: removeAddress,
};

const AddressesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Addresses);

export default AddressesContainer;
