import { useEffect, useState } from "react";

function getWindowAndBreakpoints() {
  const { innerHeight: h, innerWidth: w } = window;
  return {
    h,
    w,
    mobile: w < 1024,
    desktop: w >= 1024,
  };
}

export default function useViewportDimensions() {
  const [dimensions, setDimensions] = useState(getWindowAndBreakpoints);

  useEffect(() => {
    function onResize() {
      setDimensions(getWindowAndBreakpoints);
    }

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  return dimensions;
}
