import { Money } from "@upserve/financials";
import Haikunator from "haikunator";
import { isEmpty, isNil } from "ramda";
import { Maybe } from "true-myth";
import uuid from "uuid/v4";
import {
  type DigitalGiftCardOrderForm,
  deliveryOptions,
} from "components/digital_gift_cards/order_form";

import {
  type DigitalGiftCardApi,
  DigitalGiftCardDeliveryMethod,
  type DigitalGiftCardsSettings,
  type DigitalGiftCardsSettingsApiResponse,
} from "types/digital_gift_cards";

export function transformSettingsApiResponse(
  apiResponse: DigitalGiftCardsSettingsApiResponse
): DigitalGiftCardsSettings {
  return {
    // TODO: implement a more sophisticated error check around missing required values (HQ-7985)
    storePrettyUrl: isNil(apiResponse.storePrettyUrl)
      ? ""
      : apiResponse.storePrettyUrl,
    ugMerchantKey: isNil(apiResponse.ugMerchantKey)
      ? ""
      : apiResponse.ugMerchantKey,
    digitalGiftCardEnabled: isNil(apiResponse.digitalGiftCardEnabled)
      ? false
      : apiResponse.digitalGiftCardEnabled,
    name: isNil(apiResponse.name) ? "" : apiResponse.name,
    street1: isNil(apiResponse.street1) ? "" : apiResponse.street1,
    street2: isNil(apiResponse.street2) ? "" : apiResponse.street2,
    city: isNil(apiResponse.city) ? "" : apiResponse.city,
    state: isNil(apiResponse.state) ? "" : apiResponse.state,
    zip: isNil(apiResponse.zip) ? "" : apiResponse.zip,
    phone: Maybe.of(apiResponse.phone),
    minValue: isNil(apiResponse.minValue)
      ? Money(0) // default value is $0
      : Money.fromDollars(apiResponse.minValue),
    maxValue: isNil(apiResponse.maxValue)
      ? Money(999900) // default value is $9,999
      : Money.fromDollars(apiResponse.maxValue),
    website: Maybe.of(apiResponse.website),
    bannerUrl: !isEmpty(apiResponse.bannerUrl)
      ? Maybe.of(apiResponse.bannerUrl)
      : Maybe.nothing(),
    logoUrl: !isEmpty(apiResponse.logoUrl)
      ? Maybe.of(apiResponse.logoUrl)
      : Maybe.nothing(),
    additionalDetails: Maybe.of(apiResponse.additionalDetails),
  };
}

export function transformOrderInfo(
  formInfo: DigitalGiftCardOrderForm
): DigitalGiftCardApi {
  const base = {
    message: formInfo.message && formInfo.sendAsGift ? formInfo.message : null,
    oloGiftCardId: uuid(),
    amount: parseFloat(formInfo.amount) * 100,
  };

  if (!formInfo.sendAsGift) {
    return {
      ...base,
      deliveryMethod:
        formInfo.deliveryMethodType === deliveryOptions.SMS
          ? DigitalGiftCardDeliveryMethod.TEXT_ME
          : DigitalGiftCardDeliveryMethod.EMAIL_ME,
      from: null,
      recipient: null,
      recipientEmail: null,
      recipientPhone: null,
    };
  }

  if (formInfo.deliveryMethodType === deliveryOptions.SMS) {
    return {
      ...base,
      deliveryMethod: DigitalGiftCardDeliveryMethod.TEXT_RECEIPT,
      from: formInfo.senderName,
      recipient: formInfo.recipientName,
      recipientEmail: null,
      recipientPhone: formInfo.recipientPhone.replace(/\D/g, ""),
    };
  } else {
    return {
      ...base,
      deliveryMethod: DigitalGiftCardDeliveryMethod.EMAIL_RECEIPT,
      from: formInfo.senderName,
      recipient: formInfo.recipientName,
      recipientEmail: formInfo.recipientEmail,
      recipientPhone: null,
    };
  }
}

export const generateConfirmationCode = (prettyUrl: string) => {
  return new Haikunator({
    adjectives: [prettyUrl],
    nouns: [],
    defaults: {
      delimiter: "-",
      tokenLength: 5,
    },
  }).haikunate();
};
