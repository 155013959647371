import { type ComponentType, lazy } from "react";
import type { CreditCardFormContainerImplProps } from "./CreditCardFormContainer";
import type { PaymentConfigurationOptions } from "./PaymentConfigurationOptions";
import type { CCProcessor } from "types/payments/credit_card";
import type { PaymentReturnUrlAuthorizationProps } from "types/payments/PaymentReturnUrlAuthorizationProps";

export interface CCProcessorConfiguration extends PaymentConfigurationOptions {
  formContainer: ComponentType<CreditCardFormContainerImplProps<any>>;
  /**
   * The component that handles credit card authorization on return url
   */
  returnUrlAuthorization?: ComponentType<PaymentReturnUrlAuthorizationProps>;
}

export const CCProcessorConfigurationMap: Record<
  CCProcessor,
  CCProcessorConfiguration
> = {
  adyen: {
    formContainer: lazy(() => import("./adyen/AdyenOLOFormContainer")),
    returnUrlAuthorization: lazy(
      () => import("./adyen/AdyenOLOPaymentReturnUrlAuthorization")
    ),
    isCustomizedSubmitProcessing: true,
  },
  firstData: {
    formContainer: lazy(() => import("./first_data/FirstDataOLOFormContainer")),
    isCustomizedSubmitProcessing: false,
  },
};
