import { Money } from "@upserve/financials";
import React from "react";
import styled from "styled-components";
import { Maybe } from "true-myth";

import { DigitalGiftCardApp } from "./DigitalGiftCardApp";
import { DigitalGiftCardCreditCardForm } from "./form/DigitalGiftCardCreditCardForm";
import { StyledWrapper } from "components/digital_gift_cards/error_page";
import Footer from "components/digital_gift_cards/footer";
import Header from "components/digital_gift_cards/header";
import {
  StyledDGCMainContent,
  StyledDefaultGiftCardWrapper,
  StyledGiftCardOutline,
} from "components/digital_gift_cards/main_content";
import OrderForm from "components/digital_gift_cards/order_form";
import { StyledDGCPurchasePage } from "components/digital_gift_cards/purchase_page";
import Icon from "components/icon";
import { StyledErrorHeader } from "components/shared/errors/StyledErrorHeader";
import { StyledErrorText } from "components/shared/errors/StyledErrorText";
import { calculateLeftMargin } from "helpers/digital_gift_cards";
import useViewportDimensions from "helpers/hooks/useViewportDimensions";

const DefaultGiftCard = require("images/default-gift-card.png");

const StyledFormWrapper = styled.div`
  width: 424px;
  padding: 0 32px;

  .upserve-dgc-terms {
    display: none;
  }

  @media (max-width: 1024px) {
    width: auto;
  }
`;

const DEFAULT_SETTINGS_VALUES = {
  storePrettyUrl: "preview-preview",
  ugMerchantKey: "preview",
  digitalGiftCardEnabled: true,
  name: "Preview Restaurant",
  street1: "123 Main Street",
  street2: "",
  city: "City",
  state: "RI",
  zip: "11111",
  phone: Maybe.of("555-555-5555"),
  minValue: Money(1),
  maxValue: Money(999),
  website: Maybe.of("https://upserve.com"),
  additionalDetails: Maybe.of(""),
};

interface IPreviewParams {
  logoUrl: Maybe<string>;
  bannerUrl: Maybe<string>;
}

export function DigitalGiftCardPreviewApp({
  logoUrl,
  bannerUrl,
}: IPreviewParams) {
  const { w } = useViewportDimensions();

  const previewSettings = {
    ...DEFAULT_SETTINGS_VALUES,
    logoUrl,
    bannerUrl,
  };

  const giftCardImage = logoUrl.match({
    Just: url => (
      <StyledGiftCardOutline>
        <img src={url} alt="gift card" />
      </StyledGiftCardOutline>
    ),
    Nothing: () => (
      <StyledDefaultGiftCardWrapper>
        <img src={DefaultGiftCard} alt="gift card" />
      </StyledDefaultGiftCardWrapper>
    ),
  });

  if (logoUrl.isNothing() && bannerUrl.isNothing()) {
    return (
      <StyledWrapper>
        <Icon type="receipt-ghost" />
        <StyledErrorHeader>Sorry!</StyledErrorHeader>
        <StyledErrorText>Error loading your page preview.</StyledErrorText>
      </StyledWrapper>
    );
  }

  return (
    <DigitalGiftCardApp>
      <StyledDGCPurchasePage>
        <Header
          settings={previewSettings}
          leftMargin={calculateLeftMargin(w)}
        />
        <StyledDGCMainContent leftMargin={calculateLeftMargin(w)}>
          <StyledFormWrapper>
            <OrderForm
              settings={previewSettings}
              setFormValid={() => {}}
              setFormValues={() => {}}
            />
            <DigitalGiftCardCreditCardForm
              merchantInfo={{
                ugMerchantKey: previewSettings.ugMerchantKey,
                storePrettyUrl: previewSettings.storePrettyUrl,
              }}
              paymentInfo={{ balanceDue: Money(500) }}
              isSubmitDisabled
              isPreview
            />
            <h2>This page is for preview only.</h2>
          </StyledFormWrapper>
          {giftCardImage}
        </StyledDGCMainContent>
        <Footer />
      </StyledDGCPurchasePage>
    </DigitalGiftCardApp>
  );
}
