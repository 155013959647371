import React, { type Ref, Suspense } from "react";
import type { CCProcessorConfiguration } from "./CCProcessorConfigurationMap";
import type {
  PaymentFormValidator,
  PaymentSubmitHandler,
} from "actions/online_ordering";
import Loader from "components/loader";
import type { OrderResponse } from "types/reducers/checkout_status";

export interface CreditCardFormContainerImplProps<
  T extends OrderResponse = OrderResponse,
> {
  paymentFormValidatorRef: Ref<PaymentFormValidator<any> | null>;
  paymentSubmitHandlerRef: Ref<PaymentSubmitHandler<any, any>>;
  orderResponse: T | null;
}

export interface CreditCardFormContainerProps
  extends CreditCardFormContainerImplProps {
  ccProcessorConfiguration: CCProcessorConfiguration;
}

export const CreditCardFormContainer = ({
  ccProcessorConfiguration,
  ...rest
}: CreditCardFormContainerProps) => {
  const { formContainer: FormContainer } = ccProcessorConfiguration;

  return (
    <Suspense fallback={<Loader />}>
      <FormContainer {...rest} />
    </Suspense>
  );
};
