import React, { useMemo } from "react";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { Account } from "./Account";
import { OloAppBase } from "components/online_ordering/OloAppBase";
import oloAccountReducers from "reducers/online_ordering/account";
import type { AviatorRequest } from "types/Aviator";

export interface OLOAccountAppProps {
  request: AviatorRequest;
}

export const OLOAccountApp = ({ request }: OLOAccountAppProps) => {
  const store = useMemo(() => {
    const initialState = {};
    const composeEnhancer =
      // @ts-expect-error this doesn't exist in ts
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return createStore(
      oloAccountReducers,
      initialState,
      composeEnhancer(applyMiddleware(thunk))
    );
  }, []);

  return (
    <OloAppBase store={store} identifyTrackingMessage="Access Account Page">
      <Account request={request} />
    </OloAppBase>
  );
};
