import { useQuery } from "react-query";
import { TrackJS } from "trackjs";
import { getCheckInfo } from "interfaces/mobile_pay";

export const useMobilePayCheck = (
  prettyUrl: string,
  mobilePayParameters: URLSearchParams
) => {
  return useQuery(
    ["mobilePayCheck", prettyUrl, mobilePayParameters.get("h") ?? ""],
    async () => {
      return getCheckInfo(prettyUrl, mobilePayParameters);
    },
    {
      onError: error => {
        TrackJS.track(`error fetching check info from OLO backend: ${error}`);
      },
      retry: () => false,
    }
  );
};
