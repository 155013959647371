import { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const usePaymentAuthorization = (url: string) => {
  const [params] = useSearchParams();
  const paymentProcessor: string | null = params.get("callback");

  const navigate = useNavigate();
  const navigateToBase = useCallback(() => {
    navigate(url);
  }, [navigate, url]);

  return {
    paymentProcessor,
    navigateToBase,
  };
};
