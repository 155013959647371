import PropTypes from "prop-types";
import React from "react";

import Button from "components/button";
import Modal from "components/modal";

function AlertModal({
  className,
  confirmButtonText,
  message,
  onConfirm,
  ...rest
}) {
  return (
    <Modal {...rest} className={`alert-modal ${className}`}>
      <div className="contents">
        <div className="message">{message}</div>

        <Button
          className="confirm"
          type="primary"
          onClick={onConfirm}
          size="medium">
          {confirmButtonText}
        </Button>
      </div>
    </Modal>
  );
}

AlertModal.propTypes = {
  className: PropTypes.string,
  confirmButtonText: PropTypes.string.isRequired,
  message: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default AlertModal;
