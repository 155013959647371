import type { Money } from "@upserve/financials";
import type { Maybe } from "true-myth";
import type { DigitalGiftCardFormValues } from "./digital_gift_cards/DigitalGiftCardFormValues";
import type { AdyenPayload } from "./payments/adyen/AdyenPayload";
import type { AdyenPaymentStatus } from "./payments/adyen/AdyenPaymentStatus";
import type { DigitalGiftCardOrderForm } from "components/digital_gift_cards/order_form";
import type { AdditionalPaymentData } from "components/mobile_pay/payment/CCProcessorConfigurationMap";

export type DigitalGiftCardsSettings = {
  storePrettyUrl: string;
  ugMerchantKey: string;
  digitalGiftCardEnabled: boolean;
  name: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  phone: Maybe<string>;
  minValue: Money;
  maxValue: Money;
  website: Maybe<string>;
  bannerUrl: Maybe<string>;
  logoUrl: Maybe<string>;
  additionalDetails: Maybe<string>;
};

export type DigitalGiftCardsSettingsApiResponse = {
  storePrettyUrl: string;
  ugMerchantKey: string;
  digitalGiftCardEnabled: boolean;
  name: string;
  street1: string | null;
  street2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  phone: string | null;
  minValue: number | null;
  maxValue: number | null;
  website: string | null;
  bannerUrl: string | null;
  logoUrl: string | null;
  additionalDetails: string | null;
};

export type DigitalGiftCardsOrderApiRequest = {
  sessionId: string;
  submissionId: string; // uuid created by frontend
  storePrettyUrl: string;
  timePlaced: string; // iso datetime, created by frontend
  confirmationCode: string; // created by frontend, similar to OLO confirmations
  orderTotal: number; // in cents
  digitalGiftCards: Array<DigitalGiftCardApi>;
};

export enum DigitalGiftCardDeliveryMethod {
  EMAIL_RECEIPT = 1,
  TEXT_RECEIPT = 2,
  EMAIL_ME = 3,
  TEXT_ME = 4,
}

export type DigitalGiftCardApi = {
  message: string | null; // optional
  oloGiftCardId: string; // uuid created by frontend
  amount: number; // cents
} & (
  | {
      deliveryMethod: DigitalGiftCardDeliveryMethod.EMAIL_RECEIPT;
      from: string;
      recipient: string;
      recipientEmail: string;
      recipientPhone: null;
    }
  | {
      deliveryMethod: DigitalGiftCardDeliveryMethod.TEXT_RECEIPT;
      from: string;
      recipient: string;
      recipientEmail: null;
      recipientPhone: string;
    }
  | {
      deliveryMethod:
        | DigitalGiftCardDeliveryMethod.EMAIL_ME
        | DigitalGiftCardDeliveryMethod.TEXT_ME;
      from: null;
      recipient: null;
      recipientEmail: null;
      recipientPhone: null;
    }
);

export enum DeliveryMethodType {
  EMAIL = "Email",
  SMS = "SMS",
}

export type DigitalGiftCard = {
  deliveryMethodType: DeliveryMethodType;
  sendToSelf: boolean;
  from: Maybe<string>; // nil if delivery_method is not emailReceipt, textReceipt
  recipient: Maybe<string>; // nil if delivery_method is not emailReceipt, textReceipt
  recipientEmail: Maybe<string>; // nil if delivery_method is not emailReceipt, emailMe
  recipientPhone: Maybe<string>; // nil if delivery_method is not textReceipt, textMe
  message: Maybe<string>;
  oloGiftCardId: string; // uuid created by frontend
  amount: Money; // cents
  consumerPhoneNumber: Maybe<string>;
  consumerEmail: string;
};

export type DigitalGiftCardCreditCardInfo = DigitalGiftCardFormValues;

export interface DigitalGiftCardOrderApiResponse {
  submissionId: string;
  sessionId: string;
  storePrettyUrl: string;
  timePlaced: string;
  confirmationCode: string;
  orderTotal: number; // in cents
  digitalGiftCards: Array<DigitalGiftCardApi>;
  consumerEmail: string;
}

export interface FirstDataDigitalGiftCardOrderResponse
  extends DigitalGiftCardOrderApiResponse {
  consumerUuid: string;
  consumerFirstName: string;
  consumerLastName: string;
  consumerPhoneNumber?: string;
  lastFour: string;
}

export interface AdyenDigitalGiftCardOrderResponse
  extends DigitalGiftCardOrderApiResponse {
  payment: AdyenPaymentStatus;
}

export type Flags = {
  [key: string]: boolean;
};

export interface SubmitGiftCardCreditCardPaymentProps {
  total: Money;
  formInfo: DigitalGiftCardOrderForm;
  storePrettyUrl: string;
  sessionId: string;
  additionalPaymentData: AdditionalPaymentData | void;
}

export interface FirstDataPayment {
  paymentMethodToken: string;
  consumerUuid: string;
  cardUuid: string;
  consumerFirstName: string;
  consumerLastName: string;
  consumerEmail: string;
  consumerPhoneNumber?: string;
  cvv: string;
}

export interface AdyenPayment {
  payment: AdyenPayload;
}

export interface FirstDataConfirmationModalData {
  lastFour: string;
}
