import PropTypes from "prop-types";
import { pipe } from "ramda";
import React from "react";

import { trackAnalytic } from "helpers/analytics";
import { getTargetValue } from "helpers/event";

const CHARACTER_LIMIT = 500;

function DeliveryInstructions({ instructions, onUpdateDeliveryInstructions }) {
  const charsLeft = CHARACTER_LIMIT - instructions.length;

  return (
    <div className="delivery-instructions">
      <label>Delivery Instructions</label>
      <textarea
        value={instructions}
        onChange={pipe(getTargetValue, onUpdateDeliveryInstructions)}
        maxLength={CHARACTER_LIMIT}
        onBlur={() =>
          trackAnalytic("OLO - Checkout - add delivery instructions")
        }
      />
      <span className="length-left">{charsLeft} characters left</span>
    </div>
  );
}

DeliveryInstructions.propTypes = {
  instructions: PropTypes.string.isRequired,
  onUpdateDeliveryInstructions: PropTypes.func.isRequired,
};

export default DeliveryInstructions;
