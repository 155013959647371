import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect } from "react";

import Icon from "components/icon";
import Overlay from "components/overlay";
import FreezeBody from "helpers/context/freeze_body";

function Modal(props) {
  const { title, onClose, className, children, ...rest } = props;
  const freezeBodyContext = useContext(FreezeBody);
  useEffect(() => {
    freezeBodyContext.freezeBody();
    return () => {
      freezeBodyContext.unFreezeBody();
    };
    // added since a new eslint rule,
    // feel free to fix this eslint error if you can test it properly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseClick = useCallback(
    event => {
      event.preventDefault();
      if (event && typeof event.stopPropagation === "function")
        event.stopPropagation();
      if (typeof onClose === "function") onClose();
    },
    [onClose]
  );

  const handleKeyup = useCallback(
    event => {
      if (event.key === "Escape") {
        handleCloseClick(event);
      }
    },
    [handleCloseClick]
  );

  useEffect(() => {
    document.addEventListener("keyup", handleKeyup, false);
    return () => document.removeEventListener("keyup", handleKeyup, false);
  }, [handleKeyup]);

  const header = title && (
    <header>
      <h2>{title}</h2>
    </header>
  );

  const closeButton = typeof onClose === "function" && (
    <a href="" className="close" onClick={handleCloseClick} title="Close Modal">
      <Icon type="x" className="close" />
    </a>
  );

  return (
    <Overlay onClick={handleCloseClick}>
      <div className={`modal ${className}`} {...rest}>
        {closeButton}
        {header}
        {children}
      </div>
    </Overlay>
  );
}

Modal.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string.isRequired,
  children: PropTypes.node,
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  className: "",
};

export default Modal;
