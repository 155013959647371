import Aviator from "aviator";

import DefaultRouteTarget from "default_route_target";

const Routes = {
  dispatch() {
    // If the hash portion of the URL is not empty, we're in build-preview mode.
    // We're forcing hash routing mode by turning pushState compatibility off,
    // since Aviator only looks at hash portion for routing if pushState feature
    // is not implemented, or disabled as in this case.
    if (
      window.location.hash.length > 0 &&
      !window.location.pathname.includes("/login_success") &&
      !window.location.pathname.includes("/social_login_success")
    ) {
      Aviator.pushStateEnabled = false;
    }

    Aviator.setRoutes({
      target: DefaultRouteTarget,

      "/*": "loadAnalytics",

      "/": "landing",

      // olo and mobile pay are store level
      "/s": {
        "/": "landing",

        "/login_success": "loginRedirect",
        "/social_login_success": "handleSocialLogin",

        "/:prettyUrl": {
          "/*": "trackPrettyUrl",

          "/": "oloIndex",
          "/payment": "paymentHandler",
          "/account": "oloAccount",
          "/pay": "mobilePay",
        },
      },

      // loyalty is brand level
      "/b": {
        "/": "landing",

        "/login_success": "loginRedirect",

        "/:prettyUrl": {
          "/*": "trackPrettyUrl",

          "/": "index",
          "/account": "account",
          "/gift-card": {
            "/": "giftCard",
            "/preview": "giftCardPreview",
          },
          "/payment": "paymentHandler",
        },
      },

      notFound: "notFound",
    });

    Aviator.dispatch();
  },
};

export default Routes;
