import React from "react";
import { OloAccountInput } from "./OloAccountInput";
import {
  validateAddress1,
  validateCity,
  validateState,
  validateZip,
} from "helpers/credit_card_form";

export const OloAccountCreditCardAddressInputs = () => {
  return (
    <div className="cc-address-form">
      <div className="row">
        <OloAccountInput
          name="address1"
          id="edit-cred-card-address-address-1"
          label="Address 1"
          autoComplete="address-line1"
          placeholder="Street and number"
          required
          type="text"
          validate={validateAddress1}
        />
      </div>
      <div className="row">
        <OloAccountInput
          name="address2"
          id="edit-cred-card-address-address-2"
          label="Address 2"
          autoComplete="address-line2"
          placeholder="Apartment, suite, unit, etc"
          type="text"
        />
      </div>
      <div className="row">
        <OloAccountInput
          name="city"
          id="edit-cred-card-address-city"
          label="City"
          required
          type="text"
          validate={validateCity}
        />
      </div>
      <div className="row">
        <OloAccountInput
          name="state"
          id="edit-cred-card-address-state"
          label="State / Province"
          maxLength={2}
          validate={validateState}
        />
        <OloAccountInput
          name="zip"
          id="edit-cred-card-address-postal-code"
          label="Zip / Postal Code"
          labelProps={{ className: "zip" }}
          autoComplete="postal-code"
          maxLength={10}
          required
          type="text"
          validate={validateZip}
        />
      </div>
    </div>
  );
};
