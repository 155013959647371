import React from "react";
import type { Store } from "redux";
import { OloAppBase } from "./OloAppBase";
import { OnlineOrdering } from "components/online_ordering/OnlineOrdering";

export interface OloAppProps {
  store: Store<unknown>;
}

export const OloApp = ({ store }: OloAppProps) => {
  return (
    <OloAppBase store={store} identifyTrackingMessage="Access OLO Page">
      <OnlineOrdering />
    </OloAppBase>
  );
};
