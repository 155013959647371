import type {
  DigitalGiftCardFormValues,
  FirstDataDigitalGiftCardFormValues,
} from "types/digital_gift_cards/DigitalGiftCardFormValues";

export const getFirstDataInitialValues = (
  formValues: DigitalGiftCardFormValues
): FirstDataDigitalGiftCardFormValues => {
  return {
    ...formValues,
    cardNumber: "",
    cvv: "",
    expiration: "",
    expiryMonth: "",
    expiryYear: "",
  };
};
