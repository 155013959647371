import { isEmpty, omit } from "ramda";

export const LdFlags = {
  EXTENDED_ORDER_AHEAD: "orderAheadTimeExtension",
  ERROR_BANNER: "uoloErrorBanner",
};

export function hasFeatureFlagEnabled(flags, flagToCheck) {
  if (typeof flags[flagToCheck] !== "undefined" && flags[flagToCheck]) {
    return true;
  }
  return false;
}

export function updatedStoreInfoPerFlags(storeInfo, flags) {
  let filteredSettings = storeInfo.settings;

  const pickupSettingsKeys = {
    tipSettings: [
      "customTipsEnabled",
      "showSuggestedTips",
      "suggestedTipDefault",
      "suggestedTipPercentHigh",
      "suggestedTipPercentLow",
      "suggestedTipPercentMiddle",
    ],
    curbside: ["curbsideEnabled", "curbsideGuestInstructions"],
  };

  if (!flags.oloCurbsidePickupMvp) {
    filteredSettings.pickup = omit(
      pickupSettingsKeys.curbside,
      filteredSettings.pickup
    );
  }

  // If both flags are off, all attributes will be filtered out and
  // we should remove the pickup settings
  if (isEmpty(filteredSettings.pickup)) {
    filteredSettings = omit(["pickup"], filteredSettings);
  }

  return {
    ...storeInfo,
    settings: filteredSettings,
  };
}
