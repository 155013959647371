import { Money } from "@upserve/financials";
import { reduce } from "ramda";
import { useMemo } from "react";
import { getCheckTotal } from "helpers/mobile_pay";
import type { CheckInfo } from "types/mobile_pay";

export const useIsCheckPaid = (checkInfo: CheckInfo) => {
  return useMemo(() => {
    // check the QR code balance due
    if (checkInfo.balanceDue.lte(Money(0))) {
      return true;
    }
    // check any payments retunred by the backend
    const paymentTotal = reduce(
      (acc, payment) => {
        return acc.add(payment.amount);
      },
      Money(0),
      checkInfo.payments
    );
    const checkTotal = getCheckTotal(checkInfo);

    return checkTotal.lte(paymentTotal);
  }, [checkInfo]);
};
