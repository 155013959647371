export const UPDATE_CONSUMER_INFO =
  "online_ordering/consumer/UPDATE_CONSUMER_INFO";

export function updateConsumerInfo(consumerInfo, replace = false) {
  return {
    type: UPDATE_CONSUMER_INFO,
    consumerInfo,
    replace,
  };
}
