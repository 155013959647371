import PropTypes from "prop-types";
import { isEmpty } from "ramda";
import React from "react";

import CardEntry from "components/loyalty/sign_up/card_entry";
import Confirmation from "components/loyalty/sign_up/confirmation";
import EmailEntry from "components/loyalty/sign_up/email_entry";

class SignUp extends React.Component {
  constructor(props) {
    super(props);

    this.handleEmailSubmit = this.handleEmailSubmit.bind(this);
    this.handleCardNumberSubmit = this.handleCardNumberSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  handleEmailSubmit(email) {
    const { onSubmitEmail } = this.props;
    onSubmitEmail(email);
  }

  handleCardNumberSubmit(cardNumber) {
    const { user, onSubmitCard } = this.props;
    onSubmitCard(
      user.uuid,
      user.enrollmentSalt,
      user.enrollmentSig,
      cardNumber
    );
  }

  handleBack() {
    const { onClearUser } = this.props;
    onClearUser();
  }

  render() {
    const { brandInfo, loyalty, user } = this.props;

    let renderedStep;
    if (isEmpty(user)) {
      renderedStep = (
        <EmailEntry
          brandInfo={brandInfo}
          loyalty={loyalty}
          onSubmit={this.handleEmailSubmit}
        />
      );
    } else if (user.email && !user.hasCards) {
      renderedStep = (
        <CardEntry
          brandInfo={brandInfo}
          loyalty={loyalty}
          email={user.email}
          uuid={user.uuid}
          enrollmentSalt={user.enrollmentSalt}
          enrollmentSig={user.enrollmentSig}
          onBack={this.handleBack}
          onSubmit={this.handleCardNumberSubmit}
        />
      );
    } else if (user.email && user.hasCards) {
      renderedStep = (
        <Confirmation
          brandInfo={brandInfo}
          loyalty={loyalty}
          email={user.email}
        />
      );
    }

    return <div className="loyalty-sign-up">{renderedStep}</div>;
  }
}

SignUp.propTypes = {
  brandInfo: PropTypes.object.isRequired,
  loyalty: PropTypes.shape({
    progression: PropTypes.shape({
      pointsAccrued: PropTypes.number,
      fpoRedeemed: PropTypes.bool,
      pointRewardLoaded: PropTypes.bool,
    }),
  }),
  user: PropTypes.shape({
    displayName: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    hasCards: PropTypes.bool,
    hasPassword: PropTypes.bool,
    isAdmin: PropTypes.bool,
    uuid: PropTypes.string,
    enrollmentSalt: PropTypes.string,
    enrollmentSig: PropTypes.string,
  }).isRequired,
  onSubmitEmail: PropTypes.func.isRequired,
  onSubmitCard: PropTypes.func.isRequired,
  onClearUser: PropTypes.func.isRequired,
};

export default SignUp;
