import { map } from "ramda";
// Take only the data we need and adjust keys as necessary to match olo data structure & keys
function formatOrder(order) {
  const { shoppingPurchase: purchase } = order;

  return {
    fulfillmentInfo: { type: "In Store" },
    confirmationCode: purchase.uuid,
    timePlaced: purchase.occurredAt,
    charges: { total: purchase.price * 100 },
  };
}

function formatOrderHistory(history) {
  return map(formatOrder, history);
}

export default formatOrderHistory;
