import PropTypes from "prop-types";
import { equals, isEmpty, partial, pipe } from "ramda";
import React from "react";

import Button from "components/button";
import Input from "components/input";
import { fillAddress, initGoogleAutocomplete } from "helpers/address";
import { getTargetValue } from "helpers/event";

class EditAddress extends React.Component {
  componentDidMount() {
    const { onUpdateAddress, editableAddress } = this.props;

    const input = this.addressInputLine1;
    const onAddressSelected = ({ autocomplete }) => {
      fillAddress(editableAddress, autocomplete, onUpdateAddress);
    };
    initGoogleAutocomplete({ input, onAddressSelected });
  }

  render() {
    const {
      editableAddress,
      existingAddress,
      onCancel,
      onSaveAddress,
      onUpdateAddressField,
    } = this.props;
    const { line1, line2, city, state, zip, uuid } = editableAddress;

    let fullAddressLine1 = line1;
    if (line1 && city && state && zip) {
      fullAddressLine1 = `${line1}, ${city} ${state} ${zip}`;
    }

    return (
      <div className="form expanded-form edit-address">
        <div className="row">
          <label>Address</label>
          <span className="text-input">
            <Input
              type="text"
              ref={input => {
                this.addressInputLine1 = input;
              }}
              value={fullAddressLine1}
              onChange={pipe(
                getTargetValue,
                partial(onUpdateAddressField, [uuid, "line1"])
              )}
            />
          </span>
        </div>
        <div className="row">
          <label>Apt, Suite</label>
          <Input
            id="edit-address-apt-suite"
            value={line2}
            onChange={pipe(
              getTargetValue,
              partial(onUpdateAddressField, [uuid, "line2"])
            )}
          />
        </div>
        <div className="buttons">
          <Button
            className="button cancel"
            type="secondary"
            onClick={partial(onCancel, [uuid])}
            size="small">
            Cancel
          </Button>

          <Button
            className="button update"
            type="primary"
            onClick={partial(onSaveAddress, [editableAddress])}
            disabled={isEmpty(zip) || equals(existingAddress, editableAddress)}
            size="small">
            {equals(uuid, "new-address") ? "Save" : "Update"}
          </Button>
        </div>
      </div>
    );
  }
}

EditAddress.propTypes = {
  existingAddress: PropTypes.object.isRequired,
  editableAddress: PropTypes.object.isRequired,
  onUpdateAddress: PropTypes.func.isRequired,
  onUpdateAddressField: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSaveAddress: PropTypes.func.isRequired,
};

export default EditAddress;
