import React from "react";
import type { TransportDetailsProps } from "components/online_ordering/checkout/payment/order_confirm_modal/OrderCompleteModal";
import { OrderCompleteModalTestIds } from "components/online_ordering/checkout/payment/order_confirm_modal/OrderCompleteModalTestIds";
import { useOrderReadyTime } from "hooks/online_ordering/payment/order_confirm_modal/useOrderReadyTime";

export const PickupDetails = ({ orderInformation }: TransportDetailsProps) => {
  const orderReadyTime = useOrderReadyTime(orderInformation);
  return (
    <div
      className="pickup-confirmation"
      data-testid={OrderCompleteModalTestIds.pickupConfirmation}>
      <h2 className="time-estimate">
        Estimated pick up time: {orderReadyTime}
      </h2>
    </div>
  );
};
