import PropTypes from "prop-types";
import React from "react";

export default function GoogleMap({ mapUrl }) {
  return (
    <div
      className="map"
      style={{
        backgroundImage: `url(${mapUrl})`,
        width: "400px",
        height: "200px",
        maxWidth: "100%",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
    />
  );
}

GoogleMap.propTypes = {
  mapUrl: PropTypes.string,
};
