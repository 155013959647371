import React from "react";
import styled from "styled-components";
import Loader from "components/loader";

import Overlay from "components/overlay";

const StyledProcessingLoader = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 60%;
  width: 60%;
  padding-top: 100px;

  font-size: 24px;

  background-color: #ffffff;
  border-radius: 5px;

  text-align: center;
`;

export const CreditCardProcessLoading = () => {
  return (
    <Overlay>
      <StyledProcessingLoader>
        <Loader>We're processing your card...</Loader>
      </StyledProcessingLoader>
    </Overlay>
  );
};
