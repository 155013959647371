import { camelizeKeys } from "humps";
import { contains, equals, prop } from "ramda";

import { ORDERS_URL, STORE_BUCKET } from "api_urls";
import { getPrettyUrl } from "get_base_uri";

import {
  checkStatus,
  cloudfrontGetRequest,
  getRequest,
  parseJSON,
} from "helpers/ajax_request";
import getEnv from "helpers/get_env";
import { formatMenu } from "helpers/transforms/online_ordering/menu_transform";

import type { IStockLevels } from "types/menu";

export function getItemStockLevelInfo(): Promise<IStockLevels> {
  return fetch(`${ORDERS_URL}/${getPrettyUrl()}/items`, getRequest() as any)
    .then(checkStatus)
    .then(parseJSON)
    .catch(ex => {
      if (equals(getEnv(), "development")) {
        const CHROME_EXCEPTION = "Failed to fetch:";
        const FF_EXCEPTION = "NetworkError when attempting to fetch resource";
        if (
          contains(CHROME_EXCEPTION, ex.message) ||
          contains(FF_EXCEPTION, ex.message)
        ) {
          return {};
        }
        console.log(
          `failed stock level request for local development: ${ex.message}`
        );
      }
      throw ex;
    });
}

function getOrderingInfo() {
  return fetch(
    `${STORE_BUCKET}/${getPrettyUrl()}/menu.json`,
    cloudfrontGetRequest() as any
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys)
    .then((data: any) => prop("menu", data))
    .then(formatMenu);
}

export default getOrderingInfo;
