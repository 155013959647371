import { camelizeKeys } from "humps";
import { TrackJS } from "trackjs";
import { ORDERS_URL, STORE_BUCKET } from "api_urls";
import { getPrettyUrl, getPreviewToken } from "get_base_uri";
import {
  checkStatus,
  cloudfrontGetRequest,
  parseJSON,
  postRequest,
} from "helpers/ajax_request";

import oloStoreInfoTransform from "helpers/transforms/online_ordering/olo_store_info_transform";
import type { StoreInfo, StoreInfoRedirect } from "types/reducers/store_info";

export async function getOLOStoreInfo(
  storePrettyUrl: string
): Promise<StoreInfo | StoreInfoRedirect> {
  try {
    const response = await fetch(
      `${STORE_BUCKET}/${storePrettyUrl}/online_ordering.json`,
      cloudfrontGetRequest()
    );
    checkStatus(response);

    return oloStoreInfoTransform(
      storePrettyUrl,
      camelizeKeys(await parseJSON(response))
    );
  } catch (ex) {
    // if we aren't getting a brand back it either
    // doesn't exist or AWS is down

    if ((ex as Error).message) {
      TrackJS.track((ex as Error).message);
    }

    throw ex;
  }
}

function publishStore() {
  return fetch(
    `${ORDERS_URL}/${getPrettyUrl()}/publish`,
    postRequest({
      preview_token: getPreviewToken(),
    })
  ).then(checkStatus);
}

export { publishStore };
