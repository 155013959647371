import PropTypes from "prop-types";
import { partial } from "ramda";
import React from "react";

import Icon from "components/icon";

function PickerOption({ option, formatter, onOptionClick, className }) {
  return (
    <li
      onClick={partial(onOptionClick, [option])}
      className={`${className} option`}>
      {formatter(option)} <Icon className="checkmark" type="check" />
    </li>
  );
}

PickerOption.propTypes = {
  option: PropTypes.any.isRequired,
  formatter: PropTypes.func.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

PickerOption.defaultProps = {
  className: "",
};

export default PickerOption;
