import { connect } from "react-redux";

import { updatePaymentType } from "actions/online_ordering/checkout";

import Payment from "components/online_ordering/checkout/Payment";

function mapStateToProps(state) {
  return {
    storeInfo: state.storeInfo,
    checkout: state.checkout,
    orderingOptions: state.orderingOptions,
    consumer: state.consumer,
  };
}

const mapDispatchToProps = {
  onPaymentTypeUpdate: updatePaymentType,
};

const PaymentContainer = connect(mapStateToProps, mapDispatchToProps)(Payment);

export default PaymentContainer;
