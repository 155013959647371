import PropTypes from "prop-types";
import { both, equals, isEmpty, partial } from "ramda";
import React from "react";

import Button from "components/button";

import EditTimeFrameOption from "components/online_ordering/cart/edit_ordering_options/edit_time_frame_option";
import EditTransportOption from "components/online_ordering/cart/edit_ordering_options/edit_transport_option";
import { withTracking } from "helpers/analytics";
import {
  isPickupOnlyAvailable,
  requiresValidAddress,
} from "helpers/online_ordering/fulfillment_types";

const isValidOrderAheadTime = both(
  time => !isEmpty(time),
  time => !equals("Closed", time)
);

function EditOrderingOptions({
  cartIsEmpty,
  consumerInfo,
  contactInfo,
  deliveryAddress,
  onChangeDeliveryTimeFrame,
  onChangeTransportMethod,
  onEditOrderOptions,
  onUpdateDeliveryAddress,
  onUpdateOrderDate,
  onUpdateOrderTime,
  orderingOptions,
  selectedOptionName,
  storeInfo,
  useExtendedOrderAhead,
}) {
  const {
    canUpdate,
    isEditable,
    orderAheadTime,
    selectedTimeFrame,
    selectedTransport,
  } = orderingOptions;
  const isValidTime =
    equals(selectedTimeFrame, "ASAP") || isValidOrderAheadTime(orderAheadTime);
  const isValidAddress =
    !requiresValidAddress(selectedTransport) ||
    equals(deliveryAddress.addressValidationStatus, "VALID");
  const canUpdateOptions = canUpdate && isValidTime && isValidAddress;

  let controls;
  if (cartIsEmpty) {
    controls = null;
  } else if (isEditable) {
    controls = [
      <Button
        size="medium"
        className="update"
        key="update"
        disabled={!canUpdateOptions}
        onClick={withTracking(
          partial(onEditOrderOptions, [false]),
          "OLO - Order Method Form - click schedule/update"
        )}>
        Update
      </Button>,
    ];
  }

  let transportOption;
  let timeFrame;

  if (!isPickupOnlyAvailable(storeInfo.fulfillmentTypes))
    transportOption = (
      <EditTransportOption
        cartIsEmpty={cartIsEmpty}
        orderingOptions={orderingOptions}
        selectedOptionName={selectedOptionName}
        storeInfo={storeInfo}
        contactInfo={contactInfo}
        consumerInfo={consumerInfo}
        onChangeTransportMethod={onChangeTransportMethod}
        onUpdateDeliveryAddress={onUpdateDeliveryAddress}
      />
    );
  if (storeInfo.futureOrder) {
    timeFrame = (
      <EditTimeFrameOption
        storeInfo={storeInfo}
        orderingOptions={orderingOptions}
        onChangeDeliveryTimeFrame={onChangeDeliveryTimeFrame}
        onUpdateOrderDate={onUpdateOrderDate}
        onUpdateOrderTime={onUpdateOrderTime}
        useExtendedOrderAhead={useExtendedOrderAhead}
      />
    );
  }

  return (
    <div className="edit-ordering-options">
      {transportOption}
      {timeFrame}
      {controls}
    </div>
  );
}

EditOrderingOptions.propTypes = {
  cartIsEmpty: PropTypes.bool.isRequired,
  consumerInfo: PropTypes.object,
  contactInfo: PropTypes.object.isRequired,
  deliveryAddress: PropTypes.object.isRequired,
  onChangeDeliveryTimeFrame: PropTypes.func.isRequired,
  onChangeTransportMethod: PropTypes.func.isRequired,
  onEditOrderOptions: PropTypes.func.isRequired,
  onUpdateDeliveryAddress: PropTypes.func.isRequired,
  onUpdateOrderDate: PropTypes.func.isRequired,
  onUpdateOrderTime: PropTypes.func.isRequired,
  orderingOptions: PropTypes.object.isRequired,
  selectedOptionName: PropTypes.string.isRequired,
  storeInfo: PropTypes.object.isRequired,
  useExtendedOrderAhead: PropTypes.bool,
};

export default EditOrderingOptions;
