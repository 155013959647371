import React from "react";
import { MobilePayPaymentReturnUrlAuthorization } from "./MobilePayPaymentReturnUrlAuthorization";
import { useMobilePayUrl } from "hooks/mobile_pay/useMobilePayUrl";
import { usePaymentAuthorization } from "hooks/payment/usePaymentAuthorization";
import type { CheckInfo, ProcessedPaymentInfo } from "types/mobile_pay";

export interface MobilePayPaymentAuthorizationProps {
  checkInfo: CheckInfo;
  onSuccess: (response: ProcessedPaymentInfo) => void;
  onError: (error: string) => void;
}

export const MobilePayPaymentAuthorization = ({
  checkInfo,
  onSuccess,
  onError,
}: MobilePayPaymentAuthorizationProps) => {
  const mobilePayUrl = useMobilePayUrl();
  const { paymentProcessor, navigateToBase } =
    usePaymentAuthorization(mobilePayUrl);

  if (!paymentProcessor) {
    return null;
  }

  return (
    <MobilePayPaymentReturnUrlAuthorization
      paymentProcessor={paymentProcessor}
      checkInfo={checkInfo}
      onSuccess={onSuccess}
      onError={onError}
      onFinalize={navigateToBase}
    />
  );
};
