import React, { useCallback } from "react";
import { OloAccountCreditCardForm } from "./credit_cards/OloAccountCreditCardForm";
import { OnlineOrderingReAddAdyenCardsModal } from "./OnlineOrderingReAddAdyenCardsModal";
import { CreditCardList } from "components/account/CreditCardList";
import { Loader } from "components/loader";
import type { OloCreditCard } from "components/online_ordering/objects/OloCreditCard";
import { usePrettyUrl } from "helpers/hooks/usePrettyUrl";
import { useOloDeleteCreditCard } from "hooks/online_ordering/accounts/useOloDeleteCreditCard";
import { useOloCreditCards } from "hooks/online_ordering/useOloCreditCards";
import { useOloStoreInfo } from "hooks/online_ordering/useOloStoreInfo";

export const OnlineOrderingCreditCardList = () => {
  const storePrettyUrl = usePrettyUrl();
  const { data: creditCards } = useOloCreditCards(storePrettyUrl);
  const { data: storeInfo } = useOloStoreInfo(storePrettyUrl);

  const { mutateAsync: deleteCreditCard } =
    useOloDeleteCreditCard(storePrettyUrl);

  const handleRemoveCreditCard = useCallback(
    async (card: OloCreditCard) => {
      return deleteCreditCard(card.cardUuid);
    },
    [deleteCreditCard]
  );

  if (!storeInfo || !creditCards) {
    return <Loader />;
  }

  return (
    <div className="account-section">
      <CreditCardList
        appName="OLO"
        creditCardList={creditCards}
        onRemoveCreditCard={handleRemoveCreditCard}>
        {({ onCloseForm }) => (
          <OloAccountCreditCardForm
            storePrettyUrl={storePrettyUrl}
            storeInfo={storeInfo}
            onSaveSuccess={() => onCloseForm()}
            onCancel={() => onCloseForm()}
          />
        )}
      </CreditCardList>
      <OnlineOrderingReAddAdyenCardsModal storePrettyUrl={storePrettyUrl} />
    </div>
  );
};
