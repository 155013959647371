import { equals } from "ramda";

declare const ENV_FE_PROXY: unknown;

export function readEnvVariableAsBoolean(
  envVariable: unknown
): boolean | undefined {
  if (envVariable) {
    return envVariable !== "0" && envVariable !== "false";
  }
  return undefined;
}

function getEnv() {
  if (equals(window.location.host, "app.upserve.com")) {
    return "production";
  }
  if (equals(window.location.host, "app.staging.upserve.com")) {
    return "staging";
  }

  const isFEProxy: boolean =
    (() => {
      if (typeof ENV_FE_PROXY === "undefined") {
        return undefined;
      }
      return readEnvVariableAsBoolean(ENV_FE_PROXY);
    })() ?? true;
  if (isFEProxy) {
    return "development_proxy";
  } else {
    return "development";
  }
}

/**
 * @deprecated use `ENV` constant
 */
export default getEnv;

export type Environment = ReturnType<typeof getEnv>;

export const ENV = getEnv();

export function isDevEnvironment() {
  return ENV === "development" || ENV === "development_proxy";
}
