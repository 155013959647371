import React from "react";

import Loader from "components/loader";
import { MobilePayCheck } from "components/mobile_pay/MobilePayCheck";
import ErrorPage from "components/mobile_pay/views/error_page";
import { usePrettyUrl } from "helpers/hooks/usePrettyUrl";

import { useMerchantInfo } from "hooks/mobile_pay/useMerchantInfo";
import { useMobilePayCheck } from "hooks/mobile_pay/useMobilePayCheck";
import { useMobilePayParameters } from "hooks/mobile_pay/useMobilePayParameters";

export const MobilePayContainer = () => {
  const prettyUrl = usePrettyUrl();
  const mobilePayParameters = useMobilePayParameters();

  const { data: merchantInfo, isError: isMerchantInfoError } =
    useMerchantInfo(prettyUrl);
  const { data: checkInfo, isError: isCheckInfoError } = useMobilePayCheck(
    prettyUrl,
    mobilePayParameters
  );

  if (isMerchantInfoError || isCheckInfoError) {
    return <ErrorPage merchantInfo={merchantInfo} />;
  }

  if (!checkInfo || !merchantInfo) {
    return (
      <div className="app">
        <Loader />
      </div>
    );
  }

  return <MobilePayCheck checkInfo={checkInfo} merchantInfo={merchantInfo} />;
};
