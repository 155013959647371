import cn from "classnames";
import React from "react";
import svgInjector from "svg-injector";

const ICON_TYPES = [
  "account",
  "angle-down-solid",
  "angle-left-regular",
  "angle-right-regular",
  "angle-up-solid",
  "bag-2",
  "calendar-alt-solid",
  "caret-down",
  "caret-left",
  "caret-right",
  "caret-up",
  "check",
  "chevron-down",
  "chevron-left",
  "chevron-right",
  "chevron-up",
  "circle-check",
  "clock-solid",
  "credit_cards/amex",
  "credit_cards/discover",
  "credit_cards/mastercard",
  "credit_cards/visa",
  "exclamation-circle-light",
  "gift-card-balance",
  "hamburger-menu",
  "heart-break",
  "info",
  "location-arrow",
  "lock",
  "map-marker-alt-solid",
  "minus-circle-solid",
  "plus-circle-solid",
  "price-tag",
  "receipt-ghost",
  "shopping-bag-solid",
  "star",
  "success-check",
  "upserve-by-lightspeed",
  "upserve-lockup",
  "upserve",
  "user",
  "x",
] as const;

export interface IconProps {
  type: (typeof ICON_TYPES)[number];
  className?: string;
  circled?: boolean;
  afterMount?: () => void;
  size?: number;
}

class Icon extends React.Component<IconProps> {
  componentDidMount() {
    // @ts-expect-error Please fix all ts errors if you need to touch this file
    svgInjector(this.node, {}, this.props.afterMount);
  }

  render() {
    const { className, circled, type, size } = this.props;

    /* eslint-disable global-require, import/no-dynamic-require */
    return (
      <span className={cn("icon-wrapper", { circled })}>
        <img
          // @ts-expect-error Please fix all ts errors if you need to touch this file
          ref={node => (this.node = node)}
          className={cn("icon", type, className)}
          src={require(`images/icons/${type}.svg`)}
          style={size ? { width: `${size}px`, height: `${size}px` } : undefined}
        />
      </span>
    );
    /* eslint-enable global-require, import/no-dynamic-require */
  }
}

export default Icon;
