import React from "react";
import ReactDOM from "react-dom";
import { AppContainer } from "react-hot-loader";
import { ThemeProvider } from "styled-components";

import ErrorBoundary from "components/error_boundary";
import THEME from "theme";

const renderIntoApp = (Component, props = {}) => {
  ReactDOM.render(
    <AppContainer>
      <ThemeProvider theme={THEME}>
        <ErrorBoundary>
          <Component {...props} />
        </ErrorBoundary>
      </ThemeProvider>
    </AppContainer>,
    document.getElementById("root")
  );
};

export default renderIntoApp;
