import styled from "styled-components";

export const StyledSubmitButtonRow = styled.div`
  min-height: 4rem;
  grid-area: button;

  button {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    background: #3e5b83;
    border-radius: 4px;

    &:disabled {
      cursor: pointer;
      background-color: #b5b5b5;
    }
  }
`;
