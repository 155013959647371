import type { Money } from "@upserve/financials";
import {
  filter,
  find,
  gt,
  gte,
  isEmpty,
  lt,
  map,
  not,
  partial,
  propEq,
} from "ramda";
import React from "react";
import styled from "styled-components";

import Button from "components/button";
import LoaderEllipsis from "components/loader_ellipsis";
import CartItem from "components/online_ordering/cart/cart_item";
import CheckoutSummary from "components/online_ordering/cart/checkout_summary";

import { withTracking } from "helpers/analytics";
import { removeByCartId } from "helpers/collections";
import { canAcceptTips } from "helpers/gratuity";
import { formatMoneyFromCents } from "helpers/money";
import { selectedOptionOfAvailable } from "helpers/online_ordering/cart";
import { determineMin } from "helpers/online_ordering/cart/checkout_summary";
import { getTipSettings } from "helpers/online_ordering/checkout";
import applyStockLevels from "helpers/transforms/online_ordering/stock_level_transform";

import type { ICartItem } from "types/cart";
import type { IStockLevels } from "types/menu";
import type { BalanceGiftCard, PaymentGiftCard } from "types/reducers/payment";
import type { ICalculatedTotals } from "types/totals";

export interface IAcceptingOrdersProps {
  calculatedTotals: ICalculatedTotals;
  calculationError: string | null;
  cartItems: ICartItem[];
  createEditableItem: () => void;
  giftCards: Array<BalanceGiftCard>;
  isCheckingOut: boolean;
  onAddMoreItems: () => void;
  onCheckout: () => void;
  onDeleteEditableItem: () => void;
  onRemoveItem: () => void;
  onUpdateItem: () => void;
  orderingOptions: any;
  paymentType: "cash" | "credit";
  stockLevels: IStockLevels;
  storeInfo: any;
  tip: Money;
}

const StyledLineItemsWrapper = styled.div`
  margin: 20px;
`;

export default function AcceptingOrders({
  calculatedTotals,
  calculationError,
  cartItems,
  createEditableItem,
  giftCards,
  isCheckingOut,
  onAddMoreItems,
  onCheckout,
  onDeleteEditableItem,
  onRemoveItem,
  onUpdateItem,
  orderingOptions,
  paymentType,
  stockLevels,
  storeInfo,
  tip,
}: IAcceptingOrdersProps) {
  const minByMethod = determineMin(
    storeInfo.deliveryMinimumOrder,
    orderingOptions.selectedTransport
  );

  const selectedFulfillmentOption = selectedOptionOfAvailable(
    orderingOptions.selectedTransport,
    storeInfo.fulfillmentTypes
  );

  const paymentOptions = (
    find(
      propEq("name", selectedFulfillmentOption),
      storeInfo.fulfillmentTypes
    ) as any
  ).paymentMethods;

  const cartItemsList = (
    <div className="items">
      {map((item: ICartItem) => {
        const cartWithoutCurrentItem = removeByCartId(item.cartId, cartItems);
        const itemWithStockLevels: ICartItem = applyStockLevels(
          item,
          cartWithoutCurrentItem || [],
          stockLevels || {}
        );
        return (
          <CartItem
            key={itemWithStockLevels.cartId}
            item={itemWithStockLevels}
            onUpdateItem={partial(onUpdateItem, [itemWithStockLevels])}
            onRemoveItem={partial(onRemoveItem, [itemWithStockLevels.cartId])}
            onDeleteEditableItem={onDeleteEditableItem}
            createEditableItem={createEditableItem}
          />
        );
      }, cartItems)}
    </div>
  );

  const {
    deliveryFee: feeByMethod,
    menuTotal,
    serviceFee,
    tax,
    total,
    upserveFee,
  } = calculatedTotals;
  const showTip =
    (paymentType === "credit" || !isEmpty(giftCards)) &&
    canAcceptTips(
      getTipSettings(
        storeInfo.fulfillmentTypes,
        orderingOptions.selectedTransport,
        storeInfo.settings
      )
    );

  let minNotMetClass;
  let minRequirement;
  if (minByMethod && lt(menuTotal, minByMethod)) {
    minRequirement = (
      <div className="min-requirement">
        Please add another {formatMoneyFromCents(minByMethod - menuTotal)} to
        meet the minimum
      </div>
    );
    minNotMetClass = "min-not-met";
  }

  const appliedGiftCards = calculatedTotals
    ? (filter(
        propEq("paymentType", "GIFT_CARD"),
        calculatedTotals.payments.payments
      ) as PaymentGiftCard[])
    : [];

  const chargesAndPayments = gte(calculatedTotals.total, 0) ? (
    <>
      <Button
        type="primary"
        onClick={withTracking(
          onAddMoreItems,
          'Used "Any updates, change your order"'
        )}>
        Update Order
      </Button>
      <CheckoutSummary
        balanceDue={calculatedTotals ? calculatedTotals.balanceDue : null}
        feeByMethod={feeByMethod}
        giftCards={appliedGiftCards}
        isAwaitingCalculation={calculatedTotals === null}
        isShowingTip={showTip}
        promo={calculatedTotals.promo}
        serviceFee={serviceFee}
        subtotal={menuTotal}
        tax={tax}
        tip={tip}
        total={total}
        upserveFee={upserveFee}
      />
    </>
  ) : (
    !calculationError && <LoaderEllipsis title="Calculating order total" />
  );

  const checkout = isCheckingOut ? (
    <StyledLineItemsWrapper>{chargesAndPayments}</StyledLineItemsWrapper>
  ) : (
    <div className="checkout-button-wrapper">
      <Button
        type="primary"
        className={`checkout-button ${minNotMetClass}`}
        size="large"
        onClick={withTracking(
          partial(onCheckout, [paymentOptions]),
          "Clicked Check Out"
        )}
        disabled={lt(menuTotal, minByMethod)}>
        Checkout
        {minRequirement}
      </Button>
    </div>
  );

  return (
    <div className="accepting-orders">
      {gt(cartItems.length, 0) ? (
        cartItemsList
      ) : (
        <div className="edit-ordering-options">
          <div className="edit-ordering-options">
            <h3 className="cart-prompt">Your bag is empty.</h3>
          </div>
        </div>
      )}
      {not(orderingOptions.isEditable) && gt(cartItems.length, 0) && checkout}
    </div>
  );
}
