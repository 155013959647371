import VALIDATION_MESSAGES from "helpers/validation/messages";
import Validators from "helpers/validation/validators";

export function validateExpirationInput(value: string) {
  const strippedValue = value.replace(/[^0-9]+/g, "");
  const monthPart = strippedValue.slice(0, 2);
  const yearPart = strippedValue.slice(2, 4);

  if (monthPart.length !== 2) return VALIDATION_MESSAGES.expiryMonth;
  if (yearPart.length !== 2) return VALIDATION_MESSAGES.expiryYear;

  return (
    validateExpiryMonth(monthPart) ||
    validateExpiryYear(yearPart.padStart(4, "20"))
  );
}

function validateExpiryMonth(month) {
  return !Validators.isValidMonth(month)
    ? VALIDATION_MESSAGES.expiryMonth
    : null;
}

function validateExpiryYear(year) {
  return !Validators.isValidExpirationYear(year)
    ? VALIDATION_MESSAGES.expiryYear
    : null;
}

// form validation
export function validateFirstName(name) {
  return !Validators.isNotEmpty(name) ? VALIDATION_MESSAGES.firstName : null;
}

export function validateLastName(name) {
  return !Validators.isNotEmpty(name) ? VALIDATION_MESSAGES.lastName : null;
}

export function validateCreditCard(cardNumber: number) {
  return !Validators.isValidCardNumber(cardNumber.toString())
    ? VALIDATION_MESSAGES.cardNumber
    : null;
}

export function validateEmail(email) {
  return !Validators.isValidEmail(email) ? VALIDATION_MESSAGES.email : null;
}

export function validatePhone(phone) {
  return Validators.isNotEmpty(phone) &&
    (!Validators.isValidPhoneNumber(phone) || !Validators.isNANP(phone))
    ? VALIDATION_MESSAGES.phone
    : null;
}

export function validateAddress1(address1) {
  return !Validators.isNotEmpty(address1) ? VALIDATION_MESSAGES.address1 : null;
}

export function validateCity(city) {
  return !Validators.isNotEmpty(city) ? VALIDATION_MESSAGES.city : null;
}

export function validateState(state) {
  return !Validators.isNotEmpty(state) ? VALIDATION_MESSAGES.state : null;
}

export function validateZip(zip) {
  return !Validators.isNotEmpty(zip) ? VALIDATION_MESSAGES.zip : null;
}
