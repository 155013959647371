import PropTypes from "prop-types";
import React from "react";

function StoreAddress({ store }) {
  const { name, street, locality, region, postalCode, phone, web } = store;
  return (
    <div className="location">
      <h5 className="store-name">{name}</h5>
      <div className="address">
        <div>{street}</div>
        <div>
          {locality}, {region} {postalCode}
        </div>
      </div>
      <div className="phone">{phone}</div>
      <div className="website">
        <a href={`//${web}`} target="_blank" rel="noopener noreferrer">
          {web}
        </a>
      </div>
    </div>
  );
}

StoreAddress.propTypes = {
  store: PropTypes.object.isRequired,
};

export default StoreAddress;
