import { insert } from "ramda";

// todo: Need to get image public ids from server-side use in conjunction
//       with cloudinary js sdk to provide client-side dpr_auto detection.
function transformUrl(url, transform) {
  return insert(6, transform, url.split("/")).join("/");
}

function generateThumbnailUrl(baseUrl, transform = null) {
  const thumbnailTransform =
    transform || "c_thumb,g_center,w_150,h_140,f_auto,q_auto:best,dpr_3.0";
  return transformUrl(baseUrl, thumbnailTransform);
}

function generateMainUrl(baseUrl, transform = null) {
  const mainTransform =
    transform ||
    "c_lpad,g_center,h_300,w_700,c_limit,f_auto,q_auto:best,dpr_3.0";
  return transformUrl(baseUrl, mainTransform);
}

export { generateThumbnailUrl, generateMainUrl };
