import { useField } from "formik";
import type { FieldValidator } from "formik/dist/types";
import React, { type InputHTMLAttributes, forwardRef } from "react";
import { StyledFormControl } from "./StyledFormControl";
import MuiTextfield, { type MuiTextfieldProps } from "components/mui_textfield";

export interface DigitalGiftCardInputProps
  extends Omit<MuiTextfieldProps, "onChange" | "onBlur" | "value">,
    Pick<
      InputHTMLAttributes<HTMLInputElement>,
      "autoComplete" | "min" | "max" | "pattern"
    > {
  name: string;
  label: string;
  validate?: FieldValidator;
}

export const DigitalGiftCardInput = forwardRef<
  HTMLInputElement,
  DigitalGiftCardInputProps
>(({ name, validate, autoComplete, min, max, pattern, ...rest }, ref) => {
  const [inputProps, { error, touched }] = useField({
    name,
    validate,
  });
  return (
    <StyledFormControl className={name} key={name} gridArea={name}>
      <MuiTextfield
        {...rest}
        {...inputProps}
        error={Boolean(error && touched)}
        errorMsg={error && touched ? error : ""}
        InputProps={{
          autoComplete,
          pattern,
          min,
          max,
        }}
        inputRef={ref}
      />
    </StyledFormControl>
  );
});
DigitalGiftCardInput.displayName = "DigitalGiftCardInput";
