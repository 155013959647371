import { multiply, pipe } from "ramda";
import formatMoney from "sig/formatters/money";

const formatMoneyFromCents = pipe<number, number, string>(
  multiply(0.01),
  formatMoney
);

function calculateGratuity(percentage: number, baseTotal: number) {
  return Math.round(percentage * 0.01 * baseTotal);
}

export { calculateGratuity, formatMoneyFromCents };
