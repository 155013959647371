import PropTypes from "prop-types";
import { equals, map, not } from "ramda";
import React from "react";

import MenuSection from "components/online_ordering/menu/menu_section";

import {
  getCurrentDay,
  getCurrentTime,
  withinValidOrderAheadRange,
} from "helpers/order_times";

function Menu({
  cartItems,
  createEditableItem,
  onAddItem,
  onDeleteEditableItem,
  orderingInfo,
  orderingOptions,
  settings,
  timezone,
}) {
  if (!orderingInfo.menu) return <span />;

  const renderedMenuSections = map(section => {
    if (section.timingMask) {
      const isOrderAheadWithoutDate =
        equals(orderingOptions.selectedTimeFrame, "Later") &&
        (equals(orderingOptions.orderAheadDate, "") ||
          equals(orderingOptions.orderAheadTime, ""));

      const targetDayName =
        orderingOptions.orderAheadDate.day || getCurrentDay(timezone);
      // A day with a timing mask means that section may be available
      if (section.timingMask && not(isOrderAheadWithoutDate)) {
        const targetDayAsMoment = equals(
          orderingOptions.selectedTimeFrame,
          "Later"
        )
          ? orderingOptions.orderAheadDate.dayAsMoment
          : getCurrentTime(timezone);

        const isActiveTimedMenuSection = withinValidOrderAheadRange({
          targetDayAsMoment,
          targetTime: orderingOptions.orderAheadTime,
          targetDayName,
          timingMask: section.timingMask,
        });

        if (isActiveTimedMenuSection) {
          return (
            <MenuSection
              key={section.name}
              section={section}
              onAddItem={onAddItem}
              createEditableItem={createEditableItem}
              onDeleteEditableItem={onDeleteEditableItem}
              settings={settings}
              stockLevels={orderingInfo.stockLevels}
              cartItems={cartItems}
            />
          );
        }
        return null;
      }
      return null;
    }
    return (
      <MenuSection
        key={section.name}
        section={section}
        onAddItem={onAddItem}
        createEditableItem={createEditableItem}
        onDeleteEditableItem={onDeleteEditableItem}
        settings={settings}
        stockLevels={orderingInfo.stockLevels}
        cartItems={cartItems}
      />
    );
  }, orderingInfo.menu);

  return <div className="menu">{renderedMenuSections}</div>;
}

Menu.propTypes = {
  cartItems: PropTypes.array,
  createEditableItem: PropTypes.func.isRequired,
  onAddItem: PropTypes.func.isRequired,
  onDeleteEditableItem: PropTypes.func.isRequired,
  orderingInfo: PropTypes.shape({
    menu: PropTypes.array,
    stockLevels: PropTypes.object,
  }),
  orderingOptions: PropTypes.object,
  settings: PropTypes.object,
  timezone: PropTypes.string,
};

export default Menu;
