import type { OloAccountCreditCardFormValues } from "types/online_ordering/accounts/OloAccountCreditCardFormValues";
import type { OloAccountFirstDataCreditCardFormValues } from "types/online_ordering/accounts/payment/OloAccountFirstDataCreditCardFormValues";

export const getFirstDataInitialValues = (
  formValues: OloAccountCreditCardFormValues
): OloAccountFirstDataCreditCardFormValues => {
  return {
    ...formValues,
    cardType: "",
    cardUuid: "",
    cardNumber: "",
    cvv: "",
    expiryMonth: "",
    expiryYear: "",
  };
};
