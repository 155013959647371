import PropTypes from "prop-types";
import { map } from "ramda";
import React from "react";

import Button from "components/button";
import Modal from "components/modal";

function ItemsRemovedModal({ items, onCancel }) {
  return (
    <Modal
      title="Items not available for reorder"
      onClose={onCancel}
      className="items-removed-modal">
      <div className="contents">
        <p>
          We’re sorry, some items are no longer available exactly as you
          previously ordered them. Some items may be available on the menu but
          have been changed by the restaurant. The following items have not been
          added to your cart:
        </p>
        <ul>
          {map(
            item => (
              <li key={item.itemId}>{item.name}</li>
            ),
            items
          )}
        </ul>
        <div className="confirm-actions">
          <Button type="primary" onClick={onCancel} size="large">
            Okay
          </Button>
        </div>
      </div>
    </Modal>
  );
}

ItemsRemovedModal.propTypes = {
  items: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ItemsRemovedModal;
