import { InputAdornment, TextField } from "@material-ui/core";
import { equals, not } from "ramda";
import React, { type InputHTMLAttributes, type Ref } from "react";
import styled, { css } from "styled-components";

export const HelperTextStyles = css`
  font-family: "Graphik", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem; // 14px based on 14px root font-size; decrease to .875rem for 16px root
  line-height: 1.55rem; // ~22px based on 14px root font-size; decrease to 1.33rem for 16px root
  margin-top: 8px;
  margin-left: 0;
  margin-right: 0;
  text-align: right;
`;

export const ErrorHelperTextStyles = css`
  color: #c8341e;
  text-align: left;
`;

export const StyledTextField = styled.div`
  width: 100%;

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .MuiTextField-root {
    font-family: "Graphik", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem; // 14px based on 14px root font-size; decrease to .875rem for 16px root
    line-height: 1.55rem; // ~22px based on 14px root font-size; decrease to 1.33rem for 16px root
    color: #888888;
    width: 100%;

    .MuiFilledInput-root {
      background-color: #ffffff;
    }
  }

  .MuiInputBase-root {
    font-family: "Graphik", sans-serif;
    border-radius: 4px;
    border: 2px solid #e0e0e0;
    &.Mui-error {
      border: 2px solid #c8341e;
    }
    &.Mui-focused {
      outline: 3px solid #6699cc;
    }
  }

  .MuiInputBase-input {
    color: #212322;
    background-color: #ffffff;
    border-radius: 4px;
  }

  .MuiFormLabel-root {
    &.Mui-focused {
      color: #888888;
    }
    &.Mui-error {
      color: #c8341e;
    }
  }

  .MuiFormLabel-asterisk.Mui-error {
    color: #c8341e;
  }

  .MuiInputLabel-root {
    font-family: "Graphik", sans-serif;
    color: #888888;

    &.MuiInputLabel-filled.MuiInputLabel-shrink {
      transform: translate(12px, 10px);
      font-size: 0.875rem; // ~12px based on 14px root font-size; decrease to 0.75 for 16px root
    }
  }

  .MuiFormHelperText-root {
    ${HelperTextStyles}

    &.Mui-error {
      ${ErrorHelperTextStyles}
    }
  }
`;

export type MuiTextfieldProps = {
  error?: boolean;
  errorMsg?: string | null;
  helperText?: string;
  label: string;
  maxLength?: number;
  multiline?: boolean;
  name?: string;
  onBlur?: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onChange?: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  startAdornment?: string;
  type?: string;
  value: string;
  InputProps?: InputHTMLAttributes<HTMLInputElement>;
  inputRef?: Ref<HTMLInputElement>;
};

export default function MuiTextfield({
  error,
  errorMsg,
  helperText,
  label,
  maxLength,
  multiline = false,
  name,
  onBlur,
  onChange,
  placeholder,
  required = false,
  rows,
  startAdornment,
  type = "text",
  value,
  InputProps,
  inputRef,
}: MuiTextfieldProps) {
  const isErrorState = !!(errorMsg && not(equals(errorMsg, null))) || error;
  const labelId = `${name}_label`;
  const helperTextId = `${name}_helperText`;
  return (
    <StyledTextField>
      <TextField
        error={isErrorState}
        helperText={errorMsg || helperText}
        inputProps={{
          maxLength,
          "aria-labelledby": labelId,
          "aria-describedby": helperTextId,
        }}
        // yes, `inputProps` and `InputProps` are actually different
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          disableUnderline: true,
          startAdornment: startAdornment && (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ),
          ...InputProps,
        }}
        InputLabelProps={{
          id: labelId,
        }}
        FormHelperTextProps={{
          id: helperTextId,
        }}
        inputRef={inputRef}
        label={label}
        multiline={multiline}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        rows={rows}
        type={type}
        value={value}
        variant="filled"
      />
    </StyledTextField>
  );
}
