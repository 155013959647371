import React from "react";
import Icon from "components/icon";

import trackAnalytic from "helpers/analytics";

function Footer() {
  return (
    <footer className="footer">
      <div className="outside-reference">
        <a
          href="https://upserve.com/platform/"
          onClick={() => {
            trackAnalytic("Click Footer Link (Restaurant Platform)");
            return true;
          }}
          target="_blank"
          rel="noopener noreferrer">
          Restaurant Platform
        </a>
        <span className="separator">|</span>
        <a
          href="https://upserve.com/restaurant-insider/"
          onClick={() => {
            trackAnalytic("Click Footer Link (Restaurant Blog)");
            return true;
          }}
          target="_blank"
          rel="noopener noreferrer">
          Restaurant Blog
        </a>
        <span className="separator">|</span>
        <a
          href="https://upserve.com/restaurant-pos-free-tour/"
          onClick={() => {
            trackAnalytic("Click Footer Link (Free POS Tour)");
            return true;
          }}
          target="_blank"
          rel="noopener noreferrer">
          Free POS Tour
        </a>
        <span className="separator">|</span>
        <a
          href="https://upserve.com/platform/upserve-hq/online-ordering"
          onClick={() => {
            trackAnalytic("Click Footer Link (Upserve Online Ordering)");
            return true;
          }}
          target="_blank"
          rel="noopener noreferrer">
          Lightspeed (U-Series) Online Ordering
        </a>
        <span className="separator">|</span>
        <a
          href="https://assets-upserve.s3.amazonaws.com/terms-and-conditions/online-ordering-terms-and-conditions.html"
          onClick={() => {
            trackAnalytic("Click Footer Link (Terms and Conditions)");
            return true;
          }}
          target="_blank"
          rel="noopener noreferrer">
          Terms and Conditions
        </a>
      </div>
      <div className="upserve">
        <a
          className="powered"
          href="https://upserve.com/"
          onClick={() => {
            trackAnalytic("Click Footer Link (Powered by Upserve)");
            return true;
          }}
          target="_blank"
          rel="noopener noreferrer">
          <Icon className="logo-icon" type="upserve-by-lightspeed" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
