import React, { type Ref, useImperativeHandle, useMemo, useRef } from "react";
import type {
  PaymentFormValidator,
  PaymentSubmitHandler,
} from "actions/online_ordering";

import { Loader } from "components/loader";
import { CCProcessorConfigurationMap } from "components/online_ordering/checkout/payment/CCProcessorConfigurationMap";
import { CreditCardFormContainer } from "components/online_ordering/checkout/payment/CreditCardFormContainer";
import type { PaymentConfiguration } from "components/online_ordering/checkout/payment/PaymentConfiguration";
import { usePrettyUrl } from "helpers/hooks/usePrettyUrl";
import { useAdyenOLOPaymentAccess } from "hooks/online_ordering/payment/adyen/useAdyenOLOPaymentAccess";
import type { OrderResponse } from "types/reducers/checkout_status";

export interface CreditCardPaymentProps {
  orderResponse: OrderResponse;
  getPaymentConfigurationRef: Ref<(() => PaymentConfiguration) | null>;
}

export const CreditCardPayment = ({
  orderResponse,
  getPaymentConfigurationRef,
}: CreditCardPaymentProps) => {
  const prettyUrl = usePrettyUrl();
  const { data: isAdyenEnabled } = useAdyenOLOPaymentAccess(prettyUrl);

  const ccProcessorConfiguration = useMemo(() => {
    if (isAdyenEnabled === undefined) {
      return null;
    }
    const ccProcessor = isAdyenEnabled ? "adyen" : "firstData";
    return CCProcessorConfigurationMap[ccProcessor];
  }, [isAdyenEnabled]);

  const paymentFormValidatorRef = useRef<PaymentFormValidator>(null);
  const paymentSubmitHandlerRef = useRef<PaymentSubmitHandler>(null);
  useImperativeHandle(
    getPaymentConfigurationRef,
    (): (() => PaymentConfiguration) | null => {
      if (!ccProcessorConfiguration) {
        return null;
      }
      const { isCustomizedSubmitProcessing } = ccProcessorConfiguration;
      return () => ({
        isCustomizedSubmitProcessing,
        paymentFormValidator: paymentFormValidatorRef.current,
        paymentSubmitHandler: paymentSubmitHandlerRef.current,
      });
    },
    [ccProcessorConfiguration]
  );

  if (!ccProcessorConfiguration) {
    return <Loader />;
  }

  return (
    <CreditCardFormContainer
      orderResponse={orderResponse}
      paymentFormValidatorRef={paymentFormValidatorRef}
      paymentSubmitHandlerRef={paymentSubmitHandlerRef}
      ccProcessorConfiguration={ccProcessorConfiguration}
    />
  );
};
