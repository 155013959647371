import type { Maybe } from "true-myth";

function toNullable<T>(maybe: Maybe<T>): T | null {
  return maybe.match({
    Just: val => val,
    Nothing: () => null,
  });
}

export { toNullable };
