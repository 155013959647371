import { camelizeKeys } from "humps";

import { MOBILE_PAYMENTS_URL, STORE_BUCKET } from "api_urls";
import { getPrettyUrl } from "get_base_uri";

import {
  checkStatus,
  cloudfrontGetRequest,
  parseJSON,
  postRequest,
} from "helpers/ajax_request";
import {
  transformCheckInfoApiResponse,
  transformCreditCardPaymentApiResponse,
  transformMerchantInfoApiResponse,
} from "helpers/transforms/mobile_pay";
import type {
  CheckInfo,
  MerchantInfo,
  MobilePayRedirect,
  PaymentForProcessing,
  ProcessedPaymentInfo,
} from "types/mobile_pay";

export function getMerchantInfo(
  prettyUrl: string
): Promise<MerchantInfo | MobilePayRedirect> {
  return fetch(
    `${STORE_BUCKET}/${prettyUrl}/mobile_payments.json`,
    cloudfrontGetRequest() as RequestInit
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys)
    .then(transformMerchantInfoApiResponse);
}

export function getCheckInfo(
  prettyUrl: string,
  mobilePayParameters: URLSearchParams
): Promise<CheckInfo> {
  const fetchUrl = `${MOBILE_PAYMENTS_URL}/${prettyUrl}/checks?${mobilePayParameters.toString()}`;

  return fetch(fetchUrl)
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys)
    .then(transformCheckInfoApiResponse);
}

export function postMobilePayCreditCardPayment(
  paymentInfo: PaymentForProcessing
): Promise<ProcessedPaymentInfo> {
  return fetch(
    `${MOBILE_PAYMENTS_URL}/${getPrettyUrl()}/mobile_payments`,
    postRequest(paymentInfo) as RequestInit
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(response => {
      const responseCamelized = camelizeKeys(response);
      return {
        ...responseCamelized,
        authorisationResponse: {
          ...responseCamelized.authorisationResponse,
          // Do NOT camelize the action object
          action: response.authorisation_response.action,
        },
      };
    })
    .then(transformCreditCardPaymentApiResponse);
}
