import { STATUS_URL } from "api_urls";
import { checkStatus, getRequest, parseJSON } from "helpers/ajax_request";
import type { IOLOStatus } from "types/status";

export async function getOLOStatus(): Promise<IOLOStatus> {
  let response = await fetch(`${STATUS_URL}/status`, getRequest() as any);
  response = checkStatus(response);
  const json = await parseJSON(response);
  return {
    messageHeader: json.message_header,
    messageContentHTML: json.message_content_html,
    active: json.active,
  };
}
