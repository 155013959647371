import { type UseQueryOptions, useQuery } from "react-query";
import type { AdyenPaymentAccessResponse } from "interfaces/credit_card/adyen/AdyenPaymentMethodsResponse";
import { getAdyenOLOPaymentAccess } from "interfaces/online_ordering/payment/adyen/getAdyenOLOPaymentAccess";

export const useAdyenOLOPaymentAccess = <TData = boolean>(
  storePrettyUrl: string,
  options?: UseQueryOptions<
    AdyenPaymentAccessResponse,
    unknown,
    TData,
    readonly ["adyenOLOPaymentAccess", string]
  >
) => {
  return useQuery(
    ["adyenOLOPaymentAccess", storePrettyUrl],
    () => {
      return getAdyenOLOPaymentAccess(storePrettyUrl);
    },
    {
      select: ({ enabled }) => enabled as TData,
      ...options,
    }
  );
};
