import { Money } from "@upserve/financials";
import cn from "classnames";
import { equals, find, gt, isNil, map, propEq } from "ramda";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Button from "components/button";
import Input from "components/input";

import {
  type RestaurantTipSettings,
  SuggestedTipSelection,
  canAcceptTips,
  generateTipOptions,
} from "helpers/gratuity";

const StyledGratuityWrapper = styled.div`
  // SHARED: All Variants
  button {
    border: 1px solid #ededed;
    color: #88888d;
    font-weight: 300;

    height: 5.25rem;

    &.selected {
      border: 1px solid #445a82;
    }
  }

  // todo: replace this div/span element with pure input as these duplications
  // are problematic
  div {
    position: relative;

    border: 1px solid #ededed;

    &.selected {
      border-color: #445a82;

      &.error {
        border: 0;
      }

      &::before {
        content: "$";
        color: #445a82;
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    input {
      border: none;
      color: #88888d;
      font-weight: 300;
      padding: 0;
      text-align: center;
    }
  }

  button,
  div,
  input {
    border-radius: 5px;
  }

  // VARIANT: Mobile Pay
  &.variant-mobile-pay {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
    position: relative;

    button,
    div {
      margin-bottom: 18px;

      // Tip Options: Low + Med + Hi + Custom + None (3 col mobile / 5 col desktop)
      width: 30%;

      @media (min-width: 450px) {
        width: 17%;
      }
    }

    // Tip Options: Low + Med + Hi + None (3 col mobile / 4 col desktop)
    &.custom-tips-disabled.suggested-tips-enabled {
      button,
      div {
        @media (min-width: 450px) {
          width: 22%;
        }
      }
    }

    // Tip Options: Custom + None (2 col always)
    &.custom-tips-enabled.suggested-tips-disabled {
      button,
      div {
        width: 47%; // 2-col
      }
    }

    button,
    input {
      font-size: 1.15rem;
    }
  }

  // VARIANT: OLO
  &.variant-olo {
    display: grid;
    gap: 1.25rem;
    margin: 2.5rem 0 3.25rem;

    // Tip Options: Low + Med + Hi + Custom + None (3 col mobile)
    // Tip Options: Low + Med + Hi + None (3 col mobile)
    grid-template-columns: repeat(3, 1fr);

    // Tip Options: Custom + None (2 col mobile)
    &.custom-tips-enabled.suggested-tips-disabled {
      grid-template-columns: repeat(2, 1fr);

      // Don't stretch options horizontally when there are only 2 on desktop
      @media (min-width: 680px) {
        display: inline-flex;
      }
    }

    // All tip options should fit in 1 row on desktop
    @media (min-width: 680px) {
      display: flex;
      flex-wrap: nowrap;
    }

    button,
    input {
      padding: 0;
      font-size: 0.875rem;
    }

    button,
    div {
      width: 100%;
    }
  }
`;

const StyledFullWidthLabel = styled.label`
  width: 100%;
  text-align: left;
  min-height: 18px;
  color: #5a5a5a;

  // VARIANT: Mobile Pay
  &.variant-mobile-pay {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 9px;
    text-transform: capitalize;
  }

  // VARIANT: OLO
  &.variant-olo {
    font-size: 0.875rem;
    font-weight: 600;
    grid-column: 1/-1; // full-width (mobile)

    // match other left-aligned labels (desktop)
    @media (min-width: 680px) {
      flex-shrink: 0;
      width: calc(120px - 1.25rem);
    }
  }
`;

const StyledCustomTipInput = styled(Input)`
  & + .error-text {
    text-align: left;
    line-height: normal;
    margin: 6px 0 0 !important;
  }

  &.variant-mobile-pay + .error-text {
    font-size: inherit !important;
  }
`;

type GratuityComponentProps = {
  tipSettings: RestaurantTipSettings;
  preTaxTotal: Money;
  setTip: (tipToSet: Money) => void;
  setIsTipValid?: (validity: boolean) => void;
  setSelectedTipOption: (tipOption: SuggestedTipSelection | undefined) => void;
  selectedTipOption: SuggestedTipSelection | undefined;
  tip?: Money;
  variant?: "olo" | "mobile-pay";
};

export default function GratuityComponent({
  tipSettings,
  preTaxTotal,
  setTip,
  setIsTipValid,
  setSelectedTipOption,
  selectedTipOption,
  tip,
  variant,
}: GratuityComponentProps) {
  const { customTipsEnabled, showSuggestedTips, suggestedTipDefault } =
    tipSettings;

  const [customTipError, setCustomTipError] = useState("");

  const [customTip, setCustomTip] = useState<string | number>("");

  const tipSuggestionOptions = generateTipOptions(tipSettings, preTaxTotal);

  useEffect(() => {
    if (showSuggestedTips) {
      if (!isNil(selectedTipOption)) {
        const selectedTip = find(
          propEq("tipSelection", selectedTipOption),
          tipSuggestionOptions
        );
        if (selectedTip) {
          setTip(selectedTip.monetaryValue);
          setSelectedTipOption(selectedTipOption);
        }
      } else if (tip && gt(tip.valueOf(), 0)) {
        setCustomTip(tip.toDollars());
      } else {
        const defaultTipOption = find(
          propEq("tipSelection", suggestedTipDefault),
          tipSuggestionOptions
        );
        if (defaultTipOption) {
          setTip(defaultTipOption.monetaryValue);
          setSelectedTipOption(defaultTipOption.tipSelection);
        }
      }
    }
    // added since a new eslint rule,
    // feel free to fix this eslint error if you can test it properly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const percentageButtons = map(option => {
    let selectedClass;
    if (equals(selectedTipOption, option.tipSelection)) {
      selectedClass = "selected";
    }
    const clickAction = tipOption => {
      setTip(tipOption.monetaryValue);
      setCustomTip("");
      setCustomTipError("");
      setSelectedTipOption(tipOption.tipSelection);
      if (setIsTipValid) {
        setIsTipValid(true);
      }
    };
    return (
      <Button
        type="secondary"
        size="small"
        key={option.label}
        onClick={() => clickAction(option)}
        className={cn(selectedClass)}>
        {option.label}
        <br />
        {`(${option.monetaryValue.format()})`}
      </Button>
    );
  }, tipSuggestionOptions);

  const clickNoTip = () => {
    setTip(Money(0));
    setCustomTip("");
    setCustomTipError("");
    setSelectedTipOption(SuggestedTipSelection.NoTip);
    if (setIsTipValid) {
      setIsTipValid(true);
    }
  };

  const noTipButton = (
    <Button
      type="secondary"
      size="small"
      key="no tip"
      onClick={() => clickNoTip()}
      className={cn({
        selected: equals(selectedTipOption, SuggestedTipSelection.NoTip),
      })}>
      No tip
    </Button>
  );

  const customTipAction = () => {
    if (equals("", customTip)) {
      setTip(Money(0));
      setCustomTipError("");
      setSelectedTipOption(SuggestedTipSelection.NoTip);
      return;
    }
    const tipAsNumber =
      typeof customTip === "string" ? parseFloat(customTip) : customTip;
    if (equals(NaN, tipAsNumber) || customTip < 0) {
      setCustomTipError("Please enter a valid tip");
      setTip(Money(0));
      if (setIsTipValid) {
        setIsTipValid(false);
      }
    } else {
      setCustomTipError("");
      setCustomTip(tipAsNumber.toFixed(2));
      setTip(Money.fromDollars(customTip));
      if (setIsTipValid) {
        setIsTipValid(true);
      }
    }
    setSelectedTipOption(undefined);
  };

  const customTipInput = (
    <div
      className={cn({
        error: !!customTipError,
        selected: !equals("", customTip),
      })}>
      <StyledCustomTipInput
        id="gratuity-component-tip"
        autoComplete="off"
        className={`variant-${variant}`}
        error={customTipError}
        placeholder="Custom"
        value={customTip}
        inputMode="decimal"
        onChange={event => setCustomTip(event.target.value)}
        onBlur={() => customTipAction()}
      />
    </div>
  );

  return (
    <>
      {canAcceptTips(tipSettings) && (
        <StyledGratuityWrapper
          className={
            // todo: refactor this into distinct styled components
            `custom-tips-${
              customTipsEnabled ? "enabled" : "disabled"
            } suggested-tips-${
              showSuggestedTips ? "enabled" : "disabled"
            } variant-${variant}`
          }>
          <StyledFullWidthLabel className={`variant-${variant}`}>
            Add gratuity
          </StyledFullWidthLabel>
          {showSuggestedTips && percentageButtons}
          {customTipsEnabled && customTipInput}
          {noTipButton}
        </StyledGratuityWrapper>
      )}
    </>
  );
}
