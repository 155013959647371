import cn from "classnames";
import React, { type MouseEvent, type ReactNode } from "react";

export type ButtonTypes =
  | "primary" // Theme color
  | "secondary" // Inverted theme color
  | "text"; // text, no border

interface IButtonProps {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onDisabledClick?: () => void;
  size?: "small" | "medium" | "large";
  type?: ButtonTypes;
  isSubmit?: boolean;
}

export default function Button({
  type = "primary",
  size = "small",
  disabled,
  onClick,
  onDisabledClick,
  className = "",
  children,
  isSubmit,
}: IButtonProps) {
  const classes = cn("button", className, size, type, { disabled });

  const handleClick = event => {
    if (disabled) {
      event.preventDefault();
      if (onDisabledClick) onDisabledClick();
    } else if (onClick) {
      onClick(event);
    }
  };

  return (
    <button
      disabled={onDisabledClick ? false : disabled}
      className={classes}
      onClick={handleClick}
      type={isSubmit ? "submit" : "button"}>
      {children}
    </button>
  );
}
