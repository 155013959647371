import { equals } from "ramda";

function determineFee(fee, method) {
  if (fee && fee > 0 && equals(method, "Delivery")) {
    return fee;
  }

  return 0;
}

function determineMin(min, method) {
  if (min && min > 0 && equals(method, "Delivery")) {
    return min;
  }

  return 0;
}

export { determineFee, determineMin };
