import PropTypes from "prop-types";
import { head, isEmpty, map } from "ramda";
import React from "react";

import GoogleMap from "components/google_map";
import StoreAddress from "components/store_address";
import isOLO from "helpers/is_olo";

function LocationInfo({ isBrand = true, info }) {
  let stores = [];
  if (isBrand) {
    stores = info.stores;
  } else {
    stores = [
      {
        name: info.locationName,
        street:
          info.address.line1 +
          (info.address.line2 ? `,${info.address.line2}` : ""),
        locality: info.address.city,
        region: info.address.state,
        postalCode: info.address.zip,
        phone: info.phoneNumber,
        web: info.website,
        longitude: info.longitude,
        latitude: info.latitude,
        mapUrl: info.mapUrl,
      },
    ];
  }

  const { mapUrl } = head(stores);
  // Just grab the location of first store for map,
  // postpone updating of map for each location to v2
  // ux design has not been defined for this

  const storeAddresses = map(
    store => <StoreAddress key={store.name} store={store} />,
    stores
  );

  let description;
  if (!isEmpty(info.description) && !isOLO()) {
    description = <div className="promo-description">{info.description}</div>;
  }

  return (
    <div className="location-info card">
      {description}
      <div className="lower">
        <div className="address-column">{storeAddresses}</div>
        <GoogleMap mapUrl={mapUrl} />
      </div>
    </div>
  );
}

LocationInfo.propTypes = {
  info: PropTypes.object.isRequired,
  isBrand: PropTypes.bool,
};

export default LocationInfo;
