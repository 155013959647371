import PropTypes from "prop-types";
import React from "react";

import formatMoney from "sig/formatters/money";

import LoyaltyImage from "components/loyalty/loyalty_image";
import RewardProgressBar from "components/loyalty/reward_progress_bar";

function WelcomeReward({ reward, progression, pointReward }) {
  if (progression.fpoRedeemed) return <span />;
  const pointGoal = parseInt(pointReward.price, 10);

  return (
    <div className="welcome-reward reward">
      <LoyaltyImage type="welcome" imagePath={reward.coverPhoto} />
      <div className="reward-content">
        <h2>Welcome!</h2>
        <div className="description">
          You'll get {reward.rewardValue} back on your next purchase of{" "}
          {reward.threshold} or more. After that you'll get{" "}
          {pointReward.rewardValue} back for every{" "}
          {formatMoney(pointGoal, { precision: 0 })} you spend with us.
        </div>
        <RewardProgressBar
          points={progression.pointsAccrued}
          goal={pointReward.price}
          rewardValue={pointReward.rewardValue}
        />
      </div>
    </div>
  );
}

WelcomeReward.propTypes = {
  reward: PropTypes.shape({
    rewardValue: PropTypes.string.isRequired,
    coverPhoto: PropTypes.string,
    threshold: PropTypes.string,
  }).isRequired,
  pointReward: PropTypes.shape({
    rewardValue: PropTypes.string.isRequired,
    coverPhoto: PropTypes.string,
    price: PropTypes.string,
  }).isRequired,
  progression: PropTypes.object.isRequired,
};

export default WelcomeReward;
