import { type UseQueryOptions, useQuery } from "react-query";
import type { IConsumerInfo } from "components/online_ordering/objects/IConsumerInfo";
import type { IResponseError } from "helpers/ajax_request";
import { getConsumerInfo } from "interfaces/consumer_info";
import isLoggedIn from "is_logged_in";

export const oloConsumerInfoQueryKey = (storePrettyUrl: string) =>
  ["consumerInfo", storePrettyUrl] as const;

export const useOloConsumerInfo = <TQueryFnData = IConsumerInfo>(
  storePrettyUrl: string,
  options?: UseQueryOptions<
    IConsumerInfo,
    IResponseError,
    TQueryFnData,
    readonly ["consumerInfo", string]
  >
) => {
  return useQuery(
    oloConsumerInfoQueryKey(storePrettyUrl),
    async () => {
      return getConsumerInfo(storePrettyUrl);
    },
    {
      ...options,
      // This endpoint can only be retrieved if the user is logged in.
      enabled: isLoggedIn(true) && (options?.enabled ?? true),
    }
  );
};
