import React, { type ReactNode } from "react";

export interface LoaderProps {
  className?: string;
  style?: React.CSSProperties;
  children?: ReactNode;
}

export function Loader({ className, style, children }: LoaderProps) {
  return (
    <div className={`loader ${className}`} style={style} data-testid="loader">
      <div className="indicator">
        <div className="logo" />
      </div>
      {children}
    </div>
  );
}

/**
 * @deprecated Please use the non-default export
 */
export default Loader;
