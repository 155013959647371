import React from "react";

function UnapprovedMenuWarning() {
  return (
    <div id="unapproved-menu-warning" className="message">
      Online Ordering is not yet available for this restaurant because the
      restaurateur has not approved their online ordering menu.
    </div>
  );
}

export default UnapprovedMenuWarning;
