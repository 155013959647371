import React from "react";
import { TrackJS } from "trackjs";

import ErrorPage from "components/digital_gift_cards/error_page";
import PurchasePage from "components/digital_gift_cards/purchase_page";
import Loader from "components/loader";
import { settingsIncludesRequiredValues } from "helpers/digital_gift_cards";
import { usePrettyUrl } from "helpers/hooks/usePrettyUrl";
import { useDigitalGiftCardsSettings } from "hooks/digital_gift_cards/useDigitalGiftCardsSettings";

export function DigitalGiftCardsContainer() {
  const brandPrettyUrl = usePrettyUrl();
  const { data: settings, isError } = useDigitalGiftCardsSettings(
    brandPrettyUrl,
    {
      retry: false,
    }
  );

  if (isError) {
    return <ErrorPage />;
  }

  if (!settings) {
    return (
      <div className="app">
        <Loader />
      </div>
    );
  }

  const { digitalGiftCardEnabled } = settings;
  const hasRequiredSettings = settingsIncludesRequiredValues(settings);

  if (!hasRequiredSettings) {
    TrackJS.track(
      "digital gift card configuration is missing required settings"
    );
  }

  if (!digitalGiftCardEnabled || !hasRequiredSettings) {
    return <ErrorPage />;
  }

  return <PurchasePage settings={settings} />;
}
