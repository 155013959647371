import React from "react";
import styled from "styled-components";

import Icon from "components/icon";
import Page from "components/mobile_pay/views/page";
import { StyledErrorHeader } from "components/shared/errors/StyledErrorHeader";
import { StyledErrorText } from "components/shared/errors/StyledErrorText";
import type { MerchantInfo } from "types/mobile_pay";

const StyledWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  height: 100vh;

  padding: 50px 35px;

  text-align: center;

  > .icon-wrapper svg.receipt-ghost {
    width: 100px;
    height: 96px;
  }
`;

type ErrorPageProps = {
  merchantInfo?: MerchantInfo;
};

export default function ErrorPage({ merchantInfo }: ErrorPageProps) {
  const messaging = (
    <>
      <Icon type="receipt-ghost" />
      <StyledErrorHeader>Sorry!</StyledErrorHeader>
      <StyledErrorText>
        We were unable to find your check. Please try scanning your receipt
        again.
      </StyledErrorText>
    </>
  );
  return merchantInfo ? (
    <Page merchantInfo={merchantInfo}>{messaging}</Page>
  ) : (
    <StyledWrapper>{messaging}</StyledWrapper>
  );
}
