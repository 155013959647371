import Aviator from "aviator";
import { equals, is } from "ramda";

import {
  CAPTURE_AUTH_PARAMS,
  CHANGE_MODAL_TYPE,
  LOG_OUT,
  RECEIVE_USER_EMAIL,
  RETURN_AUTH_ERRORS,
  SHOW_AUTHENTICATING_LOADER,
  SHOW_SET_PASSWORD_MODAL,
  TOGGLE_MODAL,
  UPDATE_FIELD,
} from "actions/authentication";
import getBaseUri, { dineInQueryString } from "get_base_uri";
import { removeUserFromLocalStorage } from "helpers/local_storage";

const initialState = {
  isShown: false,
  modalType: "LOGIN",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  newPassword: "",
  confirmPassword: "",
  emailToken: "",
  reset: false,
  errors: null,
  authenticating: false,
  customAuthMessage: "",
};

function authentication(state = initialState, action) {
  switch (action.type) {
    case CHANGE_MODAL_TYPE: {
      return {
        ...state,
        modalType: action.modalType,
      };
    }

    case TOGGLE_MODAL: {
      return {
        ...state,
        isShown: action.isShown,
        modalType: "LOGIN",
        email: "",
        password: "",
        confirmPassword: "",
        errors: null,
        customAuthMessage: is(String, action.customAuthMessage)
          ? action.customAuthMessage
          : "",
      };
    }

    case SHOW_SET_PASSWORD_MODAL: {
      return {
        ...state,
        modalType: "SET_PASSWORD",
        isShown: true,
      };
    }

    case UPDATE_FIELD: {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case CAPTURE_AUTH_PARAMS: {
      return {
        ...state,
        emailToken: action.emailToken,
        reset: equals(action.reset, "true"),
      };
    }

    case RETURN_AUTH_ERRORS: {
      return {
        ...state,
        errors: action.errors,
      };
    }

    case LOG_OUT: {
      removeUserFromLocalStorage();

      if (action.forceRefresh) {
        Aviator.navigate(`${getBaseUri()}${dineInQueryString()}`); // redirecting instead of reloading because there are some query parameters we don't want to persist
        return initialState;
      }

      // This case covers if a user is logged in and a new user
      // lands on the page and is trying to set their password
      return {
        ...initialState,
        emailToken: state.emailToken,
        reset: state.reset,
      };
    }

    case RECEIVE_USER_EMAIL: {
      return {
        ...state,
        email: action.email,
      };
    }

    case SHOW_AUTHENTICATING_LOADER: {
      return {
        ...state,
        authenticating: true,
        password: "",
        confirmPassword: "",
      };
    }

    default:
      return state;
  }
}

export default authentication;
