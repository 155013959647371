import { camelizeKeys } from "humps";
import { STORE_BUCKET } from "api_urls";
import { getPrettyUrl } from "get_base_uri";
import {
  checkStatus,
  cloudfrontGetRequest,
  parseJSON,
} from "helpers/ajax_request";

function getStoreInfo(callback, storePrettyUrl = getPrettyUrl()) {
  return fetch(
    `${STORE_BUCKET}/${storePrettyUrl}/store.json`,
    cloudfrontGetRequest()
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys)
    .then(storeInfo => {
      if (callback) callback(storeInfo);
      return storeInfo;
    })
    .catch(error => {
      console.error(error);
    });
}

export default getStoreInfo;
