import PropTypes from "prop-types";
import { equals } from "ramda";
import React from "react";

import OrderTimeEditor from "components/online_ordering/cart/order_time_editor";
import Toggle from "components/toggle";
import { withTracking } from "helpers/analytics";

function EditTimeFrameOption({
  storeInfo,
  orderingOptions,
  onChangeDeliveryTimeFrame,
  onUpdateOrderDate,
  onUpdateOrderTime,
  useExtendedOrderAhead,
}) {
  let timeEditor;
  if (equals(orderingOptions.selectedTimeFrame, "Later")) {
    timeEditor = (
      <OrderTimeEditor
        storeInfo={storeInfo}
        orderingOptions={orderingOptions}
        onUpdateOrderDate={onUpdateOrderDate}
        onUpdateOrderTime={onUpdateOrderTime}
        useExtendedOrderAhead={useExtendedOrderAhead}
      />
    );
  }

  return (
    <div className="order-option time">
      <div className="title">When would you like your order?</div>
      <Toggle
        options={["ASAP", "Later"]}
        selectedOption={orderingOptions.selectedTimeFrame}
        handleChange={withTracking(
          onChangeDeliveryTimeFrame,
          orderingOptions.selectedTimeFrame === "ASAP"
            ? "OLO - Order Method Form - select later"
            : "OLO - Order Method Form - select ASAP"
        )}
      />
      {timeEditor}
    </div>
  );
}

EditTimeFrameOption.propTypes = {
  storeInfo: PropTypes.object.isRequired,
  orderingOptions: PropTypes.object.isRequired,
  onChangeDeliveryTimeFrame: PropTypes.func.isRequired,
  onUpdateOrderDate: PropTypes.func.isRequired,
  onUpdateOrderTime: PropTypes.func.isRequired,
  useExtendedOrderAhead: PropTypes.bool,
};

export default EditTimeFrameOption;
