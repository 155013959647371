import React from "react";
import type { TransportDetailsProps } from "components/online_ordering/checkout/payment/order_confirm_modal/OrderCompleteModal";
import { OrderCompleteModalTestIds } from "components/online_ordering/checkout/payment/order_confirm_modal/OrderCompleteModalTestIds";
import { useOrderReadyTime } from "hooks/online_ordering/payment/order_confirm_modal/useOrderReadyTime";

export const DeliveryDetails = ({
  orderInformation,
}: TransportDetailsProps) => {
  const {
    orderingOptions,
    contactInfo: { firstName, lastName },
  } = orderInformation;
  const orderReadyTime = useOrderReadyTime(orderInformation);

  return (
    <div
      className="delivery-confirmation"
      data-testid={OrderCompleteModalTestIds.deliveryConfirmation}>
      <h2 className="time-estimate">
        Estimated delivery time: {orderReadyTime}
      </h2>
      <div className="delivery-address">
        <div>Delivering to:</div>
        <div className="customer-name">{`${firstName} ${lastName}`}</div>
        <div className="customer-address">
          {orderingOptions.deliveryAddress}
        </div>
        {orderingOptions.deliveryAddressLine2 && (
          <div className="customer-address">
            {orderingOptions.deliveryAddressLine2}
          </div>
        )}
      </div>
    </div>
  );
};
