import Aviator from "aviator";
import { equals, isEmpty, match } from "ramda";
import getEnv from "helpers/get_env";

function checkValidity(prettyUrl) {
  if (prettyUrl.match(/^[a-z0-9-]+$/)) {
    return prettyUrl;
  }
  console.error(`${prettyUrl} is not a valid pretty-url`);
  if (equals(getEnv(), "production")) {
    window.location.href = "http://upserve.com";
  }
  return undefined;
}

/**
 * @deprecated Please use `usePrettyUrl`
 */
function getPrettyUrl(request = Aviator.getCurrentRequest()) {
  const { prettyUrl } = request.namedParams;
  return checkValidity(prettyUrl);
}

function getBaseUri(request = Aviator.getCurrentRequest()) {
  const prettyUrl = getPrettyUrl(request);

  if (request.uri.startsWith("/b")) {
    return `/b/${prettyUrl}`;
  }

  return `/s/${prettyUrl}`;
}

/* eslint-disable camelcase */
function getPreviewToken(request = Aviator.getCurrentRequest()) {
  const { preview_token } = request.queryParams;
  return preview_token;
}

function getPreviewHeaderUrl(request = Aviator.getCurrentRequest()) {
  const { header_preview } = request.queryParams;
  if (header_preview) {
    const decodedHeaderLink = atob(header_preview);
    if (
      match(
        /^https:\/\/res(-\d)?.cloudinary.com\/upserve(-\w*)*\/image\/upload\/.*$/,
        decodedHeaderLink
      ).length >= 1
    ) {
      return decodedHeaderLink;
    }
  }
  return false;
}

function showDefaultHeaderImage(request = Aviator.getCurrentRequest()) {
  const { show_default_image } = request.queryParams;
  if (equals(show_default_image, "true")) {
    return true;
  }
  return false;
}

function getDineInParams(request = Aviator.getCurrentRequest()) {
  const { ft, table_id, table_name } = request.queryParams;
  // we shouldn't have to check these values:
  // `ft` is only used in an equality check
  // `table_id` just gets added to redux and then to the order endpoint payload
  // `table_name` is displayed in the UI but react dom prevents injection attacks: https://reactjs.org/docs/introducing-jsx.html#jsx-prevents-injection-attacks
  return { ft, tableId: table_id, tableName: table_name };
}
/* eslint-enable camelcase */

function getQueryString(request = Aviator.getCurrentRequest()) {
  return request.queryString;
}

function isValidDineInParams(ft, tableId, tableName) {
  return equals(ft, "dine_in") && !isEmpty(tableId) && !isEmpty(tableName);
}

function isDineInUri(request = Aviator.getCurrentRequest()) {
  const { ft, tableId, tableName } = getDineInParams(request);
  return isValidDineInParams(ft, tableId, tableName);
}

function dineInQueryString(request = Aviator.getCurrentRequest()) {
  const { ft, tableId, tableName } = getDineInParams(request);
  if (isValidDineInParams(ft, tableId, tableName)) {
    return `?ft=dine_in&table_id=${tableId}&table_name=${tableName}`;
  }
  return "";
}

export {
  dineInQueryString,
  getBaseUri,
  getDineInParams,
  getPrettyUrl,
  getPreviewHeaderUrl,
  getPreviewToken,
  getQueryString,
  isDineInUri,
  isValidDineInParams,
  showDefaultHeaderImage,
};
export default getBaseUri;
