import { camelizeKeys } from "humps";
import { equals, pipe } from "ramda";

import { LOYALTY_API_URL } from "api_urls";
import {
  checkStatus,
  getRequest,
  parseJSON,
  putRequest,
} from "helpers/ajax_request";
import getEnv from "helpers/get_env";
import formatAccountInfo from "helpers/transforms/loyalty/account_info_transform";

/* eslint-disable */
///////////////////
///////////////////////////////////////////////////////////////////////////
//////////
/* eslint-enable */

function getContactInfo() {
  if (equals(getEnv(), "development")) {
    return pipe(camelizeKeys, formatAccountInfo)(LOCAL_ACCOUNT_INFO);
  }

  return fetch(`${LOYALTY_API_URL}/user`, getRequest())
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys)
    .then(formatAccountInfo);
}

function putContactInfo(values, callback) {
  return fetch(`${LOYALTY_API_URL}/user`, putRequest(values))
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys)
    .then(formatAccountInfo)
    .then(callback);
}

function putPassword(newPassword, currentPassword, callback) {
  return fetch(
    `${LOYALTY_API_URL}/user/password`,
    putRequest({
      isNew: false,
      oldPassword: currentPassword,
      password: newPassword,
    })
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys)
    .then(callback);
}

function getAccountInfo() {
  return Promise.all([getContactInfo()]).then(([contactInfo]) => {
    return contactInfo;
  });
}

export default getAccountInfo;

export { putContactInfo, putPassword, getAccountInfo };
