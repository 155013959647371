const VALIDATION_MESSAGES = {
  address1: "Address is required",
  cardNumber: "Please enter a valid credit card number",
  city: "Please enter a valid city",
  confirmPassword: "Passwords do not match",
  currentPassword: "Please enter your current password",
  newPassword: "Passwords must be 6 or more characters",
  cvv: "Valid CVV is required",
  email: "Please enter a valid email",
  expiryDate: "Please enter a valid date",
  expiryMonth: "Please enter a valid month",
  expiryYear: "Please enter a valid year",
  firstName: "First name is required",
  gratuity: "Cannot be less than 0",
  isRequired: fieldName => `${fieldName} is required`,
  lastName: "Last name is required",
  phone: "Please enter a valid phone number",
  state: "Please enter a valid state",
  zip: "Please enter a valid zip code",
};

const ACCOUNT_CREATION_MESSAGES = {
  ...VALIDATION_MESSAGES,
  confirmPassword: "Passwords must be 6 or more characters",
};

export { ACCOUNT_CREATION_MESSAGES };
export default VALIDATION_MESSAGES;
