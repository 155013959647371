import { equals } from "ramda";
import React, { type ComponentType, useCallback } from "react";

import { useDispatch } from "react-redux";
import { OrderCompleteModalTestIds } from "./OrderCompleteModalTestIds";
import { DeliveryDetails } from "./transport_specific_details/DeliveryDetails";
import { PickupDetails } from "./transport_specific_details/PickupDetails";
import { updateOrderStatus } from "actions/online_ordering/checkout";
import Icon from "components/icon";
import Modal from "components/modal";
import {
  type MinifiedOrderInfo,
  useModalOrderInformation,
} from "hooks/online_ordering/payment/order_confirm_modal/useModalOrderInformation";
import type { SelectedTransportOptions } from "types/reducers/ordering_options";

function formatStoreAddress(address) {
  const { line1, line2, city, state } = address;

  if (equals(line2, "")) {
    return `${line1}, ${city} ${state}`;
  }

  return `${line1}, ${line2}, ${city} ${state}`;
}

export interface TransportDetailsProps {
  orderInformation: MinifiedOrderInfo;
}

const TransportSpecificDetailsMap: Record<
  SelectedTransportOptions,
  ComponentType<TransportDetailsProps> | null
> = {
  Delivery: DeliveryDetails,
  "Pick up": PickupDetails,
  "Dine in": null,
};

export const OrderCompleteModal = () => {
  const orderInfo = useModalOrderInformation();
  const { storeInfo, checkoutStatus, orderingOptions, contactInfo } = orderInfo;

  const dispatch = useDispatch();
  const handleCloseModal = useCallback(() => {
    dispatch(updateOrderStatus("pending"));
  }, [dispatch]);

  const DetailsComponent =
    TransportSpecificDetailsMap[orderingOptions.selectedTransport];

  return (
    <Modal className="order-confirm-modal" onClose={handleCloseModal}>
      <div
        className="modal-content"
        data-testid={OrderCompleteModalTestIds.modal}>
        <h2>Thank you for your order!</h2>
        <Icon className="check-icon" type="circle-check" />

        {DetailsComponent && <DetailsComponent orderInformation={orderInfo} />}

        <div className="store-details">
          <h5 className="store-name">{storeInfo.locationName}</h5>
          <div className="store-address">
            {formatStoreAddress(storeInfo.address)}
          </div>
          <div className="store-phone">{storeInfo.phoneNumber}</div>
        </div>
        <div className="email-message">
          We'll send an email confirmation to{" "}
          <span className="email">{contactInfo.email}</span> to let you know
          your order is in.
        </div>
        <div className="order-number">
          Your order number is {checkoutStatus.confirmationCode}
        </div>
      </div>
    </Modal>
  );
};
