import {
  deleteAddress,
  postAddress,
  putAddress,
} from "interfaces/consumer_info";

export const CREATE_NEW_ADDRESS =
  "online_ordering/addresses/CREATE_NEW_ADDRESS";
export const CANCEL_ADDRESS_EDITS =
  "online_ordering/addresses/CANCEL_ADDRESS_EDITS";
export const REMOVE_LOCAL_ADDRESS =
  "online_ordering/addresses/REMOVE_LOCAL_ADDRESS";
export const UPDATE_LOCAL_ADDRESS_UUID =
  "online_ordering/addresses/UPDATE_LOCAL_ADDRESS_UUID";
export const UPDATE_ADDRESS_FIELD =
  "online_ordering/addresses/UPDATE_ADDRESS_FIELD";
export const UPDATE_SAVED_ADDRESS_LOCALLY =
  "online_ordering/addresses/UPDATE_SAVED_ADDRESS_LOCALLY";
export const EDIT_EXISTING_ADDRESS =
  "online_ordering/addresses/EDIT_EXISTING_ADDRESS";
export const UPDATE_ADDRESS = "online_ordering/addresses/UPDATE_ADDRESS";

function createNewAddress() {
  return {
    type: CREATE_NEW_ADDRESS,
  };
}

function updateAddressField(uuid, field, value) {
  return {
    type: UPDATE_ADDRESS_FIELD,
    uuid,
    field,
    value,
  };
}

function updateAddress(uuid, address) {
  return {
    type: UPDATE_ADDRESS,
    uuid,
    address,
  };
}

function editExistingAddress(uuid) {
  return {
    type: EDIT_EXISTING_ADDRESS,
    uuid,
  };
}

function cancelAddressEdits(uuid) {
  return {
    type: CANCEL_ADDRESS_EDITS,
    uuid,
  };
}

function updateLocalAddressUuid(uuid) {
  return {
    type: UPDATE_LOCAL_ADDRESS_UUID,
    uuid,
  };
}

function removeLocalAddress(uuid) {
  return { type: REMOVE_LOCAL_ADDRESS, uuid };
}

function updateSavedAddressLocally(uuid) {
  return {
    type: UPDATE_SAVED_ADDRESS_LOCALLY,
    uuid,
  };
}

function saveNewAddress(address) {
  return dispatch => {
    postAddress(address).then(({ addressId }) =>
      dispatch(updateLocalAddressUuid(addressId))
    );
  };
}

function saveUpdatedAddress(address) {
  return dispatch => {
    putAddress(address).then(() =>
      dispatch(updateSavedAddressLocally(address.uuid))
    );
  };
}

function removeAddress(uuid) {
  return dispatch => {
    deleteAddress(uuid).then(() => dispatch(removeLocalAddress(uuid)));
  };
}

export {
  createNewAddress,
  updateAddressField,
  updateAddress,
  editExistingAddress,
  cancelAddressEdits,
  saveNewAddress,
  removeAddress,
  saveUpdatedAddress,
};
