import { curry, isNil, map, merge, propEq, reject } from "ramda";
import { Maybe } from "true-myth";
import { getById } from "helpers/collections";

const compact = reject(isNil);

function mergeModifiersWithModifierGroups(modifiers, modifierGroups) {
  return map(group => {
    return merge(group, {
      modifiers: compact(
        map(id => {
          return {
            ...getById(id, modifiers),
            modifierGroupId: group.id,
          };
        }, group.modifierIds)
      ),
    });
  }, modifierGroups);
}

const mergeDetailsWithItems = (richModifierGroups, sides, taxRates) => {
  return curry(
    map(item => {
      return merge(item, {
        modifierGroups: item.modifierGroupIds
          ? compact(
              map(
                id =>
                  getById(
                    id,
                    reject(propEq("maximumRequired", 0), richModifierGroups)
                  ),
                item.modifierGroupIds
              )
            )
          : [],
        sides:
          item.sideIds && item.maxSides > 0
            ? compact(map(id => getById(id, sides), item.sideIds))
            : [],
        price: item.priceCents,
        taxRate: getById(item.taxRateId, taxRates),
        stockLevel: Maybe.nothing(),
      });
    })
  );
};

const mergeTaxRatesWithMods = taxRates => {
  return curry(
    map(mod => {
      return merge(mod, {
        taxRate: getById(mod.taxRateId, taxRates),
        price: mod.priceCents,
      });
    })
  );
};

const mergeTaxRateWithSides = (sides, taxRates, items) => {
  return compact(
    map(side => {
      const item = getById(side.sideId, items);

      if (item) {
        return {
          ...side,
          taxRateId: item.taxRateId,
          taxRate: getById(item.taxRateId, taxRates),
          price: side.priceCents,
          stockLevel: Maybe.nothing(),
        };
      }
      return null;
    }, sides)
  );
};

function itemsBySection(assembledItems, sections) {
  return map(section => {
    return merge(section, {
      items: compact(map(id => getById(id, assembledItems), section.itemIds)),
    });
  }, sections);
}

function formatMenu(menu) {
  const {
    deliveryFeeItem,
    sections,
    items,
    modifiers,
    taxRates,
    modifierGroups,
    sides,
  } = menu;

  const richModifierGroups = mergeModifiersWithModifierGroups(
    mergeTaxRatesWithMods(taxRates)(modifiers),
    modifierGroups
  );

  const richSides = mergeTaxRateWithSides(sides, taxRates, items);

  const assembledItems = mergeDetailsWithItems(
    richModifierGroups,
    richSides,
    taxRates
  )(items);

  return {
    menu: itemsBySection(assembledItems, sections),
    deliveryTaxRate: deliveryFeeItem.taxRateId
      ? getById(deliveryFeeItem.taxRateId, taxRates).percentageRate
      : "0",
  };
}

export { formatMenu };
