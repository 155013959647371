import {
  all,
  append,
  equals,
  filter,
  gt,
  gte,
  head,
  map,
  min,
  pipe,
  reduce,
} from "ramda";
import { Maybe } from "true-myth";

function allMinimumsMet(item) {
  // DB does not currently prevent setting minimums that are higher
  // than the number of options available, so we need to check for the
  // min of the two values.
  const safeMinSides = min(item.sides.length, item.minSides);

  return all(equals(true))(
    append(
      gte(item.selectedSides.length, safeMinSides), // Sides meet minimum requirement
      map(
        group => gte(group.mods.length, group.safeMin),
        item.selectedModifiers
      )
    )
  );
}

function scrollToMissingRequirements(scrollElement) {
  const nodesMissingRequired = scrollElement.getElementsByClassName("required");

  // HTMLCollection is not an array so isEmpty will not work here.
  if (nodesMissingRequired && gt(nodesMissingRequired.length, 0)) {
    const scrollingElement = scrollElement;
    scrollingElement.scrollTop =
      head(nodesMissingRequired).offsetTop -
      scrollElement.childNodes[0].offsetTop;
  }
}

function getMaxAllowedQty(item) {
  return pipe(
    map(i => i.stockLevel),
    filter(Maybe.isJust),
    map(s => s.value),
    reduce(min, Infinity)
  )([item].concat(item.selectedSides));
}

export { allMinimumsMet, scrollToMissingRequirements, getMaxAllowedQty };
