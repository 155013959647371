import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export const MobilePayParameters = [
  "checkId",
  "deviceId",
  "checkNumber",
  "serverId",
  "serverName",
  "checkType",
  "checkName",
  "netTotal",
  "subtotalBeforeComp",
  "checkCompTotal",
  "total",
  "mandatoryTip",
  "mandatoryTipAmount",
  "tax",
  "balanceDue",
  "h",
] as const;

export type MobilePayParameterNames = (typeof MobilePayParameters)[number];

export const useMobilePayParameters = () => {
  const [searchParams] = useSearchParams();
  return useMemo(() => {
    return new URLSearchParams(
      [...searchParams.entries()].filter(([key]) => {
        return (MobilePayParameters as readonly string[]).includes(key);
      })
    );
  }, [searchParams]);
};
