import { connect } from "react-redux";

import {
  cancelPasswordFormEdits,
  showPasswordForm,
  updatePassword,
  updatePasswordField,
} from "actions/account";

import PasswordForm from "components/account/password_form";

function mapStateToProps(state) {
  return {
    password: state.password,
  };
}

const mapDispatchToProps = {
  updatePassword,
  updatePasswordField,
  showPasswordForm,
  cancelPasswordFormEdits,
};

const PasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordForm);

export default PasswordContainer;
