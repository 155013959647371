/* eslint no-use-before-define: ["error", { "functions": false }] */
import { contains, head } from "ramda";

import { updateDeliveryAddress } from "actions/online_ordering/ordering_options";
import {
  NEW_EMPTY_ADDRESS,
  formatAddressForDropdown,
  getAddressFromGooglePlace,
  getAddressValidation,
  validateLocationIsWithinZone,
} from "helpers/address";
import trackAnalytic from "helpers/analytics";

import {
  coordinatesToNearestAddress,
  fetchUserAddressViaMobileGeolocation,
} from "interfaces/delivery_address";
import { selectDeliveryGeojson } from "selectors";

export const SET_USER_GEOLOCATED_ADDRESS =
  "online_ordering/delivery/UPDATE_USER_GEOLOCATION";
export const SET_GEOLOCATION_FETCH_ERROR =
  "online_ordering/delivery/SET_GEOLOCATION_FETCH_ERROR";
export const UPDATE_GEOLOCATION_INFLIGHT_STATUS =
  "online_ordering/delivery/UPDATE_GEOLOCATION_INFLIGHT_STATUS";
export const UPDATE_DELIVERY_ADDRESS_VALUE =
  "online_ordering/delivery/UPDATE_DELIVERY_ADDRESS_VALUE";
export const SET_ADDRESS_VALIDATION_STATUS =
  "online_ordering/delivery/SET_ADDRESS_VALIDATION_STATUS";
export const RESET_DELIVERY_ADDRESS =
  "online_ordering/delivery/RESET_DELIVERY_ADDRESS";

function updateGeolocationInflightStatus(inFlight = true) {
  return {
    type: UPDATE_GEOLOCATION_INFLIGHT_STATUS,
    inFlight,
  };
}

function setAddressValidationStatus(isValidAddress) {
  return {
    type: SET_ADDRESS_VALIDATION_STATUS,
    addressValidationStatus: getAddressValidation(isValidAddress),
  };
}

function validateAndUpdateAddress(address) {
  return (dispatch, getState) => {
    dispatch(updateAddressValue(address));

    const deliveryGeojson = selectDeliveryGeojson(getState());
    validateLocationIsWithinZone(deliveryGeojson, address)
      .then(({ isValidLocation }) => {
        dispatch(setAddressValidationStatus(isValidLocation));
        dispatch(updateDeliveryAddress(address, isValidLocation));
      })
      .catch(({ isValidLocation }) => {
        dispatch(setAddressValidationStatus(isValidLocation));
        dispatch(updateDeliveryAddress(address, isValidLocation));
      });
    trackAnalytic("Enter Address", address);
  };
}

function updateAddressSelection(selectedAddress) {
  return dispatch => {
    dispatch(handleDropDownChange(selectedAddress));
  };
}

function updateAddressValue(address) {
  return { type: UPDATE_DELIVERY_ADDRESS_VALUE, address };
}

function handleDropDownChange(selectedAddress) {
  return dispatch => {
    if (
      contains(selectedAddress.pretty, [
        "Select an address",
        "+ Add a new address",
      ])
    ) {
      dispatch(updateAddressValue(selectedAddress));
      dispatch(updateDeliveryAddress(NEW_EMPTY_ADDRESS, false));
      dispatch(setAddressValidationStatus(null));
    } else {
      dispatch(validateAndUpdateAddress(selectedAddress));
    }
  };
}

function setUserGeolocatedAddress(response) {
  const addressFromResponse = formatAddressForDropdown(
    getAddressFromGooglePlace(head(response))
  );
  return dispatch => {
    dispatch(updateGeolocationInflightStatus(false));
    dispatch({ type: SET_USER_GEOLOCATED_ADDRESS, addressFromResponse });
    dispatch(updateAddressSelection(addressFromResponse));
  };
}

function setGeolocationFetchError(error) {
  return dispatch => {
    dispatch(updateGeolocationInflightStatus(false));
    dispatch({ type: SET_GEOLOCATION_FETCH_ERROR, error });
  };
}

function getAddressForCoordinates(coordinates) {
  return dispatch => {
    coordinatesToNearestAddress(coordinates)
      .then(response => dispatch(setUserGeolocatedAddress(response)))
      .catch(error => dispatch(setGeolocationFetchError(error)));
  };
}

function getUserGeolocation() {
  return dispatch => {
    dispatch(updateGeolocationInflightStatus());
    fetchUserAddressViaMobileGeolocation()
      .then(coordinates => dispatch(getAddressForCoordinates(coordinates)))
      .catch(error => dispatch(setGeolocationFetchError(error)));
  };
}

function handleDeliveryAddressChange(address) {
  /*
    This is in place in order to prepare the address
    to be correctly validated by google api.
  */
  const preValidationAddress = {
    ...address,
    line2: "",
    city: "",
    state: "",
    zip: "",
  };

  return dispatch => {
    dispatch(updateDeliveryAddress(preValidationAddress, false));
    dispatch(setAddressValidationStatus(null));
  };
}

function resetDeliveryAddress() {
  return {
    type: RESET_DELIVERY_ADDRESS,
  };
}

export {
  getAddressForCoordinates,
  getUserGeolocation,
  handleDeliveryAddressChange,
  resetDeliveryAddress,
  setAddressValidationStatus,
  setGeolocationFetchError,
  updateAddressSelection,
  validateAndUpdateAddress,
};
