import styled, { keyframes } from "styled-components";

const SpinnerIcon = require("images/icons/spinner-solid.svg");

const spin = keyframes`
  from { transform:rotate(0deg); }
  to { transform:rotate(360deg); }
`;

const LoaderSmall = styled.div`
  animation: ${spin} 2s ease-in-out infinite;
  background-image: url(${SpinnerIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  height: 30px;
  padding: 5px;
  width: 30px;
`;

export default LoaderSmall;
