import { combineReducers } from "redux";

import { password } from "reducers/account";
import authentication from "reducers/authentication";
import consumer from "reducers/online_ordering/consumer";

const oloAccountReducers = combineReducers({
  storeInfo: (state = {}) => state,
  consumer,
  authentication,
  password,
});

export default oloAccountReducers;
