import { ORDERS_URL } from "api_urls";
import type { OloConsumerSettings } from "components/online_ordering/objects/OloConsumerSettings";
import { checkStatus, putRequest } from "helpers/ajax_request";

export const oloUpdateConsumerSettings = async (
  storePrettyUrl: string,
  consumerSettings: Partial<OloConsumerSettings>
) => {
  const response = await fetch(
    `${ORDERS_URL}/${storePrettyUrl}/consumer_settings`,
    putRequest({ consumerSettings })
  );
  checkStatus(response);
};
