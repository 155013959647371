/* eslint-disable react/no-unused-state */
import React, { type ReactNode } from "react";
import { TrackJS } from "trackjs";

import ErrorMessage from "components/error_message";

import { isTracking as envIsTracking } from "helpers/analytics";
import HasError, { type IHasErrorContext } from "helpers/context/has_error";

interface IErrorWrapperProps {
  children: ReactNode;
}

// It is each child component's job to consume HasError context
// and respond to errors in its own way.
// This allows Loyalty vs OLO vs any other view to handle errors
// independently.
interface IErrorWrapperState extends IHasErrorContext {
  catchCount: number;
  isTracking: boolean;
}

// Number of times we should trust `props.children` to render something.
// Each time componentDidCatch, increment catchCount in state.
// After reaching this many errors, remove children from the tree and
// instead render a generic error.
const RETRY_THRESHOLD = 3;

class ErrorBoundary extends React.Component<
  IErrorWrapperProps,
  IErrorWrapperState
> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      catchCount: 0,
      isTracking: envIsTracking(),
    };
  }

  componentDidCatch(error) {
    TrackJS.track(error);
    this.setState(({ catchCount }) => ({
      catchCount: catchCount + 1,
      error,
      hasError: true,
    }));
  }

  render() {
    const { children } = this.props;
    const { hasError, error, catchCount } = this.state;

    if (catchCount >= RETRY_THRESHOLD) {
      return (
        <div className="app">
          <ErrorMessage />
        </div>
      );
    }
    return (
      <HasError.Provider value={{ hasError, error }}>
        {children}
      </HasError.Provider>
    );
  }
}

export default ErrorBoundary;
