import { type UseQueryOptions, useQuery } from "react-query";
import type { AdyenPaymentAccessResponse } from "interfaces/credit_card/adyen/AdyenPaymentMethodsResponse";
import { getAdyenDigitalGiftCardPaymentAccess } from "interfaces/digital_gift_card/payment/adyen/getAdyenDigitalGiftCardPaymentAccess";

export const useAdyenDigitalGiftCardPaymentAccess = <TData = boolean>(
  prettyUrl: string,
  options?: UseQueryOptions<
    AdyenPaymentAccessResponse,
    unknown,
    TData,
    ["adyenDigitalGiftCardPaymentAccess", string]
  >
) => {
  return useQuery(
    ["adyenDigitalGiftCardPaymentAccess", prettyUrl],
    () => {
      return getAdyenDigitalGiftCardPaymentAccess(prettyUrl);
    },
    {
      select: ({ enabled }) => enabled as TData,
      ...options,
    }
  );
};
