import { equals } from "ramda";

import { LOG_OUT } from "actions/authentication";
import {
  CANCEL_ADDRESS_EDITS,
  CREATE_NEW_ADDRESS,
  EDIT_EXISTING_ADDRESS,
  REMOVE_LOCAL_ADDRESS,
  UPDATE_ADDRESS,
  UPDATE_ADDRESS_FIELD,
  UPDATE_LOCAL_ADDRESS_UUID,
  UPDATE_SAVED_ADDRESS_LOCALLY,
} from "actions/online_ordering/addresses";
import { UPDATE_CONSUMER_INFO } from "actions/online_ordering/consumer";
import {
  NEW_EMPTY_ADDRESS,
  findAddressByUuid,
  removeAddressByUuid,
} from "helpers/address";

const initialState = {
  existing: [],
  editable: [],
};

function addresses(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CONSUMER_INFO: {
      if (action.consumerInfo.addresses) {
        return {
          ...state,
          existing: action.replace
            ? action.consumerInfo.addresses
            : [...state.existing, ...action.consumerInfo.addresses],
        };
      }
      return state;
    }

    case CREATE_NEW_ADDRESS: {
      return {
        ...state,
        editable: [...state.editable, NEW_EMPTY_ADDRESS],
      };
    }

    case UPDATE_ADDRESS_FIELD: {
      let existingCopy = findAddressByUuid(action.uuid, state.editable);
      // In order to support a single line input we need to temporarily set city, state, and
      // zip to empty strings and store the entirety of the address in line1, on selecting
      // an option from the google autocomplete dropdown the address is parsed and set.
      if (equals(action.field, "line1") && existingCopy) {
        existingCopy = { ...existingCopy, city: "", state: "", zip: "" };
      }

      if (existingCopy) {
        return {
          ...state,
          editable: [
            ...removeAddressByUuid(action.uuid, state.editable),
            {
              ...existingCopy,
              [action.field]: action.value,
            },
          ],
        };
      }

      let currentAddress = findAddressByUuid(action.uuid, state.existing);

      if (equals(action.field, "line1")) {
        currentAddress = { ...currentAddress, city: "", state: "", zip: "" };
      }
      return {
        ...state,
        editable: [
          ...state.editable,
          {
            ...currentAddress,
            [action.field]: action.value,
          },
        ],
      };
    }

    case UPDATE_ADDRESS: {
      const editableAddress = findAddressByUuid(action.uuid, state.editable);

      return {
        ...state,
        editable: [
          ...removeAddressByUuid(action.uuid, state.editable),
          {
            ...editableAddress,
            ...action.address,
          },
        ],
      };
    }

    case EDIT_EXISTING_ADDRESS: {
      const editableCopy = findAddressByUuid(action.uuid, state.existing);
      return {
        ...state,
        editable: [...state.editable, editableCopy],
      };
    }

    case UPDATE_LOCAL_ADDRESS_UUID: {
      const newAddress = findAddressByUuid("new-address", state.editable);
      const newAddressWithUuid = { ...newAddress, uuid: action.uuid };

      return {
        ...state,
        editable: removeAddressByUuid("new-address", state.editable),
        existing: [...state.existing, newAddressWithUuid],
      };
    }

    case UPDATE_SAVED_ADDRESS_LOCALLY: {
      const newAddress = findAddressByUuid(action.uuid, state.editable);

      return {
        ...state,
        editable: removeAddressByUuid(action.uuid, state.editable),
        existing: [
          ...removeAddressByUuid(action.uuid, state.existing),
          newAddress,
        ],
      };
    }

    case REMOVE_LOCAL_ADDRESS: {
      return {
        ...state,
        existing: removeAddressByUuid(action.uuid, state.existing),
      };
    }

    case CANCEL_ADDRESS_EDITS: {
      return {
        ...state,
        editable: removeAddressByUuid(action.uuid, state.editable),
      };
    }

    case LOG_OUT: {
      return [];
    }

    default:
      return state;
  }
}

export default addresses;
