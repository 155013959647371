import React from "react";
import styled from "styled-components";

import Icon from "components/icon";
import { StyledErrorHeader } from "components/shared/errors/StyledErrorHeader";
import { StyledErrorText } from "components/shared/errors/StyledErrorText";

export const StyledWrapper = styled.div`
  width: 50%;
  margin: auto;

  padding: 50px 35px;

  text-align: center;

  > .icon-wrapper svg.receipt-ghost {
    width: 100px;
    height: 96px;
  }
`;

export default function ErrorPage() {
  return (
    <StyledWrapper>
      <Icon type="receipt-ghost" />
      <StyledErrorHeader>Sorry!</StyledErrorHeader>
      <StyledErrorText>
        Digital gift card purchase is not currently available at this venue. For
        more information, please reach out to the venue directly.
      </StyledErrorText>
    </StyledWrapper>
  );
}
