import PropTypes from "prop-types";
import { equals, flip, isEmpty, map, objOf, partial, path, pipe } from "ramda";
import React, { Component } from "react";

import Icon from "components/icon";
import Picker from "components/picker";
import {
  formatAddressForDropdown,
  initGoogleAutocomplete,
  isValidAddressStatus,
  validateLocationIsWithinZone,
} from "helpers/address";
import { getTargetValue } from "helpers/event";

const addressMessage = {
  NOT_STARTED: "",
  VALID: "Good news! You're within the delivery range.",
  INVALID: "Sorry about this. We don't deliver to your address.",
};

class DeliveryAddressEditor extends Component {
  componentDidMount() {
    const {
      contactInfo,
      deliveryAddress: { value },
      deliveryGeojson,
      handleDeliveryAddressChange,
      setAddressValidationStatus,
      updateAddressSelection,
    } = this.props;
    const { address } = contactInfo;

    if (address && address.zip && !equals(value.pretty, "Select an address")) {
      setAddressValidationStatus(
        validateLocationIsWithinZone(deliveryGeojson, address)
      );
    }

    const input = this.addressInput;
    const onAddressSelected = ({ address: selectedAddress }) => {
      const completedPlace = { ...selectedAddress, line2: "" };

      updateAddressSelection(
        formatAddressForDropdown(completedPlace),
        deliveryGeojson
      );
    };
    initGoogleAutocomplete({ input, onAddressSelected });

    if (!isEmpty(address.line1)) {
      handleDeliveryAddressChange(formatAddressForDropdown(address));
    }
  }

  render() {
    const {
      contactInfo,
      deliveryAddress,
      deliveryGeojson,
      handleDeliveryAddressChange,
      handleMobileGeolocationAddressEntry,
      updateAddressSelection,
      updateDeliveryAddress,
    } = this.props;

    const {
      addressValidationStatus,
      value,
      geolocationFetchError,
      geolocationInFlight,
    } = deliveryAddress;

    const { line1, line2, city, state, zip } = contactInfo.address;
    let newAddressInputAreaClass = "hidden"; // need to keep it on the screen all the time for the google plugin to find it
    let addressLine1Value = line1;

    let addressNag;
    if (line1 && city && state && zip) {
      // fully formed address
      addressLine1Value = `${line1}, ${city} ${state} ${zip}`;
    }

    const newAddressInputArea = (
      <input
        key="addressInputLine"
        className={addressValidationStatus.toLowerCase()}
        ref={input => {
          this.addressInput = input;
        }}
        type="text"
        placeholder="Add your address"
        value={addressLine1Value}
        onChange={pipe(
          getTargetValue,
          objOf("line1"),
          handleDeliveryAddressChange
        )}
      />
    );

    let secondLineAddress;
    if (isValidAddressStatus(addressValidationStatus)) {
      secondLineAddress = (
        <input
          key="addressInputLine2"
          type="text"
          placeholder="Apt., suite, floor, etc"
          value={line2}
          onChange={pipe(
            getTargetValue,
            objOf("line2"),
            partial(flip(updateDeliveryAddress), [true])
          )}
        />
      );
    }

    let dropdown;
    if (contactInfo && contactInfo.addresses.length > 0) {
      const formattedOptions = [
        { pretty: "Select an address" },
        ...map(formatAddressForDropdown, contactInfo.addresses),
        { pretty: "+ Add a new address" },
      ];

      dropdown = (
        <div>
          <h5>Deliver to</h5>
          <Picker
            currentOption={value}
            onOptionClick={partial(flip(updateAddressSelection), [
              deliveryGeojson,
            ])}
            options={formattedOptions}
            formatter={path(["pretty"])}
          />
        </div>
      );

      if (equals("+ Add a new address", value.pretty)) {
        newAddressInputAreaClass = "block";
      }
    } else {
      newAddressInputAreaClass = "block";
    }

    if (
      isEmpty(line1) ||
      (contactInfo.addresses.length > 0 &&
        equals(value.pretty, "Select an address"))
    ) {
      addressNag = (
        <div key="address-nag" className="address-nag">
          Please add your address to see if we deliver to you!
        </div>
      );
    }

    let geolocationFetchErrorNotice;
    if (geolocationFetchError) {
      geolocationFetchErrorNotice = (
        <div className="status-message invalid">
          There was an error fetching your address via geolocation.
        </div>
      );
    }

    let geolocationInFlightNotice;
    if (geolocationInFlight) {
      geolocationInFlightNotice = "Fetching geolocation...";
    }

    return (
      <div className="delivery-address-container">
        {dropdown}
        <div className={newAddressInputAreaClass}>
          <div
            className="geolocation-container"
            onClick={() => {
              handleMobileGeolocationAddressEntry(deliveryGeojson);
            }}>
            <Icon type="location-arrow" className="geolocation" />
          </div>
          {newAddressInputArea}
          {secondLineAddress}
        </div>
        {addressNag}
        {geolocationInFlightNotice}
        {geolocationFetchErrorNotice}
        <div
          className={`status-message ${addressValidationStatus.toLowerCase()}`}>
          {addressMessage[addressValidationStatus]}
        </div>
      </div>
    );
  }
}

DeliveryAddressEditor.propTypes = {
  addressValidationStatus: PropTypes.string,
  contactInfo: PropTypes.object,
  deliveryAddress: PropTypes.object.isRequired,
  deliveryGeojson: PropTypes.string,
  value: PropTypes.object,
  handleDeliveryAddressChange: PropTypes.func,
  handleMobileGeolocationAddressEntry: PropTypes.func,
  setAddressValidationStatus: PropTypes.func,
  updateAddressSelection: PropTypes.func,
  updateDeliveryAddress: PropTypes.func,
};

export default DeliveryAddressEditor;
