import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { LoyaltyAccountCreditCardForm } from "./LoyaltyAccountCreditCardForm";
import { removeCreditCard, saveNewCreditCard } from "actions/credit_cards";
import { CreditCardList } from "components/account/CreditCardList";
import { Loader } from "components/loader";
import { useLoyaltyCreditCards } from "hooks/loyalty/useLoyaltyCreditCards";
import storeInfo from "reducers/online_ordering/store_info";
import type { OloAccountCreditCardFormValues } from "types/online_ordering/accounts/OloAccountCreditCardFormValues";

export const LoyaltyCreditCardList = () => {
  const { data: creditCards } = useLoyaltyCreditCards();
  const dispatch = useDispatch();

  const handleRemoveCreditCard = useCallback(
    (card: unknown) => {
      return dispatch(removeCreditCard(card));
    },
    [dispatch]
  );

  const handleSaveNewCreditCard = useCallback(
    (card: OloAccountCreditCardFormValues) => {
      return dispatch(saveNewCreditCard(card));
    },
    [dispatch]
  );

  if (!storeInfo || !creditCards) {
    return <Loader />;
  }

  return (
    <div className="account-section">
      <CreditCardList
        appName="Loyalty"
        creditCardList={creditCards}
        onRemoveCreditCard={handleRemoveCreditCard}>
        {({ onCloseForm }) => (
          <LoyaltyAccountCreditCardForm
            onCloseForm={onCloseForm}
            // @ts-expect-error ts error that takes too much time
            onUpdateCard={handleSaveNewCreditCard}
          />
        )}
      </CreditCardList>
    </div>
  );
};
