import React from "react";

import ConfirmationPage, {
  StyledConfirmationHeader,
  StyledConfirmationText,
  StyledConfirmationTextSpaced,
  StyledDetailWrapper,
} from "components/mobile_pay/views/confirmation_page";

import { StyledBoldText } from "components/mobile_pay/views/page";

import type {
  CheckInfo,
  MerchantInfo,
  ProcessedPaymentInfo,
} from "types/mobile_pay";

type PaymentSuccessPageProps = {
  merchantInfo: MerchantInfo;
  checkInfo: CheckInfo;
  processedPaymentInfo: ProcessedPaymentInfo;
};

export default function PaymentSuccessPage({
  processedPaymentInfo,
  checkInfo,
  merchantInfo,
}: PaymentSuccessPageProps) {
  return (
    <ConfirmationPage merchantInfo={merchantInfo}>
      <StyledConfirmationHeader>Thank you!</StyledConfirmationHeader>
      <StyledConfirmationText>
        We let the restaurant know, so you can leave when you’re ready.
      </StyledConfirmationText>

      <StyledDetailWrapper>
        <StyledConfirmationTextSpaced>
          Total:{" "}
          <StyledBoldText>
            {processedPaymentInfo.payment.amount
              .add(processedPaymentInfo.payment.tipAmount)
              .format()}
          </StyledBoldText>
        </StyledConfirmationTextSpaced>
        <StyledConfirmationTextSpaced>
          Payment Method:{" "}
          <StyledBoldText style={{ whiteSpace: "nowrap" }}>
            {processedPaymentInfo.payment.tenderName}
          </StyledBoldText>
        </StyledConfirmationTextSpaced>
        <StyledConfirmationTextSpaced>
          Check #: <StyledBoldText>{checkInfo.checkNumber}</StyledBoldText>
        </StyledConfirmationTextSpaced>
      </StyledDetailWrapper>

      <StyledConfirmationText>
        {`We’ll send an email receipt to ${processedPaymentInfo.email}`}
      </StyledConfirmationText>
    </ConfirmationPage>
  );
}
