import React from "react";
import styled from "styled-components";

import Icon from "components/icon";

const StyledHeaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin: 0 8px 12px 10px;

  // Use flex-end here and line-height on the product monkier to simulate
  // baseline alignment that sticks to any length wrapped merchant name.
  align-items: flex-end;
`;

const StyledMerchantName = styled.div`
  flex-grow: 1;
  font-size: 32px;
  font-weight: bold;
`;

const StyledProductMoniker = styled.div`
  font-size: 16px;
  line-height: 25px;
  white-space: nowrap;
`;

const StyledIconWrapper = styled.span`
  margin-right: 6px;

  > .icon-wrapper svg.lock {
    margin-bottom: -3px;
  }
`;

type HeaderProps = {
  merchantName: string;
};

export default function Header({ merchantName }: HeaderProps) {
  return (
    <StyledHeaderWrapper>
      <StyledMerchantName>{merchantName}</StyledMerchantName>
      <StyledProductMoniker>
        <StyledIconWrapper>
          <Icon type="lock" />
        </StyledIconWrapper>
        Mobile Check Pay
      </StyledProductMoniker>
    </StyledHeaderWrapper>
  );
}
