import classNames from "classnames";
import PropTypes from "prop-types";
import { equals, map } from "ramda";
import React from "react";

function Toggle({ options, selectedOption, handleChange }) {
  const renderedOptions = map(option => {
    const classes = classNames({
      "toggle-option": true,
      selected: equals(option, selectedOption),
    });

    return (
      <a
        href=""
        className={classes}
        key={option}
        onClick={event => {
          // It prevents the page getting refreshed cause there is a href=""
          event.preventDefault();
          handleChange(option);
        }}>
        {option}
      </a>
    );
  }, options);

  return <div className="toggle">{renderedOptions}</div>;
}

Toggle.propTypes = {
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Toggle;
