import { combineReducers } from "redux";

import {
  CANCEL_PASSWORD_FORM_EDITS,
  RETURN_PASSWORD_FORM_ERRORS,
  SHOW_PASSWORD_FORM,
  UPDATE_PASSWORD_FIELD,
  UPDATE_PASSWORD_RESPONSE,
} from "actions/account";
import { LOG_OUT } from "actions/authentication";
import authentication from "reducers/authentication";
import consumer from "reducers/online_ordering/consumer";

const initialState = {
  password: {
    isEditing: false,
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    errors: null,
  },
};

function password(state = initialState.password, action) {
  switch (action.type) {
    case UPDATE_PASSWORD_RESPONSE:
      if (action.response.error) {
        return {
          ...state.errors,
          currentPassword: action.response.error,
        };
      }
      return {
        ...state,
        isEditing: false,
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      };

    case UPDATE_PASSWORD_FIELD: {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case SHOW_PASSWORD_FORM: {
      return {
        ...state,
        isEditing: true,
      };
    }

    case CANCEL_PASSWORD_FORM_EDITS: {
      return {
        ...state,
        isEditing: false,
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        errors: null,
      };
    }

    case RETURN_PASSWORD_FORM_ERRORS: {
      return {
        ...state,
        errors: action.errors,
      };
    }

    case LOG_OUT: {
      return initialState.password;
    }

    default:
      return state;
  }
}

const reducers = combineReducers({
  brandInfo: (state = {}) => state,
  consumer,
  authentication,
  password,
});

export default reducers;

export { password };
