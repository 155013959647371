import cn from "classnames";
import { filter } from "ramda";
import React from "react";

import ItemModalContainer from "containers/online_ordering/item_modal_container";

import { formatMoneyFromCents } from "helpers/money";
import {
  generateMainUrl,
  generateThumbnailUrl,
} from "helpers/online_ordering/image_url";
import type { IItem, ISide } from "types/menu";

interface IMenuItemProps {
  item: IItem;
  onAddItem: (item: IItem) => void;
  createEditableItem: (item: IItem) => void;
  onDeleteEditableItem: () => void;
}

interface IMenuItemState {
  showModal: boolean;
}

class MenuItem extends React.Component<IMenuItemProps, IMenuItemState> {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };

    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
  }

  handleShowModal() {
    const { createEditableItem, item } = this.props;

    createEditableItem(item);
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    const { onDeleteEditableItem } = this.props;
    this.setState({ showModal: false });
    onDeleteEditableItem();
  }

  handleAddItem(item) {
    const { onAddItem } = this.props;
    onAddItem(item);
    this.handleCloseModal();
  }

  render() {
    const { item } = this.props;
    const { name, description, price, images, stockLevel, minSides } = item;
    const { showModal } = this.state;
    let thumbUrl;
    let mainUrl;

    if (images && images.onlineOrderingMenu && images.onlineOrderingMenu.main) {
      thumbUrl = generateThumbnailUrl(images.onlineOrderingMenu.main);
      mainUrl = generateMainUrl(images.onlineOrderingMenu.main);
    }

    let modal;
    if (showModal) {
      modal = (
        <ItemModalContainer
          onAddItem={this.handleAddItem}
          onClose={this.handleCloseModal}
          imageUrl={mainUrl}
        />
      );
    }

    let image;
    if (thumbUrl) {
      image = (
        <img className="thumbnail" src={thumbUrl} alt={`${name} thumbnail`} />
      );
    }

    const numInStockSides = filter((side: ISide) => {
      return side.stockLevel.match({
        Just: stock => {
          return stock > 0;
        },
        Nothing: () => true,
      });
    })(item.sides).length;

    const hasEnoughRequireSides =
      minSides > 0 ? numInStockSides >= minSides : true;

    const isOutOfStock = () => {
      const topLevelItemInStock = stockLevel.match({
        Just: stock => {
          return stock > 0;
        },
        Nothing: () => true,
      });
      return !topLevelItemInStock || !hasEnoughRequireSides;
    };

    return (
      <div
        className={cn("menu-item", { "out-of-stock": isOutOfStock() })}
        onClick={isOutOfStock() ? undefined : this.handleShowModal}>
        <div className="content-box">
          <div className="name">{name}</div>
          <div className="description">{description}</div>
          <div className="price">
            {isOutOfStock() ? "Out of Stock" : formatMoneyFromCents(price)}
          </div>
        </div>
        {image}
        {modal}
      </div>
    );
  }
}

export default MenuItem;
