import type { StoreInfo } from "types/reducers/store_info";

export const UPDATE_STORE_INFO = "store_info/UPDATE_STORE_INFO";

type updateStoreInfoAction = {
  type: typeof UPDATE_STORE_INFO;
  storeInfo: StoreInfo;
};

export type StoreInfoActionTypes = updateStoreInfoAction;

export function updateStoreInfo(storeInfo: StoreInfo) {
  return {
    type: UPDATE_STORE_INFO,
    storeInfo,
  };
}
