import { Money } from "@upserve/financials";
import { equals, map } from "ramda";
import { Maybe } from "true-myth";

import type { AdditionalPaymentData } from "components/mobile_pay/payment/CCProcessorConfigurationMap";
import { toNullable } from "helpers/maybe";

import type {
  CheckInfo,
  CheckInfoApiResponse,
  MerchantInfo,
  MerchantInfoApiResponse,
  MobilePayCreditCardInfo,
  MobilePayRedirect,
  MobilePayment,
  MobilePaymentApiResponse,
  PaymentForProcessing,
  PaymentForProcessingApiResponse,
  ProcessedPaymentInfo,
} from "types/mobile_pay";

export function transformCheckInfoApiResponse(
  apiResponse: CheckInfoApiResponse
): CheckInfo {
  const payments = map((payment: MobilePaymentApiResponse): MobilePayment => {
    return {
      id: payment.id,
      amount: Money.fromDollars(payment.amount),
      tipAmount: Money.fromDollars(payment.tipAmount),
      tenderName: payment.tenderName,
      cardType: Maybe.of(payment.cardType),
      lastFourDigits: Maybe.of(payment.lastFourDigits),
    };
  }, apiResponse.payments);
  return {
    balanceDue: Money(apiResponse.balanceDue),
    checkCompTotal: apiResponse.checkCompTotal
      ? Money(apiResponse.checkCompTotal)
      : Money(0),
    checkId: apiResponse.checkId,
    checkName: apiResponse.checkName,
    checkNumber: apiResponse.checkNumber,
    checkType: apiResponse.checkType,
    createdAt: apiResponse.createdAt,
    deviceId: Maybe.of(apiResponse.deviceId),
    mandatoryTip: Maybe.of(apiResponse.mandatoryTip),
    mandatoryTipAmount: apiResponse.mandatoryTipAmount
      ? Money(apiResponse.mandatoryTipAmount)
      : Money(0),
    netTotal: Money(apiResponse.netTotal),
    payments,
    serverId: Maybe.of(apiResponse.serverId),
    serverName: apiResponse.serverName,
    subtotalBeforeComp: Money(apiResponse.subtotalBeforeComp),
    tax: Money(apiResponse.tax),
    total: Money(apiResponse.total),
  };
}

export function getNothingFromEmptyStringOrNull(
  input: Maybe<string>
): Maybe<string> {
  return Maybe.of(
    input.match({
      Just: value => (equals("", value) ? null : value),
      Nothing: () => null,
    })
  );
}

export function transformMerchantInfoApiResponse(
  apiResponse: MerchantInfoApiResponse | MobilePayRedirect
): MerchantInfo | MobilePayRedirect {
  let transformedResponse;

  if ("redirectPrettyUrl" in apiResponse) {
    transformedResponse = {
      redirectPrettyUrl: apiResponse.redirectPrettyUrl,
    };
  } else {
    transformedResponse = {
      ugMerchantKey: apiResponse.ugMerchantKey,
      prettyUrl: apiResponse.prettyUrl,
      name: apiResponse.name,
      streetLine1: apiResponse.streetLine1,
      streetLine2: getNothingFromEmptyStringOrNull(
        Maybe.of<string>(apiResponse.streetLine2)
      ),
      locality: apiResponse.locality,
      region: apiResponse.region,
      postalCode: apiResponse.postalCode,
      phone: apiResponse.phone,
      suggestedTipPercentLow:
        typeof apiResponse.suggestedTipPercentLow === "string"
          ? parseFloat(apiResponse.suggestedTipPercentLow)
          : apiResponse.suggestedTipPercentLow,
      suggestedTipPercentMiddle:
        typeof apiResponse.suggestedTipPercentMiddle === "string"
          ? parseFloat(apiResponse.suggestedTipPercentMiddle)
          : apiResponse.suggestedTipPercentMiddle,
      suggestedTipPercentHigh:
        typeof apiResponse.suggestedTipPercentHigh === "string"
          ? parseFloat(apiResponse.suggestedTipPercentHigh)
          : apiResponse.suggestedTipPercentHigh,
      mobilePaymentsStatus: apiResponse.mobilePaymentsStatus,
      customTipsEnabled: apiResponse.customTipsEnabled,
      suggestedTipDefault: apiResponse.suggestedTipDefault,
      showSuggestedTips: apiResponse.showSuggestedTips,
    };
  }

  return transformedResponse;
}

export function transformCardForProcessing(
  checkInfo: CheckInfo,
  total: Money,
  balanceDue: Money,
  cardInfo: MobilePayCreditCardInfo,
  tip: Money,
  submissionId: string,
  additionalPaymentData: AdditionalPaymentData | void
): PaymentForProcessing {
  return {
    checkId: checkInfo.checkId,
    email: cardInfo.email,
    phone: cardInfo.phone,
    total: total.valueOf(),
    balanceDue: balanceDue.valueOf(),
    submissionId,
    payment: {
      amount: total.add(tip).valueOf(),
      deviceId: toNullable(checkInfo.deviceId),
      serverId: toNullable(checkInfo.serverId),
      tipAmount: tip.valueOf(),
      ...additionalPaymentData,
    },
  };
}

export function transformCreditCardPaymentApiResponse<
  T extends ProcessedPaymentInfo = ProcessedPaymentInfo,
>(apiResponse: PaymentForProcessingApiResponse): T {
  return {
    ...apiResponse,
    total: Money(apiResponse.total),
    balanceDue: Money(apiResponse.balanceDue),
    email: apiResponse.email,
    payment: {
      id: apiResponse.payment.id, // UUID
      amount: Money(apiResponse.payment.amount), // Total charged to the card in cents (tip + balance due)
      tipAmount: Money(apiResponse.payment.tipAmount), // Tip amount in cents

      tenderName: "Credit Card", // todo: when we support other types, un-hardcode this
      cardType: Maybe.nothing<string>(),
      lastFourDigits: Maybe.nothing<string>(),
    },
  } as T;
}
