import Aviator from "aviator";
import { endsWith, startsWith } from "ramda";

import isOLO from "helpers/is_olo";

function isLoyalty() {
  const current = Aviator.getCurrentRequest();

  return current
    ? current.uri.indexOf("/b/") !== -1
    : startsWith("/b/", window.location.pathname);
}

function isMobilePay() {
  return endsWith("/pay", window.location.pathname);
}

function isGiftCard() {
  return endsWith("/gift-card", window.location.pathname);
}

export default function getApp() {
  if (isMobilePay()) {
    return "pay";
  }
  if (isGiftCard()) {
    return "giftCard";
  }
  if (isOLO()) {
    return "olo";
  }
  if (isLoyalty()) {
    return "loyalty";
  }

  return "unknown";
}
