import MemoryStorage from "memorystorage";

let storage;

try {
  storage = window.localStorage;
} catch (e) {
  storage = new MemoryStorage("olo");
}

export default storage;
