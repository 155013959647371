import trackAnalytic from "helpers/analytics";

import {
  newCardValidators,
  validateBlurredField,
} from "helpers/validation/forms";
import VALIDATION_MESSAGES from "helpers/validation/messages";
import {
  deleteCreditCard,
  postCreditCard,
  postTokenRequestForNewCard,
} from "interfaces/credit_cards";
import type { INewCreditCard } from "types/reducers/payment";

export const DELETE_LOCAL_CARD_RECORD = "credit_cards/DELETE_LOCAL_CARD_RECORD";
export const RECEIVE_CREDIT_CARD = "credit_cards/RECEIVE_CREDIT_CARD";
export const VALIDATE_CC_FIELD_ON_BLUR =
  "credit_cards/VALIDATE_CC_FIELD_ON_BLUR";
export const SET_CREDIT_CARD_ERROR_MESSAGES =
  "credit_cards/SET_CREDIT_CARD_ERROR_MESSAGES";

function validateCCFieldOnBlur(field: string, card: INewCreditCard) {
  const error = validateBlurredField(
    field,
    card,
    newCardValidators,
    VALIDATION_MESSAGES
  );

  return {
    type: VALIDATE_CC_FIELD_ON_BLUR,
    error,
    field,
    card,
  };
}

function receiveCreditCard(card) {
  return {
    type: RECEIVE_CREDIT_CARD,
    card,
  };
}

function deleteLocalCardRecord(cardUuid) {
  return {
    type: DELETE_LOCAL_CARD_RECORD,
    cardUuid,
  };
}

function saveNewCreditCardWithToken(card, tokenResponse) {
  return async dispatch => {
    // BE is currently ONLY expecting the unencryptedPan and token
    // will expand to include further details in next version

    const cardWithToken = {
      cardEnrollmentToken: tokenResponse.token,
      unencryptedPan: card.cardNumber,
    };

    try {
      await postCreditCard(cardWithToken, response => {
        dispatch(receiveCreditCard(response));
      });
    } catch (errorResp) {
      // 409 CONFLICT
      throw new Error(
        "Sorry, that card is already enrolled. Did you add it to a different account?"
      );
    }
  };
}

function saveNewCreditCard(card) {
  return async dispatch => {
    // LOYALTY SAVE ONLY, OLO USES IFRAME
    trackAnalytic("Account Page: Add CC");

    // Only stored data for loyalty cards at the moment
    await postTokenRequestForNewCard(response => {
      return dispatch(saveNewCreditCardWithToken(card, response));
    });
  };
}

function removeCreditCard(card) {
  return dispatch => {
    deleteCreditCard(card, () => {
      // On Success: We receive []
      dispatch(deleteLocalCardRecord(card.cardUuid));
    });
  };
}

export function setCreditCardValidationMessages(error) {
  return {
    type: SET_CREDIT_CARD_ERROR_MESSAGES,
    error,
  };
}

export {
  removeCreditCard,
  saveNewCreditCard,
  saveNewCreditCardWithToken,
  validateCCFieldOnBlur,
};
