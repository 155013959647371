import PropTypes from "prop-types";
import { isEmpty, isNil, map } from "ramda";
import React from "react";

import formatMoney from "sig/formatters/money";

import Icon from "components/icon";
import ItemModalContainer from "containers/online_ordering/item_modal_container";
import { trackAnalytic, withTracking } from "helpers/analytics";
import { getItemSubtotal } from "helpers/online_ordering/item_totals";

class CartItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };

    this.showEditModal = this.showEditModal.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.updateItem = this.updateItem.bind(this);
  }

  showEditModal() {
    const { createEditableItem, item } = this.props;

    createEditableItem(item);
    this.setState({ showModal: true });
  }

  closeEditModal() {
    const { onDeleteEditableItem } = this.props;

    onDeleteEditableItem();
    this.setState({ showModal: false });
  }

  updateItem(updatedItem) {
    const { onUpdateItem } = this.props;
    this.closeEditModal();
    onUpdateItem(updatedItem);
  }

  render() {
    const { item, onRemoveItem } = this.props;
    const { showModal } = this.state;
    const { name, quantity, instructions } = item;

    let modal;
    if (showModal) {
      modal = (
        <ItemModalContainer
          onAddItem={this.updateItem}
          onClose={this.closeEditModal}
        />
      );
    }

    let renderedInstructions;
    if (!isNil(instructions) && !isEmpty(instructions)) {
      renderedInstructions = (
        <div className="instructions">
          <span className="semi-bold">Instructions:</span> {instructions}
        </div>
      );
    }

    const modifiers = map(modGroup => {
      return map(mod => {
        return (
          <div key={mod.id} className="modifier">
            {mod.name}
          </div>
        );
      }, modGroup.mods);
    }, item.selectedModifiers);

    const sides = map(side => {
      return (
        <div key={side.id} className="side">
          {side.name}
        </div>
      );
    }, item.selectedSides);

    return (
      <div
        className="cart-item"
        onClick={(...e) => {
          trackAnalytic("Update Item (Via Tab)", item);
          this.showEditModal(...e);
        }}>
        <div className="quantity">{quantity}</div>
        <div className="details">
          <div className="name">{name}</div>
          <div className="price">
            {formatMoney(getItemSubtotal(item) * 0.01)}
          </div>
          <div className="add-ons">
            <div key="mods">{modifiers}</div>
            <div key="sides">{sides}</div>
          </div>
          {renderedInstructions}
        </div>
        <a
          className="remove-button"
          onClick={withTracking(
            ev => {
              ev.stopPropagation();
              onRemoveItem();
            },
            "Remove Item",
            item
          )}>
          <Icon type="x" />
        </a>
        {modal}
      </div>
    );
  }
}

CartItem.propTypes = {
  item: PropTypes.object.isRequired,
  onUpdateItem: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  createEditableItem: PropTypes.func.isRequired,
  onDeleteEditableItem: PropTypes.func.isRequired,
};

export default CartItem;
