import { connect } from "react-redux";

import { reorder } from "actions/online_ordering/ordering_options";
import OrderHistory from "components/account/order_history";

function mapStateToProps(state) {
  return {
    orderHistory: state.consumer.orders,
  };
}

const mapDispatchToProps = {
  reorder,
};

const OrderHistoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderHistory);

export default OrderHistoryContainer;
