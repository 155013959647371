import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";

import Icon from "components/icon";

function Checkbox({
  label,
  checked,
  onChangeHandler,
  onBlurHandler,
  disabled,
}) {
  return (
    <div key={label} className="checkbox-option">
      <input
        type="checkbox"
        id={label}
        checked={checked}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        disabled={disabled}
      />
      <label htmlFor={label} className={cn({ disabled })}>
        {label}
        <Icon className="checkmark" type="check" />
      </label>
    </div>
  );
}

Checkbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChangeHandler: PropTypes.func,
  onBlurHandler: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Checkbox;
