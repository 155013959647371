import PropTypes from "prop-types";
import { equals, find, pluck, propEq } from "ramda";
import React from "react";

import formatMoney from "sig/formatters/money";

import Toggle from "components/toggle";
import DeliveryAddressEditorContainer from "containers/online_ordering/cart/delivery_address_editor_container";
import { trackAnalytic } from "helpers/analytics";
import { getAvailableFulfillmentTypes } from "helpers/online_ordering/fulfillment_types";

function costFormatter(cost) {
  if (equals(cost, 0)) return "None";
  return formatMoney(cost * 0.01, { precision: 2 });
}

function EditTransportOption({
  cartIsEmpty,
  orderingOptions,
  selectedOptionName,
  storeInfo,
  onChangeTransportMethod,
}) {
  const { fulfillmentTypes, deliveryFee, deliveryMinimumOrder } = storeInfo;
  const displayableFulfillmentTypes =
    getAvailableFulfillmentTypes(fulfillmentTypes);
  const methodDetails = find(
    propEq("name", selectedOptionName),
    displayableFulfillmentTypes
  );

  let minimumText;
  let deliveryFeeMessage;
  if (equals(selectedOptionName, "Delivery")) {
    minimumText = (
      <div>
        Minimum delivery order
        <span className="value">{costFormatter(deliveryMinimumOrder)}</span>
      </div>
    );
    deliveryFeeMessage = (
      <div>
        Delivery fee <span className="value">{costFormatter(deliveryFee)}</span>
      </div>
    );
  }

  let addressEditor;
  if (
    equals(selectedOptionName, "Delivery") &&
    orderingOptions.isEditable &&
    !cartIsEmpty
  ) {
    addressEditor = <DeliveryAddressEditorContainer />;
  }

  return (
    <div className="order-option method">
      <h5>How do you want to get your food?</h5>
      <Toggle
        options={pluck("name", displayableFulfillmentTypes)}
        selectedOption={selectedOptionName}
        handleChange={(...opts) => {
          trackAnalytic(`Select ${opts[0]}`);
          return onChangeTransportMethod(...opts);
        }}
      />
      <div className="details">
        <div className="costs">
          {minimumText}
          {deliveryFeeMessage}
        </div>
        <div className="estimate">
          {methodDetails.estimateCopy}: {methodDetails.estimate.join(" - ")}m
        </div>
        <div>{addressEditor}</div>
      </div>
    </div>
  );
}

EditTransportOption.propTypes = {
  cartIsEmpty: PropTypes.bool.isRequired,
  orderingOptions: PropTypes.object.isRequired,
  selectedOptionName: PropTypes.string.isRequired,
  storeInfo: PropTypes.object.isRequired,
  consumerInfo: PropTypes.object,
  onChangeTransportMethod: PropTypes.func.isRequired,
  onUpdateDeliveryAddress: PropTypes.func.isRequired,
};

export default EditTransportOption;
