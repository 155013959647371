import { type UseQueryOptions, useQuery } from "react-query";
import { BASE_URL } from "api_urls";
import type { IResponseError } from "helpers/ajax_request";
import { ENV } from "helpers/get_env";
import { sleep } from "helpers/sleep";
import { getOLOStoreInfo } from "interfaces/olo_store_info";
import type { StoreInfo, StoreInfoRedirect } from "types/reducers/store_info";

export const oloStoreInfoQueryKey = (storePrettyUrl: string) =>
  ["storeInfo", storePrettyUrl] as const;

export const useOloStoreInfo = (
  storePrettyUrl: string,
  options?: UseQueryOptions<
    StoreInfo | StoreInfoRedirect,
    IResponseError,
    StoreInfo,
    readonly ["storeInfo", string]
  >
) => {
  return useQuery(
    oloStoreInfoQueryKey(storePrettyUrl),
    async () => {
      const storeInfo = await getOLOStoreInfo(storePrettyUrl);
      if (storeInfo.redirectPrettyUrl) {
        window.location.replace(`${BASE_URL}/s/${storeInfo.redirectPrettyUrl}`);
        await sleep(30000);
        throw new Error("Redirect is requested but not executed after 30s.");
      }
      return storeInfo;
    },
    {
      onError: () => {
        if (ENV === "production") {
          window.location.href = "https://upserve.com";
        }
      },
      ...options,
    }
  );
};
