import { useMutation, useQueryClient } from "react-query";
import { oloConsumerInfoQueryKey } from "hooks/online_ordering/useOloConsumerInfo";
import { oloDeleteCreditCard } from "interfaces/online_ordering/accounts/oloDeleteCreditCard";

export const useOloDeleteCreditCard = (storePrettyUrl: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (cardUuid: string) => {
      return oloDeleteCreditCard(storePrettyUrl, cardUuid);
    },
    {
      onSuccess: async () => {
        return queryClient.invalidateQueries(
          oloConsumerInfoQueryKey(storePrettyUrl)
        );
      },
    }
  );
};
