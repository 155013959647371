import { connect } from "react-redux";

import Loyalty from "components/loyalty";

function mapStateToProps({ brandInfo, loyalty }) {
  return {
    brandInfo,
    loyalty,
  };
}

const LoyaltyContainer = connect(mapStateToProps)(Loyalty);

export default LoyaltyContainer;
