import { ENV, type Environment } from "./helpers/get_env";

declare const S3_BUCKET: unknown;
declare const ENV_PORT: unknown;

const S3_DEV_BUCKET_HOST =
  typeof S3_BUCKET !== "undefined" && S3_BUCKET === "localstack"
    ? "http://localhost:4566/consumer-platform-public-development"
    : "https://d25m9qxb4mfiyi.cloudfront.net"; // N.B. CLOUDFRONT STAGING
const UI_PORT = typeof ENV_PORT !== "undefined" ? ENV_PORT : "8080";

interface Config {
  BASE_URL: string;
  BRAND_BUCKET: string;
  CARD_API_URL: string;
  GOOGLE_RECAPTCHA_SITE_KEY: string;
  LAUNCH_DARKLY_CLIENT_ID: string;
  LOYALTY_API_URL: string;
  MOBILE_PAYMENTS_URL: string;
  ORDERS_URL: string;
  STATUS_URL: string;
  PASSWORDS_URL: string;
  PAYMENTS_URL: string;
  STORE_BUCKET: string;
  YOUR_REWARDS_API_URL: string;
}

const CONFIG: Record<Environment, Config> = {
  development: {
    BASE_URL: `http://localhost:${UI_PORT}`,
    BRAND_BUCKET: `${S3_DEV_BUCKET_HOST}/b`,
    CARD_API_URL: "",
    GOOGLE_RECAPTCHA_SITE_KEY: "6LcG1qwbAAAAAGwaCYEmvXzI-sJJBSN6fnVptZoF",
    LAUNCH_DARKLY_CLIENT_ID: "56932174559981069b000013",
    LOYALTY_API_URL: "http://localhost:3000/c",
    MOBILE_PAYMENTS_URL: "http://localhost:3000",
    ORDERS_URL: "http://localhost:3000",
    STATUS_URL: "http://localhost:3000",
    PASSWORDS_URL: "http://localhost:3000/passwords",
    PAYMENTS_URL: "https://payments.staging.upserve.com/v1/payment_methods",
    STORE_BUCKET: `${S3_DEV_BUCKET_HOST}/s`,
    YOUR_REWARDS_API_URL: "http://localhost:3000/your_rewards",
  },

  development_proxy: {
    BASE_URL: `http://localhost:${UI_PORT}`,
    BRAND_BUCKET: "https://d25m9qxb4mfiyi.cloudfront.net/b",
    CARD_API_URL: `http://localhost:${UI_PORT}/cards-api`,
    GOOGLE_RECAPTCHA_SITE_KEY: "6LeFM74bAAAAAAowqwX5hgXUB2hGSAlF55REaS96",
    LAUNCH_DARKLY_CLIENT_ID: "56b3645f581f3c06d8000004",
    LOYALTY_API_URL: `http://localhost:${UI_PORT}/app-api/c`,
    MOBILE_PAYMENTS_URL: `http://localhost:${UI_PORT}/orders-api`,
    ORDERS_URL: `http://localhost:${UI_PORT}/orders-api`,
    STATUS_URL: `http://localhost:${UI_PORT}/orders-api`,
    PASSWORDS_URL: `http://localhost:${UI_PORT}/orders-api/passwords`,
    PAYMENTS_URL: `http://localhost:${UI_PORT}/ubergateway/v1/payment_methods`,
    STORE_BUCKET: "https://d25m9qxb4mfiyi.cloudfront.net/s",
    YOUR_REWARDS_API_URL: `http://localhost:${UI_PORT}/app-api/your_rewards`,
  },

  staging: {
    BASE_URL: "https://app.staging.upserve.com",
    BRAND_BUCKET: "https://d25m9qxb4mfiyi.cloudfront.net/b",
    CARD_API_URL: "https://cards.staging.upserve.com",
    GOOGLE_RECAPTCHA_SITE_KEY: "6LeFM74bAAAAAAowqwX5hgXUB2hGSAlF55REaS96",
    LAUNCH_DARKLY_CLIENT_ID: "56b3645f581f3c06d8000004",
    LOYALTY_API_URL: "https://app.staging.upserve.com/c",
    MOBILE_PAYMENTS_URL: "https://orders.staging.upserve.com",
    ORDERS_URL: "https://orders.staging.upserve.com",
    STATUS_URL: "https://orders.staging.upserve.com",
    PASSWORDS_URL: "https://app.staging.upserve.com/passwords",
    PAYMENTS_URL: "https://payments.staging.upserve.com/v1/payment_methods",
    STORE_BUCKET: "https://d25m9qxb4mfiyi.cloudfront.net/s",
    YOUR_REWARDS_API_URL: "https://app.staging.upserve.com/your_rewards",
  },

  production: {
    BASE_URL: "https://app.upserve.com",
    BRAND_BUCKET: "https://d2evh2mef3r450.cloudfront.net/b",
    CARD_API_URL: "https://cards.swipely.com",
    GOOGLE_RECAPTCHA_SITE_KEY: "6Lc0U70bAAAAAOYnYhRnLUeE-8s-J7AeG-D0K7J3",
    LAUNCH_DARKLY_CLIENT_ID: "56932174559981069b000014",
    LOYALTY_API_URL: "https://app.upserve.com/c",
    MOBILE_PAYMENTS_URL: "https://orders.upserve.com",
    ORDERS_URL: "https://orders.upserve.com",
    STATUS_URL: "https://orders.upserve.com",
    PASSWORDS_URL: "https://app.upserve.com/passwords",
    PAYMENTS_URL: "https://payments.upserve.com/v1/payment_methods",
    STORE_BUCKET: "https://d2evh2mef3r450.cloudfront.net/s",
    YOUR_REWARDS_API_URL: "https://app.upserve.com/your_rewards",
  },
};

const {
  BASE_URL,
  BRAND_BUCKET,
  CARD_API_URL,
  GOOGLE_RECAPTCHA_SITE_KEY,
  LAUNCH_DARKLY_CLIENT_ID,
  LOYALTY_API_URL,
  MOBILE_PAYMENTS_URL,
  ORDERS_URL,
  STATUS_URL,
  PASSWORDS_URL,
  PAYMENTS_URL,
  STORE_BUCKET,
  YOUR_REWARDS_API_URL,
} = CONFIG[ENV];

export {
  BASE_URL,
  BRAND_BUCKET,
  CARD_API_URL,
  GOOGLE_RECAPTCHA_SITE_KEY,
  LAUNCH_DARKLY_CLIENT_ID,
  LOYALTY_API_URL,
  MOBILE_PAYMENTS_URL,
  ORDERS_URL,
  STATUS_URL,
  PASSWORDS_URL,
  PAYMENTS_URL,
  STORE_BUCKET,
  YOUR_REWARDS_API_URL,
};
