import moment from "moment";

import {
  contains,
  curry,
  equals,
  forEach,
  gt,
  gte,
  hasIn,
  is,
  isEmpty,
  isNil,
  keys,
  lt,
  lte,
  match,
  not,
  reduce,
  reverse,
  slice,
} from "ramda";

const emailRegex = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
const phoneRegex =
  /^(\+?\d{1,2})?[.-\s]?\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/;
const zipRegex = /^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/;

function isValidLuhn(cardNumber) {
  let sum = 0;
  let evenDigit = false;

  forEach(cardDigit => {
    let digitValue = parseInt(cardDigit, 10);

    if (evenDigit) {
      if ((digitValue *= 2) > 9) digitValue -= 9;
    }

    sum += digitValue;
    evenDigit = !evenDigit;
  }, reverse(cardNumber));

  return sum % 10 === 0;
}

function isValidLength(cardNumber) {
  if (cardNumber.startsWith("3")) {
    return cardNumber.length === 15;
  }
  if (cardNumber.startsWith("4")) {
    return cardNumber.length >= 13 && cardNumber.length <= 16;
  }
  if (cardNumber.startsWith("5")) {
    return cardNumber.length === 16;
  }
  if (cardNumber.startsWith("6")) {
    return cardNumber.length === 16;
  }

  return false;
}

const Validators = {
  isValid(data, validators, errors) {
    return reduce(
      (memo, key) => {
        const result = validators[key](data[key], data);

        if (errors && !result) errors.push(key);

        return memo && result;
      },
      true,
      keys(validators)
    );
  },

  isValidEmail(email) {
    return emailRegex.test(email.trim());
  },

  isValidPhoneNumber(phone) {
    return phoneRegex.test(phone.trim());
  },

  isNANP(phoneNumber) {
    const [ccc, npa, nxx, xxxx] = slice(1, 5, match(phoneRegex, phoneNumber));
    // Phone number format: ccc (country code) - npa (3 digit area code) - nxx (3 digit prefix) - xxxx (remaining 4 digits)
    // US country code only
    if (!isNil(ccc) && !contains(ccc, ["+1", "1"])) return false;
    // Area codes reserved for future use
    if (!isNil(npa) && contains(npa.substr(0, 2), ["37", "96"])) return false;
    // Service numbers (ie. 911)
    if (!isNil(nxx) && equals(nxx.substr(1, 2), "11")) return false;
    // Numbers too similar to 911
    const similarTo911 = ["912", "913", "914", "915", "916"];
    // Misc. reserved numbers
    const reservedNumbers = ["700", "950", "958", "959"];
    if (contains(nxx, [...similarTo911, ...reservedNumbers])) return false;
    // Reserved "fake" numbers
    const xxxxInt = parseInt(xxxx, 10);
    if (equals(nxx, "555") && gte(xxxxInt, 100) && lt(xxxxInt, 200))
      return false;
    return true;
  },

  validatePhoneNANP(contact) {
    const { phone, textAlerts } = contact;
    if (Validators.isNANP(phone)) {
      return {
        field: "phone",
        error: null,
        allowSave: true,
        regex: "$2-$3-$4",
      };
    }

    if (textAlerts) {
      return {
        field: "phone",
        error: {
          phone:
            "Sorry, SMS messaging is only available for numbers in North America. Please uncheck text updates or use a different phone number to continue.",
        },
        allowSave: false,
      };
    }

    return {
      field: "phone",
      error: {
        phone:
          "Your number appears to be outside the U.S., SMS messaging will not be available.",
      },
      allowSave: true,
      regex: "$1-$2-$3-$4",
    };
  },

  isValidExpirationYear(year) {
    return gte(year, moment().get("year"));
  },

  isValidZipCode(zipCode) {
    return zipRegex.test(zipCode);
  },

  isValidCvv(val, data) {
    const cvv = val.trim();
    if (hasIn("lowercaseType", data)) {
      if (equals(data.lowercaseType, "amex")) {
        return /^[0-9]{3,4}$/.test(cvv);
      }
      return /^[0-9]{3}$/.test(cvv);
    }
    if (isEmpty(data.cardNumber)) return /^[0-9]{3,4}$/.test(cvv);
    if (/^3/.test(data.cardNumber)) return /^[0-9]{3,4}$/.test(cvv);
    return /^[0-9]{3}$/.test(cvv);
  },

  // Luhn algorithm
  isValidCardNumber(cardNumber) {
    if (/[^0-9-\s]+/.test(cardNumber)) return false;
    const cardNumberValue = cardNumber.replace(/\D/g, "");

    return isValidLength(cardNumberValue) && isValidLuhn(cardNumberValue);
  },

  minimumLength: curry((length, value) => {
    return is(String, value) && value.trim().length >= length;
  }),

  isNotEmpty(value) {
    return not(equals(value.trim(), ""));
  },

  isValidMonth(month) {
    return gt(month, 0) && lte(month, 12);
  },
};

export { phoneRegex };
export default Validators;
