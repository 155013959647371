import { camelizeKeys } from "humps";
import { ORDERS_URL } from "api_urls";
import type { OloConsumerSettings } from "components/online_ordering/objects/OloConsumerSettings";
import { checkStatus, getRequest, parseJSON } from "helpers/ajax_request";

export const oloGetConsumerSettings = async (
  storePrettyUrl: string
): Promise<OloConsumerSettings> => {
  const response = await fetch(
    `${ORDERS_URL}/${storePrettyUrl}/consumer_settings`,
    getRequest()
  );
  checkStatus(response);
  const json = await parseJSON(response);
  return camelizeKeys(json);
};
