import PropTypes from "prop-types";
import { find, propEq } from "ramda";
import React from "react";

import LoyaltyImage from "components/loyalty/loyalty_image";

function Confirmation({ brandInfo, loyalty, email }) {
  const welcomeReward = find(propEq("isFpo", true), loyalty.rewards);
  const pointReward = find(propEq("isFpo", false), loyalty.rewards);
  const reward = welcomeReward || pointReward;

  return (
    <div className="signup-confirmation reward">
      <LoyaltyImage type="loyalty" imagePath={reward.coverPhoto} />
      <div className="reward-content">
        <h2>You're almost there!</h2>

        <div className="description">
          Check your email at <span className="highlight">{email}</span>. Click
          the confirmation link and begin earning rewards at{" "}
          {brandInfo.locationName}.
        </div>
      </div>
    </div>
  );
}

Confirmation.propTypes = {
  email: PropTypes.string.isRequired,
  brandInfo: PropTypes.object.isRequired,
  loyalty: PropTypes.shape({
    progression: PropTypes.shape({
      pointsAccrued: PropTypes.number,
      fpoRedeemed: PropTypes.bool,
      pointRewardLoaded: PropTypes.bool,
    }),
  }),
};

export default Confirmation;
