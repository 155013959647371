import PropTypes from "prop-types";
import { map } from "ramda";
import React from "react";

import Address from "components/account/addresses/address";
import EditAddress from "components/account/addresses/edit_address";
import Button from "components/button";
import { NEW_EMPTY_ADDRESS, findAddressByUuid } from "helpers/address";
import { withTracking } from "helpers/analytics";

function Addresses({
  addresses,
  editableAddresses,
  onCreateNewAddress,
  onUpdateAddressField,
  onUpdateAddress,
  onSaveUpdatedAddress,
  onEditExistingAddress,
  onCancelAddressEdits,
  onSaveNewAddress,
  onRemoveAddress,
}) {
  const addressList = map(
    address => (
      <Address
        key={address.uuid}
        address={address}
        editableAddress={findAddressByUuid(address.uuid, editableAddresses)}
        onEditExistingAddress={onEditExistingAddress}
        onSaveUpdatedAddress={onSaveUpdatedAddress}
        onUpdateAddressField={onUpdateAddressField}
        onCancelAddressEdits={onCancelAddressEdits}
        onRemoveAddress={onRemoveAddress}
        onUpdateAddress={onUpdateAddress}
      />
    ),
    addresses
  );

  let newAddressForm;
  const newAddress = findAddressByUuid("new-address", editableAddresses);

  if (newAddress) {
    newAddressForm = (
      <EditAddress
        existingAddress={NEW_EMPTY_ADDRESS}
        editableAddress={newAddress}
        onCancel={onCancelAddressEdits}
        onUpdateAddressField={onUpdateAddressField}
        onUpdateAddress={onUpdateAddress}
        onSaveAddress={onSaveNewAddress}
      />
    );
  } else {
    newAddressForm = (
      <Button
        type="text"
        onClick={withTracking(
          onCreateNewAddress,
          "OLO - Account - click add new address"
        )}>
        + Add new address
      </Button>
    );
  }
  // If an address with id 'new-address', exists at all we know that the form
  // must be shown.

  return (
    <div className="account-section address-list-wrapper">
      <h3>Address information</h3>
      <ul className="address-list">{addressList}</ul>
      {newAddressForm}
    </div>
  );
}

Addresses.propTypes = {
  addresses: PropTypes.array.isRequired,
  editableAddresses: PropTypes.array.isRequired,
  onCreateNewAddress: PropTypes.func.isRequired,
  onCancelAddressEdits: PropTypes.func.isRequired,
  onUpdateAddressField: PropTypes.func.isRequired,
  onUpdateAddress: PropTypes.func.isRequired,
  onSaveNewAddress: PropTypes.func.isRequired,
  onSaveUpdatedAddress: PropTypes.func.isRequired,
  onEditExistingAddress: PropTypes.func.isRequired,
  onRemoveAddress: PropTypes.func.isRequired,
};

export default Addresses;
