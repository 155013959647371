import PropTypes from "prop-types";
import { find, isEmpty, isNil, propEq } from "ramda";
import React from "react";

import Loader from "components/loader";
import LocationInfo from "components/location_info";
// import MiniLoyalty from 'components/loyalty/mini_loyalty';
import PointReward from "components/loyalty/point_reward";
import WelcomeReward from "components/loyalty/welcome_reward";
import SignUpContainer from "containers/loyalty/sign_up_container";
import isLoggedIn from "is_logged_in";

function Loyalty({ brandInfo, loyalty }) {
  // TODO when implementing online ordering we'll need to rethink this logic
  // Loyalty is brand level so we don't have and individual store's info
  // if (storeInfo.hasOrdering) return <MiniLoyalty loyalty={loyalty} />;

  if (isNil(loyalty) || isEmpty(loyalty)) return <Loader />;
  if (isEmpty(loyalty.rewards)) return <span />;

  const fpoReward = find(propEq("isFpo", true), loyalty.rewards);

  let renderedReward;
  if (!isLoggedIn()) {
    renderedReward = <SignUpContainer />;
  } else if (fpoReward && !loyalty.progression.fpoRedeemed) {
    const pointReward = find(propEq("isFpo", false), loyalty.rewards);
    renderedReward = (
      <WelcomeReward
        reward={fpoReward}
        progression={loyalty.progression}
        pointReward={pointReward}
      />
    );
  } else {
    const reward = find(propEq("isFpo", false), loyalty.rewards);
    renderedReward = (
      <PointReward reward={reward} progression={loyalty.progression} />
    );
  }

  return (
    <div className="loyalty">
      <div className="rewards card">{renderedReward}</div>
      <LocationInfo info={brandInfo} />
    </div>
  );
}

Loyalty.propTypes = {
  brandInfo: PropTypes.object.isRequired,
  loyalty: PropTypes.shape({
    progression: PropTypes.shape({
      pointsAccrued: PropTypes.number,
      fpoRedeemed: PropTypes.bool,
      pointRewardLoaded: PropTypes.bool,
    }),
  }),
};

export default Loyalty;
