import PropTypes from "prop-types";
import { map, path } from "ramda";
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Icon from "components/icon";
import MenuItem from "components/online_ordering/menu/menu_item";
import { withTracking } from "helpers/analytics";
import applyStockLevels from "helpers/transforms/online_ordering/stock_level_transform";

const collapsePath = path(["settings", "collapseGroupsByDefault"]);

class MenuSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: !collapsePath(props),
    };

    this.handleExpandToggle = this.handleExpandToggle.bind(this);
  }

  handleExpandToggle() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  render() {
    const {
      section,
      createEditableItem,
      onAddItem,
      onDeleteEditableItem,
      stockLevels,
      cartItems,
    } = this.props;
    const { expanded } = this.state;
    const { items, name } = section;

    let iconType = "chevron-right";
    let renderedMenuItems;
    if (expanded) {
      iconType = "chevron-down";
      renderedMenuItems = (
        <CSSTransition
          classNames="expanding"
          timeout={{ enter: 300, exit: 300 }}>
          <div className="menu-items">
            {map(item => {
              const itemWithStockLevels = applyStockLevels(
                item,
                cartItems || [],
                stockLevels || {}
              );
              return (
                <MenuItem
                  key={itemWithStockLevels.name}
                  item={itemWithStockLevels}
                  onAddItem={onAddItem}
                  onDeleteEditableItem={onDeleteEditableItem}
                  createEditableItem={createEditableItem}
                />
              );
            }, items)}
          </div>
        </CSSTransition>
      );
    }

    return (
      <div className="menu-section">
        <h2
          className="name"
          onClick={withTracking(
            this.handleExpandToggle,
            expanded
              ? "OLO - Menu pg - collapse menu category"
              : "OLO - Menu pg - expand menu category"
          )}>
          <Icon type={iconType} key={iconType} />
          {name}
        </h2>

        <TransitionGroup>{renderedMenuItems}</TransitionGroup>
      </div>
    );
  }
}

MenuSection.propTypes = {
  section: PropTypes.object,
  onAddItem: PropTypes.func.isRequired,
  createEditableItem: PropTypes.func.isRequired,
  onDeleteEditableItem: PropTypes.func.isRequired,
  settings: PropTypes.object,
  stockLevels: PropTypes.object,
  cartItems: PropTypes.array,
};

export default MenuSection;
