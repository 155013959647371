import { createContext } from "react";

export interface IHasErrorContext {
  hasError: boolean;
  error?: any;
}

const HasError = createContext<IHasErrorContext>({ hasError: false });

export default HasError;
