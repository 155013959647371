import auth0 from "auth0-js";
import Aviator from "aviator";
import JwtDecode from "jwt-decode";
import { equals, merge } from "ramda";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import { OLOAccountApp } from "./components/online_ordering/account/OLOAccountApp";
import { OloApp } from "./components/online_ordering/OloApp";
import { updateLoyaltyInfo } from "actions";
import {
  captureAuthParams,
  logOut,
  showSetPasswordModal,
  toggleModal,
} from "actions/authentication";
import { updateConsumerInfo } from "actions/online_ordering/consumer";

import Account from "components/account";
import App from "components/app";
import { DigitalGiftCardApp } from "components/digital_gift_cards/DigitalGiftCardApp";
import { DigitalGiftCardPreviewApp } from "components/digital_gift_cards/DigitalGiftCardPreviewApp";
import Landing from "components/landing";
import { MobilePayApp } from "components/mobile_pay/app";

// import { SEGMENT_API_KEY } from "app_constants";
import getBaseUri from "get_base_uri";
import { setTrackJSPrettyUrlMetadata } from "helpers/analytics";
import auth0Config from "helpers/auth0_config";

import { getPreviewBannerUrl, getPreviewLogoUrl } from "helpers/preview";

import getAccountInfo from "interfaces/account_info";
import { getBrandAndStoreInfo, getBrandInfo } from "interfaces/brand_info";
import getCreditCardInfo from "interfaces/credit_cards";
import { getLoyalty, getRewards } from "interfaces/loyalty";
import getOrderHistory from "interfaces/order_history";
import isLoggedIn from "is_logged_in";
import reducers from "reducers";
import accountReducers from "reducers/account";
import onlineOrderingReducers from "reducers/online_ordering";

import renderIntoApp from "render_into_app";
import storage from "storage";

const env = process.env.NODE_ENV || "development";

const emptyState = {
  storeInfo: null,
  brandInfo: null,
  consumer: {},
  loyalty: {},
};

const accountEmptyState = {
  brandInfo: {},
  consumer: {},
};

const DefaultRouteTarget = {
  landing() {
    if (equals(env, "development")) {
      renderIntoApp(Landing);
      return;
    }

    this.notFound();
  },

  noAccessRedirect() {
    const baseUri = getBaseUri();

    Aviator.navigate(baseUri);
  },

  account(request) {
    if (!isLoggedIn()) {
      this.noAccessRedirect();
    } else {
      renderIntoApp(Account, { request });

      Promise.all([
        getBrandInfo(),
        getAccountInfo(),
        getOrderHistory(),
        getCreditCardInfo(),
      ]).then(([brandInfo, consumer, orderHistory, cards]) => {
        const initialState = {
          ...accountEmptyState,
          brandInfo,
          consumer: {
            contactInfo: consumer,
            orders: orderHistory,
            cards,
          },
        };

        const composeEnhancer =
          // eslint-disable-next-line no-underscore-dangle
          window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
        const store = createStore(
          accountReducers,
          initialState,
          composeEnhancer(applyMiddleware(thunk))
        );

        renderIntoApp(Account, { request, store });
      });
    }
  },

  index(request) {
    renderIntoApp(App, { request });

    getBrandAndStoreInfo(brandInfo => {
      const initialState = merge(emptyState, { brandInfo });
      let store;
      if (env === "development") {
        const composeEnhancer =
          // eslint-disable-next-line no-underscore-dangle
          window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
        store = createStore(
          reducers,
          initialState,
          composeEnhancer(applyMiddleware(thunk))
        );
      } else {
        store = createStore(reducers, initialState, applyMiddleware(thunk));
      }

      renderIntoApp(App, { request, store });

      if (storage.getItem("displayModalUserTokenExpired")) {
        storage.removeItem("displayModalUserTokenExpired");
        store.dispatch(
          toggleModal(
            true,
            "For your security, we have automatically logged you out of the Lightspeed (U-Series) platform. Please log back in below."
          )
        );
      }

      if (isLoggedIn(true)) {
        getLoyalty(loyalty => {
          store.dispatch(updateLoyaltyInfo(loyalty));
        });

        getAccountInfo().then(accountInfo => {
          store.dispatch(updateConsumerInfo(accountInfo));
        });
      } else {
        getRewards(rewards => {
          store.dispatch(updateLoyaltyInfo({ rewards }));
        });
      }
    });
  },

  loadAnalytics() {
    // temporarily commenting out as Segment has been turned off for this source
    // click-tracking will be reevaluated and reenabled as part of HQ-8965
    // if (env === "production" && typeof window.analytics !== "undefined") {
    //   if (typeof window.analytics.load === "function")
    //     window.analytics.load(SEGMENT_API_KEY);
    //   if (typeof window.analytics.page === "function") {
    //     // This will set the property values: https://github.com/segmentio/analytics.js/blob/master/analytics.js#L587
    //     window.analytics.page({ referrer: null, search: null, url: null });
    //   }
    // }
  },

  oloIndex(request) {
    const initialState = {};

    // Temporarily turn this on to aid with debugging in prod
    const composeEnhancer =
      // eslint-disable-next-line no-underscore-dangle
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
      onlineOrderingReducers,
      initialState,
      composeEnhancer(applyMiddleware(thunk))
    );

    if (request.queryParams.email_token) {
      const { email_token, reset } = request.queryParams;

      store.dispatch(captureAuthParams(email_token, reset));
      store.dispatch(logOut(false));
      store.dispatch(showSetPasswordModal());
    }

    renderIntoApp(OloApp, { store });

    if (storage.getItem("displayModalUserTokenExpired")) {
      storage.removeItem("displayModalUserTokenExpired");
      store.dispatch(
        toggleModal(
          true,
          "For your security, we have automatically logged you out of the Lightspeed (U-Series) platform. Please log back in below."
        )
      );
    }
  },

  oloAccount(request) {
    renderIntoApp(OLOAccountApp, { request });

    if (!isLoggedIn(true)) {
      this.noAccessRedirect();
    }
  },

  paymentHandler(request) {
    const { params } = request;

    if (params.payment_method_token && window.parent) {
      window.parent.postMessage(
        {
          paymentMethodToken: params.payment_method_token,
          source: "upserve-payments",
        },
        window.location.origin
      );
    } else {
      window.parent.postMessage({
        paymentError: true,
        source: "upserve-payments",
      });
    }
  },

  handleSocialLogin() {
    const { domain, clientID } = auth0Config();

    const webAuth = new auth0.WebAuth({
      domain,
      clientID,
      responseType: "token id_token",
      redirectUri: `${window.location.origin}/s/social_login_success`,
    });

    webAuth.parseHash(
      { hash: window.location.hash },
      (parseErr, authResult) => {
        if (parseErr) {
          console.log(parseErr);
          window.location.href = storage.getItem("currentUrl");
        } else {
          storage.setItem("accessToken", authResult.accessToken);
          storage.setItem("idToken", authResult.idToken);
          storage.setItem("jwtExpiration", JwtDecode(authResult.idToken).exp);

          webAuth.client.userInfo(authResult.accessToken, (userErr, user) => {
            if (user) storage.setItem("user", JSON.stringify(user));
            window.location.href = storage.getItem("currentUrl");
          });
        }
      }
    );
  },

  mobilePay() {
    renderIntoApp(MobilePayApp);
  },

  giftCard() {
    renderIntoApp(DigitalGiftCardApp);
  },

  giftCardPreview(request) {
    const bannerUrl = getPreviewBannerUrl(request);
    const logoUrl = getPreviewLogoUrl(request);

    renderIntoApp(DigitalGiftCardPreviewApp, { bannerUrl, logoUrl });
  },

  notFound() {
    const baseUri = getBaseUri();

    Aviator.navigate(baseUri);
  },

  trackPrettyUrl() {
    setTrackJSPrettyUrlMetadata();
  },
};

export default DefaultRouteTarget;
