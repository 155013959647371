import Aviator from "aviator";
import { startsWith } from "ramda";

const isOLO = function () {
  const current = Aviator.getCurrentRequest();

  return current
    ? current.uri.indexOf("/s/") !== -1
    : startsWith("/s/", window.location.pathname);
};

export default isOLO;
