import { fetchCalculatedTotals } from "actions/online_ordering/calculate_totals";

export const ADD_TO_CART = "online_ordering/cart/ADD_TO_CART";
export const CREATE_EDITABLE_ITEM = "online_ordering/cart/CREATE_EDITABLE_ITEM";
export const DELETE_EDITABLE_ITEM = "online_ordering/cart/DELETE_EDITABLE_ITEM";
export const UPDATE_CART_ITEM = "online_ordering/cart/UPDATE_CART_ITEM";
export const REMOVE_FROM_CART = "online_ordering/cart/REMOVE_FROM_CART";
export const EDIT_INSTRUCTIONS = "online_ordering/cart/EDIT_INSTRUCTIONS";
export const TOGGLE_MODIFIER = "online_ordering/cart/TOGGLE_MODIFIER";
export const TOGGLE_SIDE = "online_ordering/cart/TOGGLE_SIDE";
export const INCREMENT_ITEM_QUANTITY =
  "online_ordering/cart/INCREMENT_ITEM_QUANTITY";
export const DECREMENT_ITEM_QUANTITY =
  "online_ordering/cart/DECREMENT_ITEM_QUANTITY";
export const EMPTY_CART = "online_ordering/cart/EMPTY_CART";
export const APPLY_CART_FROM_LOCAL_STORAGE =
  "online_ordering/cart/APPLY_CART_FROM_LOCAL_STORAGE";
export const APPLY_REORDER_FROM_LOCAL_STORAGE =
  "online_ordering/cart/APPLY_REORDER_FROM_LOCAL_STORAGE";
export const REMOVE_INVALID_REORDER_ITEMS =
  "online_ordering/cart/REMOVE_INVALID_REORDER_ITEMS";
export const EMPTY_ORDERED_ITEMS = "online_ordering/cart/EMPTY_ORDERED_ITEMS";

function addToCart(item) {
  return dispatch => {
    dispatch({
      type: ADD_TO_CART,
      item,
    });
    dispatch(fetchCalculatedTotals(0));
  };
}

function removeInvalidReorderItems() {
  return {
    type: REMOVE_INVALID_REORDER_ITEMS,
  };
}

function updateCartItem(oldItem, updatedItem) {
  return dispatch => {
    dispatch({
      type: UPDATE_CART_ITEM,
      updatedItem,
      oldItem,
    });
    dispatch(fetchCalculatedTotals(0));
  };
}

// Add / Edit Item Modal is shown
// If the user cancels we need to know to be able to
// drop changes and revert to the previous state of the cart item
function createEditableItem(item) {
  return {
    type: CREATE_EDITABLE_ITEM,
    item,
  };
}

function deleteEditableItem() {
  return {
    type: DELETE_EDITABLE_ITEM,
  };
}

function removeFromCart(cartId) {
  return dispatch => {
    dispatch({
      type: REMOVE_FROM_CART,
      cartId,
    });
    dispatch(fetchCalculatedTotals(0));
  };
}

function toggleModifier(mod, section) {
  return {
    type: TOGGLE_MODIFIER,
    mod,
    section,
  };
}

function toggleSide(side, maxSides) {
  return {
    type: TOGGLE_SIDE,
    side,
    maxSides,
  };
}

function incrementItemQuantity() {
  return {
    type: INCREMENT_ITEM_QUANTITY,
  };
}

function decrementItemQuantity() {
  return {
    type: DECREMENT_ITEM_QUANTITY,
  };
}

function editInstructions(instructions) {
  return {
    type: EDIT_INSTRUCTIONS,
    instructions,
  };
}

function applyCartFromLocalStorage(cart) {
  return dispatch => {
    dispatch({
      type: APPLY_CART_FROM_LOCAL_STORAGE,
      cart,
    });
    dispatch(fetchCalculatedTotals(0));
  };
}

function applyReorderFromLocalStorage(reorder, menu, stockLevels) {
  return dispatch => {
    dispatch({
      type: APPLY_REORDER_FROM_LOCAL_STORAGE,
      reorder,
      menu,
      stockLevels,
    });
    dispatch(fetchCalculatedTotals(0));
  };
}

function emptyCart() {
  return dispatch => {
    dispatch({ type: EMPTY_CART });
    dispatch(fetchCalculatedTotals(0));
  };
}
function emptyOrderedItems() {
  return dispatch => {
    dispatch({
      type: EMPTY_ORDERED_ITEMS,
    });
    dispatch(fetchCalculatedTotals(0));
  };
}
export {
  addToCart,
  applyCartFromLocalStorage,
  applyReorderFromLocalStorage,
  createEditableItem,
  decrementItemQuantity,
  deleteEditableItem,
  editInstructions,
  emptyCart,
  emptyOrderedItems,
  incrementItemQuantity,
  removeFromCart,
  removeInvalidReorderItems,
  toggleModifier,
  toggleSide,
  updateCartItem,
};
