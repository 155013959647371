import { combineReducers } from "redux";

import { LOG_OUT } from "actions/authentication";
import {
  ADD_ORDER_CONFIRMATION_DETAILS,
  PROCEED_TO_CHECKOUT,
  RETURN_TO_MENU,
  UPDATE_ORDER_STATUS,
  UPDATE_ORDER_STATUS_WITH_ERROR,
} from "actions/online_ordering/checkout";
import payment from "reducers/online_ordering/checkout/payment";

const initialCheckout = {
  isCheckingOut: false,
  orderStatus: "pending",
  orderStatusError: null,
  orderStatusErrorSource: null,
  orderMenuError: false,
  confirmationCode: null,
  orderTime: null,
  isOrderAhead: false,
};

function status(state = initialCheckout, action) {
  switch (action.type) {
    case UPDATE_ORDER_STATUS:
      return {
        ...state,
        orderStatus: action.status,
      };

    case UPDATE_ORDER_STATUS_WITH_ERROR:
      return {
        ...state,
        orderStatus: "error",
        orderStatusError: action.error,
        orderStatusErrorSource: action.errorSource,
        orderMenuError: action.menuError,
      };

    case ADD_ORDER_CONFIRMATION_DETAILS:
      return {
        ...state,
        confirmationCode: action.orderCompletionDetails.confirmationCode,
        orderTime: action.orderCompletionDetails.orderTime,
        isOrderAhead: action.orderCompletionDetails.isOrderAhead,
        orderResponse: action.orderCompletionDetails.orderResponse,
      };

    case RETURN_TO_MENU: {
      return {
        ...state,
        isCheckingOut: false,
        orderStatusError: initialCheckout.orderStatusError,
        orderStatusErrorSource: initialCheckout.orderStatusErrorSource,
        orderMenuError: initialCheckout.orderMenuError,
      };
    }

    case PROCEED_TO_CHECKOUT: {
      return {
        ...state,
        isCheckingOut: true,
      };
    }

    case LOG_OUT: {
      return initialCheckout;
    }

    default:
      return state;
  }
}

const checkoutReducers = combineReducers({
  status,
  payment,
});

export default checkoutReducers;
