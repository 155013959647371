import PropTypes from "prop-types";
import { gt, head, partial, without } from "ramda";
import React from "react";

import { CreditCardPayment } from "./payment/credit_card/CreditCardPayment";
import Toggle from "components/toggle";

import { withTracking } from "helpers/analytics";
import { getFullfillmentTypeFromSelectedTransport } from "helpers/online_ordering/fulfillment_types";

function scopePaymentOptionToAvailable(paymentOptions, selectedPaymentOption) {
  if (gt(paymentOptions.length, 1)) {
    return selectedPaymentOption;
  }

  return head(paymentOptions);
}

function Payment({
  checkout,
  consumer,
  onPaymentTypeUpdate,
  orderingOptions,
  storeInfo,
  orderResponse,
  getPaymentConfigurationRef,
}) {
  const {
    payment: { paymentType: selectedPaymentOption },
  } = checkout;

  const selectedFulfillmentType = getFullfillmentTypeFromSelectedTransport(
    orderingOptions.selectedTransport,
    storeInfo.fulfillmentTypes
  );
  const paymentOptions = without(
    "gift_card",
    selectedFulfillmentType.paymentMethods
  );

  return (
    <div className="payment">
      <Toggle
        options={paymentOptions}
        selectedOption={scopePaymentOptionToAvailable(
          paymentOptions,
          selectedPaymentOption
        )}
        handleChange={withTracking(
          partial(onPaymentTypeUpdate, [consumer.cards]),
          `Select ${selectedPaymentOption
            .charAt(0)
            .toUpperCase()}${selectedPaymentOption.slice(1)}`
        )}
      />
      {selectedPaymentOption === "credit" && (
        <CreditCardPayment
          orderResponse={orderResponse}
          getPaymentConfigurationRef={getPaymentConfigurationRef}
        />
      )}
    </div>
  );
}

Payment.propTypes = {
  checkout: PropTypes.object.isRequired,
  consumer: PropTypes.object.isRequired,
  onPaymentTypeUpdate: PropTypes.func.isRequired,
  orderingOptions: PropTypes.object.isRequired,
  storeInfo: PropTypes.object.isRequired,
  orderResponse: PropTypes.object,
  getPaymentConfigurationRef: PropTypes.object.isRequired,
};

export default Payment;
