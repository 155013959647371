import JwtDecode from "jwt-decode";
import { isEmpty, isNil } from "ramda";

import { itemExpired, removeUserFromLocalStorage } from "helpers/local_storage";
import storage from "storage";

function isJwtExpired() {
  let expiration = storage.getItem("jwtExpiration");

  if (isNil(expiration)) {
    const token = storage.getItem("idToken");
    if (token) {
      const decodedToken = JwtDecode(token);
      expiration = decodedToken.exp;
    } else {
      return false;
    }
  }

  if (itemExpired(expiration)) {
    // remove user data on expired token
    removeUserFromLocalStorage(false);

    storage.setItem("displayModalUserTokenExpired", true);

    return true;
  }

  return false;
}

function notEmptyAndNil(item) {
  return !isEmpty(item) && !isNil(item);
}

function isLoggedIn(checkTokenExpiration = false) {
  const user = storage.getItem("user");
  const idToken = storage.getItem("idToken");
  /*
    Only check the token when explicitly told to do so,
    otherwise the token is assumed not expired (return false)
  */
  const tokenExpired = checkTokenExpiration ? isJwtExpired() : false;

  return notEmptyAndNil(idToken) && notEmptyAndNil(user) && !tokenExpired;
}

export default isLoggedIn;
