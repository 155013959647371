import { equals } from "ramda";
import React from "react";
import styled from "styled-components";

import { StyledWrapper } from "components/mobile_pay/views/page";
import { getCheckTotal } from "helpers/mobile_pay";
import type { CheckInfo } from "types/mobile_pay";

const StyledCheckInfoWrapper = styled(StyledWrapper)`
  margin-bottom: 20px;

  text-align: left;

  font-size: 20px;
  line-height: 22px;
`;

const StyledCheckInfoLineItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;

  padding: 5px 0;
`;

const StyledCheckInfoTotalItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;

  padding: 5px 0;

  border-top: 1px solid #ededed;
  font-weight: bold;
`;

type CheckInfoComponentProps = {
  checkInfo: CheckInfo;
};

export default function CheckInfoComponent({
  checkInfo,
}: CheckInfoComponentProps) {
  const { netTotal, mandatoryTip, mandatoryTipAmount, tax, balanceDue } =
    checkInfo;

  const totalPaymentDueForCheck = getCheckTotal(checkInfo);

  const mandatoryTipLineItem = mandatoryTip.match({
    // eslint-disable-next-line react/no-multi-comp
    Just: tip => {
      return (
        <StyledCheckInfoLineItem>
          <span>{`Included Gratuity (${tip}%)`}</span>
          <span>{mandatoryTipAmount.format()}</span>
        </StyledCheckInfoLineItem>
      );
    },
    Nothing: () => null,
  });

  return (
    <StyledCheckInfoWrapper>
      <StyledCheckInfoLineItem>
        <span>Net Total (including fees and comps)</span>
        <span>{netTotal.format()}</span>
      </StyledCheckInfoLineItem>
      {mandatoryTipLineItem}
      <StyledCheckInfoLineItem>
        <span>Tax</span>
        <span>{tax.format()}</span>
      </StyledCheckInfoLineItem>
      {!equals(totalPaymentDueForCheck, balanceDue) && (
        <>
          <StyledCheckInfoTotalItem>
            <span>Check Total</span>
            <span>{totalPaymentDueForCheck.format()}</span>
          </StyledCheckInfoTotalItem>
          <StyledCheckInfoLineItem>
            <span>Payments</span>
            <span>{totalPaymentDueForCheck.subtract(balanceDue).format()}</span>
          </StyledCheckInfoLineItem>
        </>
      )}
      <StyledCheckInfoTotalItem>
        <span>Balance Due</span>
        <span>{balanceDue.format()}</span>
      </StyledCheckInfoTotalItem>
    </StyledCheckInfoWrapper>
  );
}
