import { flatten, map, pluck } from "ramda";
import { Maybe } from "true-myth";

import { getItemTotals } from "helpers/online_ordering/item_totals";
import type { ICartItem } from "types/cart";
import type { ICartItemSummary } from "types/totals";

export function transformCartItem(cartItem: ICartItem): ICartItemSummary {
  const { subtotal } = getItemTotals(cartItem);
  const {
    id,
    name,
    price,
    quantity,
    instructions,
    selectedModifiers,
    selectedSides,
  } = cartItem;

  return {
    itemId: id,
    name,
    price,
    quantity,
    instructions,
    total: Math.round(subtotal),
    modifiers: flatten(pluck("mods", selectedModifiers)),
    sides: selectedSides,
  };
}

export function convertStockLevelToMaybe(serializedCart): ICartItem[] {
  return map(item => {
    const sidesWithStocks = map(side => {
      return {
        ...side,
        stockLevel: Maybe.of(side.stockLevel.value),
      };
    }, item.sides);
    const selectedSidesWithStocks = map(side => {
      return {
        ...side,
        stockLevel: Maybe.of(side.stockLevel.value),
      };
    }, item.selectedSides);
    return {
      ...item,
      sides: sidesWithStocks,
      selectedSides: selectedSidesWithStocks,
      stockLevel: Maybe.of(item.stockLevel.value),
    };
  }, serializedCart);
}
