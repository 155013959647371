import PropTypes from "prop-types";
import { isEmpty, partial } from "ramda";
import React from "react";

import EditAddress from "components/account/addresses/edit_address";
import ConfirmModal from "components/confirm_modal";
import { withTracking } from "helpers/analytics";

class Address extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };

    this.showRemoveModal = this.showRemoveModal.bind(this);
    this.closeRemoveModal = this.closeRemoveModal.bind(this);
  }

  showRemoveModal() {
    this.setState({ showModal: true });
  }

  closeRemoveModal() {
    this.setState({ showModal: false });
  }

  render() {
    const {
      address,
      editableAddress,
      onSaveUpdatedAddress,
      onUpdateAddressField,
      onUpdateAddress,
      onCancelAddressEdits,
      onRemoveAddress,
      onEditExistingAddress,
    } = this.props;
    const { showModal } = this.state;
    const { uuid, line1, line2, city, state, zip } = address;

    if (editableAddress) {
      return (
        <li className="address">
          <EditAddress
            existingAddress={address}
            editableAddress={editableAddress}
            onCancel={onCancelAddressEdits}
            onUpdateAddressField={onUpdateAddressField}
            onSaveAddress={onSaveUpdatedAddress}
            onUpdateAddress={onUpdateAddress}
          />
        </li>
      );
    }

    const line2formatted = isEmpty(line2) ? "" : `${line2},`;
    const formattedAddress = `${line1}, ${line2formatted} ${city}, ${state}, ${zip}`;

    let modal;
    if (showModal) {
      modal = (
        <ConfirmModal
          className="remove-address-modal"
          confirmButtonText="Remove address"
          message="Are you sure you want to remove this address?"
          messageLine2={formattedAddress}
          onCancel={this.closeRemoveModal}
          onConfirm={withTracking(
            partial(onRemoveAddress, [uuid]),
            "Account Page: Remove Address"
          )}
        />
      );
    }

    return (
      <li className="address">
        {formattedAddress}
        <div className="actions">
          <a
            className="update edit"
            onClick={withTracking(
              partial(onEditExistingAddress, [uuid]),
              "OLO - Account - click edit address"
            )}>
            Edit
          </a>
          <a
            className="update remove"
            onClick={withTracking(
              this.showRemoveModal,
              "OLO - Account - click remove address"
            )}>
            Remove
          </a>
        </div>
        {modal}
      </li>
    );
  }
}

Address.propTypes = {
  address: PropTypes.object.isRequired,
  editableAddress: PropTypes.object,
  onSaveUpdatedAddress: PropTypes.func.isRequired,
  onUpdateAddressField: PropTypes.func.isRequired,
  onUpdateAddress: PropTypes.func.isRequired,
  onCancelAddressEdits: PropTypes.func.isRequired,
  onEditExistingAddress: PropTypes.func.isRequired,
  onRemoveAddress: PropTypes.func.isRequired,
};

export default Address;
