import moment from "moment-timezone";
import {
  curry,
  equals,
  is,
  map,
  mapObjIndexed,
  merge,
  pipe,
  prop,
  sortBy,
  split,
  values,
} from "ramda";
import TIMEZONES from "helpers/timezones";
import type { StoreInfo, StoreInfoRedirect } from "types/reducers/store_info";

const SCHEDULE_BASE = {
  mon: [],
  tue: [],
  wed: [],
  thu: [],
  fri: [],
  sat: [],
  sun: [],
};

const SORT_DAYS = {
  sun: 0,
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
};

const DAY_NAMES = {
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thu: "Thursday",
  fri: "Friday",
  sat: "Saturday",
  sun: "Sunday",
};

const convertToMoment = curry((timezone, time) => {
  moment.tz.setDefault(TIMEZONES[timezone]);
  return moment().hours(time[0]).minutes(time[1]);
});

// Example: 10pm - 2am
// They day key will be correct for the first
// moment but not the second
function correctRangesSpanningTwoDays(range) {
  if (range[0].isAfter(range[1])) {
    return [range[0], range[1].add(1, "days")];
  }

  return range;
}

const formatDeliveryWindows = curry((timezone, ranges, key) => {
  const timesAsMoments = map(
    map(pipe(split(":"), convertToMoment(timezone))),
    ranges
  );

  return {
    day: key,
    order: SORT_DAYS[key],
    timeRanges: map(correctRangesSpanningTwoDays, timesAsMoments),
    prettyName: DAY_NAMES[key],
  };
});

const scheduleTransform = (timezone, schedule) =>
  pipe(
    mapObjIndexed(formatDeliveryWindows(timezone)),
    values,
    sortBy(prop("order"))
  )(schedule);

const addTransportCopy = curry((timezone, prepTime, transportOption) => {
  const completeSchedule = merge(SCHEDULE_BASE, transportOption.schedule);
  let estimateRange = [30, 40];
  if (prepTime) {
    if (is(Array, prepTime)) {
      estimateRange = prepTime;
    } else {
      estimateRange = [prepTime, prepTime + 10];
    }
  }
  if (equals(transportOption.type, "delivery")) {
    return {
      ...transportOption,
      name: "Delivery",
      estimate: estimateRange,
      estimateCopy: "Est Delivery",
      schedule: scheduleTransform(timezone, completeSchedule),
    };
  }
  if (equals(transportOption.type, "dine_in")) {
    return {
      ...transportOption,
      name: "Dine in",
      estimate: estimateRange,
      estimateCopy: "Est Dine in",
      schedule: scheduleTransform(timezone, completeSchedule),
    };
  }
  return {
    ...transportOption,
    name: "Pick up",
    estimate: estimateRange,
    estimateCopy: "Ready for pick up",
    schedule: scheduleTransform(timezone, completeSchedule),
  };
});

const extendFulfillmentTypes = storeInfo => {
  return {
    ...storeInfo,
    fulfillmentTypes: map(transportOption => {
      const { type } = transportOption;
      const orderReady = storeInfo[`${type}OrderReadyBy`];
      const orderReadyMax = storeInfo[`${type}OrderReadyByMax`];
      if (orderReady && orderReadyMax) {
        return addTransportCopy(
          storeInfo.timezone,
          [orderReady, orderReadyMax],
          transportOption
        );
      }
      return addTransportCopy(
        storeInfo.timezone,
        storeInfo.orderReadyBy,
        transportOption
      );
    }, storeInfo.fulfillmentTypes),
  };
};

function oloStoreInfoTransform(
  storePrettyUrl: string,
  storeInfo
): StoreInfo | StoreInfoRedirect {
  if (storeInfo.redirectPrettyUrl) {
    return { redirectPrettyUrl: storeInfo.redirectPrettyUrl };
  }

  const parsedInfo = {
    ...storeInfo,
    prettyUrl: storePrettyUrl,
    fulfillmentTypes: map(fulfillmentType => {
      return {
        ...fulfillmentType,
        schedule: JSON.parse(fulfillmentType.schedule),
      };
    }, storeInfo.fulfillmentTypes),
  };

  return extendFulfillmentTypes(parsedInfo);
}

export { SORT_DAYS, scheduleTransform, extendFulfillmentTypes };
export default oloStoreInfoTransform;
