import { difference } from "ramda";
import { connect } from "react-redux";

import { awaitCalculatedTotals } from "actions/online_ordering/calculate_totals";
import {
  createEditableItem,
  deleteEditableItem,
  removeFromCart,
  removeInvalidReorderItems,
  updateCartItem,
} from "actions/online_ordering/cart";

import {
  proceedToCheckout,
  returnToMenu,
} from "actions/online_ordering/checkout";
import {
  changeDeliveryTimeFrame,
  changeTransportMethod,
} from "actions/online_ordering/ordering_options";

import Cart from "components/online_ordering/cart";
import { filterCart } from "helpers/order_times";

function mapStateToProps(state) {
  const filteredCart = filterCart({
    cart: state.cart,
    menu: state.orderingInfo.menu,
    orderingOptions: state.orderingOptions,
  });

  return {
    cart: filteredCart,
    orderingOptions: state.orderingOptions,
    storeInfo: state.storeInfo,
    checkout: state.checkout,
    invalidReorderItems: state.cart.invalidItems,
    deliveryTaxRate: state.orderingInfo.deliveryTaxRate,
    filteredItems: difference(state.cart.cartItems, filteredCart.cartItems),
    stockLevels: state.orderingInfo.stockLevels,
  };
}

const mapDispatchToProps = {
  clearCalculatedTotals: awaitCalculatedTotals,
  createEditableItem,
  onChangeDeliveryTimeFrame: changeDeliveryTimeFrame,
  onChangeTransportMethod: changeTransportMethod,
  onDeleteEditableItem: deleteEditableItem,
  onProceedToCheckout: proceedToCheckout,
  onRemoveItem: removeFromCart,
  onReturnToMenu: returnToMenu,
  onUpdateItem: updateCartItem,
  removeInvalidReorderItems,
};

const CartContainer = connect(mapStateToProps, mapDispatchToProps)(Cart);

export default CartContainer;
