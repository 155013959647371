import PropTypes from "prop-types";
import React from "react";

import EditOrderingOptions from "components/online_ordering/cart/edit_ordering_options";
import OrderingOptions from "components/online_ordering/cart/ordering_options";

import { isPickupOnlyAvailable } from "helpers/online_ordering/fulfillment_types";

function OrderingOptionsBar({
  cartIsEmpty,
  consumerInfo,
  contactInfo,
  deliveryAddress,
  onChangeDeliveryTimeFrame,
  onChangeTransportMethod,
  onEditOrderOptions,
  onUpdateDeliveryAddress,
  onUpdateOrderDate,
  onUpdateOrderTime,
  orderingOptions,
  selectedOptionName,
  storeInfo,
  useExtendedOrderAhead,
}) {
  const pickupOnly = isPickupOnlyAvailable(storeInfo.fulfillmentTypes);
  const { futureOrder } = storeInfo;

  if ((pickupOnly && !futureOrder) || !orderingOptions.isEditable) {
    return (
      <OrderingOptions
        pickupOnly={pickupOnly}
        selectedOptionName={selectedOptionName}
        orderingOptions={orderingOptions}
        storeInfo={storeInfo}
        contactInfo={contactInfo}
        onEditOrderOptions={onEditOrderOptions}
      />
    );
  }
  return (
    <EditOrderingOptions
      cartIsEmpty={cartIsEmpty}
      consumerInfo={consumerInfo}
      contactInfo={contactInfo}
      deliveryAddress={deliveryAddress}
      onChangeDeliveryTimeFrame={onChangeDeliveryTimeFrame}
      onChangeTransportMethod={onChangeTransportMethod}
      onEditOrderOptions={onEditOrderOptions}
      onUpdateDeliveryAddress={onUpdateDeliveryAddress}
      onUpdateOrderDate={onUpdateOrderDate}
      onUpdateOrderTime={onUpdateOrderTime}
      orderingOptions={orderingOptions}
      selectedOptionName={selectedOptionName}
      storeInfo={storeInfo}
      useExtendedOrderAhead={useExtendedOrderAhead}
    />
  );
}

OrderingOptionsBar.propTypes = {
  cartIsEmpty: PropTypes.bool.isRequired,
  consumerInfo: PropTypes.object,
  contactInfo: PropTypes.object.isRequired,
  deliveryAddress: PropTypes.object.isRequired,
  onChangeDeliveryTimeFrame: PropTypes.func.isRequired,
  onChangeTransportMethod: PropTypes.func.isRequired,
  onEditOrderOptions: PropTypes.func.isRequired,
  onUpdateDeliveryAddress: PropTypes.func.isRequired,
  onUpdateOrderDate: PropTypes.func.isRequired,
  onUpdateOrderTime: PropTypes.func.isRequired,
  orderingOptions: PropTypes.object,
  selectedOptionName: PropTypes.string.isRequired,
  storeInfo: PropTypes.object.isRequired,
  useExtendedOrderAhead: PropTypes.bool,
};

export default OrderingOptionsBar;
