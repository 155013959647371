import { useMutation, useQueryClient } from "react-query";
import { oloConsumerSettingsQueryKey } from "./useOloConsumerSettings";
import type { OloConsumerSettings } from "components/online_ordering/objects/OloConsumerSettings";
import { oloUpdateConsumerSettings } from "interfaces/online_ordering/accounts/oloUpdateConsumerSettings";

export const useOloUpdateConsumerSettings = (storePrettyUrl: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (consumerSettings: Partial<OloConsumerSettings>) => {
      return oloUpdateConsumerSettings(storePrettyUrl, consumerSettings);
    },
    {
      onMutate: async (consumerSettings: Partial<OloConsumerSettings>) => {
        queryClient.setQueryData(
          oloConsumerSettingsQueryKey(storePrettyUrl),
          (original: Partial<OloConsumerSettings> | undefined) => ({
            ...original,
            ...consumerSettings,
          })
        );
      },
      onSuccess: async () => {
        return queryClient.invalidateQueries(
          oloConsumerSettingsQueryKey(storePrettyUrl)
        );
      },
    }
  );
};
