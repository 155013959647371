import cn from "classnames";
import debounce from "lodash.debounce";
import React, {
  type ReactNode,
  type RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import Icon from "components/icon";
import Portal from "components/portal";
import useToolTipTrigger from "helpers/hooks/useToolTipTrigger";

interface ITooltipProps {
  children: ReactNode;
}

type Position = "left" | "right";
interface ICoordPosition {
  style: {
    left: number;
    top: number;
  };
  isNarrow: boolean;
  position: Position;
}

const FULL_WIDTH_THRESHOLD = 300;
const NARROW_WIDTH_THRESHOLD = 200;

function getRefOffet(ref: RefObject<HTMLDivElement>): ICoordPosition {
  let left = 0;
  let top = 0;
  let isNarrow = false;
  let position: Position = "right";

  if (ref.current) {
    const { x, y, height, width } = ref.current.getBoundingClientRect();
    left = x + width / 2;
    top = y + height / 2;
  }
  const spaceOnRight = window.innerWidth - left;
  if (spaceOnRight < NARROW_WIDTH_THRESHOLD) {
    position = "left";
    isNarrow = left < FULL_WIDTH_THRESHOLD;
  } else {
    isNarrow = spaceOnRight < FULL_WIDTH_THRESHOLD;
  }
  return { style: { left, top }, isNarrow, position };
}

const Tooltip = ({ children }: ITooltipProps) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const { isOpen, bind } = useToolTipTrigger();
  const [{ style: coordStyle, position, isNarrow }, setCoordPosition] =
    useState(getRefOffet(anchorRef));

  const updateCoordsDebounced = useMemo(() => {
    function updateCoords() {
      setCoordPosition(getRefOffet(anchorRef));
    }
    return debounce(updateCoords, 20);
  }, [anchorRef, setCoordPosition]);

  useEffect(() => {
    updateCoordsDebounced();
    if (isOpen) {
      window.addEventListener("scroll", updateCoordsDebounced);
      window.addEventListener("resize", updateCoordsDebounced);
      return () => {
        window.removeEventListener("scroll", updateCoordsDebounced);
        window.removeEventListener("resize", updateCoordsDebounced);
      };
    }
    return undefined;
  }, [isOpen, updateCoordsDebounced]);

  return (
    <article aria-describedby="tooltip-content" className="tooltip" {...bind}>
      {isOpen && (
        <Portal>
          <article
            id="tooltip-content"
            role="tooltip"
            className={cn("tooltip-portal", {
              positionLeft: position === "left",
              positionRight: position === "right",
            })}
            style={coordStyle}>
            <div className="triangle" />
            <div className={cn("content", { isNarrow })}>{children}</div>
          </article>
        </Portal>
      )}
      <div className="tooltip-icon-wrapper" ref={anchorRef}>
        <Icon type="info" />
      </div>
    </article>
  );
};

export default Tooltip;
