import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { map } from "ramda";
import React from "react";
import styled from "styled-components";

const StyledSelect = styled.div`
  margin-right: 4px;

  .MuiFormControl-root {
    width: 100%;
    border-radius: 4px;
    border: 2px solid #e0e0e0;
    background-color: #ffffff;
    &.Mui-error {
      border: 2px solid #c8341e;
    }
    font-family: "Graphik", sans-serif;
    color: #212322;
  }

  .MuiInput-formControl {
    margin-bottom: 8px;
  }

  .MuiSelect-selectMenu {
    margin-left: 12px;
    margin-top: 12px;
    padding-bottom: 0;
  }

  .MuiInputBase-input {
    font-family: "Graphik", sans-serif;
    color: #212322;
    padding-top: 0;
  }

  .MuiInput-underline::before {
    border-bottom: none;
  }

  .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom: none;
  }

  .MuiSelect-select:focus {
    background-color: transparent;
  }

  .MuiInput-underline::after {
    border-bottom: none;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #888888;
  }

  .MuiInputLabel-root {
    font-family: "Graphik", sans-serif;
    color: #888888;

    &.MuiInputLabel-shrink {
      margin-top: 8px;
      margin-left: 12px;
      transform: none;
      font-size: 0.875rem; // ~12px based on 14px root font-size; decrease to 0.75 for 16px root
    }
  }

  .MuiSelect-icon {
    right: 10px;
  }
`;

type MuiSelectProps = {
  label: string;
  menuItems: Array<MuiMenuItem>;
  value: string;
  name: string;
  onChange: (
    event: React.ChangeEvent<{
      value: unknown;
    }>
  ) => void;
};

type MuiMenuItem = {
  value: string | number;
  label?: string;
};

export default function MuiSelect({
  label,
  menuItems,
  value,
  name,
  onChange,
}: MuiSelectProps) {
  const items = map(item => {
    return (
      <MenuItem value={item.value} key={item.value}>
        {item.label ? item.label : item.value}
      </MenuItem>
    );
  }, menuItems);
  return (
    <StyledSelect>
      <FormControl>
        <InputLabel htmlFor={`${name}-select`}>{label}</InputLabel>
        <Select
          id={`${name}-select`}
          value={value}
          onChange={onChange}
          name={name}>
          {items}
        </Select>
      </FormControl>
    </StyledSelect>
  );
}
