import { connect } from "react-redux";

import {
  addToCart,
  createEditableItem,
  deleteEditableItem,
} from "actions/online_ordering/cart";

import Menu from "components/online_ordering/menu";

function mapStateToProps(state) {
  return {
    cartItems: state.cart.cartItems,
    orderingInfo: state.orderingInfo,
    orderingOptions: state.orderingOptions,
    settings: state.storeInfo.settings,
    timezone: state.storeInfo.timezone,
  };
}

const mapDispatchToProps = {
  createEditableItem,
  onAddItem: addToCart,
  onDeleteEditableItem: deleteEditableItem,
};

const MenuContainer = connect(mapStateToProps, mapDispatchToProps)(Menu);

export default MenuContainer;
