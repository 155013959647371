import React from "react";
import styled from "styled-components";
import Icon from "components/icon";
import Page from "components/mobile_pay/views/page";
import type { MerchantInfo } from "types/mobile_pay";

export const StyledConfirmationHeader = styled.h1`
  margin-top: 8px;
  margin-bottom: 12px;

  font-weight: bold;
  font-size: 36px;
  line-height: 40px;

  color: #212322;
`;

export const StyledConfirmationText = styled.div`
  font-size: 24px;
  line-height: 30px;

  word-break: break-word;

  color: #212322;
`;

export const StyledConfirmationTextSpaced = styled(StyledConfirmationText)`
  margin: 8px 0;
`;

export const StyledDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin: 48px 0;
`;

type ConfirmationPageProps = {
  merchantInfo: MerchantInfo;
  children: React.ReactNode;
};

export default function ConfirmationPage({
  merchantInfo,
  children,
}: ConfirmationPageProps) {
  return (
    <Page merchantInfo={merchantInfo}>
      <Icon type="success-check" />
      {children}
    </Page>
  );
}
