import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { MobilePayPaymentAuthorization } from "./payment/MobilePayPaymentAuthorization";
import CheckPaidPage from "components/mobile_pay/views/check_paid_page";
import { PaymentPage } from "components/mobile_pay/views/payment_page";

import PaymentSuccessPage from "components/mobile_pay/views/payment_success_page";
import { useIsCheckPaid } from "hooks/mobile_pay/useIsCheckPaid";

import type {
  CheckInfo,
  MerchantInfo,
  ProcessedPaymentInfo,
} from "types/mobile_pay";

const StyledMobilePayWrapper = styled.div`
  background-color: #ededed;
  max-width: 1024px;
  height: 100vh;
  margin: 0 auto;
  padding: 24px 12px;
`;

interface MobilePayCheckProps {
  merchantInfo: MerchantInfo;
  checkInfo: CheckInfo;
}

export const MobilePayCheck = ({
  merchantInfo,
  checkInfo,
}: MobilePayCheckProps) => {
  useEffect(() => {
    document.title = merchantInfo.name;
  }, [merchantInfo.name]);

  const isCheckPaid = useIsCheckPaid(checkInfo);
  const [processedPaymentInfo, setProcessedPaymentInfo] =
    useState<ProcessedPaymentInfo | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handlePaymentSuccess = useCallback(
    (paymentResponse: ProcessedPaymentInfo) => {
      setProcessedPaymentInfo(paymentResponse);
    },
    []
  );

  const handlePaymentError = useCallback((errorMessage: string) => {
    setError(errorMessage);
  }, []);

  if (processedPaymentInfo) {
    return (
      <StyledMobilePayWrapper>
        <PaymentSuccessPage
          merchantInfo={merchantInfo}
          checkInfo={checkInfo}
          processedPaymentInfo={processedPaymentInfo}
        />
      </StyledMobilePayWrapper>
    );
  }

  if (isCheckPaid) {
    return (
      <StyledMobilePayWrapper>
        <CheckPaidPage merchantInfo={merchantInfo} checkInfo={checkInfo} />
      </StyledMobilePayWrapper>
    );
  }

  return (
    <StyledMobilePayWrapper>
      <PaymentPage
        merchantInfo={merchantInfo}
        checkInfo={checkInfo}
        onPaymentSuccess={handlePaymentSuccess}
        onPaymentError={handlePaymentError}
        error={error}
      />
      <MobilePayPaymentAuthorization
        checkInfo={checkInfo}
        onSuccess={handlePaymentSuccess}
        onError={handlePaymentError}
      />
    </StyledMobilePayWrapper>
  );
};
