import PropTypes from "prop-types";
import { find, propEq } from "ramda";
import React from "react";

import Button from "components/button";
import Input from "components/input";

import LoyaltyImage from "components/loyalty/loyalty_image";
import { withTracking } from "helpers/analytics";
import Validators from "helpers/validation/validators";

const validators = {
  cardNum: Validators.isValidCardNumber,
};

class CardEntry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cardNum: "",
    };

    this.updateCardNum = this.updateCardNum.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  updateCardNum(event) {
    if (event.target.value.length <= 19) {
      this.setState({
        cardNum: event.target.value,
      });
    }
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleSubmit();
    }
  }

  handleSubmit() {
    const { onSubmit } = this.props;
    const { cardNum } = this.state;

    if (!Validators.isValid(this.state, validators)) {
      this.setState({ error: "Please enter a valid credit card number" });
    } else {
      onSubmit(cardNum);
    }
  }

  render() {
    const { brandInfo, loyalty, email, onBack } = this.props;

    const welcomeReward = find(propEq("isFpo", true), loyalty.rewards);
    const pointReward = find(propEq("isFpo", false), loyalty.rewards);
    const reward = welcomeReward || pointReward;

    return (
      <div className="card-entry reward">
        <LoyaltyImage type="loyalty" imagePath={reward.coverPhoto} />
        <div className="reward-content">
          <h2>
            Rewards are applied as credits directly to your credit card. No
            punch cards, no complicated redemption.
          </h2>

          <div className="card-form">
            <Input
              id="card-entry-cc-number"
              error={this.state.error}
              className="text"
              type="text"
              placeholder="Enter your credit card number"
              value={this.state.cardNum}
              onChange={this.updateCardNum}
              onKeyDown={this.handleKeyDown}
              autoFocus
            />
            <Button onClick={this.handleSubmit}>Add card</Button>
          </div>

          <div className="explanation">
            {brandInfo.locationName} uses Lightspeed (U-Series) credit card
            processing. When you use this credit card at{" "}
            {brandInfo.locationName}, you automatically progress through their
            rewards program. Cash back rewards are put directly on your card as
            credits. You can enroll as many cards as you wish. You're currently
            enrolling with {email}{" "}
            <a onClick={withTracking(onBack, 'Click "change email"')}>
              change email
            </a>
          </div>
        </div>
      </div>
    );
  }
}

CardEntry.propTypes = {
  brandInfo: PropTypes.object.isRequired,
  loyalty: PropTypes.shape({
    progression: PropTypes.shape({
      pointsAccrued: PropTypes.number,
      fpoRedeemed: PropTypes.bool,
      pointRewardLoaded: PropTypes.bool,
    }),
  }),
  email: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CardEntry;
