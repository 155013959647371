import { concat, equals, isEmpty, map, none, propEq, reject } from "ramda";
import type { BalanceGiftCard } from "types/reducers/payment";

export function addGiftCardToList(
  giftCard: BalanceGiftCard,
  giftCards: Array<BalanceGiftCard>
): Array<BalanceGiftCard> {
  if (none(propEq("index", giftCard.index), giftCards)) {
    return concat([giftCard], giftCards);
  }
  return map(card => {
    if (equals(giftCard.index, card.index)) {
      return {
        ...giftCard,
      };
    }
    return card;
  }, giftCards);
}

export function removeGiftCardFromListByIndex(
  index: number,
  giftCards: Array<BalanceGiftCard>
): Array<BalanceGiftCard> {
  const withoutRemovedCard = reject(propEq("index", index), giftCards);
  return isEmpty(withoutRemovedCard)
    ? withoutRemovedCard
    : [
        {
          ...withoutRemovedCard[0],
          index: 0,
        },
      ];
}
