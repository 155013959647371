import { Money } from "@upserve/financials";
import { camelizeKeys } from "humps";
import { equals, isNil, join, propOr } from "ramda";
import { TrackJS } from "trackjs";

import uuid from "uuid/v4";
import { fetchCalculatedTotals } from "actions/online_ordering/calculate_totals";
import type { OloCreditCard } from "components/online_ordering/objects/OloCreditCard";
import { getPrettyUrl } from "get_base_uri";
import { parseJSON } from "helpers/ajax_request";
import type { SuggestedTipSelection } from "helpers/gratuity";
import {
  addGiftCardToList,
  removeGiftCardFromListByIndex,
} from "helpers/online_ordering/cart/gift_card";
import {
  ERROR_MAPPINGS,
  GENERIC_ERROR,
} from "helpers/online_ordering/checkout";
import { getGiftCardBalance } from "interfaces/calculate_totals";
import { selectGiftCards } from "selectors";
import type {
  BalanceGiftCard,
  GiftCardBalanceRequest,
  GiftCardProps,
  INewCreditCard,
} from "types/reducers/payment";
import { RequestStatus } from "types/requests";

export const UPDATE_CONTACT_INFO =
  "online_ordering/checkout/UPDATE_CONTACT_INFO";
export const UPDATE_NEW_CREDIT_CARD =
  "online_ordering/checkout/UPDATE_NEW_CREDIT_CARD";
export const UPDATE_PAYMENT_TYPE =
  "online_ordering/checkout/UPDATE_PAYMENT_TYPE";
export const UPDATE_ORDER_STATUS =
  "online_ordering/checkout/UPDATE_ORDER_STATUS";
export const UPDATE_ORDER_STATUS_WITH_ERROR =
  "online_ordering/checkout/UPDATE_ORDER_STATUS_WITH_ERROR";
export const ADD_ORDER_CONFIRMATION_DETAILS =
  "online_ordering/checkout/ADD_ORDER_CONFIRMATION_DETAILS";
export const PROCEED_TO_CHECKOUT =
  "online_ordering/checkout/PROCEED_TO_CHECKOUT";
export const RETURN_TO_MENU = "online_ordering/checkout/RETURN_TO_MENU";
export const UPDATE_DELIVERY_INSTRUCTIONS =
  "online_ordering/checkout/UPDATE_DELIVERY_INSTRUCTIONS";
export const UPDATE_IS_CURBSIDE_PICKUP =
  "online_ordering/checkout/UPDATE_IS_CURBSIDE_PICKUP";
export const UPDATE_CURBSIDE_PICKUP_INSTRUCTIONS =
  "online_ordering/checkout/UPDATE_CURBSIDE_PICKUP_INSTRUCTIONS";
export const APPLY_ORDER_ADDRESS_FROM_LOCAL_STORAGE =
  "online_ordering/checkout/APPLY_ORDER_ADDRESS_FROM_LOCAL_STORAGE";
export const SET_PAYMENT_GIFT_CARD_REQUEST =
  "online_ordering/checkout/SET_PAYMENT_GIFT_CARD_REQUEST";
export const SET_PAYMENT_GIFT_CARD_INFO =
  "online_ordering/checkout/SET_PAYMENT_GIFT_CARD_INFO";
export const UPDATE_TIP = "online_ordering/checkout/UPDATE_TIP";
export const UPDATE_SELECTED_TIP_OPTION =
  "online_ordering/checkout/UPDATE_SELECTED_TIP_OPTION";

function updateContactInfo(contactInfo) {
  return {
    type: UPDATE_CONTACT_INFO,
    contactInfo,
  };
}

function updateNewCreditCard(newCreditCard: Partial<INewCreditCard>) {
  return {
    type: UPDATE_NEW_CREDIT_CARD,
    newCreditCard,
  };
}

function updatePaymentType(cards: OloCreditCard[] = [], paymentType) {
  return {
    type: UPDATE_PAYMENT_TYPE,
    paymentType,
    cards,
  };
}

function updateOrderStatus(status) {
  return {
    type: UPDATE_ORDER_STATUS,
    status,
  };
}

type OrderErrorSource = "submitOrder" | "calculateTotal";
function parseOrderError(
  errorSource: OrderErrorSource,
  response?: Response,
  fallbackMessage = GENERIC_ERROR
) {
  return dispatch => {
    return new Promise<Response>((resolve, reject) =>
      response ? resolve(response) : reject()
    )
      .then(parseJSON)
      .then(camelizeKeys)
      .then(({ error, errors, errorType }) => {
        let mappedError = fallbackMessage;
        if (equals("submitOrder", errorSource) && !isNil(errors)) {
          // errors will be an array of errors of string
          const orderErrors = errors as string[];
          // For now join the responses as they are already in sentence format.
          mappedError = join(" ", orderErrors);
        } else {
          // Handle regular error response
          mappedError = propOr(error, error, ERROR_MAPPINGS); // FIXME - Improve error mapping - See HQ-4459
        }
        dispatch(
          updateOrderStatusWithError(
            errorSource,
            mappedError,
            equals("menu", errorType)
          )
        );
        TrackJS.console.error(mappedError, { error, errors, errorType });
      })
      .catch(() => {
        dispatch(
          updateOrderStatusWithError(
            errorSource,
            "Sorry, online ordering is temporarily unavailable.",
            false
          )
        );
      });
  };
}

export const parseOrderSubmissionError = parseOrderError.bind(
  null,
  "submitOrder"
);
export const parseOrderCalculationError = parseOrderError.bind(
  null,
  "calculateTotal"
);

function updateOrderStatusWithError(
  errorSource: OrderErrorSource,
  error,
  menuError: boolean
) {
  return {
    type: UPDATE_ORDER_STATUS_WITH_ERROR,
    error,
    errorSource,
    menuError,
  };
}

function addOrderConfirmationDetails(orderCompletionDetails) {
  return {
    type: ADD_ORDER_CONFIRMATION_DETAILS,
    orderCompletionDetails,
  };
}

function updateDeliveryInstructions(instructions) {
  return {
    type: UPDATE_DELIVERY_INSTRUCTIONS,
    instructions,
  };
}

function updateIsCurbsidePickup(isCurbsidePickup) {
  return {
    type: UPDATE_IS_CURBSIDE_PICKUP,
    isCurbsidePickup,
  };
}

function updateCurbsidePickupInstructions(instructions) {
  return {
    type: UPDATE_CURBSIDE_PICKUP_INSTRUCTIONS,
    instructions,
  };
}

function proceedToCheckout(paymentOptions) {
  return {
    type: PROCEED_TO_CHECKOUT,
    paymentOptions,
  };
}

function returnToMenu() {
  return {
    type: RETURN_TO_MENU,
  };
}

function applyOrderAddressFromLocalStorage(address) {
  return {
    type: APPLY_ORDER_ADDRESS_FROM_LOCAL_STORAGE,
    address,
  };
}

function setPaymentGiftCardRequestStatus(status: RequestStatus) {
  return {
    type: SET_PAYMENT_GIFT_CARD_REQUEST,
    status,
  };
}

function SetPaymentGiftCardInfo(giftCards: Array<BalanceGiftCard>) {
  return {
    type: SET_PAYMENT_GIFT_CARD_INFO,
    giftCards,
  };
}

function applyGiftCard(newGiftCard: GiftCardProps) {
  const { index, giftCardNumber, pin } = newGiftCard;
  let giftCard: BalanceGiftCard = {
    index,
    giftCardNumber,
    balance: Money(0),
    pin,
  };
  const requestId = uuid();
  return (dispatch, getState) => {
    dispatch(setPaymentGiftCardRequestStatus(RequestStatus.REQUESTING));
    const giftCardForBalanceRequest: GiftCardBalanceRequest = {
      giftCode: giftCard.giftCardNumber,
      pin: giftCard.pin,
      storePrettyUrl: getPrettyUrl(),
      requestId,
    };
    getGiftCardBalance(giftCardForBalanceRequest)
      .then(({ balance }) => {
        giftCard = {
          ...giftCard,
          balance: Money(balance),
        };
        const { giftCards } = selectGiftCards(getState());
        dispatch(
          SetPaymentGiftCardInfo(addGiftCardToList(giftCard, giftCards))
        );
        dispatch(fetchCalculatedTotals(0));
      })
      .catch(error => {
        dispatch(setPaymentGiftCardRequestStatus(RequestStatus.FAILURE));
        TrackJS.track(
          `error fetching balance for gift card - request id: ${requestId}`
        );
        TrackJS.track(`ubergateway error response: ${error}`);
      });
  };
}

function removeGiftCard(index: number) {
  return (dispatch, getState) => {
    dispatch(setPaymentGiftCardRequestStatus(RequestStatus.REQUESTING));
    const { giftCards } = selectGiftCards(getState());
    dispatch(
      SetPaymentGiftCardInfo(removeGiftCardFromListByIndex(index, giftCards))
    );
    dispatch(fetchCalculatedTotals(0));
  };
}

function updateTip(tip: Money) {
  return {
    type: UPDATE_TIP,
    tip,
  };
}

interface IUpdateTip {
  type: string;
  tip: Money;
}

function updateSelectedtipOption(
  selectedTipOption: SuggestedTipSelection | undefined
) {
  return {
    type: UPDATE_SELECTED_TIP_OPTION,
    selectedTipOption,
  };
}

export {
  applyGiftCard,
  applyOrderAddressFromLocalStorage,
  IUpdateTip,
  addOrderConfirmationDetails,
  proceedToCheckout,
  removeGiftCard,
  returnToMenu,
  setPaymentGiftCardRequestStatus,
  updateContactInfo,
  updateCurbsidePickupInstructions,
  updateDeliveryInstructions,
  updateIsCurbsidePickup,
  updateNewCreditCard,
  updateOrderStatus,
  updateOrderStatusWithError,
  updatePaymentType,
  updateSelectedtipOption,
  updateTip,
};
