import { equals } from "ramda";

import { LOG_OUT } from "actions/authentication";
import {
  UPDATE_CURBSIDE_PICKUP_INSTRUCTIONS,
  UPDATE_DELIVERY_INSTRUCTIONS,
  UPDATE_IS_CURBSIDE_PICKUP,
} from "actions/online_ordering/checkout";
import {
  APPLY_OPTIONS_FROM_LOCAL_STORAGE,
  CHANGE_DELIVERY_TIME_FRAME,
  CHANGE_TRANSPORT_METHOD,
  EDIT_ORDER_OPTIONS,
  SET_DINE_IN_TABLE_PROPS,
  SET_ORDER_TIME_ERROR,
  UPDATE_DELIVERY_ADDRESS_VALIDITY,
  UPDATE_ORDER_DATE,
  UPDATE_ORDER_TIME,
} from "actions/online_ordering/ordering_options";
import { saveOrderingOptionsToLocalStorage } from "helpers/local_storage";

const initialState = {
  canUpdate: true, // is the update button enabled
  deliveryInstructions: "",
  isCurbsidePickup: false,
  curbsidePickupInstructions: "",
  isEditable: true,
  orderAheadDate: "",
  orderAheadTime: "",
  selectedTimeFrame: "ASAP",
  selectedTransport: "Pick up",
  timeError: false,
  dineInTableId: "",
  dineInTableName: "",
};

function orderingOptions(state = initialState, action) {
  switch (action.type) {
    case CHANGE_DELIVERY_TIME_FRAME:
      return {
        ...state,
        selectedTimeFrame: action.timeFrame,
        orderAheadDate: "",
        orderAheadTime: "",
        isEditable: true,
      };

    case UPDATE_ORDER_DATE: {
      return {
        ...state,
        orderAheadDate: action.date,
        orderAheadTime: "",
        timeError: false,
      };
    }

    case UPDATE_ORDER_TIME: {
      return {
        ...state,
        orderAheadTime: action.time,
        timeError: false,
      };
    }

    case SET_ORDER_TIME_ERROR: {
      return {
        ...state,
        isEditable: true,
        orderAheadDate: "",
        orderAheadTime: "",
        timeError: true,
      };
    }

    case APPLY_OPTIONS_FROM_LOCAL_STORAGE: {
      const { options } = action;
      return {
        ...state,
        ...options,
      };
    }

    case CHANGE_TRANSPORT_METHOD: {
      const updatedTransportMethod = {
        ...state,
        selectedTransport: action.method,
        canUpdate: !equals("Delivery", action.method),
        orderAheadTime: "",
      };

      saveOrderingOptionsToLocalStorage(updatedTransportMethod);

      return updatedTransportMethod;
    }

    case UPDATE_DELIVERY_ADDRESS_VALIDITY:
      return {
        ...state,
        canUpdate: action.isValid,
      };

    case UPDATE_DELIVERY_INSTRUCTIONS:
      return {
        ...state,
        deliveryInstructions: action.instructions,
      };

    case UPDATE_IS_CURBSIDE_PICKUP:
      return {
        ...state,
        isCurbsidePickup: action.isCurbsidePickup,
      };

    case UPDATE_CURBSIDE_PICKUP_INSTRUCTIONS:
      return {
        ...state,
        curbsidePickupInstructions: action.instructions,
      };

    case EDIT_ORDER_OPTIONS: {
      const updatedOptions = {
        ...state,
        isEditable: action.isEditable,
      };

      saveOrderingOptionsToLocalStorage(updatedOptions);

      return updatedOptions;
    }

    case LOG_OUT: {
      return initialState;
    }

    case SET_DINE_IN_TABLE_PROPS: {
      return {
        ...state,
        dineInTableId: action.tableId,
        dineInTableName: action.tableName,
      };
    }

    default:
      return state;
  }
}

export default orderingOptions;
