import moment from "moment";
import { equals, omit } from "ramda";

import {
  applyCartFromLocalStorage,
  applyReorderFromLocalStorage,
} from "actions/online_ordering/cart";
import { applyOrderAddressFromLocalStorage } from "actions/online_ordering/checkout";
import { applyOptionsFromLocalStorage } from "actions/online_ordering/ordering_options";
import { getPrettyUrl } from "get_base_uri";

import isLoggedIn from "is_logged_in";
import storage from "storage";

const CART_LIFE_IN_DAYS = 10;
// All store-specific keys (ie cartItems) must
// have a prettyUrl prefix since localStorage is shared by
// all online ordering locations

// If a user is NOT logged In only the cart items are persisted
// Persisting address is a personal security risk

const setCartExpirationDate = prettyUrl =>
  storage.setItem(
    `${prettyUrl}_cartExpiration`,
    moment().add(CART_LIFE_IN_DAYS, "days").unix()
  );

const itemExpired = dateAsUnix => {
  const expirationWithBuffer = moment.unix(dateAsUnix).subtract(20, "minutes");

  return moment().isSameOrAfter(expirationWithBuffer);
};

const saveCartToLocalStorage = items => {
  const prettyUrl = getPrettyUrl();

  storage.setItem(`${prettyUrl}_cartItems`, JSON.stringify(items));
  setCartExpirationDate(prettyUrl);
};

const saveReorderToLocalStorage = items => {
  const prettyUrl = getPrettyUrl();
  storage.setItem(`${prettyUrl}_reorder`, JSON.stringify(items));
};

const saveOrderingOptionsToLocalStorage = options => {
  const prettyUrl = getPrettyUrl();

  const optionsToPersist = omit(
    [
      "dineInTableId",
      "dineInTableName",
      "orderAheadDate",
      "orderAheadTime",
      "selectedTimeFrame",
    ],
    options
  );

  if (isLoggedIn() && !equals(options.selectedTransport, "Dine in")) {
    storage.setItem(
      `${prettyUrl}_orderOptions`,
      JSON.stringify(optionsToPersist)
    );
    setCartExpirationDate(prettyUrl);
  }
};

const saveOrderAddressToLocalStorage = address => {
  const prettyUrl = getPrettyUrl();

  if (isLoggedIn()) {
    storage.setItem(`${prettyUrl}_orderAddress`, JSON.stringify(address));
    setCartExpirationDate(prettyUrl);
  }
};

const getOrderAddressFromLocalStorage = () => {
  const prettyUrl = getPrettyUrl();

  return JSON.parse(storage.getItem(`${prettyUrl}_orderAddress`));
};

const removeCartFromLocalStorage = () => {
  const prettyUrl = getPrettyUrl();

  storage.removeItem(`${prettyUrl}_cartItems`);
  storage.removeItem(`${prettyUrl}_cartExpiration`);
  storage.removeItem(`${prettyUrl}_orderOptions`);
  storage.removeItem(`${prettyUrl}_orderAddress`);
  storage.removeItem(`${prettyUrl}_reorder`);
};

const removeUserFromLocalStorage = (removeCart = true) => {
  storage.removeItem("accessToken");
  storage.removeItem("rememberToken");
  storage.removeItem("idToken");
  storage.removeItem("user");
  storage.removeItem("jwtExpiration");

  if (removeCart) removeCartFromLocalStorage();
};

const checkAndApplyLocalStorage = (menu, stockLevels, selectedTransport) => {
  return dispatch => {
    const prettyUrl = getPrettyUrl();

    const serializedCart = storage.getItem(`${prettyUrl}_cartItems`);
    const serializedOptions = storage.getItem(`${prettyUrl}_orderOptions`);
    const serializedAddress = storage.getItem(`${prettyUrl}_orderAddress`);
    const expirationDate = storage.getItem(`${prettyUrl}_cartExpiration`);
    const reorder = storage.getItem(`${prettyUrl}_reorder`);

    if (reorder) {
      dispatch(applyReorderFromLocalStorage(reorder, menu, stockLevels));
    } else {
      if (expirationDate && itemExpired(expirationDate)) {
        removeCartFromLocalStorage();
      }

      if (
        serializedOptions &&
        !itemExpired(expirationDate) &&
        !equals(selectedTransport, "Dine in")
      ) {
        dispatch(applyOptionsFromLocalStorage(JSON.parse(serializedOptions)));
      }

      if (serializedAddress && !itemExpired(expirationDate)) {
        dispatch(
          applyOrderAddressFromLocalStorage(JSON.parse(serializedAddress))
        );
      }

      if (serializedCart && !itemExpired(expirationDate)) {
        dispatch(applyCartFromLocalStorage(JSON.parse(serializedCart)));
      }
    }
  };
};

const saveOrderInfoToLocalStorage = orderInfo => {
  const prettyUrl = getPrettyUrl();
  storage.setItem(`${prettyUrl}_orderInfo`, JSON.stringify(orderInfo));
};

const getOrderInfoFromLocalStorage = () => {
  const prettyUrl = getPrettyUrl();
  return JSON.parse(storage.getItem(`${prettyUrl}_orderInfo`));
};

const removeOrderInfoFromLocalStorage = () => {
  const prettyUrl = getPrettyUrl();
  localStorage.removeItem(`${prettyUrl}_orderInfo`);
};

export {
  checkAndApplyLocalStorage,
  removeCartFromLocalStorage,
  removeUserFromLocalStorage,
  saveCartToLocalStorage,
  saveOrderAddressToLocalStorage,
  getOrderAddressFromLocalStorage,
  saveOrderingOptionsToLocalStorage,
  setCartExpirationDate,
  itemExpired,
  saveReorderToLocalStorage,
  saveOrderInfoToLocalStorage,
  getOrderInfoFromLocalStorage,
  removeOrderInfoFromLocalStorage,
};
