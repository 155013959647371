import { LDProvider } from "launchdarkly-react-client-sdk";
import React, { type ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

import { LAUNCH_DARKLY_CLIENT_ID } from "api_urls";
import { DigitalGiftCardsContainer } from "containers/DigitalGiftCardsContainer";

import { usePrettyUrl } from "helpers/hooks/usePrettyUrl";

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 5000 * 60 } },
});

export interface DigitalGiftCardAppProps {
  children: ReactNode;
}

export const DigitalGiftCardApp = ({ children }: DigitalGiftCardAppProps) => {
  const brandPrettyUrl = usePrettyUrl();
  return (
    <QueryClientProvider client={queryClient}>
      <LDProvider
        clientSideID={LAUNCH_DARKLY_CLIENT_ID}
        user={{ kind: "user", key: brandPrettyUrl }}>
        <BrowserRouter>
          {children ?? <DigitalGiftCardsContainer />}
        </BrowserRouter>
      </LDProvider>
    </QueryClientProvider>
  );
};
