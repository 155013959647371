import spinnerAnimation from "images/spinner_animation.gif";
import successAnimation from "images/success_animation.gif";
import PropTypes from "prop-types";
import { equals } from "ramda";
import React from "react";

import {
  PUBLISHING,
  PUBLISH_FAIL,
  PUBLISH_SUCCESS,
  START_PUBLISH,
} from "actions/online_ordering/publish_status";

import Button from "components/button";
import Modal from "components/modal";

function PublishConfirmModal({
  publishStatus,
  onCancelPublish,
  onConfirmPublish,
  onDonePublish,
}) {
  let cancelButton;
  if (equals("NOT_STARTED", publishStatus)) {
    // do nothing
    return null;
  }
  let title = "";
  let modalContent;
  if (equals(START_PUBLISH, publishStatus)) {
    cancelButton = onCancelPublish;
    title = "Are you sure?";
    modalContent = (
      <div className="center">
        <div className="body">
          You’re about to publish your online ordering page for the world to
          see. Do you want to continue?
        </div>
        <Button onClick={onConfirmPublish}>
          Publish my online ordering page
        </Button>
        <Button type="secondary" onClick={onCancelPublish}>
          No, go back
        </Button>
      </div>
    );
  }
  if (equals(PUBLISHING, publishStatus)) {
    modalContent = (
      <div className="center">
        <div>
          <img className="animation" src={spinnerAnimation} />
        </div>
        <div>
          <h3>Publishing your online ordering page</h3>
        </div>
      </div>
    );
  }

  if (equals(PUBLISH_SUCCESS, publishStatus)) {
    title = "Done and done!";
    modalContent = (
      <div className="center">
        <img className="animation" src={successAnimation} />
        <div className="body">
          Your online ordering page is now live. Customer orders from this page
          will flow directly into your Breadcrumb POS. Questions? Email{" "}
          <a href="mailto:members@upserve.com">members@upserve.com</a>
        </div>
        <Button onClick={onDonePublish}>Thanks!</Button>
      </div>
    );
  }

  if (equals(PUBLISH_FAIL, publishStatus)) {
    title = "Uh oh!";
    modalContent = (
      <div className="center">
        <div className="body">
          We were unable to publish your page. Please email{" "}
          <a href="mailto:members@upserve.com">members@upserve.com</a>.
        </div>
        <Button onClick={onCancelPublish}>OK</Button>
      </div>
    );
  }

  return (
    <Modal onClose={cancelButton} className="order-confirm-modal" title={title}>
      <div className="modal-content">{modalContent}</div>
    </Modal>
  );
}

PublishConfirmModal.propTypes = {
  publishStatus: PropTypes.string.isRequired,
  onCancelPublish: PropTypes.func.isRequired,
  onConfirmPublish: PropTypes.func.isRequired,
  onDonePublish: PropTypes.func.isRequired,
};

export default PublishConfirmModal;
