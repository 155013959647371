import { Money } from "@upserve/financials";
import { any, contains, equals, isEmpty, not } from "ramda";
import React, { type ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";

import MuiCheckbox from "components/mui_checkbox";
import MuiSelect from "components/mui_select";
import MuiTextfield from "components/mui_textfield";

import useViewportDimensions from "helpers/hooks/useViewportDimensions";
import VALIDATION_MESSAGES from "helpers/validation/messages";
import Validators from "helpers/validation/validators";

import type { DigitalGiftCardsSettings } from "types/digital_gift_cards";

const StyledCardSubsection = styled.div`
  font-size: 1rem; // 14px based on 14px root font-size; decrease to .875rem for 16px root
  line-height: 1.5rem; // ~22.5px based on 14px root font-size; decrease to 1.33rem for 16px root
  margin-bottom: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 16px;
  grid-template-areas:
    "header header"
    "amount deliveryMethod"
    "senderEmail senderEmail"
    "senderPhone senderPhone"
    "sendAsGift sendAsGift";

  .MuiFormControl-root,
  h2 {
    margin-bottom: 16px;
  }
`;

const StyledDetailsSubsection = styled.div`
  font-size: 1rem; // 14px based on 14px root font-size; decrease to .875rem for 16px root
  line-height: 1.5rem; // ~22.5px based on 14px root font-size; decrease to 1.33rem for 16px root
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;

  .MuiFormControl-root,
  h2 {
    margin-bottom: 16px;
  }
`;

const StyledSubheader = styled.h2`
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  grid-area: header;
`;

export enum deliveryOptions {
  EMAIL = "Email",
  SMS = "SMS",
}

export type DigitalGiftCardOrderForm = {
  amount: string;
  deliveryMethodType: deliveryOptions;
  sendAsGift: boolean;
  senderName: string;
  message: string;
  recipientName: string;
  recipientEmail: string;
  recipientPhone: string;
  senderEmail: string;
  senderPhone: string;
  sendToSelf: false;
};

type OrderFormProps = {
  settings: DigitalGiftCardsSettings;
  setFormValid: React.Dispatch<React.SetStateAction<boolean>>;
  setFormValues: React.Dispatch<React.SetStateAction<any>>;
};

export default function OrderForm({
  settings,
  setFormValid,
  setFormValues,
}: OrderFormProps) {
  const emailMaxChars = 200;
  const smsMaxChars = 160;

  const { mobile } = useViewportDimensions();

  const { minValue, maxValue } = settings;

  const emptyOrderInfo: DigitalGiftCardOrderForm = {
    amount: "",
    deliveryMethodType: deliveryOptions.EMAIL,
    sendAsGift: false,
    message: "",
    recipientEmail: "",
    recipientName: "",
    recipientPhone: "",
    senderEmail: "",
    senderName: "",
    senderPhone: "",
    sendToSelf: false,
  };

  const defaultOrderInfoErrors = {
    amount: false,
    recipientEmail: false,
    recipientName: false,
    recipientPhone: false,
    senderEmail: false,
    senderName: false,
    senderPhone: false,
  };

  const [orderInfo, setOrderInfo] = useState(emptyOrderInfo);
  const [orderInfoErrors, setOrderInfoErrors] = useState(
    defaultOrderInfoErrors
  );
  const [amountErrorMessage, setAmountErrorMessage] = useState("");

  const updateOrderInfoProperty = (key: string, val: string) => {
    setOrderInfo({
      ...orderInfo,
      [key]: val,
    });
  };

  const onTextFieldUpdate = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    let entry = e.target.value;
    if (contains(e.target.name, ["senderPhone", "recipientPhone"])) {
      entry = entry.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }
    updateOrderInfoProperty(e.target.name, entry);
  };

  const onSelectUpdate = (e: ChangeEvent<{ value: any }>) => {
    updateOrderInfoProperty("deliveryMethodType", e.target.value);
  };

  const toggleSendAsGift = () => {
    setOrderInfo({
      ...orderInfo,
      sendAsGift: !orderInfo.sendAsGift,
    });
  };

  const [maxMessageChars, setMaxMessageChars] = useState(emailMaxChars);
  useEffect(() => {
    setMaxMessageChars(
      equals(deliveryOptions.EMAIL, orderInfo.deliveryMethodType)
        ? emailMaxChars - orderInfo.message.length
        : smsMaxChars - orderInfo.message.length
    );
  }, [orderInfo.message, orderInfo.deliveryMethodType]);

  const isValueEmptyOrError = name => {
    return isEmpty(orderInfo[name]) || orderInfoErrors[name];
  };

  const isValueInvalid = name => {
    switch (name) {
      case "senderPhone":
      case "recipientPhone":
        return (
          equals(deliveryOptions.SMS, orderInfo.deliveryMethodType) &&
          isValueEmptyOrError(name)
        );
      case "recipientEmail":
        return (
          equals(deliveryOptions.EMAIL, orderInfo.deliveryMethodType) &&
          isValueEmptyOrError(name)
        );
      default:
        return isValueEmptyOrError(name);
    }
  };

  useEffect(() => {
    const cardHasErrors = any(isValueInvalid, [
      "amount",
      "senderEmail",
      "senderPhone",
    ]);

    const detailHasErrors =
      equals(true, orderInfo.sendAsGift) &&
      any(isValueInvalid, [
        "senderName",
        "recipientName",
        "recipientPhone",
        "recipientEmail",
      ]);

    setFormValid(not(cardHasErrors || detailHasErrors));
    // added since a new eslint rule,
    // feel free to fix this eslint error if you can test it properly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderInfoErrors, orderInfo.sendAsGift, orderInfo.deliveryMethodType]);

  // added since a new eslint rule,
  // feel free to fix this eslint error if you can test it properly
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setFormValues(orderInfo), [orderInfo]);
  // name, email, and phone validators
  const validateField = e => {
    switch (e.target.name) {
      case "recipientEmail":
      case "senderEmail":
        setOrderInfoErrors({
          ...orderInfoErrors,
          [e.target.name]: !Validators.isValidEmail(orderInfo[e.target.name]),
        });
        break;

      case "recipientPhone":
      case "senderPhone":
        setOrderInfoErrors({
          ...orderInfoErrors,
          [e.target.name]: !Validators.isValidPhoneNumber(
            orderInfo[e.target.name]
          ),
        });
        break;

      case "recipientName":
      case "senderName":
        setOrderInfoErrors({
          ...orderInfoErrors,
          [e.target.name]: !Validators.isNotEmpty(orderInfo[e.target.name]),
        });
        break;

      default:
    }
  };

  // amount-specific event handlers and validators
  const onAmountChange = amount => {
    const amountAsNumber: number =
      typeof amount === "string" ? parseFloat(amount) : amount;
    if (equals(NaN, amountAsNumber)) {
      setAmountErrorMessage(
        `Gift card amount must be between ${minValue.format()} and ${maxValue.format()}.`
      );
      updateOrderInfoProperty("amount", amount);
      return;
    }
    if (Money.fromDollars(amountAsNumber)) {
      if (
        Money.fromDollars(amountAsNumber).lt(minValue) ||
        maxValue.lt(Money.fromDollars(amountAsNumber))
      ) {
        setAmountErrorMessage(
          `Gift card amount must be between ${minValue.format()} and ${maxValue.format()}.`
        );
      } else {
        setAmountErrorMessage("");
      }
    } else {
      setAmountErrorMessage(
        `Gift card amount must be between ${minValue.format()} and ${maxValue.format()}.`
      );
    }
    updateOrderInfoProperty("amount", amount);
  };

  const onAmountBlur = amount => {
    if (isEmpty(amount)) {
      setAmountErrorMessage(
        `Gift card amount must be between ${minValue.format()} and ${maxValue.format()}.`
      );
      return;
    }
    if (isEmpty(amountErrorMessage)) {
      const amountAsNumber: number =
        typeof amount === "string" ? parseFloat(amount) : amount;
      updateOrderInfoProperty("amount", amountAsNumber.toFixed(2).toString());
      setOrderInfoErrors({
        ...orderInfoErrors,
        amount: false,
      });
      return;
    }
    setOrderInfoErrors({
      ...orderInfoErrors,
      amount: true,
    });
  };

  const giftDetails = (
    <StyledDetailsSubsection>
      <StyledSubheader>Gift Details</StyledSubheader>
      <MuiTextfield
        error={orderInfoErrors.senderName}
        errorMsg={
          orderInfoErrors.senderName
            ? VALIDATION_MESSAGES.isRequired("Sender")
            : null
        }
        label="Sender Name"
        name="senderName"
        onBlur={validateField}
        onChange={onTextFieldUpdate}
        required
        value={orderInfo.senderName}
      />
      <MuiTextfield
        error={orderInfoErrors.recipientName}
        errorMsg={
          orderInfoErrors.recipientName
            ? VALIDATION_MESSAGES.isRequired("Recipient")
            : null
        }
        label="Recipient Name"
        name="recipientName"
        onBlur={validateField}
        onChange={onTextFieldUpdate}
        required
        value={orderInfo.recipientName}
      />
      {equals(deliveryOptions.EMAIL, orderInfo.deliveryMethodType) && (
        <MuiTextfield
          error={orderInfoErrors.recipientEmail}
          errorMsg={
            orderInfoErrors.recipientEmail ? VALIDATION_MESSAGES.email : null
          }
          label="Recipient Email"
          name="recipientEmail"
          onBlur={validateField}
          onChange={onTextFieldUpdate}
          required
          value={orderInfo.recipientEmail}
        />
      )}
      {equals(deliveryOptions.SMS, orderInfo.deliveryMethodType) && (
        <MuiTextfield
          label="Recipient Phone Number"
          name="recipientPhone"
          value={orderInfo.recipientPhone}
          onChange={onTextFieldUpdate}
          onBlur={validateField}
          error={orderInfoErrors.recipientPhone}
          errorMsg={
            orderInfoErrors.recipientPhone ? VALIDATION_MESSAGES.phone : null
          }
          required
        />
      )}
      <MuiTextfield
        helperText={`${maxMessageChars} characters left`}
        label="Message (optional)"
        maxLength={
          equals(deliveryOptions.SMS, orderInfo.deliveryMethodType)
            ? smsMaxChars
            : emailMaxChars
        }
        multiline
        name="message"
        onChange={onTextFieldUpdate}
        rows={5}
        value={orderInfo.message}
      />
    </StyledDetailsSubsection>
  );

  return (
    <>
      <StyledCardSubsection>
        <StyledSubheader>Gift Card</StyledSubheader>
        <div style={{ gridArea: "amount" }}>
          <MuiTextfield
            errorMsg={amountErrorMessage}
            label={mobile ? "Amount" : "Gift Card Amount"}
            name="amount"
            onBlur={e => {
              onAmountBlur(e.target.value);
            }}
            onChange={e => onAmountChange(e.target.value)}
            required
            startAdornment="$"
            value={orderInfo.amount}
          />
        </div>
        <div style={{ gridArea: "deliveryMethod" }}>
          <MuiSelect
            label="Delivery Method"
            menuItems={[
              { value: deliveryOptions.EMAIL },
              { value: deliveryOptions.SMS },
            ]}
            name="deliveryMethod"
            onChange={onSelectUpdate}
            value={orderInfo.deliveryMethodType}
          />
        </div>
        <div style={{ gridArea: "senderEmail" }}>
          <MuiTextfield
            error={orderInfoErrors.senderEmail}
            errorMsg={
              orderInfoErrors.senderEmail ? VALIDATION_MESSAGES.email : null
            }
            label="Your Email"
            name="senderEmail"
            onBlur={validateField}
            onChange={onTextFieldUpdate}
            required
            value={orderInfo.senderEmail}
          />
        </div>
        {equals(deliveryOptions.SMS, orderInfo.deliveryMethodType) && (
          <div style={{ gridArea: "senderPhone" }}>
            <MuiTextfield
              error={orderInfoErrors.senderPhone}
              errorMsg={
                orderInfoErrors.senderPhone ? VALIDATION_MESSAGES.phone : null
              }
              label="Phone Number"
              name="senderPhone"
              onBlur={validateField}
              onChange={onTextFieldUpdate}
              required
              value={orderInfo.senderPhone}
            />
          </div>
        )}
        <div style={{ gridArea: "sendAsGift" }}>
          <MuiCheckbox
            checked={orderInfo.sendAsGift}
            label="Send as a gift"
            onChange={() => toggleSendAsGift()}
          />
        </div>
      </StyledCardSubsection>
      {orderInfo.sendAsGift && giftDetails}
    </>
  );
}
