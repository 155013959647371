import PropTypes from "prop-types";
import { objOf, partial, pipe } from "ramda";
import React from "react";

import Checkbox from "components/checkbox";
import Input from "components/input";
import Tooltip from "components/online_ordering/tooltip";
import { withTracking } from "helpers/analytics";
import { handleErrors } from "helpers/credit_cards";
import { getTargetChecked, getTargetValue } from "helpers/event";

function CustomerInfo({
  contactInfo,
  onContactInfoUpdate,
  onValidateAndSaveContactInfo,
  saveOnBlur,
}) {
  const { firstName, lastName, email, phone, textAlerts } = contactInfo;

  return (
    <div className="customer-info account-section form">
      <div className="row first-name">
        <label htmlFor="customer-info-first-name">First name</label>
        <Input
          id="customer-info-first-name"
          type="text"
          autoComplete="given-name"
          value={firstName}
          error={handleErrors("firstName", contactInfo)}
          onBlur={withTracking(
            partial(onValidateAndSaveContactInfo, [
              "firstName",
              contactInfo,
              saveOnBlur,
            ]),
            "OLO - enter/edit first name"
          )}
          required
          onChange={pipe(
            getTargetValue,
            objOf("firstName"),
            onContactInfoUpdate
          )}
        />
      </div>

      <div className="row last-name">
        <label htmlFor="customer-info-last-name">Last name</label>
        <Input
          id="customer-info-last-name"
          type="text"
          autoComplete="family-name"
          value={lastName}
          error={handleErrors("lastName", contactInfo)}
          onBlur={withTracking(
            partial(onValidateAndSaveContactInfo, [
              "lastName",
              contactInfo,
              saveOnBlur,
            ]),
            "OLO - enter/edit last name"
          )}
          required
          onChange={pipe(
            getTargetValue,
            objOf("lastName"),
            onContactInfoUpdate
          )}
        />
      </div>

      <div className="row email">
        <label htmlFor="customer-info-email">Email</label>
        <Input
          id="customer-info-email"
          type="text"
          autoComplete="email"
          value={email}
          error={handleErrors("email", contactInfo)}
          onBlur={partial(onValidateAndSaveContactInfo, [
            "email",
            contactInfo,
            saveOnBlur,
          ])}
          required
          onChange={pipe(getTargetValue, objOf("email"), onContactInfoUpdate)}
        />
      </div>

      <div className="row phone">
        <label htmlFor="customer-info-phone">Phone</label>
        <Input
          id="customer-info-phone"
          type="tel"
          autoComplete="tel"
          value={phone}
          error={handleErrors("phone", contactInfo)}
          onBlur={withTracking(
            partial(onValidateAndSaveContactInfo, [
              "phone",
              contactInfo,
              saveOnBlur,
            ]),
            "OLO - enter/edit phone"
          )}
          required
          onChange={pipe(getTargetValue, objOf("phone"), onContactInfoUpdate)}
        />
      </div>
      <div className="text-alerts">
        <Checkbox
          label="Send me text updates about my order"
          checked={textAlerts}
          onChangeHandler={withTracking(event => {
            pipe(
              getTargetChecked,
              objOf("textAlerts"),
              onContactInfoUpdate
            )(event);
            // If we want to valid on save as well, the new textAlert value must be
            // used, since it is not persisted and available yet!
            const updatedContactInfo = {
              ...contactInfo,
              textAlerts: getTargetChecked(event),
            };
            onValidateAndSaveContactInfo(
              "textAlerts",
              updatedContactInfo,
              saveOnBlur
            );
          }, "OLO - click text update checkbox")}
        />
        <Tooltip>
          <>
            Lightspeed (U-Series) does not charge for SMS messages. Standard
            text messaging rates may apply according to your mobile phone plan.
          </>
        </Tooltip>
      </div>
    </div>
  );
}

CustomerInfo.propTypes = {
  contactInfo: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    textAlerts: PropTypes.bool.isRequired,
  }),
  onContactInfoUpdate: PropTypes.func.isRequired,
  onValidateAndSaveContactInfo: PropTypes.func.isRequired,
  saveOnBlur: PropTypes.bool.isRequired,
};

export default CustomerInfo;
