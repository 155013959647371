import PropTypes from "prop-types";
import { equals, find, isEmpty, not, partial, propEq } from "ramda";
import React from "react";

import Icon from "components/icon";
import { formatDayOptionLabel } from "helpers/order_times";

function OrderingOptions({
  pickupOnly,
  selectedOptionName,
  contactInfo,
  onEditOrderOptions,
  storeInfo: { fulfillmentTypes, futureOrder },
  orderingOptions,
}) {
  if (equals(selectedOptionName, "Dine in")) {
    return (
      <div className="ordering-options">
        <div className="text">
          <div className="method">Dine in</div>
          <div className="details">
            <div>{orderingOptions.dineInTableName}</div>
          </div>
        </div>
      </div>
    );
  }

  const methodDetails = find(
    propEq("name", selectedOptionName),
    fulfillmentTypes
  );

  let address;
  if (equals("Delivery", methodDetails.name)) {
    const { line1, line2 = "", city, state, zip } = contactInfo.address;
    address = (
      <div>
        <div>{`${line1}, ${city}, ${state} ${zip}`}</div>
        <div>{`${line2}`}</div>
      </div>
    );
  }

  let arrow;
  let editTransport;
  if (!pickupOnly || futureOrder) {
    arrow = <Icon type="chevron-right" />;
    editTransport = partial(onEditOrderOptions, [true]);
  }

  let timeEstimate;
  if (
    equals(orderingOptions.selectedTimeFrame, "Later") &&
    not(isEmpty(orderingOptions.orderAheadDate))
  ) {
    const formattedTime = isEmpty(orderingOptions.orderAheadTime)
      ? ""
      : ` - ${orderingOptions.orderAheadTime}`;
    timeEstimate = `${formatDayOptionLabel(
      orderingOptions.orderAheadDate
    )}${formattedTime}`;
  } else {
    timeEstimate = `${methodDetails.estimate.join(" - ")}m`;
  }

  return (
    <div
      className="ordering-options clickable"
      onClick={editTransport}
      role="button"
      tabIndex={0}>
      <div className="text">
        <div className="method">{methodDetails.name}</div>
        <div className="details">
          <div>
            {methodDetails.estimateCopy}: {timeEstimate}
          </div>
          {address}
        </div>
      </div>
      {arrow}
    </div>
  );
}

OrderingOptions.propTypes = {
  selectedOptionName: PropTypes.string.isRequired,
  pickupOnly: PropTypes.bool.isRequired,
  onEditOrderOptions: PropTypes.func.isRequired,
  storeInfo: PropTypes.object.isRequired,
  contactInfo: PropTypes.object.isRequired,
  orderingOptions: PropTypes.object.isRequired,
};

export default OrderingOptions;
