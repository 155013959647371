import PropTypes from "prop-types";
import { find, propEq } from "ramda";
import React from "react";

import formatMoney from "sig/formatters/money";

import Button from "components/button";
import Input from "components/input";

import LoyaltyImage from "components/loyalty/loyalty_image";

import trackAnalytic from "helpers/analytics";
import Validators from "helpers/validation/validators";

const validators = {
  email: Validators.isValidEmail,
};

class EmailEntry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
    };

    this.updateEmail = this.updateEmail.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  updateEmail(event) {
    this.setState({
      email: event.target.value,
    });
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleSubmit();
    }
  }

  handleSubmit() {
    const { onSubmit } = this.props;
    const { email } = this.state;

    if (!Validators.isValid(this.state, validators)) {
      this.setState({ error: "Please enter a valid email address" });
    } else {
      trackAnalytic('Click "Join for FREE"', { email });
      onSubmit(email);
    }
  }

  render() {
    const { brandInfo, loyalty } = this.props;

    const welcomeReward = find(propEq("isFpo", true), loyalty.rewards);
    const pointReward = find(propEq("isFpo", false), loyalty.rewards);
    const reward = welcomeReward || pointReward;

    let description;
    if (welcomeReward) {
      description = (
        <div className="description">
          Get{" "}
          <span className="highlight">{welcomeReward.rewardValue} back</span> on
          your next purchase of {welcomeReward.threshold} or more by joining our
          free loyalty program. After that, you get{" "}
          <span className="highlight">{pointReward.rewardValue} back</span> for
          every{" "}
          <span className="highlight">
            {formatMoney(pointReward.price, { precision: 0 })} you spend.
          </span>
        </div>
      );
    } else {
      description = (
        <div className="description">
          Get <span className="highlight">{pointReward.rewardValue} back</span>{" "}
          for every{" "}
          <span className="highlight">
            {formatMoney(pointReward.price, { precision: 0 })} you spend
          </span>{" "}
          by joining our free loyalty program.
        </div>
      );
    }

    return (
      <div className="email-entry reward">
        <LoyaltyImage type="loyalty" imagePath={reward.coverPhoto} />
        <div className="reward-content">
          <h2>Welcome to the {brandInfo.locationName} rewards program!</h2>

          {description}

          <div className="email-form">
            <Input
              id="email-entry-email"
              error={this.state.error}
              className="text"
              type="text"
              placeholder="Enter your email"
              onChange={this.updateEmail}
              onKeyDown={this.handleKeyDown}
              autoFocus
            />
            <Button onClick={this.handleSubmit}>Join for FREE</Button>
          </div>
        </div>
      </div>
    );
  }
}

EmailEntry.propTypes = {
  brandInfo: PropTypes.object.isRequired,
  loyalty: PropTypes.shape({
    progression: PropTypes.shape({
      pointsAccrued: PropTypes.number,
      fpoRedeemed: PropTypes.bool,
      pointRewardLoaded: PropTypes.bool,
    }),
  }),
  onSubmit: PropTypes.func.isRequired,
};

export default EmailEntry;
