import moment from "moment";
import PropTypes from "prop-types";
import { partial } from "ramda";
import React from "react";
import formatMoney from "sig/formatters/money";
import Button from "components/button";

import { withTracking } from "helpers/analytics";
import isOLO from "helpers/is_olo";

function OrderRow({ order, reorder }) {
  const { fulfillmentInfo, confirmationCode, timePlaced, charges } = order;

  let orderNumber;
  if (isOLO()) {
    orderNumber = <td className="order-num">{confirmationCode}</td>;
  }

  return (
    <tr className="order-row">
      <td className="date">{moment(timePlaced).format("dddd, MMM D")}</td>
      <td className="method">{fulfillmentInfo.type}</td>
      <td className="total">{formatMoney(charges.total * 0.01)}</td>
      {orderNumber}
      <td>
        <Button
          classname="button"
          onClick={withTracking(
            partial(reorder, [order.items]),
            "OLO - Account - click order again"
          )}
          type="primary">
          Order Again!
        </Button>
      </td>
      <td className="space-fill" />
    </tr>
  );
}

OrderRow.propTypes = {
  order: PropTypes.object.isRequired,
  reorder: PropTypes.func.isRequired,
};

export default OrderRow;
