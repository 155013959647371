import { LDProvider } from "launchdarkly-react-client-sdk";
import React, { type ReactNode, useMemo } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import type { Store } from "redux";
import { OloAppBootstrap } from "./OloAppBootstrap";
import { LAUNCH_DARKLY_CLIENT_ID } from "api_urls";
import { usePrettyUrl } from "helpers/hooks/usePrettyUrl";

export interface OloAppBaseProps {
  store: Store<unknown>;
  identifyTrackingMessage: string;
  children: ReactNode;
}

export const OloAppBase = ({
  store,
  identifyTrackingMessage,
  children,
}: OloAppBaseProps) => {
  const storePrettyUrl = usePrettyUrl();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: { queries: { staleTime: 5000 * 60 } },
      }),
    []
  );

  return (
    // @ts-expect-error redux error will be ignored
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <LDProvider
          clientSideID={LAUNCH_DARKLY_CLIENT_ID}
          user={{ kind: "user", key: storePrettyUrl }}>
          <BrowserRouter>
            <OloAppBootstrap identifyTrackingMessage={identifyTrackingMessage}>
              {children}
            </OloAppBootstrap>
          </BrowserRouter>
        </LDProvider>
      </QueryClientProvider>
    </Provider>
  );
};
