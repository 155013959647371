import styled from "styled-components";

export const StyledFormControl = styled.div`
  display: flex;
  flex-direction: column;

  padding-bottom: 30px;

  position: relative;

  &.expiryMonth,
  &.expiryYear,
  &.cvv {
    width: 30%;

    @media (max-width: 307px) {
      width: 47%;
    }
  }
`;
