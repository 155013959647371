import { camelizeKeys } from "humps";

import { BRAND_BUCKET, ORDERS_URL } from "api_urls";

import {
  checkStatus,
  cloudfrontGetRequest,
  parseJSON,
  postRequest,
} from "helpers/ajax_request";
import { transformSettingsApiResponse } from "helpers/transforms/digital_gift_cards";

import type {
  DigitalGiftCardOrderApiResponse,
  DigitalGiftCardsOrderApiRequest,
  DigitalGiftCardsSettings,
} from "types/digital_gift_cards";

export function getDigitalGiftCardsSettings(
  prettyUrl: string
): Promise<DigitalGiftCardsSettings> {
  return fetch(
    `${BRAND_BUCKET}/${prettyUrl}/digital_gift_card.json`,
    cloudfrontGetRequest() as RequestInit
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys)
    .then(transformSettingsApiResponse);
}

export function postCreditCardPayment<
  T extends DigitalGiftCardOrderApiResponse = DigitalGiftCardOrderApiResponse,
>(orderInfo: DigitalGiftCardsOrderApiRequest): Promise<T> {
  return fetch(
    `${ORDERS_URL}/gift_cards/issue`,
    postRequest(orderInfo) as RequestInit
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(response => {
      const responseCamelized = camelizeKeys(response);
      return {
        ...responseCamelized,
        payment: {
          ...responseCamelized.payment,
          // Do NOT camelize the action object
          action: response.payment.action,
        },
      };
    });
}
