import PropTypes from "prop-types";
import { partial, pipe } from "ramda";
import React from "react";

import Button from "components/button";
import Input from "components/input";
import Loader from "components/loader";
import Modal from "components/modal";
import { getTargetValue, submitOnEnter } from "helpers/event";
import handleFormErrors from "helpers/handle_form_errors";

function SetPassword({
  onSetPassword,
  onUpdateField,
  authentication,
  validatePasswordOnBlur,
}) {
  const { password, confirmPassword, authenticating } = authentication;
  let content;
  if (authenticating) {
    content = (
      <div className="contents with-loader">
        <div className="message">Just a moment. Logging you in.</div>
        <Loader />
      </div>
    );
  } else {
    content = (
      <div className="contents">
        <div className="message">
          Add a password so you can access your account in the future.
        </div>
        <Input
          id="set-password-password"
          type="password"
          value={password}
          placeholder="New password"
          onChange={pipe(getTargetValue, partial(onUpdateField, ["password"]))}
          onKeyDown={partial(submitOnEnter, [onSetPassword, authentication])}
          error={handleFormErrors("password", authentication)}
          onBlur={partial(validatePasswordOnBlur, [authentication])}
          autoFocus
        />
        <Input
          id="set-password-confirm-password"
          type="password"
          value={confirmPassword}
          placeholder="Confirm password"
          onChange={pipe(
            getTargetValue,
            partial(onUpdateField, ["confirmPassword"])
          )}
          onKeyDown={partial(submitOnEnter, [onSetPassword, authentication])}
          onBlur={partial(validatePasswordOnBlur, [authentication])}
          error={handleFormErrors("confirmPassword", authentication)}
        />
        <Button
          onClick={partial(onSetPassword, [authentication])}
          className="set-password-button">
          Set password
        </Button>
      </div>
    );
  }
  return (
    <Modal title="Set your password" className="set-password-modal">
      {content}
    </Modal>
  );
}

SetPassword.propTypes = {
  onUpdateField: PropTypes.func.isRequired,
  onSetPassword: PropTypes.func.isRequired,
  authentication: PropTypes.object,
  validatePasswordOnBlur: PropTypes.func.isRequired,
};

export default SetPassword;
