import { LDProvider } from "launchdarkly-react-client-sdk";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { LAUNCH_DARKLY_CLIENT_ID } from "api_urls";
import { MobilePayContainer } from "containers/mobile_pay_container";
import { usePrettyUrl } from "helpers/hooks/usePrettyUrl";

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 5000 * 60 } },
});

export function MobilePayApp() {
  const prettyUrl = usePrettyUrl();
  return (
    <QueryClientProvider client={queryClient}>
      <LDProvider
        clientSideID={LAUNCH_DARKLY_CLIENT_ID}
        user={{ kind: "user", key: prettyUrl }}>
        <BrowserRouter>
          <MobilePayContainer />
        </BrowserRouter>
      </LDProvider>
    </QueryClientProvider>
  );
}
