import { camelizeKeys } from "humps";
import { join, map, path } from "ramda";

import { parseJSON } from "helpers/ajax_request";

import trackAnalytic from "helpers/analytics";
import isOLO from "helpers/is_olo";
import {
  validateEmail,
  validateFormDetails,
  validateInitialPasswordDetails,
} from "helpers/validation/forms";
import VALIDATION_MESSAGES from "helpers/validation/messages";
import Validators from "helpers/validation/validators";
import {
  authenticate,
  postNewUserConfirmation,
  postOLOAccountCreation,
  postOLOPassword,
  postPassword,
  postResetPassword,
  socialAuthenticate,
} from "interfaces/authentication";

export const RESET_PASSWORD = "authentication/RESET_PASSWORD";
export const LOG_OUT = "authentication/LOGOUT";
export const UPDATE_FIELD = "authentication/UPDATE_FIELD";
export const CHANGE_MODAL_TYPE = "authentication/CHANGE_MODAL_TYPE";
export const TOGGLE_MODAL = "authentication/TOGGLE_MODAL";
export const SHOW_SET_PASSWORD_MODAL = "authentication/SHOW_SET_PASSWORD_MODAL";
export const RETURN_AUTH_ERRORS = "authentication/RETURN_PASSWORD_FORM_ERRORS";
export const RECEIVE_USER_EMAIL = "authentication/RECEIVE_USER_EMAIL";
export const SHOW_AUTHENTICATING_LOADER =
  "authentication/SHOW_AUTHENTICATING_LOADER";
export const CAPTURE_AUTH_PARAMS = "authentication/CAPTURE_AUTH_PARAMS";

const emailValidators = {
  email: Validators.isValidEmail,
};

const oloAccountCreationValidators = {
  email: Validators.isValidEmail,
  firstName: Validators.isNotEmpty,
  lastName: Validators.isNotEmpty,
};

const emailErrorMessages = {
  email: "Please enter a valid email address",
};

function toggleModal(isShown, customAuthMessage = "") {
  return {
    type: TOGGLE_MODAL,
    isShown,
    customAuthMessage,
  };
}

function format422Errors(errors) {
  const joinedErrors = join(" and ", errors);
  return `${joinedErrors[0].toUpperCase()}${joinedErrors.slice(1)}.`;
}

function logOut(forceRefresh = true) {
  return {
    type: LOG_OUT,
    forceRefresh,
  };
}

function returnAuthErrors(errors) {
  return {
    type: RETURN_AUTH_ERRORS,
    errors,
  };
}

function changeModalType(modalType) {
  return {
    type: CHANGE_MODAL_TYPE,
    modalType,
  };
}

function createAccount(user) {
  const errors = validateFormDetails(
    user,
    oloAccountCreationValidators,
    VALIDATION_MESSAGES
  );

  if (errors) {
    return {
      type: RETURN_AUTH_ERRORS,
      errors,
    };
  }
  return dispatch => {
    postOLOAccountCreation(user, () => {
      dispatch(changeModalType("ACCOUNT_CREATED_EMAIL"));
    }).catch(errorResp => {
      switch (path(["response", "status"], errorResp)) {
        case 400:
          dispatch(
            returnAuthErrors({
              firstName: "Your request contains data that cannot be parsed.",
            })
          );
          break;
        case 409: // 409 CONFLICT
          dispatch(
            returnAuthErrors({
              email:
                "An account is already associated with this email. Please login.",
            })
          );
          break;
        case 422:
          parseJSON(errorResp.response)
            .then(({ error }) => camelizeKeys(error))
            .then(errorObj => {
              const formattedErrors = map(errorArray => {
                return format422Errors(errorArray);
              }, errorObj);
              dispatch(returnAuthErrors(formattedErrors));
            });
          break;
        default:
          // Catch generic errors from the request
          dispatch(
            returnAuthErrors({
              email: "An error with your request has occurred.",
            })
          );
          break;
      }
    });
  };
}

function logIn(authentication) {
  const { email, password } = authentication;

  return dispatch => {
    authenticate(
      email,
      password,
      () => {
        dispatch(toggleModal(!authentication.isShown));
      },
      error => {
        dispatch(
          returnAuthErrors({
            email: error.description,
            password: error.description,
          })
        );
        trackAnalytic("Log In Failure", { email });
      }
    );
  };
}

function socialSignIn(platform) {
  // eslint-disable-next-line
  return dispatch => {
    socialAuthenticate(platform);
  };
}

function updateField(field, value) {
  return {
    type: UPDATE_FIELD,
    field,
    value,
  };
}

function captureAuthParams(emailToken, reset) {
  return {
    type: CAPTURE_AUTH_PARAMS,
    emailToken,
    reset,
  };
}

function showSetPasswordModal() {
  return {
    type: SHOW_SET_PASSWORD_MODAL,
  };
}

function resetPassword(email) {
  const errors = validateEmail(email, emailValidators, emailErrorMessages);

  if (errors) {
    return {
      type: RETURN_AUTH_ERRORS,
      errors,
    };
  }
  return dispatch => {
    postResetPassword(email, () => {
      dispatch(changeModalType("EMAIL_SENT"));
    });
  };
}

function showAuthenticatingLoader() {
  return {
    type: SHOW_AUTHENTICATING_LOADER,
  };
}

const validators = {
  password: Validators.minimumLength(6),
  confirmPassword: Validators.minimumLength(6),
};

const errorMessages = {
  password: "Passwords must be 6 or more characters",
  confirmPassword: "Passwords must be 6 or more characters",
};

function receiveUserEmail(email) {
  return {
    type: RECEIVE_USER_EMAIL,
    email,
  };
}

function validatePasswordOnBlur(passwordDetails) {
  const errors = validateInitialPasswordDetails(
    passwordDetails,
    validators,
    errorMessages
  );

  return {
    type: RETURN_AUTH_ERRORS,
    errors,
  };
}

function setPassword(passwordDetails) {
  const errors = validateInitialPasswordDetails(
    passwordDetails,
    validators,
    errorMessages
  );

  if (errors) {
    return {
      type: RETURN_AUTH_ERRORS,
      errors,
    };
  }

  if (isOLO()) {
    // OLO does not require the confirmNewUser step that is required for loyalty
    return dispatch => {
      postOLOPassword(
        passwordDetails.password,
        passwordDetails.emailToken,
        passwordDetails.reset,
        resp => {
          dispatch(receiveUserEmail(resp.email));
          dispatch(logIn({ ...passwordDetails, email: resp.email }));
          dispatch(showAuthenticatingLoader());
        }
      );
    };
  }
  return dispatch => {
    postPassword(passwordDetails.password, () => {
      dispatch(logIn(passwordDetails));
      dispatch(showAuthenticatingLoader());
    });
  };
}

function confirmNewUser(emailToken) {
  return dispatch => {
    postNewUserConfirmation(emailToken, resp => {
      dispatch(receiveUserEmail(resp.email));
    });
  };
}

export {
  createAccount,
  logOut,
  logIn,
  updateField,
  resetPassword,
  setPassword,
  confirmNewUser,
  changeModalType,
  showSetPasswordModal,
  toggleModal,
  captureAuthParams,
  socialSignIn,
  validatePasswordOnBlur,
};
