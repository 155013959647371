import { useMutation } from "react-query";
import uuid from "uuid/v4";
import {
  generateConfirmationCode,
  transformOrderInfo,
} from "helpers/transforms/digital_gift_cards";
import { postCreditCardPayment } from "interfaces/digital_gift_cards";
import type {
  DigitalGiftCardOrderApiResponse,
  DigitalGiftCardsOrderApiRequest,
  SubmitGiftCardCreditCardPaymentProps,
} from "types/digital_gift_cards";

export const useSubmitDigitalGiftCardPayment = <
  T extends DigitalGiftCardOrderApiResponse = DigitalGiftCardOrderApiResponse,
>() => {
  return useMutation<T, unknown, SubmitGiftCardCreditCardPaymentProps>(
    async ({
      total,
      formInfo,
      storePrettyUrl,
      sessionId,
      additionalPaymentData,
    }) => {
      const submitDate = new Date();
      const paymentRequestInfo: DigitalGiftCardsOrderApiRequest = {
        sessionId,
        storePrettyUrl,
        confirmationCode: generateConfirmationCode(storePrettyUrl),
        timePlaced: submitDate.toISOString(),
        submissionId: uuid(),
        orderTotal: total.valueOf(),
        digitalGiftCards: [transformOrderInfo(formInfo)],
        ...additionalPaymentData,
      };
      return postCreditCardPayment<T>(paymentRequestInfo);
    }
  );
};
