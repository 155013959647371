import { type UseQueryOptions, useQuery } from "react-query";
import type { AdyenPaymentAccessResponse } from "interfaces/credit_card/adyen/AdyenPaymentMethodsResponse";
import { getAdyenMobilePayPaymentAccess } from "interfaces/mobile_pay/payment/adyen/getAdyenMobilePayPaymentAccess";

export const useAdyenMobilePayPaymentAccess = <TData = boolean>(
  prettyUrl: string,
  options?: UseQueryOptions<
    AdyenPaymentAccessResponse,
    unknown,
    TData,
    ["adyenMobilePayPaymentAccess", string]
  >
) => {
  return useQuery(
    ["adyenMobilePayPaymentAccess", prettyUrl],
    () => {
      return getAdyenMobilePayPaymentAccess(prettyUrl);
    },
    {
      select: ({ enabled }) => enabled as TData,
      ...options,
    }
  );
};
