import React, { Suspense, useCallback } from "react";
import {
  type CCProcessorConfiguration,
  CCProcessorConfigurationMap,
} from "./CCProcessorConfigurationMap";
import type { CheckInfo, ProcessedPaymentInfo } from "types/mobile_pay";

export interface MobilePayReturnUrlAuthorizationProps {
  paymentProcessor: string;
  checkInfo: CheckInfo;
  onSuccess: (response: ProcessedPaymentInfo) => void;
  onError: (error: string) => void;
  onFinalize: () => void;
}

export const MobilePayPaymentReturnUrlAuthorization = ({
  paymentProcessor,
  checkInfo,
  onSuccess,
  onError,
  onFinalize,
}: MobilePayReturnUrlAuthorizationProps) => {
  const handleSuccess = useCallback(
    (paymentInfo: ProcessedPaymentInfo) => {
      onSuccess(paymentInfo);
      onFinalize();
    },
    [onFinalize, onSuccess]
  );

  const handleError = useCallback(
    (errorMessage: string) => {
      onError(errorMessage);
      onFinalize();
    },
    [onError, onFinalize]
  );

  const ccProcessorConfiguration: CCProcessorConfiguration | undefined =
    CCProcessorConfigurationMap[paymentProcessor];
  const PaymentReturnUrlAuthorizationImpl =
    ccProcessorConfiguration?.returnUrlAuthorization;

  if (!PaymentReturnUrlAuthorizationImpl) {
    return null;
  }
  return (
    <Suspense fallback={<></>}>
      <PaymentReturnUrlAuthorizationImpl
        onSuccess={handleSuccess}
        onError={handleError}
        checkInfo={checkInfo}
      />
    </Suspense>
  );
};
