import { camelizeKeys } from "humps";

import { ORDERS_URL } from "api_urls";
import {
  checkStatus,
  deleteRequest,
  getRequest,
  parseJSON,
  postRequest,
  putRequest,
} from "helpers/ajax_request";
import formatConsumerInfo from "helpers/transforms/online_ordering/consumer_info_transform";

export function getConsumerInfo(storePrettyUrl) {
  return fetch(
    `${ORDERS_URL}/consumer_info?store_pretty_url=${storePrettyUrl}`,
    getRequest()
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys)
    .then(formatConsumerInfo);
}

function putPhoneNumber(phoneUuid, newPhoneNum) {
  return fetch(
    `${ORDERS_URL}/phone_numbers`,
    putRequest({ id: phoneUuid, phoneNumber: newPhoneNum })
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys);
}

// returns {"phone_number_id":"1b4aa3be-8c24-4d13-80fd-16cbbdb118e4"}
function postPhoneNumber(newPhoneNum) {
  return fetch(
    `${ORDERS_URL}/phone_numbers`,
    postRequest({ phoneNumber: newPhoneNum })
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys);
}

function deletePhoneNumber(phoneUuid) {
  return fetch(
    `${ORDERS_URL}/phone_numbers`,
    deleteRequest({ id: phoneUuid })
  ).then(checkStatus);
}

function putAddress(address) {
  return fetch(
    `${ORDERS_URL}/addresses`,
    putRequest({
      id: address.uuid,
      line1: address.line1,
      line2: address.line2,
      city: address.city,
      state: address.state,
      zip: address.zip,
    })
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys);
}

// returns {"address_id":"1b4aa3be-8c24-4d13-80fd-16cbbdb118e4"}
function postAddress(address) {
  return fetch(
    `${ORDERS_URL}/addresses`,
    postRequest({
      line1: address.line1,
      line2: address.line2,
      city: address.city,
      state: address.state,
      zip: address.zip,
    })
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys);
}

function deleteAddress(addressUuid) {
  return fetch(
    `${ORDERS_URL}/addresses`,
    deleteRequest({ id: addressUuid })
  ).then(checkStatus);
}

function putConsumerInfo(firstName, lastName, textAlerts) {
  return fetch(
    `${ORDERS_URL}/consumer_info`,
    putRequest({
      firstName,
      lastName,
      textAlerts,
    })
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys);
}

export {
  postPhoneNumber,
  putPhoneNumber,
  deletePhoneNumber,
  putAddress,
  postAddress,
  deleteAddress,
  putConsumerInfo,
};
