import React, { Suspense } from "react";
import { DigitalGiftCardInput } from "components/digital_gift_cards/inputs/DigitalGiftCardInput";
import type { CCProcessorConfiguration } from "components/digital_gift_cards/payment/CCProcessorConfigurationMap";
import Loader from "components/loader";
import {
  validateAddress1,
  validateCity,
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePhone,
  validateState,
  validateZip,
} from "helpers/credit_card_form";

export interface DigitalGiftCardCreditCardInputsProps {
  CCInputsComponent: CCProcessorConfiguration["ccInputs"];
  includeEmailField?: boolean;
  includePhoneField?: boolean;
}

export const DigitalGiftCardCreditCardInputs = ({
  CCInputsComponent,
  includeEmailField,
  includePhoneField,
}: DigitalGiftCardCreditCardInputsProps) => {
  return (
    <>
      <DigitalGiftCardInput
        name="firstName"
        label="First Name"
        autoComplete="given-name"
        validate={validateFirstName}
        required
      />
      <DigitalGiftCardInput
        name="lastName"
        label="Last Name"
        autoComplete="family-name"
        validate={validateLastName}
        required
      />
      <Suspense fallback={<Loader />}>
        <CCInputsComponent />
      </Suspense>
      <DigitalGiftCardInput
        name="address1"
        label="Address 1"
        autoComplete="address-line1"
        validate={validateAddress1}
        required
      />
      <DigitalGiftCardInput
        name="address2"
        label="Address 2"
        placeholder="Apartment, suite, unit, etc"
        autoComplete="address-line2"
      />
      <DigitalGiftCardInput
        name="city"
        label="City"
        autoComplete="address-level2"
        validate={validateCity}
        required
      />
      <DigitalGiftCardInput
        name="state"
        label="State / Province"
        autoComplete="address-level1"
        maxLength={2}
        pattern="[A-Za-z]+"
        validate={validateState}
        required
      />
      <DigitalGiftCardInput
        name="zip"
        label="Zip / Postal Code"
        autoComplete="postal-code"
        pattern={"[A-Za-z0-9\\s]+"}
        maxLength={10}
        validate={validateZip}
        required
      />
      {includeEmailField && (
        <DigitalGiftCardInput
          name="email"
          label="Email for Receipt"
          autoComplete="email"
          type="email"
          validate={validateEmail}
        />
      )}
      {includePhoneField && (
        <DigitalGiftCardInput
          name="phone"
          label="Phone Number (Optional)"
          autoComplete="tel"
          type="tel"
          validate={validatePhone}
        />
      )}
    </>
  );
};
