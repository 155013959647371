import { useFlags } from "launchdarkly-react-client-sdk";
import { isNil } from "ramda";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Waypoint } from "react-waypoint";
import { OnlineOrderingStatus } from "./online_ordering_status";
import {
  changeTransportMethod,
  editOrderOptions,
  setDineInTableProps,
} from "actions/online_ordering/ordering_options";
import Footer from "components/footer";

import CartContainer from "containers/online_ordering/cart_container";
import HeaderContainer from "containers/online_ordering/header_container";
import MainViewContainer from "containers/online_ordering/main_view_container";
import PreviewContainer from "containers/online_ordering/preview_container";
import {
  getDineInParams,
  getPreviewToken,
  isValidDineInParams,
} from "get_base_uri";

import FeeContext from "helpers/context/fees";
import FreezeBody from "helpers/context/freeze_body";
import { usePrettyUrl } from "helpers/hooks/usePrettyUrl";
import { LdFlags, hasFeatureFlagEnabled } from "helpers/online_ordering";
import {
  isDineInOnly,
  isPickupOnlyAvailable,
} from "helpers/online_ordering/fulfillment_types";
import { useOloStoreInfo } from "hooks/online_ordering/useOloStoreInfo";

export function OnlineOrdering() {
  const flags = useFlags();

  const [bodyIsFrozen, setFreezeBody] = useState(false);
  const [freezeBodyScrollReturn, setFreezeBodyScrollReturn] = useState(0);
  const freezeContext = useMemo(
    () => ({
      freezeBody: () => {
        // Save current scroll position
        setFreezeBodyScrollReturn(window.scrollY);
        setFreezeBody(true);
      },
      unFreezeBody: () => setFreezeBody(false),
    }),
    [setFreezeBody, setFreezeBodyScrollReturn]
  );
  useEffect(() => {
    if (!bodyIsFrozen && freezeBodyScrollReturn) {
      window.scrollTo(0, freezeBodyScrollReturn);
      setFreezeBodyScrollReturn(0);
    }
  }, [bodyIsFrozen, freezeBodyScrollReturn, setFreezeBodyScrollReturn]);

  const [cartIsSticky, setStickCart] = useState(false);
  const stickCart = useCallback(() => setStickCart(true), [setStickCart]);
  const unStickCart = useCallback(() => setStickCart(false), [setStickCart]);

  const storePrettyUrl = usePrettyUrl();
  const { data: storeInfo } = useOloStoreInfo(storePrettyUrl, {
    enabled: !isNil(getPreviewToken()),
  });

  let previewBanner;
  if (storeInfo && !storeInfo.published) {
    previewBanner = <PreviewContainer />;
  }

  const useExtendedOrderAhead = hasFeatureFlagEnabled(
    flags,
    LdFlags.EXTENDED_ORDER_AHEAD
  );

  // TODO: default values should be set in the forms not on global app load...
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      storeInfo?.fulfillmentTypes &&
      isPickupOnlyAvailable(storeInfo.fulfillmentTypes)
    ) {
      dispatch(changeTransportMethod("Pick up"));
      dispatch(editOrderOptions(false));
    }
  }, [dispatch, storeInfo?.fulfillmentTypes]);

  // TODO: default values should be set in the forms not on global app load...
  useEffect(() => {
    const { ft, tableId, tableName } = getDineInParams();
    if (
      storeInfo?.fulfillmentTypes &&
      (isValidDineInParams(ft, tableId, tableName) ||
        isDineInOnly(storeInfo.fulfillmentTypes))
    ) {
      dispatch(changeTransportMethod("Dine in"));
      dispatch(setDineInTableProps(tableId, tableName));
      dispatch(editOrderOptions(false));
    }
  }, [dispatch, storeInfo?.fulfillmentTypes]);

  const mainContent = (
    <div className="content">
      <OnlineOrderingStatus />
      <MainViewContainer />
      <FeeContext.Provider value={flags.oloConsumerFeePercentage}>
        <CartContainer
          cartClass={cartIsSticky ? "fixed-cart" : ""}
          useExtendedOrderAhead={useExtendedOrderAhead}
          key="cart"
        />
      </FeeContext.Provider>
    </div>
  );

  return (
    <FreezeBody.Provider value={freezeContext}>
      <div className={bodyIsFrozen ? "freeze-body" : ""}>
        {previewBanner}
        <div
          className="app"
          style={
            bodyIsFrozen ? { top: `-${freezeBodyScrollReturn}px` } : undefined
          }>
          <HeaderContainer />
          <Waypoint onLeave={stickCart} onEnter={unStickCart} />
          {mainContent}
          <Footer />
        </div>
      </div>
    </FreezeBody.Provider>
  );
}
