export const UPDATE_LOYALTY_INFO = "index/UPDATE_LOYALTY_INFO";

function updateLoyaltyInfo(loyalty) {
  return {
    type: UPDATE_LOYALTY_INFO,
    loyalty,
  };
}

export { updateLoyaltyInfo };
