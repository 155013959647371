import Aviator from "aviator";
import { camelizeKeys } from "humps";
import { equals } from "ramda";

import { LOYALTY_API_URL } from "api_urls";
import { checkStatus, parseJSON, postRequest } from "helpers/ajax_request";
import getEnv from "helpers/get_env";
import formatCards from "helpers/transforms/loyalty/credit_cards_transform";
import {
  postCreditCard,
  postTokenRequestForNewCard,
} from "interfaces/credit_cards";

// If debugging in dev env mocky.io script may need to be updated. It is
// currently returning a 200, if another response is desired generate
// a new mocked link.

function submitEmail(email, callback, errorCallback) {
  const request = Aviator.getCurrentRequest();
  const { prettyUrl: brandPrettyUrl } = request.namedParams;
  if (equals(getEnv(), "development")) {
    return fetch(
      "http://www.mocky.io/v2/5dc30d102f000002e94be5c0",
      postRequest()
    )
      .then(checkStatus)
      .then(parseJSON)
      .then(camelizeKeys)
      .then(callback)
      .catch(errorCallback);
  }

  return fetch(
    `${LOYALTY_API_URL}/user`,
    postRequest({
      email,
      brandPrettyUrl,
    })
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(camelizeKeys)
    .then(callback)
    .catch(errorCallback);
}

function submitCard(
  userUuid,
  enrollmentSalt,
  enrollmentSig,
  unencryptedPan,
  callback,
  errorCallback
) {
  if (equals(getEnv(), "development")) {
    return fetch(
      "http://www.mocky.io/v2/5a710d5d330000515aff5e98",
      postRequest()
    )
      .then(checkStatus)
      .then(parseJSON)
      .then(camelizeKeys)
      .then(formatCards)
      .then(callback)
      .catch(errorCallback);
  }

  return postTokenRequestForNewCard(
    ({ token }) => {
      const cardWithToken = {
        cardEnrollmentToken: token,
        unencryptedPan,
      };

      postCreditCard(cardWithToken, callback, errorCallback);
    },
    userUuid,
    enrollmentSalt,
    enrollmentSig
  );
}

export { submitEmail, submitCard };
