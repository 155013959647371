import PropTypes from "prop-types";
import React from "react";

import Button from "components/button";
import PublishConfirmModal from "components/online_ordering/publish_confirm_modal";

function PreviewBanner({
  publishStatus,
  onStartPublish,
  onCancelPublish,
  onConfirmPublish,
  onDonePublish,
}) {
  return (
    <div className="preview-banner">
      <div className="inner">
        <div className="left">
          <div className="text">
            Your page is ready for review. Once you click{" "}
            <span className="emphasis">publish</span>, your page will be live
            and ready to take orders.
            <br />
            Guests using credit cards through Lightspeed (U-Series) Online
            Ordering will be assessed a 2.99% fee. View{" "}
            <a
              href="https://assets-upserve.s3.amazonaws.com/terms-and-conditions/online-ordering-terms-and-conditions.html"
              target="_blank"
              rel="noopener noreferrer">
              terms and conditions
            </a>
            .
          </div>
          <div className="text">
            If you need help making revisions,{" "}
            <a
              href="https://u-series-support.lightspeedhq.com/s/article/Breadcrumb-Menu-Overview"
              target="_blank"
              rel="noopener noreferrer">
              see our support page
            </a>{" "}
            covering menu updates.
          </div>
        </div>
        <div className="right">
          <Button onClick={onStartPublish} size="medium" type="secondary">
            PUBLISH
          </Button>
        </div>
      </div>
      <PublishConfirmModal
        publishStatus={publishStatus}
        onCancelPublish={onCancelPublish}
        onConfirmPublish={onConfirmPublish}
        onDonePublish={onDonePublish}
      />
    </div>
  );
}

PreviewBanner.propTypes = {
  publishStatus: PropTypes.string.isRequired,
  onStartPublish: PropTypes.func.isRequired,
  onCancelPublish: PropTypes.func.isRequired,
  onConfirmPublish: PropTypes.func.isRequired,
  onDonePublish: PropTypes.func.isRequired,
};

export default PreviewBanner;
