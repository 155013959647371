import styled from "styled-components";

const DividingLine = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #ededed;
  margin: 8px 0;
`;

export default DividingLine;
