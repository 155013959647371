import { equals, find, head, propEq, without } from "ramda";
import type { SelectedTransportOptions } from "types/reducers/ordering_options";
import type { Fulfillment } from "types/reducers/store_info";

export const getFullfillmentTypeFromSelectedTransport = (
  transport: SelectedTransportOptions,
  fulfillmentTypes: Array<Fulfillment>
): Fulfillment | undefined => {
  return find(propEq("name", transport), fulfillmentTypes);
};

export const requiresValidAddress = (
  transport: SelectedTransportOptions
): boolean => {
  return equals(transport, "Delivery");
};

// dine in should not be available to be shown as a fulfillment type in the standard order flow
export const getAvailableFulfillmentTypes = (
  fulfillmentTypes: Array<Fulfillment>
): Array<Fulfillment> => {
  const dineInType = find(propEq("type", "dine_in"), fulfillmentTypes);
  return dineInType
    ? without([dineInType], fulfillmentTypes)
    : fulfillmentTypes;
};

export const isPickupOnlyAvailable = (
  fulfillmentTypes: Array<Fulfillment>
): boolean => {
  const availableTypes = getAvailableFulfillmentTypes(fulfillmentTypes);
  return (
    equals(availableTypes.length, 1) &&
    equals(head(availableTypes)!.type, "pickup")
  );
};

export const isDineInOnly = (fulfillmentTypes: Array<Fulfillment>): boolean => {
  return (
    equals(fulfillmentTypes.length, 1) &&
    equals(fulfillmentTypes[0].type, "dine_in")
  );
};
