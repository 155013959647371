import { camelize } from "humps";
import { all, equals, gte, isEmpty, isNil, none, pathOr } from "ramda";

import { SuggestedTipSelection } from "helpers/gratuity";
import { calculateGratuity } from "helpers/money";
import { determineMin } from "helpers/online_ordering/cart/checkout_summary";
import { getFullfillmentTypeFromSelectedTransport } from "helpers/online_ordering/fulfillment_types";
import getTotals from "helpers/online_ordering/item_totals";

export function isOrderValid(
  cart,
  checkout,
  storeInfo,
  orderingOptions,
  consumer
) {
  const minByMethod = determineMin(
    storeInfo.deliveryMinimumOrder,
    orderingOptions.selectedTransport
  );
  const { subtotal } = getTotals(cart.cartItems);

  let preSubmitRequirements = [];
  if (minByMethod) {
    const meetsOrderMinimum = gte(subtotal, minByMethod);

    preSubmitRequirements = [...preSubmitRequirements, meetsOrderMinimum];
  }
  if (equals(orderingOptions.selectedTransport, "Delivery")) {
    const { line1, city, state, zip } = consumer.contactInfo.address;
    const hasValidAddress = none(isEmpty, [line1, city, state, zip]);

    preSubmitRequirements = [...preSubmitRequirements, hasValidAddress];
  }
  return all(equals(true), preSubmitRequirements);
}

export const DEFAULT_TIP_SETTINGS = {
  customTipsEnabled: true,
  showSuggestedTips: true,
  suggestedTipDefault: 2,
  suggestedTipPercentHigh: 20,
  suggestedTipPercentLow: 10,
  suggestedTipPercentMiddle: 15,
};

export function getTipSettings(fulfillmentTypes, selectedTransport, settings) {
  const selectedFulfillmentType = getFullfillmentTypeFromSelectedTransport(
    selectedTransport,
    fulfillmentTypes
  );
  if (selectedFulfillmentType) {
    const { type } = selectedFulfillmentType;
    return {
      customTipsEnabled: pathOr(
        DEFAULT_TIP_SETTINGS.customTipsEnabled,
        [camelize(type), "customTipsEnabled"],
        settings
      ),
      showSuggestedTips: pathOr(
        DEFAULT_TIP_SETTINGS.showSuggestedTips,
        [camelize(type), "showSuggestedTips"],
        settings
      ),
      suggestedTipDefault: pathOr(
        DEFAULT_TIP_SETTINGS.suggestedTipDefault,
        [camelize(type), "suggestedTipDefault"],
        settings
      ),
      suggestedTipPercentHigh: pathOr(
        DEFAULT_TIP_SETTINGS.suggestedTipPercentHigh,
        [camelize(type), "suggestedTipPercentHigh"],
        settings
      ),
      suggestedTipPercentLow: pathOr(
        DEFAULT_TIP_SETTINGS.suggestedTipPercentLow,
        [camelize(type), "suggestedTipPercentLow"],
        settings
      ),
      suggestedTipPercentMiddle: pathOr(
        DEFAULT_TIP_SETTINGS.suggestedTipPercentMiddle,
        [camelize(type), "suggestedTipPercentMiddle"],
        settings
      ),
    };
  }
  return DEFAULT_TIP_SETTINGS;
}

function getSelectedTipPercentageFromSettings(selectedTipOption, tipSettings) {
  switch (selectedTipOption) {
    case SuggestedTipSelection.LowSuggestedTip:
      return tipSettings.suggestedTipPercentLow;
    case SuggestedTipSelection.MediumSuggestedTip:
      return tipSettings.suggestedTipPercentMiddle;
    case SuggestedTipSelection.HighSuggestedTip:
      return tipSettings.suggestedTipPercentHigh;
    default:
      return 0;
  }
}

export function calculateTipAmount(
  cartItems,
  selectedTransport,
  storeInfo,
  selectedTipOption,
  tip
) {
  if (isNil(selectedTipOption)) {
    return tip.valueOf();
  }
  if (equals(selectedTipOption, SuggestedTipSelection.NoTip)) {
    return 0;
  }

  const { subtotal } = getTotals(cartItems);
  const { fulfillmentTypes, settings } = storeInfo;

  const tipSettings = getTipSettings(
    fulfillmentTypes,
    selectedTransport,
    settings
  );

  const tipPercentage = getSelectedTipPercentageFromSettings(
    selectedTipOption,
    tipSettings
  );

  return calculateGratuity(tipPercentage, subtotal);
}

export const ERROR_MAPPINGS = {
  "Invalid request parameters.":
    "Please use only letters and numbers. Avoid symbols and special characters.",
};
export const GENERIC_ERROR = `Uh oh! Something went wrong with your order.
Please make sure all of the information is correct and try again.`;
