// Take only the data we need and adjust keys as necessary to match
// olo data structure & keys
function formatBrandInfo(info) {
  const { name, web, promoDescription, logo, coverPhoto, stores } = info;

  return {
    locationName: name,
    locationUrl: web,
    description: promoDescription,
    logo,
    coverPhoto,
    stores,
    address: null,
  };
}

export default formatBrandInfo;
