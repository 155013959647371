import { contains, equals, has, lt } from "ramda";

import { LOG_OUT } from "actions/authentication";
import {
  ADD_TO_CART,
  APPLY_CART_FROM_LOCAL_STORAGE,
  APPLY_REORDER_FROM_LOCAL_STORAGE,
  CREATE_EDITABLE_ITEM,
  DECREMENT_ITEM_QUANTITY,
  DELETE_EDITABLE_ITEM,
  EDIT_INSTRUCTIONS,
  EMPTY_CART,
  EMPTY_ORDERED_ITEMS,
  INCREMENT_ITEM_QUANTITY,
  REMOVE_FROM_CART,
  REMOVE_INVALID_REORDER_ITEMS,
  TOGGLE_MODIFIER,
  TOGGLE_SIDE,
  UPDATE_CART_ITEM,
} from "actions/online_ordering/cart";
import { getPrettyUrl } from "get_base_uri";
import {
  getById,
  removeByCartId,
  removeById,
  replaceByCartId,
  replaceInCollection,
} from "helpers/collections";
import {
  removeCartFromLocalStorage,
  saveCartToLocalStorage,
} from "helpers/local_storage";
import {
  addSelectedModInModGroupToItem,
  createCartItem,
} from "helpers/online_ordering/cart";
import { filterReorderIntoValidAndInvalid } from "helpers/online_ordering/reorder";
import { convertStockLevelToMaybe } from "helpers/transforms/online_ordering/cart_item";
import storage from "storage";

const initialState = {
  editableItem: null,
  cartItems: [],
  invalidItems: [],
  orderedItems: [],
};

function cart(state = initialState, action) {
  switch (action.type) {
    case APPLY_CART_FROM_LOCAL_STORAGE: {
      return {
        ...state,
        cartItems: convertStockLevelToMaybe(action.cart),
      };
    }
    case APPLY_REORDER_FROM_LOCAL_STORAGE: {
      const { validItems, invalidItems } = filterReorderIntoValidAndInvalid(
        action.reorder,
        action.menu,
        action.stockLevels
      );
      storage.removeItem(`${getPrettyUrl()}_reorder`);
      const cartItems = validItems.length > 0 ? validItems : state.cartItems;

      return {
        ...state,
        cartItems,
        invalidItems,
      };
    }
    case REMOVE_INVALID_REORDER_ITEMS: {
      return {
        ...state,
        invalidItems: [],
      };
    }
    case ADD_TO_CART: {
      const itemsWithNewItem = [...state.cartItems, action.item];

      saveCartToLocalStorage(itemsWithNewItem);

      return {
        ...state,
        cartItems: itemsWithNewItem,
        editableItem: null,
      };
    }

    case DELETE_EDITABLE_ITEM: {
      return {
        ...state,
        editableItem: null,
      };
    }

    case CREATE_EDITABLE_ITEM: {
      // THIS IS A HACK, for some reason show modal event is firing a ton
      if (state.editableItem && equals(action.item.id, state.editableItem.id))
        return state;

      let itemWithSelections;
      if (has("selectedSides", action.item)) {
        // This item is from our cart and we want to update
        itemWithSelections = action.item;
      } else {
        itemWithSelections = createCartItem(action.item);
      }

      return {
        ...state,
        editableItem: itemWithSelections,
      };
    }

    case UPDATE_CART_ITEM: {
      const itemsWithUpdatedItem = replaceByCartId(
        action.oldItem,
        action.updatedItem,
        state.cartItems
      );

      saveCartToLocalStorage(itemsWithUpdatedItem);

      return {
        ...state,
        cartItems: itemsWithUpdatedItem,
        editableItem: null,
      };
    }

    case REMOVE_FROM_CART: {
      const itemsWithoutDeletedItem = removeByCartId(
        action.cartId,
        state.cartItems
      );

      saveCartToLocalStorage(itemsWithoutDeletedItem);

      return {
        ...state,
        cartItems: itemsWithoutDeletedItem,
      };
    }

    case EMPTY_CART: {
      saveCartToLocalStorage([]);

      return {
        ...state,
        orderedItems: state.cartItems,
        cartItems: [],
      };
    }

    case EDIT_INSTRUCTIONS: {
      const updatedItem = {
        ...state.editableItem,
        instructions: action.instructions,
      };
      return {
        ...state,
        editableItem: updatedItem,
      };
    }

    case TOGGLE_MODIFIER: {
      const selectedModsGroup = getById(
        action.section.id,
        state.editableItem.selectedModifiers
      );

      let updatedItem = state.editableItem;
      if (contains(action.mod, selectedModsGroup.mods)) {
        const updatedSelectedModsGroup = {
          ...selectedModsGroup,
          mods: removeById(action.mod.id, selectedModsGroup.mods),
        };

        updatedItem = {
          ...state.editableItem,
          selectedModifiers: replaceInCollection(
            selectedModsGroup,
            updatedSelectedModsGroup,
            state.editableItem.selectedModifiers
          ),
        };
      } else if (
        lt(selectedModsGroup.mods.length, action.section.maximumRequired)
      ) {
        updatedItem = addSelectedModInModGroupToItem(
          selectedModsGroup,
          updatedItem,
          action.mod
        );
      }
      return {
        ...state,
        editableItem: updatedItem,
      };
    }

    case TOGGLE_SIDE: {
      let updatedItem = state.editableItem;
      if (contains(action.side, state.editableItem.selectedSides)) {
        updatedItem = {
          ...state.editableItem,
          selectedSides: removeById(
            action.side.id,
            state.editableItem.selectedSides
          ),
        };
      } else if (
        equals(action.maxSides, 0) ||
        lt(state.editableItem.selectedSides.length, action.maxSides)
      ) {
        updatedItem = {
          ...state.editableItem,
          selectedSides: [...state.editableItem.selectedSides, action.side],
        };
      }
      return {
        ...state,
        editableItem: updatedItem,
      };
    }

    case INCREMENT_ITEM_QUANTITY: {
      const updatedItem = {
        ...state.editableItem,
        quantity: state.editableItem.quantity + 1,
      };

      return {
        ...state,
        editableItem: updatedItem,
      };
    }

    case DECREMENT_ITEM_QUANTITY: {
      if (equals(state.editableItem.quantity, 1)) return state;

      const updatedItem = {
        ...state.editableItem,
        quantity: state.editableItem.quantity - 1,
      };

      return {
        ...state,
        editableItem: updatedItem,
      };
    }

    case LOG_OUT: {
      removeCartFromLocalStorage();

      return initialState;
    }

    case EMPTY_ORDERED_ITEMS: {
      return {
        ...state,
        orderedItems: [],
      };
    }

    default:
      return state;
  }
}

export default cart;
