import Aviator from "aviator";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import PropTypes from "prop-types";
import { equals, head, isEmpty, isNil, or, partial, reject } from "ramda";
import React from "react";

import AlertModal from "components/alert_modal";
import CreateAccount from "components/authentication/create_account";
import Login from "components/authentication/login";
import ResetPassword from "components/authentication/reset_password";
import SetPassword from "components/authentication/set_password";
import Icon from "components/icon";
import getBaseUri, { dineInQueryString } from "get_base_uri";
import { trackAnalytic, withTracking } from "helpers/analytics";

import handleFormErrors from "helpers/handle_form_errors";
import isLoggedIn from "is_logged_in";
import storage from "storage";

function AuthModal({
  authentication,
  consumer,
  locationName,
  onResetPassword,
  onSetPassword,
  onLogOut,
  onChangeModalType,
  onToggleModal,
  onUpdateField,
  onLogIn,
  onCreateAccount,
  onSocialLogIn,
  validatePasswordOnBlur,
  flags,
}) {
  const { email, emailToken, modalType, isShown } = authentication;

  let logInText = flags.uoloLoginButton ? (
    <>
      <button
        type="button"
        className="outlined-button"
        onClick={withTracking(
          partial(onToggleModal, [!isShown]),
          "Click Log In"
        )}
        aria-label="log in">
        <Icon type="user" />
        <span className="hide-on-mobile">Log in</span>
      </button>
    </>
  ) : (
    <div
      className="login"
      onClick={withTracking(
        partial(onToggleModal, [!isShown]),
        "Click Log In"
      )}>
      <Icon type="user" />
      <span className="text">Log in</span>
    </div>
  );

  if (isLoggedIn() && consumer) {
    const { firstName } = consumer.contactInfo;
    // eslint-disable-next-line
    const { name, email } = JSON.parse(storage.getItem("user"));
    const possibleUsernames = [
      firstName,
      consumer.contactInfo.email,
      name,
      email,
    ];
    // filter for the first valid name
    const username = head(reject(or(isEmpty, isNil), possibleUsernames));
    // ramda head([]) => undefined; in this case return empty string
    const usernameText = isNil(username) ? "" : `, ${username}`;
    const accountUri = `${getBaseUri()}/account${dineInQueryString()}`;
    logInText = flags.uoloLoginButton ? (
      <div className="login account-info">
        <div>
          <Icon type="user" className="hide-on-mobile" />
          <span className="hide-on-mobile">Welcome back{usernameText}!</span>
        </div>
        <span className="separator">|</span>
        <a
          onClick={() => {
            trackAnalytic("Click Account");
            Aviator.navigate(accountUri);
          }}
          className="hide-on-mobile">
          Your Profile
        </a>
        <span className="separator">|</span>
        <button
          type="button"
          className="outlined-button login"
          onClick={onLogOut}>
          Logout
        </button>
      </div>
    ) : (
      <div className="login">
        <span
          onClick={() => {
            trackAnalytic("Click Account");
            Aviator.navigate(accountUri);
          }}>
          <Icon type="user" />
          <span className="text">Welcome back{usernameText}!</span>
        </span>
        <span className="separator">|</span>
        <span
          onClick={() => {
            trackAnalytic("Click Account");
            Aviator.navigate(accountUri);
          }}
          className="text">
          Your Profile
        </span>
        <span className="separator">|</span>
        <span onClick={onLogOut}>Logout</span>
      </div>
    );
  }

  let modal;
  if (isShown && equals(modalType, "LOGIN")) {
    modal = (
      <Login
        authentication={authentication}
        onUpdateField={onUpdateField}
        onClose={partial(onToggleModal, [!isShown])}
        onChangeModalType={onChangeModalType}
        onLogIn={onLogIn}
        onSocialLogIn={onSocialLogIn}
        locationName={locationName}
      />
    );
  } else if (isShown && equals(modalType, "RESET_PASSWORD")) {
    modal = (
      <ResetPassword
        email={email}
        error={handleFormErrors("email", authentication)}
        onClose={partial(onToggleModal, [!isShown])}
        onUpdateField={onUpdateField}
        onEmailSubmit={onResetPassword}
      />
    );
  } else if (isShown && equals(modalType, "EMAIL_SENT")) {
    const message = [
      <div key="first-line">We've sent a password reset email to: {email}</div>,
      <div key="second-line">Please check your inbox.</div>,
    ];

    modal = (
      <AlertModal
        className="sent-modal"
        onConfirm={partial(onToggleModal, [!isShown])}
        title="Reset email sent"
        message={message}
        confirmButtonText="Got it"
      />
    );
  } else if (isShown && equals(modalType, "ACCOUNT_CREATED_EMAIL")) {
    const message = [
      <div key="first-line">We've sent an email to: {email}</div>,
      <div key="second-line">Please confirm your account.</div>,
    ];

    modal = (
      <AlertModal
        className="sent-modal"
        onConfirm={partial(onToggleModal, [!isShown])}
        title="Account created"
        message={message}
        confirmButtonText="Got it"
      />
    );
  } else if (isShown && equals(modalType, "SET_PASSWORD")) {
    modal = (
      <SetPassword
        emailToken={emailToken}
        onClose={partial(onToggleModal, [!isShown])}
        authentication={authentication}
        onSetPassword={onSetPassword}
        onUpdateField={onUpdateField}
        validatePasswordOnBlur={validatePasswordOnBlur}
      />
    );
  } else if (isShown && equals(modalType, "CREATE_ACCOUNT")) {
    modal = (
      <CreateAccount
        onClose={partial(onToggleModal, [!isShown])}
        authentication={authentication}
        onChangeModalType={onChangeModalType}
        onUpdateField={onUpdateField}
        onCreateAccount={onCreateAccount}
        locationName={locationName}
      />
    );
  }
  return (
    <div className="auth-modal">
      {logInText}
      {modal}
    </div>
  );
}

AuthModal.propTypes = {
  authentication: PropTypes.shape({
    modalType: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    newPassword: PropTypes.string,
    confirmPassword: PropTypes.string,
    emailToken: PropTypes.string,
    isShown: PropTypes.bool.isRequired,
  }),
  consumer: PropTypes.object,
  flags: PropTypes.object,
  locationName: PropTypes.string,
  onChangeModalType: PropTypes.func.isRequired,
  onCreateAccount: PropTypes.func.isRequired,
  onLogIn: PropTypes.func.isRequired,
  onLogOut: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired,
  onSetPassword: PropTypes.func.isRequired,
  onSocialLogIn: PropTypes.func.isRequired,
  onToggleModal: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  validatePasswordOnBlur: PropTypes.func.isRequired,
};

export default withLDConsumer()(AuthModal);
