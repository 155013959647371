import classNames from "classnames";
import PropTypes from "prop-types";
import { always, equals, head, map, prop } from "ramda";
import React from "react";

import Icon from "components/icon";
import PickerOption from "components/picker_option";

class Picker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
    this.toggleOptions = this.toggleOptions.bind(this);
    this.handleOptionClick = this.handleOptionClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  toggleOptions() {
    this.setState(current => ({ expanded: !current.expanded }));
  }

  handleOptionClick(...args) {
    const { onOptionClick } = this.props;
    this.setState({ expanded: false });
    if (onOptionClick) onOptionClick(...args);
  }

  handleClickOutside(event) {
    if (!this.node.contains(event.target)) this.setState({ expanded: false });
    else {
      document
        .querySelector("body")
        .removeEventListener("click", this.hideOptions);
    }
  }

  render() {
    const {
      OptionComponent,
      options,
      formatter,
      className,
      children,
      errorMessage,
    } = this.props;
    const { expanded } = this.state;

    const currentOption = this.props.currentOption || head(options);
    document
      .querySelector("body")
      .removeEventListener("click", this.handleClickOutside);

    let renderedOptions;
    if (expanded) {
      renderedOptions = map(option => {
        const classes = classNames({
          selected: equals(option, currentOption),
        });
        return (
          <OptionComponent
            key={formatter(option)}
            option={option}
            {...this.props}
            className={classes}
            onOptionClick={this.handleOptionClick}
          />
        );
      }, options);

      document
        .querySelector("body")
        .addEventListener("click", this.handleClickOutside);
    }

    let renderedCurrentOption;
    if (children) {
      renderedCurrentOption = children;
    } else {
      renderedCurrentOption = (
        <OptionComponent
          option={currentOption}
          {...this.props}
          onOptionClick={always()}
        />
      );
    }

    const classes = classNames({
      picker: true,
      [className]: true,
      expanded,
    });

    let renderedErrorMessage;
    if (errorMessage) {
      renderedErrorMessage = <span className="error-text">{errorMessage}</span>;
    }

    return (
      <div className={classes} ref={node => (this.node = node)}>
        <ul className="current" onClick={this.toggleOptions}>
          {renderedCurrentOption}
          <Icon type="chevron-down" />
        </ul>
        <ul className="options">{renderedOptions}</ul>
        {renderedErrorMessage}
      </div>
    );
  }
}

Picker.defaultProps = {
  OptionComponent: PickerOption,
  className: "",
  formatter: prop("name"),
};

Picker.propTypes = {
  OptionComponent: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  currentOption: PropTypes.any,
  className: PropTypes.string.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  formatter: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

export default Picker;
